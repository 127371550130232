import { push } from '@backmarket/front-noodle'

import { isNode } from '@core/helpers'

import * as DEFINITIONS from './definitions'

const pushNoodleEvent = ({ name, version }, data) => {
  // For now, Noodle does not support server side events
  if (isNode()) {
    return
  }

  push({ name, version, data })
}

export const context = (data) => {
  pushNoodleEvent(DEFINITIONS.CONTEXT, data)
}

export const pageView = ({ pageType }) => {
  const referrer = process.client ? document.referrer : ''
  const { host, hostname, href, pathname, hash, search } = window.location
  pushNoodleEvent(DEFINITIONS.PAGE_VIEW, {
    pageType,
    referrer,
    location: {
      host,
      hostname,
      href,
      pathname,
      hash,
      search,
    },
  })
}

export const resize = ({ breakpointName, breakpointRule }) => {
  pushNoodleEvent(DEFINITIONS.RESIZE, { breakpointName, breakpointRule })
}

export const click = ({ name, zone, value, position }) => {
  pushNoodleEvent(DEFINITIONS.CLICK, {
    name,
    zone,
    value,
    position,
  })
}

export const userContext = ({
  userId,
  clientId,
  emailHashed,
  merchantId,
  isStaff,
  lastOrderDate,
  numberOfOrders,
  lifetimeRevenue,
}) => {
  pushNoodleEvent(DEFINITIONS.USER_CONTEXT, {
    userId,
    clientId,
    emailHashed,
    merchantId,
    isStaff,
    lastOrderDate,
    numberOfOrders,
    lifetimeRevenue,
  })
}

export const webPerformance = (data) => {
  pushNoodleEvent(DEFINITIONS.WEB_PERFORMANCE, data)
}

export const modal = ({ status, name }) => {
  pushNoodleEvent(DEFINITIONS.MODAL, {
    status,
    name,
  })
}

export const searchAnalytics = ({
  type,
  objectId,
  searchQueryID,
  tokenId,
  position,
  index = '',
}) => {
  pushNoodleEvent(DEFINITIONS.SEARCH_ANALYTICS, {
    type,
    objectId: objectId.toString(),
    searchQueryID,
    tokenId,
    position,
    index,
  })
}

export const rumView = (data) => {
  pushNoodleEvent(DEFINITIONS.RUM_VIEW, data)
}

export const paymentFormDisplay = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentFormMethodSelect = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentFormSubmitStart = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentFormSubmitSuccess = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentFormSubmitError = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentResultSuccess = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentFormSubmitCancel = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}

export const paymentResultFailure = (data) => {
  pushNoodleEvent(DEFINITIONS.PAYMENT, data)
}
