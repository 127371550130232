import conversations from './modules/conversations'
import discussion from './modules/discussion'
import information from './modules/information'
import invoiceRequests from './modules/invoiceRequests'
import order from './modules/order'

export default {
  namespaced: true,
  modules: {
    conversations,
    discussion,
    information,
    invoiceRequests,
    order,
  },
}
