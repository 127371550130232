import globalLogger from '@logger'

// TODO: [SR-1923] Get percentage by the backend
export const getPercent = (price, reference) => {
  const number = parseInt(price, 10)
  const referencedNumber = parseInt(reference, 10)

  return number < referencedNumber
    ? Math.ceil(((number - referencedNumber) / referencedNumber) * 100)
    : null
}

export const logger = {
  info(err, { component: { name: componentName, props }, routeName }) {
    const owner = 'S&R'
    const level = 'Error'
    const message = `[${owner}][${level}][${componentName}]`
    const attributes = {
      componentName,
      props,
      routeName,
      details: {
        message: err?.message,
        stack: err?.stack,
      },
      err,
    }
    globalLogger.info(message, attributes)
  },
}

export const logLanguageMismatch = (link, locale, source) => {
  if (link && link.type !== 'external' && link.params?.locale !== locale) {
    globalLogger.info('mismatch locale', {
      link,
      locale,
      source,
    })
  }
}
