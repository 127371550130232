const NEW_AUTH_NAMESPACE = 'oauth2'

/**
 * Default options for the auth module.
 * @type {import('./types').OauthModuleOptions}
 */
export const AUTH_CONFIG = {
  clientId: '',
  domainBaseUrl: '',
  authorizationEndpoint: 'oauth2/auth',
  redirectPath: '/auth/login',
  logoutPath: '/auth/logout',
  storage: {
    prefix: `${NEW_AUTH_NAMESPACE}.`,
  },
}
