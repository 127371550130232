import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

const frontAppsB2CRoutes = [
  {
    name: ROUTES.FRONT_OFFICE.CUSTOMER_CONVERSATION.CARE_FOLDER,
    path: 'help-request/care-folder/:customerRequestId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.FRONT_OFFICE.CUSTOMER_CONVERSATION.RESOLUTION,
    path: 'help-request/:resolutionId',
    meta: { disableClientSideNavigation: true },
  },
]

export default ({ config, country }) => {
  // these routes are on the /dashboard/ so they need to be wrapped under the locale in the URL.
  // separated from ./routes.js to have them wrapped under the locale at once.
  return [
    // Customer request - SAV Discussion.
    {
      path: 'dashboard/customer-request/:customerRequestId',
      name: ROUTES.SAV_CONVERSATION,
      component: () =>
        dynamicImport(import('@sav-discussion/ConnectedDiscussion')),
      meta: {
        auth: {
          required: true,
        },
        ...insertIf(
          config.FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY.includes(country),
          {
            redirectToFrontApps: {
              name: ROUTES.FRONT_OFFICE.CUSTOMER_CONVERSATION.CARE_FOLDER,
            },
          },
        ),
      },
    },
    {
      path: 'dashboard/customer-request/resolutions/:resolutionId',
      name: ROUTES.ASP_RESOLUTION_CONVERSATION,
      component: () =>
        dynamicImport(import('@sav-discussion/ConnectedDiscussion')),
      meta: {
        auth: {
          required: true,
        },
        ...insertIf(
          config.FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY.includes(country),
          {
            redirectToFrontApps: {
              name: ROUTES.FRONT_OFFICE.CUSTOMER_CONVERSATION.RESOLUTION,
            },
          },
        ),
      },
    },
    ...frontAppsB2CRoutes,
  ]
}
