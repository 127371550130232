import { camelizeKeys, decamelizeKeys } from 'humps'

import { dangerouslyGetRuntimeConfig } from '@config/variables'

import API from './api'
import { FILE_TRANSFER_TIMEOUT } from './apiConstants'
import { HEADER_CONTENT_TYPE } from './headers'
import {
  mapToAddress,
  mapToClientAddress,
  mapToCreateBalanceAdjustment,
  mapToDiscount,
  mapToInternationalOnboarding,
} from './transformRequest'
import {
  mapFromAllCategories,
  mapFromBalanceAjustements,
  mapFromBuyingGuidesHub,
  mapFromDiscount,
  mapFromGetListings,
  mapFromInternationalOnboarding,
  mapFromKonbiniSettings,
  mapFromLandingDetails,
  mapFromPicsouConfig,
  mapFromUpdateListing,
  mapFromVatInformation,
  mapProductReviews as mapProductReviewsFromApi,
  mapRateDistributionFromApi,
  mapReviewsBreadcrumbFromApi,
  mapReviewsListFromApi,
  rewordSellerCompensationsResponse,
  transformListing,
  transformReasons,
} from './transformResponse'

const { FF_CREATE_SHIPMENT_MICROSERVICE_URL_ENABLED, TRANSLATIONS_PATH } =
  dangerouslyGetRuntimeConfig()

export const topSalesFetch = API.get({
  name: 'topSalesFetch',
  path: '/bm/navigation/v2/topsales',
})

export const navigationFetch = API.get({
  name: 'navigationFetch',
  path: '/bm/navigation/v2/sidebar',
})

export const skinnyBannersFetch = API.get({
  name: 'skinnyBannersFetch',
  path: '/bm/branding/skinny-banner',
})

export const recommendationCollection = API.get({
  name: 'recommendationCollection',
  path: '/bm/recommendation/v2/recommendations',
})

export const recommendationItem = API.get({
  name: 'recommendationItem',
  path: '/bm/recommendation/v2/recommendations/:widgetId',
})

export const saveCookiesSettings = API.post({
  name: 'saveCookiesSettings',
  path: '/bm/preferences/user/cookies',
})

/* TRANSLATIONS */

export const fetchTranslationsManifest = API.get({
  name: 'fetchTranslationsManifest',
  apiBaseUrl: TRANSLATIONS_PATH,
  path: '/manifest.json',
  withCsrf: false,
  withCredentials: false,
})

export const fetchTranslationsFiles = API.get({
  name: 'fetchTranslationsFiles',
  apiBaseUrl: TRANSLATIONS_PATH,
  path: '/:localePath',
  headers: {
    'Cache-Control': 'max-age=31536000',
  },
  withCsrf: false,
  withCredentials: false,
})

/* AUTHENTICATION */

export const register = API.post({
  name: 'register',
  path: '/bm/client/subscription/',
})

export const login = API.post({
  name: 'login',
  path: '/bm/client/login/',
})

export const logout = API.post({
  name: 'logout',
  path: '/bm/client/logout',
})

export const checkUserFromEmail = API.post({
  name: 'checkUserFromEmail',
  path: '/bm/client/check',
})

export const lostPassword = API.post({
  name: 'lostPassword',
  path: '/bm/lost-password',
})

export const resetPassword = API.post({
  name: 'resetPassword',
  path: '/bm/reset-password/:userId?',
})

export const isPasswordTokenValid = API.get({
  name: 'isPasswordTokenValid',
  path: '/bm/reset-password/:userId/check/:token',
})

export const fetchUserData = API.get({
  name: 'fetchUserData',
  path: '/bm/user/auth',
})

export const sendVerificationEmail = API.post({
  name: 'sendVerificationEmail',
  path: '/bm/client/verification-email/send',
})

export const merchantLogin = API.post({
  name: 'merchantLogin',
  path: '/bm/merchants/login',
})

export const sellerRegister = API.post({
  name: 'merchantLogin',
  path: '/bm/merchants/register',
})

export const getAuthGateway = API.get({
  name: 'getAuthGateway',
  path: '/auth/gateway',
  withCredentials: true,
})

/* CRM */

export const getUserCrmId = API.get({
  name: 'getUserCrmId',
  path: '/crm/customers/v1/data/id',
})

/* CMS */

export const getContentFromContentService = API.get({
  name: 'fetchContentFromContentService',
  path: '/bm/content/:pageType/:pageName',
})

export const getCategoryContentFromContentService = API.get({
  name: 'fetchCategoryContentFromContentService',
  path: '/bm/content/:pageType/:pageName/:pageCategory',
})

export const voteForArticle = API.post({
  name: 'voteForArticle',
  path: '/bm/branding/articles/:articleSlug/votes',
})

export const getArticleVotes = API.get({
  name: 'getArticlesVotes',
  path: '/bm/branding/articles/:articleSlug/votes',
})

/* HOMEPAGE */

export const navSeoFetch = API.get({
  name: 'navSeoFetch',
  path: '/bm/navigation/home',
})

/* SEARCHBAR */

export const searchPopular = API.get({
  name: 'searchPopular',
  path: '/bm/search/v2/popular',
})

/* LANDING */

export const sellerDetailsFetch = API.get({
  name: 'merchantDetailsFetch',
  path: '/bm/merchant/shop/:id',
  transformResponse: [mapFromLandingDetails],
})

export const landingDetailsFetch = API.get({
  name: 'landingDetailsFetch',
  path: '/bm/landing_page',
  transformResponse: [mapFromLandingDetails],
})

export const landingBreadcrumbFetch = API.get({
  name: 'landingBreadcrumbFetch',
  path: '/bm/landing_page/breadcrumb',
})

export const searchConfigurationByScope = API.get({
  name: 'searchConfiguration',
  path: 'bm/search/v2/configuration/:scope',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const searchConfigurationByScopeId = API.get({
  name: 'searchConfiguration',
  path: 'bm/search/v2/configuration/:scope/:id',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const searchApiKeys = API.get({
  name: 'searchApiKeys',
  path: 'bm/search/api-keys',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const landingNavigationSeoFetch = API.get({
  name: 'landingNavigationSeoFetch',
  path: '/bm/navigation/landing-page',
})

/* PRODUCT */

export const productDetailsV2Fetch = API.get({
  name: 'productDetailsV2Fetch',
  path: '/bm/product/v2/:id',
  defaultQueryParams: { include: 'checks,obsolescence,waterproof,accessories' },
})

export const productGradesFetchV3 = API.get({
  name: 'productGradesFetchV3',
  path: '/bm/product/:id/v3/best_offers',
})

export const productGradesDescriptionFetch = API.get({
  name: 'productGradesFetch',
  path: '/bm/product/:id/grade_descriptions',
})

export const productTechnicalSpecifications = API.get({
  name: 'productTechnicalSpecifications',
  path: '/bm/product/:id/technical_specifications',
})

export const productFrequentlyAskedQuestions = API.get({
  name: 'productFrequentlyAskedQuestions',
  path: '/bm/product/:id/listing/:listingId/faq',
})

export const productWarrantyServices = API.get({
  name: 'productWarrantyServices',
  path: '/bm/product/:id/listing/:listingId/warranty_services',
})

export const fetchSEOTags = API.get({
  name: 'fetchSEOTags',
  path: '/bm/tags',
})

export const productBreadcrumbFetch = API.get({
  name: 'productBreadcrumbFetch',
  path: '/bm/product/:id/breadcrumb',
})

export const productPickersFetch = API.get({
  name: 'productPickersFetch',
  path: '/bm/product/:id/pickers',
})

export const productAlertCreate = API.post({
  name: 'productAlertCreate',
  path: '/bm/alert/new',
})

export const insuranceOffersFetch = API.get({
  name: 'insuranceOffersFetch',
  path: '/bm/product/warranty/:id',
})

export const productExcludedAccessoriesFetch = API.get({
  name: 'productExcludedAccessoriesFetch',
  path: '/bm/product/:productId/accessory_exclusions',
})

export const productRenewedImpactModalFetch = API.get({
  name: 'productRenewedImpactModalFetch',
  path: '/bm/product/:productId/renewed-impact',
})

export const productGoodestFindsFetch = API.get({
  name: 'productGoodestFindsFetch',
  path: '/bm/product/:productId/goodest-finds',
})

export const productEcoBlockFetch = API.get({
  name: 'productEcoBlockFetch',
  path: '/bm/product/:productId/eco-block',
})

/* BUYING GUIDE HUBS */
export const buyingGuideHubFetch = API.get({
  name: 'buyingGuideHubFetch',
  path: '/bm/branding/buying-guide/hubs/:id',
  transformResponse: [mapFromBuyingGuidesHub, camelizeKeys],
})

export const buyingGuideHubFooterLinks = API.get({
  name: 'buyingGuideHubFooterLinks',
  path: '/bm/branding/buying-guide/hubs/:id/footer',
  transformResponse: [camelizeKeys],
})

export const buyingGuideRootHubFooterLinks = API.get({
  name: 'buyingGuideRootHubFooterLinks',
  path: '/bm/branding/buying-guide/hubs/footer',
  transformResponse: [camelizeKeys],
})

export const fetchLandingBuyingGuides = API.get({
  name: 'fetchLandingBuyingGuides',
  path: '/bm/branding/buying-guide/landing-pages/:landingId',
})

/* CART */

export const fetchCart = API.get({
  name: 'fetchCart',
  path: '/bm/cart',
})

export const addToCart = API.post({
  name: 'addToCart',
  path: '/bm/cart/add_product',
})

export const addProductsToCart = API.post({
  name: 'addProductsToCart',
  path: '/bm/cart/add_products',
})

export const updateQuantity = API.post({
  name: 'updateQuantity',
  path: '/bm/api/update_quantity',
})

export const updateOption = API.post({
  name: 'updateOption',
  path: '/bm/cart/update_option',
})

export const updateInsuranceOffer = API.post({
  name: 'updateInsuranceOffer',
  path: '/bm/cart/update_insurance_offer',
})

export const updateCartCollectionPoint = API.post({
  name: 'updateCartCollectionPoint',
  path: '/bm/cart/update-collection-point',
})

export const acceptAgreement = API.post({
  name: 'accept_agreement',
  path: '/bm/cart/accept_agreement',
})

export const fetchCartShippings = API.get({
  name: 'fetchCartShippings',
  path: '/bm/cart/shippings',
})

export const saveCartShippings = API.post({
  name: 'saveCartShippings',
  path: '/bm/cart/shippings',
})

export const updateCollectionPointCustomerDetails = API.post({
  name: 'updateCollectionPointCustomerDetails',
  path: '/bm/cart/update-collection-point-customer-details',
})

export const updateUserInformation = API.post({
  name: 'updateUserInformation',
  path: '/bm/cart/update-user-information',
})

/* CHECKOUT INSURANCE */

export const createInsuranceSession = API.post({
  name: 'createInsuranceSession',
  path: '/bm/checkout/shopping-session',
})

export const fetchInsurancePolicy = API.get({
  name: 'fetchInsurancePolicy',
  path: '/bm/checkout/shopping-session/:sessionId',
})

export const selectInsuranceOfferShoppingSession = API.post({
  name: 'select_insurance_offer_shopping_session',
  path: '/bm/checkout/shopping-session/:shoppingSessionId/select-insurance',
})

export const acceptAgreementShoppingSession = API.post({
  name: 'accept_agreement_shopping_session',
  path: '/bm/checkout/shopping-session/:shoppingSessionId/accept-agreement',
})

/* SWAP */

export const swapCreateOrder = API.post({
  name: 'swapCreateOrder',
  path: '/bm/sourcing/order/new',
})

/* INSTALLMENTS */

export const fetchInstallmentSimulation = API.get({
  name: 'fetchInstallmentSimulation',
  path: '/bm/loan-simulation',
  transformResponse: [camelizeKeys],
})

/* PAYMENT */

export const setClientAddress = API.post({
  name: 'setClientAddress',
  path: '/bm/client/address',
  transformRequest: [mapToClientAddress],
})

export const discountCheck = API.post({
  name: 'discountCheck',
  path: '/bm/promotion/check',
  transformRequest: [mapToDiscount],
  transformResponse: [mapFromDiscount],
})

export const fetchPaymentMethods = API.get({
  name: 'fetchPaymentMethods',
  path: '/payment/payment_methods',
  transformResponse: [camelizeKeys],
})

export const paymentCreate = API.post({
  name: 'paymentCreate',
  path: '/bm/payment/create',
  transformResponse: [camelizeKeys],
})

export const paymentApplePaySession = API.post({
  name: 'paymentApplePaySession',
  path: '/payment/applepay/session',
})

export const paymentConfirmCard = API.post({
  name: 'paymentConfirmCard',
  path: '/payment/confirm/card/:paymentId',
})

export const paymentConfirmHPP = API.post({
  name: 'paymentConfirmHPP',
  path: '/payment/confirm/hpp/:paymentId',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentAuthorize = API.post({
  name: 'paymentAuthorize',
  path: '/payment/authorise/:paymentId',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const paymentToken = API.get({
  name: 'paymentToken',
  path: '/payment/get-token',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentAuthorizeRecurring = API.post({
  name: 'paymentAuthorizeRecurring',
  path: '/payment/authorise_recurring/:paymentId',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentAuthorizeSepa = API.post({
  name: 'paymentAuthorizeSepa',
  path: '/payment/authorise_sepa/:paymentId',
})

export const updateAchPaymentMethod = API.post({
  name: 'updateAchPaymentMethod',
  path: '/payment/authorise_ach/:paymentId',
})

export const updateBacsPaymentMethod = API.post({
  name: 'updateBacsPaymentMethod',
  path: '/payment/authorise_bacs/:paymentId',
})

export const paymentBlankCreate = API.post({
  name: 'paymentBlankCreate',
  path: '/bm/payment/blank/create',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentBlankAuthorize = API.post({
  name: 'paymentBlankAuthorize',
  path: '/payment/blank-authorise/:paymentId',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const paymentResult = API.get({
  name: 'paymentResult',
  path: '/payment/payment_result/:paymentId',
})

export const paymentNextAction = API.get({
  name: 'paymentNextAction',
  path: '/payment/next-action/v1/:paymentId',
})

/* Refer a friend */

export const generatePromoCode = API.post({
  name: 'generatePromoCode',
  path: '/bm/client/referrals/referrer/generate',
})

export const getReferFriendInfos = API.get({
  name: 'getReferFriendInfos',
  path: '/bm/client/referrals/referrer',
})

/* Orders */

export const staffGetOrders = API.get({
  name: 'staffGetOrders',
  path: '/bm/orders/staff/orders/:id',
})

export const getOrderlines = API.get({
  name: 'getOrderlines',
  path: '/bm/orders/client/:id',
  defaultQueryParams: { page_size: 6 },
})

export const getExternalTransfers = API.get({
  name: 'getExternalTransfers',
  path: '/bm/client/:customerPublicId/moneyouts',
})

export const getOrderlineInfo = API.get({
  name: 'getOrderlineInfo',
  path: '/bm/orders/v1/orderline/:orderlineId',
})

export const getOrderlineWarranties = API.get({
  name: 'getOrderlineWarranties',
  path: '/bm/orders/v1/orderline/:orderlineId/coverage',
})

export const cancelOrder = API.post({
  name: 'cancelOrder',
  path: '/bm/orders/v1/orderline/:orderlineId/cancel',
})

export const getCancelReasons = API.get({
  name: 'getCancelReasons',
  path: '/bm/orders/v1/orderline/:orderlineId/cancel',
})

export const getOrderlineDetails = API.get({
  name: 'getOrderlineDetails',
  path: '/bm/orders/v1/orderline/:orderlineId',
})

/* My Orders API */

/*
 * These endpoints are targeting the My orders **microservice**.
 * A server side call from Pastrami won't go through Doorman, which is unfortunate because
 * it is responsible for hydrating the call with the needed *bm-user-id* header.
 * As a temporary fix we're forcing Pastrami to go through Doorman
 * for server side calls only (see also Help Center endpoints)
 */
export const myOrdersGetOrderlineDetails = API.get({
  name: 'getOrderlineDetails',
  path: '/my-orders/orderlines/:orderlineId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Petanque orders */

export const getPetanqueOrderLinesClient = API.get({
  name: 'getPetanqueOrderLinesClient',
  path: '/bm/petanque/orderlines',
})

/* My Favorites */

/*
 * FIXME
 * These endpoints are targeting the My Favorites **microservice**.
 * A server side call from Pastrami won't go through Doorman, which is unfortunate because
 * it is responsible for hydrating the call with the needed *bm-user-id* header.
 * As a temporary fix we're forcing Pastrami to go through Doorman
 * for server side calls only (see also Help Center endpoints)
 */

export const getUserFavorites = API.get({
  name: 'getUserFavorites',
  path: '/my-favorites/favorites',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const createFavoriteForUser = API.post({
  name: 'createFavoriteForUser',
  path: '/my-favorites/favorites',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const deleteUserFavorite = API.delete({
  name: 'deleteUserFavorite',
  path: '/my-favorites/favorites/:favoriteId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getFavoritesFromProduct = API.get({
  name: 'getFavoritesFromProduct',
  path: '/my-favorites/favorites/products/:productUuid',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Help Center */

export const getHelpCenterCustomerResolutions = API.get({
  name: 'getHelpCenterCustomerResolutions',
  path: '/bm/customer/resolutions',
})

export const getHelpCenterFAQCategories = API.get({
  name: 'getHelpCenterFAQCategories',
  path: '/help-center/api/v1/categories',
})

export const getHelpCenterFAQArticles = API.get({
  name: 'getHelpCenterFAQArticles',
  path: '/help-center/api/v1/articles/search',
})

/* Insurances */

export const withdrawInsurance = API.post({
  name: 'withdrawInsurance',
  path: '/bm/insurances/v1/policies/:policyId/termination',
})

export const getInsuranceTermination = API.get({
  name: 'getInsuranceTermination',
  path: '/bm/insurances/v2/policies/:policyId/termination',
})

export const terminateInsurance = API.post({
  name: 'getInsuranceTermination',
  path: '/bm/insurances/v2/policies/:policyId/termination',
})

/* Profile */

export const getClientProfile = API.get({
  name: 'getClientProfile',
  path: '/bm/client/profile',
})

export const getClientShippingAddress = API.get({
  name: 'getClientShippingAddress',
  path: '/bm/client/addresses/shipping',
})

export const getClientBillingAddress = API.get({
  name: 'getClientBillingAddress',
  path: '/bm/client/addresses/billing',
})

export const getBills = API.get({
  name: 'getBills',
  path: '/bm/orderline/:orderlineId/insurance_bill/:fileType',
})

export const getClientSsn = API.get({
  name: 'getClientBankDetails',
  path: '/bm/client/ssn',
})

export const setClientSsn = API.put({
  name: 'getClientBankDetails',
  path: '/bm/client/ssn',
})

export const askBill = API.post({
  name: 'askBill',
  path: '/bm/client/:id/orders/askbill',
})

export const getClientIdentityDocuments = API.get({
  name: 'getClientIdentityDocuments',
  path: '/bm/client/identity_documents',
})

export const getClientBankDetails = API.get({
  name: 'getClientBankDetails',
  path: '/bm/client/bank_details',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getNewsletterPreferences = API.get({
  name: 'getNewsletterPreferences',
  path: '/crm/preferences/v1/newsletters',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const updateClientInfo = API.put({
  name: 'updateClientInfo',
  path: '/bm/client/profile',
})

export const updateClientPassword = API.put({
  name: 'updateClientPassword',
  path: '/bm/client/update_password',
})

export const setIdentityDocuments = API.post({
  name: 'setIdentityDocuments',
  path: '/bm/client/identity_documents',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const setClientShippingAddress = API.post({
  name: 'setClientShippingAddress',
  path: '/bm/client/addresses/shipping',
})

export const setClientBillingAddress = API.post({
  name: 'setClientBillingAddress',
  path: '/bm/client/addresses/billing',
})

export const deleteIdentityDocuments = API.delete({
  name: 'deleteIdentityDocuments',
  path: '/bm/client/identity_documents',
})

export const setClientSourcingAddress = API.post({
  name: 'setClientSourcingAddress',
  path: '/bm/buyback/v1/customer/address',
  transformRequest: [mapToAddress],
})

export const getClientSourcingAddress = API.get({
  name: 'getClientSourcingAddress',
  path: '/bm/buyback/v1/customer/address',
})

export const setBankDetailsDocument = API.put({
  name: 'setBankDetailsDocument',
  path: '/bm/client/bank_details',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const deleteBankDetailsDocument = API.delete({
  name: 'deleteBankDetailsDocument',
  path: '/bm/client/bank_details',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const setNewsletterPreferences = API.patch({
  name: 'setNewsletterPreferences',
  path: '/crm/preferences/v1/newsletters/optin',
})

export const saveIbanBic = API.post({
  name: 'saveIbanBic',
  path: '/bm/client/wallet',
})

/* Address autocomplete */

export const getAddressAutocomplete = API.get({
  name: 'GetAddressAutocomplete',
  path: '/bm/shipping/v1/address-autocomplete',
})

export const getAddressDetails = API.get({
  name: 'GetAddressDetails',
  path: '/bm/shipping/v1/address-details',
})

/* Address form validation */

export const getAddressFormValidationConfig = API.get({
  name: 'getAddressFormValidationConfig',
  path: '/bm/shipping/v1/address-validation-rules',
})

/* Customer Request (SAV) Platform (merchant/admin/backcare side) */

export const fetchCustomerRequestPlatformConversations = API.get({
  name: 'fetchCustomerRequestPlatformConversations',
  path: '/bm/customer_request/:role/claims/:context',
})

export const closeCustomerRequestPlatformConversation = API.post({
  name: 'closeCustomerRequestPlatformConversation',
  path: '/bm/customer_request/:customerRequestId/:role/close',
})

export const fetchCustomerRequestPlatformAllowedActions = API.get({
  name: 'fetchCustomerRequestPlatformAllowedActions',
  path: '/bm/customer_request/:customerRequestId/:role/actions',
})

export const fetchSellerAllowedActions = API.get({
  name: 'fetchSellerAllowedActions',
  path: '/bm/customer_request/:customerRequestId/merchant/actions',
})

export const fetchCustomerRequestPlatformRemainingHours = API.get({
  name: 'fetchCustomerRequestPlatformRemainingHours',
  path: '/bm/customer_request/:customerRequestId/remaining_hours',
})

export const openCustomerRequestPlatformConversation = API.post({
  name: 'openCustomerRequestPlatformConversation',
  path: '/bm/customer_request/:customerRequestId/:role/open',
})

export const openCustomerRequestSeller = API.post({
  name: 'openCustomerRequestSeller',
  path: '/bm/customer_request/:customerRequestId/merchant/open',
})

export const fetchCustomerRequestPlatformCarriers = API.get({
  name: 'fetchCustomerRequestPlatformCarriers',
  path: '/bm/shippers',
  defaultQueryParams: { trackable: true },
})

export const refundCareFolder = API.post({
  name: 'refundCareFolder',
  path: '/bm/customer_request/:careFolderId/refund',
})

export const returnProductCustomerRequestPlatform = API.post({
  name: 'returnProductCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/product_return',
})

export const returnManualProductCustomerRequestPlatform = API.post({
  name: 'returnManualProductCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/:role/product_returns/:type',
})

export const returnBackCustomerRequestPlatform = API.post({
  name: 'returnBackCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/merchant/product_returns/manual',
})

export const fetchPendingProductReturnCustomerRequestPlatform = API.get({
  name: 'fetchPendingProductReturnCustomerRequestPlatform',
  path: '/bm/customer_request/:role/product_returns/pending',
})

export const fetchProductReturnsCustomerRequestPlatform = API.get({
  name: 'fetchProductReturnsCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/product_returns',
})

/* Customer Request (SAV) Platform (merchant side) */

export const fetchCustomerRequestPlatformInvoiceRequests = API.get({
  name: 'fetchCustomerRequestPlatformInvoiceRequests',
  path: '/bm/customer_request/merchant/invoice_requests',
})

export const provideCustomerRequestPlatformInvoiceV2 = API.post({
  name: 'provideCustomerRequestPlatformInvoiceV2',
  path: '/bm/customer_request/orders/:orderId/merchant/invoice-v2',
})

export const fetchCustomerRequestPlatformCounts = API.get({
  name: 'fetchCustomerRequestPlatformCounts',
  path: '/bm/customer_request/merchant/counts',
})

export const fetchCustomerRequestPlatformMerchant = API.get({
  name: 'fetchCustomerRequestPlatformMerchant',
  path: '/bm/customer_request/merchant/information',
})

/* Customer Request (SAV) Platform (admin/backcare side) */

export const fetchCustomerRequestPlatformMerchantInformation = API.get({
  name: 'fetchCustomerRequestPlatformMerchantInformation',
  path: '/bm/customer_request/:customerRequestId/information/merchant',
})

export const postCustomerRequestPlatformCustomerMessagesVisibility = API.post({
  name: 'postCustomerRequestPlatformCustomerMessagesVisibility',
  path: '/bm/customer_request/:customerRequestId/:role/actions/toggle-customer-messages-visibility',
})

export const updateCustomerRequestProblem = API.post({
  name: 'updateCustomerRequestProblem',
  path: 'bm/customer_request/problem/:id',
})

/* Customer Request (SAV) Discussion (merchant/admin/backcare/client side) */

export const fetchDiscussionCustomerRequestDiscussion = API.get({
  name: 'fetchDiscussionCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/:role/discussion',
})

export const postMessageCustomerRequestDiscussion = API.post({
  name: 'postMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/:role/message',
})

export const postMessageCustomer = API.post({
  name: 'postMessageCustomer',
  path: '/bm/customer_request/:customerRequestId/customer/message',
})

export const postSellerMessageCustomerRequestDiscussion = API.post({
  name: 'postSellerMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/merchant/message',
})

export const postCustomerRequestProblem = API.post({
  name: 'postCustomerRequestProblem',
  path: '/bm/customer_request/:customerRequestId/merchant/problem',
})

export const postManualProductReturn = API.post({
  name: 'postManualProductReturn',
  path: '/bm/customer_request/:careFolderId/manual-product-returns',
})

export const fetchInformationCustomerRequestDiscussion = API.get({
  name: 'fetchInformationCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/information',
})

export const fetchInformationResolutionDiscussion = API.get({
  name: 'fetchInformationResolutionDiscussion',
  path: '/bm/customer_request/resolutions/:resolutionId/information',
})

export const fetchActionsCustomerRequestDiscussion = API.get({
  name: 'fetchActionsCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer/actions',
})

export const fetchCareFolder = API.get({
  name: 'fetchCareFolder',
  path: '/bm/care-folders/:careFolderId',
})

export const fetchResolutionFlow = API.get({
  name: 'fetchResolutionFlow',
  path: '/bm/diagnosis/v1/diagnosis',
})

export const getAutoPrepaidLabelShipment = API.post({
  name: 'getAutoPrepaidLabelShipment',
  path: '/bm/customer_request/shipments',
})

export const fetchOrderlineTimeline = API.get({
  name: 'fetchOrderlineTimeline',
  path: '/my-orders/orderlines/:orderlineId/timeline',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const postTerminateLiveChat = API.post({
  name: 'postTerminateLiveChat',
  path: '/bm/care-folders/:careFolderId/live-chat/terminate',
})

/* Customer Request Claim Creation (client side) */

export const fetchLiveChat = API.get({
  name: 'fetchLiveChat',
  path: '/bm/care-folders/:careFolderPublicId/live-chat',
})

export const createCustomerRequestClaim = API.post({
  name: 'createCustomerRequestClaim',
  path: '/bm/customer_request/orderline/:orderlineId/customer_requests',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

/* Customer Request Claim Summary */

export const fetchCustomerClaimSummary = API.get({
  name: 'fetchCustomerClaimSummary',
  path: '/bm/customer_request/:customerRequestId/claims/summary',
})

/* Customer Request (SAV) Discussion (client side) */

export const rateMessageCustomerRequestDiscussion = API.patch({
  name: 'rateMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/message/:messageId/rate',
})

export const fetchNotificationStatusCustomerRequestDiscussion = API.get({
  name: 'fetchNotificationStatusCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer_notification',
})

export const updateNotificationStatusCustomerRequestDiscussion = API.put({
  name: 'updateNotificationStatusCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer_notification',
})

/* Customer Request (SAV) Discussion (admin/backcare side) */

export const deleteMessageCustomerRequestDiscussion = API.delete({
  name: 'deleteMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/message/:messageId',
})

export const shareAttachmentsWithSeller = API.post({
  name: 'shareAttachmentsWithSeller',
  path: '/bm/customer_request/:customerRequestId/actions/share-attachments',
})

/* Template service */

export const getTemplateCategories = API.get({
  name: 'getTemplateCategories',
  path: '/agent-after-sales/v1/template-categories',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getTemplateCategoriesWithTemplates = API.get({
  name: 'getTemplateCategoriesWithTemplates',
  path: '/agent-after-sales/v1/template-categories?include=templates',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const postTemplateCategory = API.post({
  name: 'postTemplateCategory',
  path: '/agent-after-sales/v1/template-categories',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const patchTemplateCategory = API.patch({
  name: 'patchTemplateCategory',
  path: '/agent-after-sales/v1/template-categories/:categoryId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const deleteTemplateCategory = API.delete({
  name: 'deleteTemplateCategory',
  path: '/agent-after-sales/v1/template-categories/:categoryId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getTemplates = API.get({
  name: 'getTemplates',
  path: '/agent-after-sales/v1/templates',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const postTemplates = API.post({
  name: 'postTemplates',
  path: '/agent-after-sales/v1/templates',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const patchTemplate = API.patch({
  name: 'patchTemplate',
  path: '/agent-after-sales/v1/templates/:templateId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const deleteTemplate = API.delete({
  name: 'deleteTemplate',
  path: '/agent-after-sales/v1/templates/:templateId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getFilledTemplate = API.get({
  name: 'getFilledTemplate',
  path: '/agent-after-sales/v1/filled-templates/:templateId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Seller Customer Care */

export const getSellerRequests = API.get({
  name: 'getSellerRequests',
  path: 'seller-after-sales/api/v1/requests',
  apiBaseUrl: process.env.DOORMAN_SERVICE_NAME
    ? process.env.DOORMAN_SERVICE_NAME
    : '',
})

export const getNextTask = API.get({
  name: 'getNextTask',
  path: 'seller-after-sales/api/v1/tasks/:taskId/next',
  apiBaseUrl: process.env.DOORMAN_SERVICE_NAME
    ? process.env.DOORMAN_SERVICE_NAME
    : '',
})

export const getOrderlineRequests = API.get({
  name: 'getOrderlineRequests',
  path: 'seller-after-sales/api/v1/orderlines/:orderlineId/requests',
  apiBaseUrl: process.env.DOORMAN_SERVICE_NAME
    ? process.env.DOORMAN_SERVICE_NAME
    : '',
})

/* Diagnosis */

/*
 * FIXME
 * That endpoint targets the Help Center **microservice**,
 * which unfortunately can't be reached on server side.
 * The reason is that Pastrami's request SDK is configured to only target Badoom
 * (cf: https://github.com/BackMarket/front-config/blob/5d8c38a0c7e6ef7e18dc3a7b44241d2cae48bb6d/prod/pastrami-config-values_override.yaml#L15).
 * So, as a temporary fix, we use the a dedicated variable **HELP_CENTER_SERVICE_URL** (set in front-config)
 * for the server API URL and we keep the current client API URL.
 * With this work around, we are able to redirect the request to the proper microservice.
 */

export const fetchIssues = API.get({
  name: 'fetchIssues',
  path: '/help-center/api/v1/auth/diagnosis/graph/nodes',
  apiBaseUrl:
    process.server && process.env.HELP_CENTER_SERVICE_URL
      ? process.env.HELP_CENTER_SERVICE_URL
      : '',
})

export const fetchSelfTroubleshootingArticles = API.get({
  name: 'fetchSelfTroubleshootingArticles',
  path: '/help-center/api/v1/auth/articles/self-troubleshooting',
  apiBaseUrl:
    process.server && process.env.HELP_CENTER_SERVICE_URL
      ? process.env.HELP_CENTER_SERVICE_URL
      : '',
})

export const getCustomerIssuesAction = API.get({
  name: 'getCustomerIssuesAction',
  path: '/help-center/api/v1/auth/diagnosis/problem',
  apiBaseUrl:
    process.server && process.env.HELP_CENTER_SERVICE_URL
      ? process.env.HELP_CENTER_SERVICE_URL
      : '',
})

/* Resolution Engine */

export const fetchResolutionOptions = API.post({
  name: 'fetchResolutionOptions',
  path: '/bm/resolution-engine/v1/resolution-options',
})

export const fetchResolution = API.get({
  name: 'fetchResolutions',
  path: '/bm/resolution-engine/v1/resolutions/:resolutionId',
})

export const fetchResolutions = API.get({
  name: 'fetchResolutions',
  path: '/bm/resolution-engine/v1/resolutions',
})

export const createShipment = API.post({
  name: 'createShipment',
  path: FF_CREATE_SHIPMENT_MICROSERVICE_URL_ENABLED
    ? '/resolution-engine/shipments'
    : '/bm/resolution-engine/v1/shipments',
})

export const createResolution = API.post({
  name: 'createResolution',
  path: '/bm/resolution-engine/v1/resolutions',
})

/* REVIEWS */

// Breadcrumb

export const fetchBackmarketReviewsBreadcrumb = API.get({
  name: 'fetchBackmarketReviewsBreadcrumb',
  path: '/bm/reviews/breadcrumb',
  transformResponse: [mapReviewsBreadcrumbFromApi],
})

export const fetchLandingReviewsBreadcrumb = API.get({
  name: 'fetchLandingReviewsBreadcrumb',
  path: '/bm/reviews/landing_page/breadcrumb',
  transformResponse: [mapReviewsBreadcrumbFromApi],
})

export const fetchMerchantReviewsBreadcrumb = API.get({
  name: 'fetchMerchantReviewsBreadcrumb',
  path: '/bm/reviews/merchant/breadcrumb',
  transformResponse: [mapReviewsBreadcrumbFromApi],
})

export const fetchReviewsBreadcrumbByProduct = API.get({
  name: 'fetchReviewsBreadcrumbByProduct',
  path: '/bm/reviews/v2/product/:productId/breadcrumb',
  transformResponse: [mapReviewsBreadcrumbFromApi],
})

// Rate

export const fetchBackmarketRateReviews = API.get({
  name: 'fetchBackmarketRateReviews',
  path: '/bm/reviews/rate',
})

export const fetchLandingRateReviews = API.get({
  name: 'fetchLandingRateReviews',
  path: '/bm/reviews/landing_page/rate',
})

export const fetchMerchantRateReviews = API.get({
  name: 'fetchMerchantRateReviews',
  path: '/bm/reviews/merchant/rate',
})

export const fetchReviewsRateByProduct = API.get({
  name: 'fetchReviewsRateByProduct',
  path: '/bm/reviews/v2/product/:productId/rate',
})

// Rate distribution

export const fetchBackmarketRateDistribution = API.get({
  name: 'fetchBackmarketRateDistribution',
  path: '/bm/reviews/rate_distribution',
  transformResponse: [camelizeKeys, mapRateDistributionFromApi],
})

export const fetchLandingRateDistribution = API.get({
  name: 'fetchLandingRateDistribution',
  path: '/bm/reviews/landing_page/rate_distribution',
  transformResponse: [camelizeKeys, mapRateDistributionFromApi],
})

export const fetchMerchantRateDistribution = API.get({
  name: 'fetchMerchantRateDistribution',
  path: '/bm/reviews/merchant/rate_distribution',
  transformResponse: [camelizeKeys, mapRateDistributionFromApi],
})

export const fetchProductRateDistribution = API.get({
  name: 'fetchProductRateDistribution',
  path: '/bm/reviews/v2/product/:productId/rate_distribution',
})

// Reviews list

export const fetchBackmarketReviewsList = API.get({
  name: 'fetchBackmarketReviewsList',
  path: '/bm/reviews/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchLandingReviewsList = API.get({
  name: 'fetchLandingReviewsList',
  path: '/bm/reviews/landing_page/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchMerchantReviewsListInBo = API.get({
  name: 'fetchMerchantReviewsList',
  path: 'bm/merchants/reviews',
})

export const fetchMerchantReviewsList = API.get({
  name: 'fetchMerchantReviewsList',
  path: '/bm/reviews/merchant/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchReviewsListByProduct = API.get({
  name: 'fetchReviewsListByProduct',
  path: '/bm/reviews/v2/product/:productId/list',
  transformResponse: [mapReviewsListFromApi],
})

// Rating categories summary

export const fetchRatingCategoriesByProduct = API.get({
  name: 'fetchRatingCategoriesByProduct',
  path: '/bm/reviews/v2/product/:productId/meta-summary',
})

// Translated review

export const fetchTranslatedReview = API.get({
  name: 'fetchBackmarketReviewsList',
  path: '/bm/reviews/:reviewId',
})

/* Review form */

export const fetchProductReviews = API.get({
  name: 'fetchProductReviews',
  path: '/bm/orderline/:productId/review',
  transformResponse: [mapProductReviewsFromApi],
})

export const submitProductReview = API.post({
  name: 'submitProductReview',
  path: '/bm/orderline/:productId/review',
})

/* Buyback */

export const getBuybackOffer = API.get({
  name: 'getBuybackOffer',
  path: '/bm/sourcing/offer/:sourcingListingId',
})

export const getBuybackShipping = API.get({
  name: 'getBuybackShipping',
  path: '/bm/sourcing/shipping_modes/:sourcingListingId',
})

export const getConfirmation = API.get({
  name: 'getConfirmation',
  path: '/bm/sourcing/order/:id/confirmation',
})

export const getCustomerSales = API.get({
  name: 'getCustomerSales',
  path: '/bm/buyback/v1/customer/orders',
})

export const getCustomerSaleDetails = API.get({
  name: 'getCustomerSaleDetails',
  path: '/bm/buyback/v1/customer/orders/:id',
})

export const getCounterOfferDetails = API.get({
  name: 'getCounterOfferDetails',
  path: 'bm/buyback/v1/customer/orders/:id/counter-offer/pending',
})

export const setCounterOfferResponse = API.put({
  name: 'respondToCounterOffer',
  path: 'bm/buyback/v1/customer/orders/:id/counter-offer/pending',
})

export const getCustomerBirthdate = API.get({
  name: 'getCustomerBirthdate',
  path: '/buyback/v1/customer/orders/:id/birthdate',
})

export const setCustomerBirthdate = API.put({
  name: 'setCustomerBirthdate',
  path: '/buyback/v1/customer/orders/:id/birthdate',
})

export const emailPrepaidLabel = API.post({
  name: 'emailPrepaidLabel',
  path: '/bm/buyback/v1/customer/orders/:id/shipping',
})

export const getCustomerBuybackOrderDetails = API.get({
  name: 'getCustomerBuybackOrderDetails',
  path: '/bm/buyback/v1/refurbisher/orders/:id',
})

export const validateOrderPayment = API.put({
  name: 'setCustomerOrderValidated',
  path: '/bm/buyback/v1/refurbisher/orders/:id/validate-order',
})

export const getRefurbisherCounterOfferDetails = API.get({
  name: 'getRefurbisherCounterOfferDetails',
  path: 'bm/buyback/v1/refurbisher/orders/:id/counter-offer/last',
})

export const getBuybackOrderOnHoldActions = API.get({
  name: 'getBuybackOrderOnHoldActions',
  path: '/bm/buyback/v1/refurbisher/orders/:id/on-hold-actions',
})

export const getBuyBackOrderCounterOfferReasons = API.get({
  name: 'getBuyBackOrderCounterOfferReasons',
  path: '/bm/buyback/v1/refurbisher/orders/:id/counter-offers/reasons',
})

export const getBuybackOrderSuspendReasons = API.get({
  name: 'getBuybackOrderSuspendReasons',
  path: '/bm/buyback/v1/refurbisher/suspensions/:suspendCategory/reasons',
})

export const postBuybackOrderSuspendReasons = API.put({
  name: 'postBuybackOrderSuspendReasons',
  path: '/bm/buyback/v1/refurbisher/orders/:id/suspensions',
})

export const setBuyBackOrderCounterOffer = API.put({
  name: 'setBuyBackOrderCounterOffer',
  path: '/bm/buyback/v1/refurbisher/orders/:id/counter-offers',
})

export const getRefurbisherOrderMessages = API.get({
  name: 'getRefurbisherOrderMessages',
  path: '/bm/buyback/v1/refurbisher/orders/:id/messages',
})

export const getCustomerOrderMessages = API.get({
  name: 'getCustomerOrderMessages',
  path: '/bm/buyback/v1/customer/orders/:id/messages',
})

export const getRefurbisherOrderMetadata = API.get({
  name: 'getRefurbisherOrderMetadata',
  path: '/bm/buyback/v1/refurbisher/orders/:id/messages/metadata',
})

export const getCustomerOrderMetadata = API.get({
  name: 'getCustomerOrderMetadata',
  path: '/bm/buyback/v1/customer/orders/:id/messages/metadata',
})

export const postRefurbisherOrderMessage = API.post({
  name: 'postRefurbisherOrderMessage',
  path: '/bm/buyback/v1/refurbisher/orders/:id/messages',
})

export const postCustomerOrderMessage = API.post({
  name: 'postCustomerOrderMessage',
  path: '/bm/buyback/v1/customer/orders/:id/messages',
})

export const getBuyBackProducts = API.get({
  name: 'getBuyBackProducts',
  path: '/bm/catalog/buyback/bo-seller/products/search',
})

export const getRefurbisherQuotations = API.get({
  name: 'getRefurbisherQuotations',
  path: '/bm/buyback/v1/refurbisher/quotations',
})

export const updateRefurbisherQuotation = API.put({
  name: 'updateRefurbisherQuotation',
  path: '/bm/buyback/v1/refurbisher/quotations',
})

export const getRefurbisherCsvFields = API.get({
  name: 'getRefurbisherCsvFields',
  path: 'bm/catalog/buyback/listings/import/metadata',
})

export const postRefurbisherCsvFile = API.post({
  name: 'postRefurbisherCsvFile',
  path: '/bm/catalog/buyback/listings/import',
})

export const postRequestRefurbisherCsvFile = API.post({
  name: 'postRequestRefurbisherCsvFile',
  path: '/bm/catalog/buyback/listings/export',
})

export const getRefurbisherProductInfo = API.get({
  name: 'getRefurbisherProductInfo',
  path: '/bm/buyback/v1/refurbisher/products/:productPublicId',
})

export const getRefurbisherProductQuotations = API.get({
  name: 'getRefurbisherProductQuotations',
  path: '/bm/buyback/v1/refurbisher/products/:productPublicId/markets/:countryCode/listings',
})

export const getBuybackStaffOrder = API.get({
  name: 'getBuybackStaffOrder',
  path: '/bm/buyback/v1/staff/orders/:id',
})

export const getStaffOrderMessages = API.get({
  name: 'getStaffOrderMessages',
  path: '/bm/buyback/v1/staff/orders/:id/messages',
})

export const getStaffOrderMetadata = API.get({
  name: 'getStaffOrderMetadata',
  path: '/bm/buyback/v1/staff/orders/:id/messages/metadata',
})

export const postStaffOrderMessage = API.post({
  name: 'postStaffOrderMessage',
  path: '/bm/buyback/v1/staff/orders/:id/messages',
})

export const getBuybackStaffOrderCounterOffer = API.get({
  name: 'getBuybackStaffOrderCounterOffer',
  path: '/bm/buyback/v1/staff/orders/:id/counter-offer/last',
})

export const postBuybackStaffShipmentLabelCreation = API.post({
  name: 'postBuybackStaffShipmentLabelCreation',
  path: '/bm/buyback/v1/staff/orders/:id/shipment',
})

export const putBuybackStaffUpdateCustomerInfo = API.put({
  name: 'putBuybackStaffUpdateCustomerInfo',
  path: '/bm/buyback/v1/staff/addresses/:addressPublicId',
})

export const getStaffCustomerIdDocuments = API.get({
  name: 'getStaffCustomerIdDocuments',
  path: '/bm/client/:clientPublicId/identity-documents',
})

export const getStaffCustomerBankDetails = API.get({
  name: 'getStaffCustomerBankDetails',
  path: '/bm/client/:clientPublicId/bank-details',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const deleteStaffCustomerBankDetails = API.delete({
  name: 'deleteStaffCustomerBankDetails',
  path: '/bm/client/:clientPublicId/bank-details',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getStaffCustomerSsnDetails = API.get({
  name: 'getStaffCustomerSsnDetails',
  path: '/payout/customer/:clientPublicId/identity-number',
})

export const postStaffCustomerIdDocuments = API.post({
  name: 'postStaffCustomerIdDocuments',
  path: '/bm/client/:clientPublicId/identity-documents',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
})

export const deleteStaffCustomerSsnDetails = API.delete({
  name: 'deleteCustomerSocialSecurityNumberByClientPublicId',
  path: '/bm/client/:clientPublicId/ssn',
})

export const deleteStaffCustomerIdDocuments = API.delete({
  name: 'deleteClientIdentityDocumentsByClientPublicId',
  path: '/bm/client/:clientPublicId/identity-documents',
})

export const putStaffCustomerBankDetails = API.put({
  name: 'putStaffCustomerBankDetails',
  path: '/bm/client/:clientPublicId/bank-details',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const putStaffCustomerSsnDetails = API.put({
  name: 'putStaffCustomerSsnDetails',
  path: '/bm/client/:clientPublicId/ssn',
})

export const putStaffCustomerIdNumberDetails = API.post({
  name: 'putStaffCustomerIdNumberDetails',
  path: '/payout/customer/:clientPublicId/identity-number',
})

export const deleteStaffCustomerIdNumberDetails = API.delete({
  name: 'deleteStaffCustomerIdNumberDetails',
  path: '/payout/customer/:clientPublicId/identity-number',
})

export const putStaffOrderValidation = API.put({
  name: 'putStaffOrderValidation',
  path: '/bm/buyback/v1/staff/orders/:id/validation',
})

export const putStaffOrderState = API.put({
  name: 'putStaffOrderState',
  path: 'bm/buyback/v1/staff/orders/:id/state',
})

export const putAutoPayoutEnabledState = API.put({
  name: 'putPayoutValidationState',
  path: 'payout/customer/:clientPublicId',
})

export const getStaffOrderCancellationReasons = API.get({
  name: 'getStaffOrderCancellationReasons',
  path: '/bm/buyback/v1/staff/orders/:id/cancellations/reasons',
})

export const putStaffCancelBuybackOrder = API.put({
  name: 'putStaffCancelBuybackOrder',
  path: '/bm/buyback/v1/staff/orders/:id/cancellations',
})

/* Merchant */

export const fetchMerchantData = API.get({
  name: 'fetchMerchantData',
  path: '/bm/merchant',
  transformResponse: [camelizeKeys],
})

/* Hyperwallet */

export const getAccessToken = API.get({
  name: 'getAccessToken',
  path: '/bm/merchants/hyperwallet/generate-access-token',
})

export const getKybUrl = API.get({
  name: 'getKybUrl',
  path: '/bm/merchants/hyperwallet/kyb/generate-drop-in-url',
})

export const getTransferMethodsUrl = API.get({
  name: 'getTransferMethodsUrl',
  path: '/bm/merchants/hyperwallet/transfer-methods/generate-drop-in-url',
})

export const submitTransferMethods = API.post({
  name: 'submitTransferMethods',
  path: '/bm/merchants/hyperwallet/transfer-methods/submit-hyperwallet-response',
})

/* Address Book */

export const getAddresses = API.get({
  name: 'getAddresses',
  path: '/bm/merchants/addresses',
})

export const createAddress = API.post({
  name: 'createAddress',
  path: '/bm/merchants/addresses',
})

export const editAddress = API.patch({
  name: 'editAddress',
  path: '/bm/merchants/addresses/:id',
})

export const deleteAddress = API.delete({
  name: 'deleteAddress',
  path: '/bm/merchants/addresses/:id',
})

export const setDefaultAddress = API.post({
  name: 'setDefaultAddress',
  path: '/bm/merchants/addresses/:id/set-default-address',
})

export const getAddressRules = API.get({
  name: 'getAddressRules',
  path: '/bm/merchants/address-rules',
})

export const createAddressRule = API.post({
  name: 'createAddressRule',
  path: '/bm/merchants/address-rules',
})

export const editAddressRule = API.put({
  name: 'editAddressRule',
  path: '/bm/merchants/address-rules/:id',
})

export const deleteAddressRule = API.delete({
  name: 'deleteAddressRule',
  path: '/bm/merchants/address-rules/:id',
})

/* Listings creation */

export const getProductMetadata = API.get({
  name: 'getProductMetadata',
  path: '/bm/merchants/product/:productId/listings/metadata',
})

export const getProductMetadataNewApi = API.get({
  name: 'getProductMetadata',
  path: '/api/seller-experience/listings/metadata/create/products/:productId',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const createListing = API.post({
  name: 'createListing',
  path: '/bm/merchants/product/:productId/listings',
})

export const createListingV2 = API.post({
  name: 'createListingV2',
  path: '/api/seller-experience/listings',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getBackboxInfo = API.get({
  name: 'getBackboxInfo',
  path: '/bm/buybox',
})

export const getBackboxInfoV2 = API.get({
  name: 'getBackboxInfoV2',
  path: '/api/seller-experience/listings/backbox',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Listings */

export const getListings = API.get({
  name: 'getListings',
  path: '/bm/catalog/listings',
  transformResponse: [mapFromGetListings],
})

export const getListing = API.get({
  name: 'getListing',
  path: '/bm/catalog/listings/:listingId',
  transformResponse: [transformListing],
})

export const updateListing = API.patch({
  name: 'updateListing',
  path: '/bm/catalog/listings/:listingId',
  transformResponse: [mapFromUpdateListing],
})

export const archiveRequest = API.post({
  name: 'archiveRequest',
  path: '/bm/catalog/listings/:listingId/archive',
})

export const publishRequest = API.post({
  name: 'publishRequest',
  path: '/bm/catalog/listings/:listingId/publish',
})

export const csvImportRequest = API.post({
  name: 'csvImportRequest',
  path: '/bm/catalog/listings/batch-upsert-with-products',
})

export const csvUpdateRequest = API.post({
  name: 'csvUpdateRequest',
  path: '/bm/catalog/listings/batch-update',
})

export const csvExportRequest = API.post({
  name: 'csvExportRequest',
  path: '/bm/catalog/listings/export',
})

export const getBuyboxDataRequest = API.get({
  name: 'getBuyboxDataRequest',
  path: '/bm/buybox/listings/:listingId',
})

export const getOnlineConditions = API.get({
  name: 'getOnlineConditions',
  path: '/bm/catalog/listings/:listingId/online-conditions',
})

export const deleteListingComment = API.delete({
  name: 'deleteListingComment',
  path: '/bm/merchants/listings/:listingId/comments/:market',
})

export const getListingComments = API.get({
  name: 'getListingComments',
  path: '/bm/merchants/listings/:listingId/comments',
})

export const addListingComment = API.post({
  name: 'addListingComment',
  path: '/bm/merchants/listings/:listingId/comments/:market',
})

export const deleteListingShipping = API.delete({
  name: 'deleteListingComment',
  path: '/bm/shipping/listings-shipping-methods/:shippingId',
})

export const getListingShippingMethods = API.get({
  name: 'getListingShippingMethods',
  path: '/bm/shipping/listings/:listingId/shippings',
})

export const addListingShipping = API.post({
  name: 'addListingShipping',
  path: '/bm/shipping/listings/:listingId/shippings/:market',
})

export const getSimilarListings = API.get({
  name: 'getSimilarListings',
  path: '/bm/merchants/listings/:listingId/similar/:productId',
})

export const getListingSearchMetadata = API.get({
  name: 'getListingSearchMetadata',
  path: '/api/seller-experience/listings/metadata/search',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Sourcing */

export const getSourcingReceived = API.get({
  name: 'getSourcingReceived',
  path: '/bm/sourcing/merchant/orders/received',
})

export const getSourcingPending = API.get({
  name: 'getSourcingPending',
  path: '/bm/sourcing/merchant/orders/pending_reply',
})

export const getSourcingOrders = API.get({
  name: 'getSourcingOrders',
  path: '/bm/sourcing/merchant/orders',
})

export const exportSourcingOrders = API.get({
  name: 'exportSourcingOrders',
  path: 'bm/buyback/v1/refurbisher/orders/export',
  responseType: 'blob',
})

export const getSourcingMerchantInfos = API.get({
  name: 'getSourcingMerchantInfos',
  path: '/bm/sourcing/merchant',
})

/* Pricing Tool */

export const changeReferenceMarket = API.post({
  name: 'changeReferenceMarket',
  path: '/bm/merchants/tools/reference-market',
})

export const getCategoriesRequest = API.get({
  name: 'getCategoriesRequest',
  path: '/bm/category/leaf/light',
  transformResponse: [mapFromAllCategories],
})

export const getMarketPricingRulesRequest = API.get({
  name: 'getMarketPricingRulesRequest',
  path: '/bm/merchants/tools/pricing-rules',
  transformResponse: [camelizeKeys],
})

export const getMarketPricingRulesRequestV2 = API.get({
  name: 'getMarketPricingRulesRequestV2',
  path: '/api/seller-experience/pricing-rules',
  transformResponse: [camelizeKeys],
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const createPricingRule = API.post({
  name: 'createPricingRule',
  path: '/bm/merchants/tools/pricing-rules',
  transformRequest: [decamelizeKeys],
  transformResponse: [camelizeKeys],
})

export const createPricingRuleV2 = API.post({
  name: 'createPricingRuleV2',
  path: '/api/seller-experience/pricing-rules',
  transformRequest: [decamelizeKeys],
  transformResponse: [camelizeKeys],
})

export const deletePricingRule = API.delete({
  name: 'deletePricingRule',
  path: '/bm/merchants/tools/pricing-rules/:ruleId',
})

export const deletePricingRuleV2 = API.delete({
  name: 'deletePricingRuleV2',
  path: '/api/seller-experience/pricing-rules/:ruleId',
})

export const applyPricingRule = API.post({
  name: 'applyPricingRule',
  path: '/bm/merchants/tools/pricing-rules/:ruleId/run-task',
})

export const applyPricingRuleRequestV2 = API.post({
  name: 'applyPricingRuleRequestV2',
  path: '/api/seller-experience/pricing-rules/:ruleId/apply',
})

export const getPricingRulesMetadata = API.get({
  name: 'getPricingRulesMetadata',
  path: '/bm/merchants/tools/pricing-rules/metadata',
})

export const getPricingRulesMetadataV2 = API.get({
  name: 'getPricingRulesMetadataV2',
  path: '/api/seller-experience/pricing-rules/metadata',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getMerchantPreferences = API.get({
  name: 'getMerchantPreferences',
  path: '/bm/merchants/tools/merchant-configuration',
})

export const getSellerPreferencesRequest = API.get({
  name: 'getSellerPreferencesRequest',
  path: '/api/seller-experience/pricing-rules/seller-configurations',
  transformResponse: [camelizeKeys],
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const updateMerchantPreferences = API.patch({
  name: 'updateMerchantPreferences',
  path: '/bm/merchants/tools/merchant-configuration',
})

export const updateMerchantPreferencesV2 = API.patch({
  name: 'updateMerchantPreferencesV2',
  path: '/api/seller-experience/pricing-rules/seller-configurations',
  transformResponse: [camelizeKeys],
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Create Shipping Label */

export const createShippingLabel = API.post({
  name: 'createShippingLabel',
  path: '/bm/shipping/labels',
  transformRequest: [decamelizeKeys],
})

export const getBackShipShippers = API.get({
  name: 'getBackShipShippers',
  path: '/bm/shipping/backship/shippers',
})

export const getSellerCharges = API.get({
  name: 'getSellerCharges',
  path: '/bm/shipping/backship/billing/seller-charges',
})

export const createSellerBackshipMargin = API.post({
  name: 'createSellerBackshipMargin',
  path: '/bm/shipping/backship/billing/seller-backship-margins',
})

export const editSellerBackshipMargin = API.put({
  name: 'editSellerBackshipMargin',
  path: '/bm/shipping/backship/billing/seller-backship-margins/:marginId',
})

export const createTradelineBackshipMargin = API.post({
  name: 'createTradelineBackshipMargin',
  path: '/bm/shipping/backship/billing/tradeline-backship-margins',
})

export const editTradelineBackshipMargin = API.put({
  name: 'editTradelineBackshipMargin',
  path: '/bm/shipping/backship/billing/tradeline-backship-margins/:marginId',
})

export const createCarrierBackshipMargin = API.post({
  name: 'createCarrierBackshipMargin',
  path: '/bm/shipping/backship/billing/carrier-backship-margins',
})

export const editCarrierBackshipMargin = API.put({
  name: 'editCarrierBackshipMargin',
  path: '/bm/shipping/backship/billing/carrier-backship-margins/:marginId',
})

export const getCarrierInvoices = API.get({
  name: 'getCarrierInvoices',
  path: '/bm/shipping/backship/billing/invoices',
})

export const getCarrierInvoicesFiltersValues = API.get({
  name: 'getCarrierInvoicesFiltersValues',
  path: '/bm/shipping/backship/billing/invoices/metadata-fields',
})

export const createSellerCharges = API.post({
  name: 'createSellerCharges',
  path: '/bm/shipping/backship/billing/invoices/create-seller-charges',
})

export const approveInvoices = API.post({
  name: 'approveInvoices',
  path: '/bm/shipping/backship/billing/invoices/approve',
})

export const deleteInvoices = API.post({
  name: 'deleteInvoices',
  path: '/bm/shipping/backship/billing/invoices/delete',
})

export const getTradelines = API.get({
  name: 'getTradelines',
  path: '/shipping/service/manage/routing/v1/tradelines',
})

export const getTradelineBackshipMarginsFiltersValues = API.get({
  name: 'getTradelineBackshipMarginsFiltersValues',
  path: '/bm/shipping/backship/billing/tradeline-backship-margins/filters',
})

export const getSellerBackshipMarginsFiltersValues = API.get({
  name: 'getSellerBackshipMarginsFiltersValues',
  path: '/bm/shipping/backship/billing/seller-backship-margins/filters',
})

export const getCarrierBackshipMarginsFiltersValues = API.get({
  name: 'getCarrierBackshipMarginsFiltersValues',
  path: '/bm/shipping/backship/billing/carrier-backship-margins/filters',
})

export const getTradelineFiltersValues = API.get({
  name: 'getTradelineFiltersValues',
  path: '/shipping/service/manage/routing/v1/tradelines/filters',
})

export const createTradeline = API.post({
  name: 'createTradeline',
  path: '/shipping/service/manage/routing/v1/tradelines',
})

export const editTradeline = API.put({
  name: 'editTradeline',
  path: '/shipping/service/manage/routing/v1/tradelines/:publicId',
})

export const postImportSellerChargeAdjustments = API.post({
  name: 'postImportSellerChargeAdjustments',
  path: '/bm/shipping/backship/billing/seller-charges/csv-batch-update',
})

export const getCarrierBackshipMargins = API.get({
  name: 'getCarrierBackshipMargins',
  path: '/bm/shipping/backship/billing/carrier-backship-margins',
})

export const getTradelineBackshipMargins = API.get({
  name: 'getTradelineBackshipMargins',
  path: '/bm/shipping/backship/billing/tradeline-backship-margins',
})

export const getSellerBackshipMargins = API.get({
  name: 'getSellerBackshipMargins',
  path: '/bm/shipping/backship/billing/seller-backship-margins',
})

export const fetchShippingCollectionPoints = API.get({
  name: 'fetchShippingCollectionPoints',
  path: '/bm/shipping/v1/collection-points',
  transformResponse: [
    (collectionPoints) =>
      collectionPoints.map((collectionPoint) => ({
        ...collectionPoint,

        // API returns an unsorted arrays, with not always every days of the week
        // TODO [CK-864] Cleanup once the API is fixed
        openingHours: Array.from({ length: 7 }).map(
          (_, index) =>
            collectionPoint.openingHours.find(
              ({ dayOfTheWeek }) => dayOfTheWeek === index + 1,
            ) || {
              dayOfTheWeek: index + 1,
              timeSlots: [],
            },
        ),
      })),
  ],
})

/* International Onboarding */

export const getPreviousOnboardingInfos = API.get({
  name: 'getPreviousOnboardingInfos',
  path: '/bm/merchants/onboarding',
  transformResponse: [mapFromInternationalOnboarding],
})

export const getNextOnboardingStep = API.post({
  name: 'getNextOnboardingStep',
  path: '/bm/merchants/onboarding',
  transformRequest: [mapToInternationalOnboarding],
  transformResponse: [mapFromInternationalOnboarding],
})

/* Performances */

export const getDashboardAnalytics = API.get({
  name: 'getDashboardAnalytics',
  path: '/bm/dashboard-analytics/embedded',
})

export const getInsightsReports = API.get({
  name: 'getInsightsReports',
  path: '/api/seller-experience/insights',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

/* Options */

export const getMerchantPaymentData = API.get({
  name: 'getMerchantPaymentData',
  path: '/bm/merchants/payment-methods',
})

export const getMerchantPayPalSettings = API.get({
  name: 'getMerchantPayPalSettings',
  path: '/bm/merchants/payment-methods/paypal',
})

export const updatePayPalActiveMarkets = API.patch({
  name: 'updatePayPalActiveMarkets',
  path: '/bm/merchants/payment-methods/paypal',
})

export const getMerchantKonbiniSettings = API.get({
  name: 'getMerchantKonbiniSettings',
  path: '/bm/merchants/payment-methods/konbini',
  transformResponse: [mapFromKonbiniSettings],
})

export const updateMerchantKonbiniSettings = API.put({
  name: 'updateMerchantKonbiniSettings',
  path: '/bm/merchants/payment-methods/konbini',
  transformResponse: [mapFromKonbiniSettings],
})

export const getMerchantShippingMethods = API.get({
  name: 'getMerchantShippingMethods',
  path: '/bm/merchants/shipping-methods',
})

export const getMerchantShippingMethodsMetaData = API.get({
  name: 'getMerchantShippingMethodsMetaData',
  path: '/bm/merchants/shipping-methods/metadata',
})

export const createMerchantShippingMethod = API.post({
  name: 'createMerchantShippingMethod',
  path: '/bm/merchants/shipping-methods/:categoryType',
})

export const editMerchantShippingMethod = API.patch({
  name: 'editMerchantShippingMethod',
  path: '/bm/merchants/shipping-methods/:categoryType/:shippingMethodId',
})

export const deleteMerchantShippingMethod = API.delete({
  name: 'deleteMerchantShippingMethod',
  path: '/bm/merchants/shipping-methods/:categoryType/:shippingMethodId',
})

/* SWAP */

export const getEstimationSwapPrice = API.get({
  name: 'getEstimationSwapPrice',
  path: '/bm/sourcing/swap/initial_discount',
})

export const addSwapToCart = API.post({
  name: 'addSwapToCart',
  path: '/bm/cart/swap',
})

export const postBuybackAnswers = API.post({
  name: 'postBuybackAnswers',
  path: '/bm/sourcing/form/answers',
})

export const getBuybackQuestions = API.get({
  name: 'getBuybackQuestions',
  path: '/buyback-funnel/api/v1/funnel/:kind/questions',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const deleteSwap = API.delete({
  name: 'deleteSwap',
  path: '/bm/cart/swap',
})

export const operationSubscribe = API.post({
  name: 'operationSubscribe',
  path: '/crm/leads',
})

/* Catalog API endpoints */

export const getSellerProductsSearch = API.post({
  name: 'getSellerProductsSearch',
  path: '/bm/catalog/bo-seller/products/search',
})

export const getSelletProductDetails = API.get({
  name: 'getSellerProductsSearch',
  path: '/bm/catalog/bo-seller/products/:productId',
})

export const importProducts = API.post({
  name: 'importProducts',
  path: '/bm/catalog/product/import',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const resurrectProducts = API.post({
  name: 'resurrectProducts',
  path: '/bm/catalog/product/resurrect',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const exportProducts = API.post({
  name: 'exportProducts',
  path: '/bm/catalog/product/export',
})

export const importProductImages = API.post({
  name: 'importProductImages',
  path: '/bm/catalog/products/images/batch-create',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const rematchListing = API.post({
  name: 'rematchListing',
  path: '/bm/catalog/listings/:listingId/rematch',
})

export const renameListings = API.post({
  name: 'renameListings',
  path: '/bm/catalog/listing/rename',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const rematchListings = API.post({
  name: 'rematchListings',
  path: '/bm/catalog/listing/rematch',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const resyncListings = API.post({
  name: 'resyncListings',
  path: '/bm/catalog/listing/resync',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const archiveListings = API.post({
  name: 'archiveListings',
  path: '/bm/catalog/listings/archive',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const akeneoSearchProducts = API.get({
  name: 'akeneoSearchProducts',
  path: '/bm/catalog/akeneo/products',
})

export const akeneoSearchProduct = API.get({
  name: 'akeneoSearchProducts',
  path: '/bm/catalog/akeneo/products/:akeneoProductId',
})

export const getAkeneoReferenceEntityRecords = API.get({
  name: 'getAkeneoReferenceEntitiesRecords',
  path: '/bm/catalog/akeneo/reference-entities/:referenceEntityCode/records',
})

/* BackRepair */

export const fetchBackRepairFactories = API.get({
  name: 'fetchBackRepairFactories',
  path: '/bm/repair/v1/orderline/:orderlineId/factories',
})

export const getBackRepairShops = API.post({
  name: 'getBackRepairShops',
  path: '/bm/repair/api/v1/nearby-shops',
})

export const getBackRepairDetails = API.get({
  name: 'getBackRepairDetails',
  path: '/bm/repair/api/v1/repairs/:repairId',
})

export const getBackRepairSellerDetails = API.get({
  name: 'getBackRepairSellerDetails',
  path: '/bm/repair/api/v1/sellers/:sellerId/config',
})

export const updateBackRepairSellerDetails = API.put({
  name: 'updateBackRepairSellerDetails',
  path: '/bm/repair/api/v1/sellers/:sellerId/config',
})

/* Models Comparison */

export const getCategories = API.get({
  name: 'getCategories',
  path: '/bm/models-comparison/categories',
})

export const getCategoryContent = API.get({
  name: 'getCategoryContent',
  path: '/bm/models-comparison/categories/:category',
})

export const getModelsComparisonResults = API.get({
  name: 'getModelsComparisonResults',
  path: '/bm/models-comparison/compare/:model1/:model2',
})

/* Admin Tools */

export const fetchTaskAnalyzerSearch = API.get({
  name: 'fetchTaskAnalyzerSearch',
  path: '/bm/taskmanager/tasks',
})

export const fetchTaskAnalyzerActions = API.get({
  name: 'fetchTaskAnalyzerActions',
  path: '/bm/taskmanager/task-actions',
})

export const getAdminListings = API.get({
  name: 'getAdminListings',
  path: '/bm/catalog/listings/admin',
})

export const fetchListingInformation = API.get({
  name: 'fetchListingInformation',
  path: '/bm/catalog/listings/:listingId/admin',
})

export const fetchListingHistory = API.get({
  name: 'fetchListingHistory',
  path: '/bm/catalog/listings/:listingId/history',
})

export const importAkeneoAsset = API.post({
  name: 'importAkeneoAsset',
  path: '/bm/catalog/akeneo/asset-families/:assetFamilyCode/assets',
})

export const getAkeneoAsset = API.get({
  name: 'getAkeneoAsset',
  path: '/bm/catalog/akeneo/asset-families/:assetFamilyCode/assets/:code',
})

export const getSalesListingsAestheticGrades = API.get({
  name: 'getSalesListingsAestheticGrades',
  path: '/bm/catalog/listings/aesthetic-grades',
})

export const getSalesListingsPublicationStates = API.get({
  name: 'getSalesListingsPublicationStates',
  path: '/bm/catalog/listings/publication-states',
})

export const getBuybackListingsGrades = API.get({
  name: 'getBuybackListingsGrades',
  path: '/bm/catalog/buyback/listings/aesthetic-grades',
})

export const getBuybackListingsPublicationStates = API.get({
  name: 'getBuybackListingsPublicationStates',
  path: '/bm/catalog/buyback/listings/publication-states',
})

export const getBuybackCategories = API.get({
  name: 'getBuybackCategories',
  path: '/bm/catalog/buyback/categories',
})

export const getCarriersAutocomplete = API.get({
  name: 'getCarriersAutocomplete',
  path: '/bm/shipping/carriers/autocomplete',
})

export const getMerchantsAutocomplete = API.get({
  name: 'getMerchantsAutocomplete',
  path: '/bm/merchants/bo-admin/merchants/autocomplete',
})

export const updateBuybackCategories = API.patch({
  name: 'updateBuybackCategories',
  path: '/bm/catalog/buyback/categories/:badoomId',
})

export const getListingsToProductMerchant = API.get({
  name: 'getListingsToProductMerchant',
  path: '/bm/catalog/buyback/bo-admin/listings/to-product-merchant',
})

export const getMerchantProductListings = API.get({
  name: 'getMerchantProductListings',
  path: '/bm/catalog/buyback/bo-admin/merchants/:merchantId/products/:productId/listings',
})

export const patchMerchantProductListing = API.patch({
  name: 'getMerchantProductListings',
  path: '/bm/catalog/buyback/bo-admin/merchants/:merchantId/products/:productId/listings/:listingId',
})

export const rematchCatalogProduct = API.post({
  name: 'rematchCatalogProduct',
  path: '/bm/catalog/products/:productId/rematch',
})

export const generateCatalogProductEan = API.post({
  name: 'generateProductEan',
  path: '/bm/catalog/products/generate-ean',
})

export const getCatalogProduct = API.get({
  name: 'getCatalogProduct',
  path: '/bm/catalog/products/:productId',
})

export const putOnHoldCatalogProductValidationsProduct = API.post({
  name: 'putOnHoldCatalogProductValidationsProduct',
  path: '/bm/catalog/product-validations/products/:productId/put-on-hold',
})

export const getCatalogProductValidationsProducts = API.get({
  name: 'getCatalogProductValidationsProduct',
  path: '/bm/catalog/product-validations/products',
})

export const getCatalogProductValidationsProduct = API.get({
  name: 'getCatalogProductValidationsProduct',
  path: '/bm/catalog/product-validations/products/:productId',
})

export const validateCatalogProduct = API.put({
  name: 'validateCatalogProduct',
  path: '/bm/catalog/product-validations/products/:productId/approve',
})

export const fetchCatalogProductsValidationHistory = API.get({
  name: 'fetchCatalogProductsValidationHistory',
  path: '/bm/catalog/product-validations/history',
})

export const fetchCatalogProductValidationRematchSearch = API.post({
  name: 'fetchCatalogProductValidationRematchSearch',
  path: '/bm/catalog/product-validations/rematch/search',
})

export const getCatalogCategories = API.get({
  name: 'getCatalogCategories',
  path: '/bm/catalog/categories',
})

export const getCatalogCategoryAttributes = API.get({
  name: 'getCatalogCategoryAttributes',
  path: '/bm/catalog/categories/:categoryId/attributes',
})

export const getAkeneoProductBackboxes = API.get({
  name: 'getAkeneoProductBackboxes',
  path: '/bm/catalog/buyback/bo-admin/products/:akeneoProductId/backboxes',
})

export const getAkeneoProductListingsCompetition = API.get({
  name: 'getAkeneoProductListingsCompetition',
  path: '/bm/catalog/buyback/bo-admin/products/:akeneoProductId/competition',
})

export const getAkeneoProductListings = API.get({
  name: 'getAkeneoProductListings',
  path: '/bm/catalog/buyback/bo-admin/products/:akeneoProductId/listings',
})

export const getRestrictedCategories = API.get({
  name: 'getRestrictedCategories',
  path: '/bm/catalog/categories/merchant-restrictions',
})

export const deleteRestrictedCategory = API.delete({
  name: 'deleteRestrictedCategory',
  path: '/bm/catalog/categories/:marketplaceCategoryId/merchant-restrictions',
})

export const postRestrictedCategory = API.post({
  name: 'postRestrictedCategory',
  path: '/bm/catalog/categories/:marketplaceCategoryId/merchant-restrictions',
})

export const putRestrictedCategory = API.put({
  name: 'putRestrictedCategory',
  path: '/bm/catalog/categories/:marketplaceCategoryId/merchant-restrictions',
})

export const fetchSellerInformation = API.get({
  name: 'fetchSellerInformation',
  path: '/bm/staff/merchants/:sellerId',
})

export const getCatalogSellerRateLimiting = API.get({
  name: 'getCatalogSellerRateLimiting',
  path: '/bm/catalog/bo-admin/sellers/:sellerId/rate-limiting',
})

export const fetchCatalogBuybackSellerProducts = API.get({
  name: 'fetchCatalogBuybackSellerProducts',
  path: '/bm/catalog/buyback/bo-admin/merchants/:sellerId/products',
})

export const getSalesLimitations = API.get({
  name: 'getSalesLimitations',
  path: '/bm/merchants/sales-limitation',
})

export const getSalesLimitationsMetadata = API.get({
  name: 'getSalesLimitationsMetadata',
  path: '/bm/merchants/sales-limitation/metadata',
})

export const createSalesLimitation = API.post({
  name: 'createSaleLimitation',
  path: '/bm/merchants/sales-limitation',
})

export const editSalesLimitation = API.put({
  name: 'editSaleLimitation',
  path: '/bm/merchants/sales-limitation/:salesLimitationId',
})

export const deleteSalesLimitation = API.delete({
  name: 'deleteSaleLimitation',
  path: '/bm/merchants/sales-limitation/:salesLimitationId',
})

export const getBackPricerStatus = API.get({
  name: 'getBackPricerStatus',
  path: '/bm/merchants/backpricer',
})

export const updateBackPricerStatus = API.post({
  name: 'updateBackPricerStatus',
  path: '/bm/merchants/backpricer',
})

export const getStudentDiscount = API.get({
  name: 'getStudentDiscount',
  path: '/bm/branding/student-discount',
})

export const getAdminReviews = API.get({
  name: 'getAdminReviews',
  path: '/bm/bo-admin/reviews',
})

export const updateReviewsPublishStatus = API.patch({
  name: 'updateReviewPublishStatus',
  path: '/bm/bo-admin/reviews/:reviewId',
})

export const getCustomers = API.get({
  name: 'getCustomers',
  path: '/bm/bo-admin/customers',
})

export const getCustomerOrders = API.get({
  name: 'getCustomerOrders',
  path: '/bm/bo-admin/customer/:customerId/orders',
})

export const getCustomerOrderline = API.get({
  name: 'getCustomerOrderline',
  path: '/bm/orders/staff/orderlines/:orderlinePublicId',
})

export const postCustomerDeleteState = API.post({
  name: 'postCustomerDeleteState',
  path: 'bm/bo-admin/customer/:customerId/delete-state',
})

export const postGoodwillGesture = API.post({
  name: 'postGoodwillGesture',
  path: 'bm/bo-admin/customer/:customerId/goodwill-gesture',
})

export const anonymizeCustomer = API.post({
  name: 'anonymizeCustomer',
  path: 'bm/bo-admin/customer/:customerId/anonymize',
})

export const updateCustomersFraudScore = API.patch({
  name: 'updateCustomersFraudScore',
  path: 'bm/bo-admin/customer/:customerId/fraud-score',
})

export const blockOrUnblockCustomerAccount = API.patch({
  name: 'blockOrUnblockCustomerAccount',
  path: 'bm/bo-admin/customer/:customerId/block',
})

export const updateCustomerEmail = API.patch({
  name: 'updateCustomerEmail',
  path: 'bm/bo-admin/customer/:customerId/email-update',
})

export const shadowRefund = API.post({
  name: 'shadowRefund',
  path: '/payment/order/:orderId/shadow-refund',
})

export const getCustomerBuybackOrders = API.get({
  name: 'getCustomerBuybackOrders',
  path: '/bm/buyback/v1/staff/customer/:customerId/orders',
})

export const getPaymentDetails = API.get({
  name: 'getPaymentDetails',
  path: 'payment/:paymentId/details',
})

/* Admin Tools - Landing Pages Bulk Imports */

export const getLpBulkImports = API.get({
  name: 'getLpBulkImports',
  path: '/bm/landing_page/bulk_imports',
})

export const getBulkImportHistoryStateCsvString = API.get({
  name: 'getBulkImportHistoryStateCsvString',
  path: '/bm/landing_page/bulk_imports/:bulkImportId/history_state/:historyState',
})

export const postLpBulkImportFromGsheetsUrl = API.post({
  name: 'postLpBulkImportFromGsheetsUrl',
  path: '/bm/landing_page/bulk_imports',
})

export const postLpBulkImportRollback = API.post({
  name: 'postLpBulkImportRollback',
  path: '/bm/landing_page/bulk_imports/:bulkImportId/rollback',
})

// Merchant Invoices

export const getPastInvoices = API.get({
  name: 'getPastInvoices',
  path: '/finance/merchant/bills',
})

export const getSellerCompensation = API.get({
  name: 'getPastInvoices',
  path: '/finance/merchant/compensations',
})

export const getGoodwillGestures = API.get({
  name: 'getGoodwillGestures',
  path: '/finance/credit-requests/merchant-list',
})

export const getPendingInvoices = API.get({
  name: 'getPendingInvoices',
  path: '/finance/merchant/in-progress-invoices',
})

export const getSellerWallet = API.get({
  name: 'getSellerWallet',
  path: '/finance/seller-ledger/live-balances',
})

export const getHomeMessages = API.get({
  name: 'getHomeMessages',
  path: '/bm/merchants/homepage/messages',
})

export const getHomeListingInfos = API.get({
  name: 'getHomeListingInfos',
  path: '/bm/merchants/homepage/listings',
})

export const getHomeSalesMetrics = API.get({
  name: 'getHomeSalesMetrics',
  path: '/bm/merchants/homepage/sales-metrics',
})

export const getHomeUserReviews = API.get({
  name: 'getHomeUserReviews',
  path: '/bm/merchants/homepage/user-reviews',
})

export const getIntegrations = API.get({
  name: 'getIntegrations',
  path: '/bm/merchants/integrations',
})

export const getIntegrationsMetadata = API.get({
  name: 'getIntegrationsMetadata',
  path: '/bm/merchants/integrations/metadata',
})

export const createIntegration = API.post({
  name: 'createIntegration',
  path: '/bm/merchants/integrations',
})

export const deleteIntegration = API.delete({
  name: 'deleteIntegration',
  path: '/bm/merchants/integrations/:id',
})

export const getSellerRegisterMetadata = API.get({
  name: 'getSellerRegisterMetadata',
  path: '/bm/merchants/register/metadata',
})

/* SELLER Profile Page */

export const getSellerProfileMetadata = API.get({
  name: 'getSellerProfileMetadata',
  path: '/bm/merchants/profile/metadata',
})

export const getSellerProfile = API.get({
  name: 'getSellerProfile',
  path: '/bm/merchants/profile',
})

export const updateSellerProfile = API.put({
  name: 'updateSellerProfile',
  path: '/bm/merchants/profile',
})

export const getContactDetailsMetadata = API.get({
  name: 'getContactDetailsMetadata',
  path: '/bm/merchants/profile/contacts/metadata',
})

export const getProfileDocumentsMetadata = API.get({
  name: 'getSellerProfileDocumentsMetadata',
  path: '/bm/merchants/profile/legal-documents/metadata',
})

export const addSellerProfileLegalDocument = API.post({
  name: 'addSellerProfileLegalDocument',
  path: '/bm/merchants/profile/legal-documents',
})

export const deleteSellerProfileLegalDocument = API.delete({
  name: 'deleteSellerProfileLegalDocument',
  path: '/bm/merchants/profile/legal-documents',
})

export const getSellerProfileLegalDocuments = API.get({
  name: 'getSellerProfileLegalDocuments',
  path: '/bm/merchants/profile/legal-documents',
})

export const getSellerProfileLegalDetailsMetadata = API.get({
  name: 'getSellerProfileLegalDetailsMetadata',
  path: '/bm/merchants/profile/legal-details/metadata',
})

export const getCopyLevyInformation = API.get({
  name: 'getCopyLevyInformation',
  path: '/bm/merchants/profile/legal-details/copy-levy',
})

export const updateCopyLevyInformation = API.put({
  name: 'updateCopyLevyInformation',
  path: '/bm/merchants/profile/legal-details/copy-levy',
})

export const getSellerProfileEprInformation = API.get({
  name: 'getSellerProfileEprInformation',
  path: '/bm/merchants/profile/legal-details/epr-information',
})

export const addSellerProfileEprInformation = API.post({
  name: 'addSellerProfileEprInformation',
  path: '/bm/merchants/profile/legal-details/epr-information',
})

export const deleteSellerProfileEprInformation = API.delete({
  name: 'deleteSellerProfileEprInformation',
  path: '/bm/merchants/profile/legal-details/epr-information/:id',
})

export const getSellerProfileVatInformations = API.get({
  name: 'getSellerProfileVatInformations',
  path: '/bm/merchants/profile/legal-details/vat-informations',
  transformResponse: [mapFromVatInformation],
})

export const updateSellerProfileVatInformations = API.put({
  name: 'updateSellerProfileVatInformations',
  path: '/bm/merchants/profile/legal-details/vat-informations',
})

export const getSellerTaxIdentificationNumbers = API.get({
  name: 'getSellerTaxIdentificationNumbers',
  path: '/bm/merchants/profile/legal-details/tax-identification-numbers',
})

export const updateSellerTaxIdentificationNumber = API.put({
  name: 'updatellerTaxIdentificationNumber',
  path: '/bm/merchants/profile/legal-details/tax-identification-numbers/:tinId',
})

export const createSellerTaxIdentificationNumber = API.post({
  name: 'createSellerTaxIdentificationNumber',
  path: '/bm/merchants/profile/legal-details/tax-identification-numbers',
})

export const deleteSellerTaxIdentificationNumber = API.delete({
  name: 'deleteSellerTaxIdentificationNumber',
  path: '/bm/merchants/profile/legal-details/tax-identification-numbers/:tinId',
})

export const getSellerTaxResidenceCountries = API.get({
  name: 'getSellerTaxResidenceCountries',
  path: '/bm/merchants/profile/legal-details/tax-information',
})

export const updateSellerTaxResidenceCountries = API.put({
  name: 'updateSellerTaxResidenceCountries',
  path: '/bm/merchants/profile/legal-details/tax-information',
})

/* Credit Requests */

export const getCreditRequests = API.get({
  name: 'getCreditRequests',
  path: '/finance/credit-requests',
})

export const postCreditRequest = API.post({
  name: 'postCreditRequest',
  path: '/finance/credit-requests/create',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const postApproveCreditRequest = API.post({
  name: 'postApproveCreditRequest',
  path: '/finance/credit-requests/:id/approve',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const postRejectCreditRequest = API.post({
  name: 'postRejectCreditRequest',
  path: '/finance/credit-requests/:id/reject',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const postConfirmCreditRequestPayment = API.post({
  name: 'postConfirmCreditRequestPayment',
  path: '/finance/credit-requests/:id/confirm-payment',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const postReverseCreditRequest = API.post({
  name: 'postReverseCreditRequest',
  path: '/finance/credit-requests/:id/reverse',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const postImportCreditRequests = API.post({
  name: 'postImportCreditRequests',
  path: '/finance/credit-requests/import',
})

/* Seller Compensations */

export const getSellerCompensations = API.get({
  name: 'getSellerCompensations',
  path: '/finance/tools/seller-compensations/list',
  transformResponse: [rewordSellerCompensationsResponse],
})
export const preCreateSellerCompensations = API.post({
  name: 'preCreateSellerCompensations',
  path: '/finance/tools/seller-compensations/pre-create',
  transformResponse: [rewordSellerCompensationsResponse],
})
export const createSellerCompensations = API.post({
  name: 'createSellerCompensations',
  path: '/finance/tools/seller-compensations',
  transformResponse: [rewordSellerCompensationsResponse],
})
export const validateSellerCompensation = API.put({
  name: 'validateSellerCompensation',
  path: '/finance/tools/seller-compensations/:id/validate',
})
export const getSellerCompensationsStates = API.get({
  name: 'getSellerCompensationsStates',
  path: '/finance/tools/seller-compensations/states',
})

export const getSearchSeller = API.get({
  name: 'getSearchSeller',
  path: '/finance/search/merchants',
})

/* Seller Compensations */

export const getTaxCollections = API.get({
  name: 'getTaxCollections',
  path: '/finance/api/tax-service/tax-collections',
})

/* Reasons */

export const getReasons = API.get({
  name: 'getReasons',
  path: '/finance/reason',
})

export const getReasonsNew = API.get({
  name: 'getReasons',
  path: '/finance/api/financial-tools/api/reasons',
  transformResponse: [transformReasons],
})

/* Finance currencies */

export const getAvailableCurrencies = API.get({
  name: 'getAvailableCurrencies',
  path: '/finance/currencies',
})

// SELLER ORDERS

export const getSellerOrders = API.get({
  name: 'getSellerOrders',
  path: '/bm/merchants/orders',
})

export const getSellerOrder = API.get({
  name: 'getSellerOrder',
  path: '/bm/merchants/orders/:orderId',
})

export const getSellerOrderFinancialDetails = API.get({
  name: 'getSellerOrderFinancialDetails',
  path: '/bm/finance/seller-ledger/live-balances/orders/:orderId',
})

export const getSellerOrdersMetadata = API.get({
  name: 'getSellerOrdersMetadata',
  path: '/bm/merchants/orders/metadata',
})

export const validateSellerOrderLine = API.put({
  name: 'validateSellerOrderLine',
  path: '/bm/merchants/orderlines/:orderLineId/validate',
})

export const updateSellerOrderLine = API.patch({
  name: 'updateSellerOrderLine',
  path: '/bm/merchants/orderlines/:orderLineId',
})

export const bulkValidateSellerOrderLine = API.put({
  name: 'bulkValidateSellerOrderLine',
  path: '/bm/merchants/orderlines/bulk/validate',
})

export const cancelOrRefundSellerOrderLine = API.put({
  name: 'cancelOrRefundSellerOrderLine',
  path: '/bm/merchants/orderlines/:orderLineId/cancel',
})

export const shipSellerOrder = API.put({
  name: 'shipSellerOrder',
  path: '/bm/merchants/orders/:orderId/ship',
})

export const sendMessageToOrderCustomer = API.post({
  name: 'sendMessageToOrderCustomer',
  path: '/bm/customer_request/orderline/:orderLineId/seller_requests',
})

export const importOrdersTrackingNumbers = API.post({
  name: 'importOrdersTrackingNumbers',
  path: '/bm/merchants/orders/import/tracking-numbers',
})

export const importOrdersImeis = API.post({
  name: 'importOrdersImeis',
  path: '/bm/merchants/orders/import/imei-numbers',
})

export const exportAllOrders = API.post({
  name: 'exportAllOrders',
  path: '/bm/merchants/orders/export',
})

export const exportImeisOrders = API.post({
  name: 'exportImeisOrders',
  path: '/bm/merchants/orders/export/missing-imei-numbers',
})

export const exportPackingSlips = API.post({
  name: 'exportPackingSlips',
  path: '/bm/merchants/orders/export/delivery-slips',
})

export const cancelOrderPickup = API.post({
  name: 'cancelOrderPickup',
  path: '/bm/orders/cancel_pickup/:orderId',
})

export const requestNewPickup = API.post({
  name: 'requestNewPickup',
  path: '/bm/orders/:orderId/new_pickup',
})

export const exportBackshipLabels = API.post({
  name: 'exportBackshipLabels',
  path: '/bm/merchants/orders/export/backship-labels',
})

export const exportCustomerReceipts = API.post({
  name: 'exportCustomerReceipts',
  path: '/bm/merchants/orders/export/invoices',
})

export const updateOrder = API.patch({
  name: 'updateOrder',
  path: '/bm/merchants/orders/:orderId',
})

/* Balance Adjustments */

export const getBalanceAdjustments = API.get({
  name: 'getBalanceAdjustments',
  path: '/finance/picsou/list',
  transformResponse: [mapFromBalanceAjustements],
})

export const createBalanceAdjustment = API.post({
  name: 'createBalanceAdjustment',
  path: '/finance/picsou/create',
  transformRequest: [mapToCreateBalanceAdjustment],
})

export const postImportBalanceAdjustments = API.post({
  name: 'postImportBalanceAdjustments',
  path: '/finance/picsou/import',
})

export const getPicsouConfig = API.get({
  name: 'getPicsouConfig',
  path: '/finance/picsou/config',
  transformResponse: [mapFromPicsouConfig],
})

/* Finance - user */

export const getFinanceUserPermissions = API.get({
  name: 'getFinanceUserPermissions',
  path: '/finance/user/permissions',
})

/* CCBM Fee Rules */

export const getCcbmFeeRules = API.get({
  name: 'getCcbmFeeRules',
  path: '/finance/ccbm/fee-rules',
})

export const importCcbmFeeRules = API.post({
  name: 'importCcbmFeeRules',
  path: '/finance/ccbm/fee-rules/import',
})

/* Staff */

export const getStaffUsers = API.get({
  name: 'getStaffUsers',
  path: '/finance/search/staff',
  transformResponse: [camelizeKeys],
})

/* Seller onboarding */

export const getSelfOnboardingBlocks = API.get({
  name: 'getSelfOnboardingBlocks',
  path: '/bm/merchants/self-onboarding',
})

export const getSellerUserList = API.get({
  name: 'getSellerUserList',
  path: '/bm/merchants/users',
})

export const updateSellerUser = API.patch({
  name: 'updateSellerUser',
  path: '/bm/merchants/users/:userId',
})

export const createSellerUser = API.post({
  name: 'createSellerUser',
  path: '/bm/merchants/users',
})

export const getSellerBoBanners = API.get({
  name: 'getSellerBanners',
  path: '/bm/merchants/banners',
})

export const getSellerFeatures = API.get({
  name: 'getSellerFeatures',
  path: 'bm/merchants/features',
})

export const getSellerFeaturesV2 = API.get({
  name: 'getSellerFeaturesV2',
  path: '/api/seller-experience/seller/features',
  apiBaseUrl:
    process.server && process.env.DOORMAN_SERVICE_NAME
      ? process.env.DOORMAN_SERVICE_NAME
      : '',
})

export const getSellerFiles = API.get({
  name: 'getSellerFiles',
  path: 'bm/merchants/notifications',
})

export const deleteSellerFiles = API.post({
  name: 'deleteSellerFiles',
  path: '/bm/merchants/notifications/bulk/delete',
})

/* Manual Refunds */
export const getManualRefunds = API.get({
  name: 'getManualRefunds',
  path: '/bm/orders/staff/orders/manual-refunds',
})

export const postManualRefundAsPaid = API.post({
  name: 'postManualRefundAsPaid',
  path: '/bm/orders/staff/orders/manual-refunds/:id/mark-succeeded',
  headers: {
    [HEADER_CONTENT_TYPE]: 'multipart/form-data',
  },
  timeout: FILE_TRANSFER_TIMEOUT,
})

export const getDocwebsiteCategory = API.get({
  name: 'getManualRefunds',
  path: '/bm/docwebsite/category/tree',
})

export const getSellerPricingOpportunitiesMetrics = API.get({
  name: 'getSellerPricingOpportunitiesMetrics',
  path: '/bm/merchants/pricing-opportunities/metrics',
})

export const getSellerPricingOpportunitiesMetadata = API.get({
  name: 'getSellerPricingOpportunitiesMetadata',
  path: '/bm/merchants/pricing-opportunities/metadata',
})

export const getSellerPricingOpportunities = API.get({
  name: 'getSellerPricingOpportunities',
  path: '/bm/merchants/pricing-opportunities',
})

export const deleteSellerPricingOpportunities = API.delete({
  name: 'deleteSellerPricingOpportunities',
  path: '/bm/merchants/pricing-opportunities/:listingId/:market',
})
