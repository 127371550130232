import { filterObjectProperties, insertIf } from '@core/helpers'
import {
  fetchBackmarketRateDistribution,
  fetchBackmarketRateReviews,
  fetchBackmarketReviewsBreadcrumb,
  fetchBackmarketReviewsList,
  fetchLandingRateDistribution,
  fetchLandingRateReviews,
  fetchLandingReviewsBreadcrumb,
  fetchLandingReviewsList,
  fetchMerchantRateDistribution,
  fetchMerchantRateReviews,
  fetchMerchantReviewsBreadcrumb,
  fetchMerchantReviewsList,
  fetchProductRateDistribution,
  fetchRatingCategoriesByProduct,
  fetchReviewsBreadcrumbByProduct,
  fetchReviewsListByProduct,
  fetchReviewsRateByProduct,
} from '@http/endpoints'
import { ROUTES } from '@router'

import { REVIEWS_TYPES } from './constants'

/**
 * Type of object returned by the get*Request methods
 * @typedef {Object} Request
 * @property {Function} request
 * @property {Object} [pathParams]
 * @property {Object} [queryParams]
 */

/**
 * Type of the params field
 * @typedef {Object} NormalizedParams
 * @property {String} id
 * @property {String} [slug]
 */

/**
 * Get the breadcrumb endpoint to call for the given route
 * @param {import('vue-router').Route} route
 * @returns {Request} breadcrumb endpoint
 */
export function getBreadcrumbRequest({ name, params } = {}) {
  switch (name) {
    case ROUTES.REVIEWS.PAGES.PRODUCT:
      return {
        request: fetchReviewsBreadcrumbByProduct,
        pathParams: { productId: params.productId },
      }

    case ROUTES.REVIEWS.PAGES.LANDING:
      return {
        request: fetchLandingReviewsBreadcrumb,
        queryParams: {
          pk: params.landingId,
        },
      }

    case ROUTES.REVIEWS.PAGES.MERCHANT:
      return {
        request: fetchMerchantReviewsBreadcrumb,
        queryParams: {
          id: params.sellerId,
        },
      }

    case ROUTES.REVIEWS.PAGES.ALL:
    default:
      return {
        request: fetchBackmarketReviewsBreadcrumb,
      }
  }
}

/**
 * Get the rating endpoint to call for the given review type
 * to display the average rate and the number of reviews
 * @param {import('./constants').REVIEWS_TYPES} type
 * @param {NormalizedParams} params
 * @returns {Request} global rate endpoint
 */
export function getGlobalRateRequest({ type, params } = {}) {
  switch (type) {
    case REVIEWS_TYPES.PRODUCT:
      return {
        request: fetchReviewsRateByProduct,
        pathParams: { productId: params.id },
        // [COM-362] TODO to remove withReviewTranslation when it is done on BE side
        queryParams: { withReviewTranslation: true },
      }

    case REVIEWS_TYPES.LANDING:
      return {
        request: fetchLandingRateReviews,
        queryParams: { pk: params.id, slug: params.slug },
      }

    case REVIEWS_TYPES.MERCHANT:
      return {
        request: fetchMerchantRateReviews,
        queryParams: {
          id: params.id,
        },
      }

    case REVIEWS_TYPES.ALL:
    default:
      return {
        request: fetchBackmarketRateReviews,
      }
  }
}

/**
 * Get the reviews endpoint to call for the given review type
 * to display the list of reviews
 * @param {import('./constants').REVIEWS_TYPES} type
 * @param {NormalizedParams} params
 * @returns {Request} reviews list endpoint
 */
export function getReviewsListRequest({
  type,
  params,
  route,
  ...options
} = {}) {
  let requestBase
  switch (type) {
    case REVIEWS_TYPES.PRODUCT: {
      requestBase = {
        request: fetchReviewsListByProduct,
        pathParams: { productId: params.id },
        // [COM-362] TODO to remove withReviewTranslation when it is done on BE side
        // This endpoint is the only one handling translated reviews
        queryParams: {
          withReviewTranslation: true,
        },
      }
      break
    }

    case REVIEWS_TYPES.LANDING: {
      requestBase = {
        request: fetchLandingReviewsList,
        queryParams: {
          pk: params.id,
        },
      }
      break
    }

    case REVIEWS_TYPES.MERCHANT: {
      requestBase = {
        request: fetchMerchantReviewsList,
        queryParams: {
          id: params.id,
        },
      }
      break
    }

    case REVIEWS_TYPES.ALL:
    default: {
      requestBase = {
        request: fetchBackmarketReviewsList,
      }
    }
  }

  const ALLOWED_QUERY = ['rate_min', 'rate_max', 'order_by']
  const filteredQuery = filterObjectProperties(route.query, (key) =>
    ALLOWED_QUERY.includes(key),
  )

  return {
    ...requestBase,
    queryParams: {
      ...requestBase.queryParams,
      ...filteredQuery,
      ...insertIf(Number.isInteger(options.limit), { limit: options.limit }),
      ...insertIf(Number.isInteger(options.page), { page: options.page }),
    },
  }
}

/**
 * Get the rate distribution endpoint to call for the given reviews type
 * to display the repartition of the rates
 * @param {import('./constants').REVIEWS_TYPES} type
 * @param {NormalizedParams} params
 * @returns {Request}
 */
export function getRateDistributionRequest({ type, params } = {}) {
  switch (type) {
    case REVIEWS_TYPES.PRODUCT: {
      return {
        request: fetchProductRateDistribution,
        pathParams: { productId: params.id },
        // [COM-362] TODO to remove withReviewTranslation when it is done on BE side
        // This endpoint is the only one handling translated reviews
        queryParams: {
          withReviewTranslation: true,
        },
      }
    }

    case REVIEWS_TYPES.LANDING: {
      return {
        request: fetchLandingRateDistribution,
        queryParams: {
          pk: params.id,
        },
      }
    }

    case REVIEWS_TYPES.MERCHANT:
      return {
        request: fetchMerchantRateDistribution,
        queryParams: {
          id: params.id,
        },
      }

    case REVIEWS_TYPES.ALL:
    default: {
      return {
        request: fetchBackmarketRateDistribution,
      }
    }
  }
}

/**
 * Get the rating categories endpoint to call for the given route
 * to display the rate of each categories (shipping, battery, ...)
 * @param {import('vue-router').Route} route
 * @returns {Request} rating categories endpoint
 */
export function getRatingCategoriesRequest({ params } = {}) {
  // used only on the page for reviews of a given product
  return {
    request: fetchRatingCategoriesByProduct,
    pathParams: { productId: params?.productId },
    // [COM-362]] TODO to remove withReviewTranslation when it is done on BE side
    queryParams: { withReviewTranslation: true },
  }
}
