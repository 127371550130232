export const CONTEXT = {
  version: '0.0.1',
  name: 'context',
}

export const MODAL = {
  version: '0.0.1',
  name: 'modal',
}

export const PAGE_VIEW = {
  version: '0.0.1',
  name: 'page-view',
}

export const RESIZE = {
  version: '0.0.1',
  name: 'resize',
}

export const CLICK = {
  version: '0.0.1',
  name: 'click',
}

export const USER_CONTEXT = {
  version: '0.0.1',
  name: 'user-context',
}

export const WEB_PERFORMANCE = {
  version: '0.0.1',
  name: 'web-performance',
}

export const SEARCH_ANALYTICS = {
  version: '0.0.1',
  name: 'search-analytics',
}

export const RUM_VIEW = {
  version: '0.0.1',
  name: 'rum-view',
}

export const PAYMENT = {
  version: '0.0.1',
  name: 'payment',
}
