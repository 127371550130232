import { COUNTRIES } from '@config/constants'

export const GDPR_SETTINGS_ANCHOR = 'settings'

export const COOKIES_TRACKING_PREFIX = 'CMP'

export const COOKIES_TRACKING_NAME = {
  ACCEPT: 'accept',
  BANNER: 'v1',
  BACK: 'back',
  DATA_PROTECTION: 'data_protection',
  INFO: 'more_information',
  LEGAL_MENTIONS: 'legal_mentions',
  MODAL: 'v2',
  MODAL_STEP: 'step',
  REFUSE: 'refuse',
  SAVE: 'save_settings',
  SETTINGS: 'settings',
  TOGGLE: 'toggle',
}

export const COUNTRIES_WITHOUT_MODAL = [COUNTRIES.AU, COUNTRIES.US]

export const LEGAL_PAGE_NAME = {
  DATA_PROTECTION: 'data-protection',
  OTHER: 'other-information',
  BUYBACK: 'terms-of-trade-in',
  COOKIES: 'cookies',
  TERMS_SALE: 'terms-of-sale',
  TERMS_USE: 'terms-of-use',
  TERMS_SERVICE: 'terms-of-service',
  WARRANTY: 'warranty',
  LEGAL_MENTIONS: 'legal-mentions',
  REFER_A_FRIEND: 'refer-a-friend',
}
