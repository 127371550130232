import { ROUTES } from '@router'

export const CHECKOUT_STEP_IDS = {
  [ROUTES.CHECKOUT.CART]: 1,
  [ROUTES.CHECKOUT.HELLO]: 2,
  [ROUTES.CHECKOUT.LOGIN]: 3,
  [ROUTES.CHECKOUT.SIGNUP]: 3,
  [ROUTES.CHECKOUT.ADDRESS_CONFIRMATION]: 4,
  [ROUTES.CHECKOUT.SHIPPING_ADDRESS]: 4,
  [ROUTES.CHECKOUT.BILLING_ADDRESS]: 5,
  [ROUTES.CHECKOUT.PAYMENT]: 6,
}
