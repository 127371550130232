const breakpoints = [
  '(min-width: 992px)',
  '(min-width: 768px)',
  '(min-width: 480px)',
  '(max-width: 480px)',
]

export const SizeCategory = {
  large: 'large',
  medium: 'medium',
  small: 'small',
}

export const sizeCategories = [
  {
    name: SizeCategory.large,
    breakpoints: [breakpoints[0]],
  },
  {
    name: SizeCategory.medium,
    breakpoints: [breakpoints[1], breakpoints[2]],
  },
  {
    name: SizeCategory.small,
    breakpoints: [breakpoints[3]],
  },
]

export default breakpoints
