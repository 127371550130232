
import { PAYMENT_GROUPS } from '../../methods'

import InstallmentSimulationModal from './InstallmentSimulationModal.vue'

export default {
  components: {
    InstallmentSimulationModal,
  },

  props: {
    name: {
      type: String,
      default: 'OneyModal',
    },

    /**
     * @typedef {Price}
     */
    basePrice: {
      type: Object,
      required: true,
    },

    paymentMethods: {
      type: Array,
      default: null,
    },
  },

  computed: {
    PAYMENT_GROUPS: () => PAYMENT_GROUPS,
  },
}
