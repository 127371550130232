import logger from '@logger'

/**
 * We have a bunch of security rules on Cloudflare that can cause issues when blocking the requests. We had
 * some errors on payment endpoints due to this, and one of the way to detect this is to look at mismatches between
 * request & response content-type.
 *
 * - https://developers.cloudflare.com/waf/managed-rules/reference/owasp-core-ruleset/
 * - https://backmarket.atlassian.net/wiki/spaces/BOT/pages/3093922351/2023-02-20+Huge+payment+decrease+in+BE+other+tier+3+countries
 */
export const cloudflareContentTypeMismatch =
  () =>
  ({ config, error }) => {
    try {
      if (
        config.requestHeaders.accept !== error.responseHeaders['content-type']
      ) {
        logger.error('Content-Type mismatch', { config, error })
      }
    } catch (e) {
      // Fail silently, as we don't want to add another layer of error :x
    }
  }
