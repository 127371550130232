import { fromPairs, mapValues } from '@core/helpers'
import { config } from '@flags/config'

/**
 * Simple dictionary of flags, where keys are flags names.
 * @typedef {Record<string, string>} Flags
 *
 * @typedef State
 * @property {Flags} flags
 */

/**
 * Filter out all flags from the dictionary that do not match the given prefix,
 * and return a new dictionary where each key is not prefixed anymore.
 *
 * @example
 * const dictionary = {
 *   'experiment.one': 'A',
 *   'experiment.two': 'B',
 *   'rollout.three': 'C',
 *   'rollout.four': 'D',
 * }
 *
 * const result = getFlagsWithPrefix(dictionary, 'experiment.')
 * // => { one: 'A', two: 'B' }
 *
 * @param {Flags} flags
 * @param {string} prefix
 * @return {Flags}
 */
function getFlagsWithPrefix(flags, prefix) {
  const pairs = Object.entries(flags)
    .filter(([key]) => key.startsWith(prefix))
    .map(([key, value]) => [key.replace(prefix, ''), value])

  return fromPairs(pairs)
}

/**
 * Simple Vuex module that is used to store experiments used to render pages.
 *
 * It works in duo with the experiments worker whose job is to assign such
 * experiments values to incoming requests. After that, those values get stored
 * in here, and then used across the whole codebase as needed.
 */
export default {
  namespaced: true,

  /** @return {State} */
  state() {
    return {
      flags: {},
    }
  },

  getters: {
    /**
     * Only get flags marked as experiment. According to our naming conventions,
     * they are prefixed with `experiment.`.
     *
     * @param {State} state
     * @return {Flags}
     */
    getExperiments: (state) => {
      return getFlagsWithPrefix(state.flags, 'experiment.')
    },

    /**
     * Only get flags marked as rollout. According to our naming conventions,
     * they are prefixed with `rollout.`.
     *
     * @param {State} state
     * @return {Flags}
     */
    getRollouts: (state) => {
      return getFlagsWithPrefix(state.flags, 'rollout.')
    },

    /**
     * Return true if all flags got assigned their control variant. Otherwise,
     * return false.
     *
     * @param {State} state
     * @return {boolean}
     */
    isDefaultSegment: (state) => {
      const storedFlagsCount = Object.keys(state.flags).length
      const configuredFlagsCount = Object.keys(config).length

      if (configuredFlagsCount !== storedFlagsCount) {
        return false
      }

      // Using forEach is more complex since we want the function to return.
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(state.flags)) {
        if (config[key].defaultValue !== value) {
          return false
        }
      }

      return true
    },

    /**
     * This is probably not what you are looking for. If you want to consume
     * flags from a Vue component or another Vuex store module, you have
     * to use the getExperiments or getRollouts getters instead.
     *
     * @param {State} state
     * @return {Flags}
     */
    getAll: (state) => state.flags,
  },

  mutations: {
    /**
     * Mandatory mutation used to actually store the flags into the state.
     *
     * @param {State} state
     * @param {Flags} flags
     */
    setFlags(state, flags) {
      state.flags = flags
    },
  },

  actions: {
    /**
     * Load the given flags into this Vuex module.
     *
     * We apply default values to all flags that were not part of the given
     * dictionary. This is especially useful when running the application
     * locally, since the experiments worker (whose job is to assign
     * experiments values and pass them via some HTTP header) is
     * not executed in that context.
     *
     * @param {Flags} flags
     */
    load({ commit }, flags) {
      const defaultFlags = mapValues(config, (flag) => flag.defaultValue)

      commit('setFlags', {
        ...defaultFlags,
        ...flags,
      })
    },
  },
}
