/**
 * Encryption options
 * See https://github.com/Adyen/adyen-cse-web/blob/master/js/adyen.encrypt.js#L88-L124
 */
const OPTIONS = {
  cvcIgnoreBins: '6703',
}

/**
 * @returns {Promise<Object>}
 */
export async function getAdyenSdk() {
  if (process.server) {
    throw new Error("Can't load Adyen clien-side SDK on server-side")
  }

  try {
    const imported = await import('../vendors/adyen.encrypt.nodom')

    return imported.default
  } catch (error) {
    throw new Error(`Could not load Adyen SDK: ${error.message || error}`)
  }
}

/**
 * @param {string} [key] Encryption key, optional for validation.
 * @returns {Promise<Object>}
 */
export async function createAdyenEncryption(key) {
  return (await getAdyenSdk()).createEncryption(key, { ...OPTIONS })
}
