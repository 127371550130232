import get from 'lodash/get'

import { BOT_JS_CHALLENGE } from '@http/errorCodes'
import { ROUTES } from '@router'

export const cloudflareBotManagement = ($navigation, $config) => (params) => {
  const errorCodes = get(params, 'error.payload.errors', []).map(
    (error) => error.code,
  )

  if (errorCodes.includes(BOT_JS_CHALLENGE)) {
    if ($config.IS_DEVELOPMENT) {
      console.error('Cloudflare bot management error', { params, errorCodes })
    } else {
      $navigation.push({
        type: 'internal',
        name: ROUTES.TEST_CHALLENGE,
      })
    }
  }
}
