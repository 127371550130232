
export default {
  props: {
    definition: {
      type: Object,
      required: true,
      validator: (definition) => {
        return (
          typeof definition.id === 'string' &&
          typeof definition.defaultMessage === 'string'
        )
      },
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  computed: {
    content() {
      // From vuejs 2.6.0 v-slot:named are missing from this.$slots on rerender
      // this.$scopedSlots will though. We extract the slots and passed it down
      // to the i18n html utils.
      const slots = Object.entries(this.$scopedSlots).reduce((acc, entry) => {
        if (typeof entry[1] === 'function') {
          return { ...acc, [entry[0]]: entry[1]() }
        }

        return acc
      }, {})

      return this.$t.html(this.definition, {
        ...this.values,
        ...slots,
      })
    },
  },
  render(h) {
    return h(this.tag, null, this.content)
  },
}
