import isEmpty from 'lodash/isEmpty'

import { getCookie, setCookie } from '@core/helpers/cookies'
import localStorage, { canUseLocalStorage } from '@core/helpers/localStorage'
import logger from '@logger'

export const canSetToLocalStorage = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  if (!canUseLocalStorage()) {
    return false
  }
  let isAvailable = true

  try {
    localStorage.setItem('space-check-test-key', 'an-acceptably-small-value')
    localStorage.removeItem('space-check-test-key')
  } catch (e) {
    isAvailable = false
  }

  return isAvailable
}

export default {
  setItem: (key, value) => {
    try {
      if (canSetToLocalStorage()) {
        localStorage.setItem(key, value)
      }
    } catch (error) {
      logger.error('Error localStorage setItem', { key, error })
    }

    try {
      setCookie(key, value)
    } catch (error) {
      logger.error('Error cookie setItem', { key, error })
    }
  },
  getItem: (key) => {
    try {
      if (canUseLocalStorage() && localStorage.hasKey(key)) {
        return localStorage.getItem(key)
      }
    } catch (error) {
      logger.error('Error localStorage getItem', { key, error })
    }

    try {
      const result = getCookie(key, document.cookie)
      if (result === undefined) {
        return null
      }

      return result
    } catch (error) {
      logger.error('Error cookie getItem', { key, error })
    }

    return null
  },
  removeItem: (key) => {
    try {
      if (canUseLocalStorage() && localStorage.hasKey(key)) {
        localStorage.removeItem(key)
      }
    } catch (error) {
      logger.error('Error localStorage removeItem', { key, error })
    }

    try {
      const expires = new Date()
      // Make it expire yesterday
      expires.setTime(expires.getTime() + -1 * 24 * 60 * 60 * 1000)

      setCookie(key, '', { expires: expires.toGMTString() })
    } catch (error) {
      logger.error('Error cookie removeItem', { key, error })
    }
  },
  hasKey: (key) => {
    try {
      if (canUseLocalStorage()) {
        if (!isEmpty(localStorage.getItem(key))) {
          return true
        }
      }
    } catch (error) {
      logger.error('Error hasKey', { key, error })
    }

    return !isEmpty(getCookie(key, document.cookie))
  },
}
