// Cookie list, including:
// name: String, name of the cookie
// expiration: String, expiration date of the cookie in ms

// Average value of one month (could be 28 to 31 days per month)
const ONE_MONTH_IN_MS = 2629746000

export const COOKIE_VISITOR_ID = {
  name: 'visitor_id',
  // maximum authorized by RGPD
  expiration: ONE_MONTH_IN_MS * 13,
}

export const COOKIE_SESSION_ID = {
  name: 'session_id',
  // 30 minutes session
  expiration: 60 * 1000 * 30,
}
