export default {
  bind(element, binding, vnode) {
    const handler = (event) => {
      if (!vnode.context) return
      if (element === event.target) return
      if (element.contains(event.target)) return

      element.clickOutsideDirective.callback(event)
    }

    // Let's save the handler on the element itself so we can access it when unbinding the directive.
    // eslint-disable-next-line no-param-reassign
    element.clickOutsideDirective = {
      handler,
      callback: binding.value,
    }

    document.body.addEventListener('click', handler)
    document.body.addEventListener('touchstart', handler)
  },
  unbind(element) {
    if (!element.clickOutsideDirective) {
      return
    }

    document.body.removeEventListener(
      'click',
      element.clickOutsideDirective.handler,
    )

    document.body.removeEventListener(
      'touchstart',
      element.clickOutsideDirective.handler,
    )
  },
}
