export const USER_GROUP_VALUES = {
  NOT_LOGGED: 'NOTLOGGED',
  LOGGED_CUSTOMER: 'LOGGEDCUSTOMER',
  LOGGED_STAFF: 'LOGGEDSTAFF',
  LOGGED_MERCHANT: 'LOGGEDMERCHANT',
}

export const USER_GROUP_NAME = 'user_type'

export const SERVER_ZONE = 'EU'
