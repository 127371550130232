export default {
  popularSearches: {
    id: 'searchbar_popular_searches_title',
    defaultMessage: 'Popular searches',
  },
  recentSearches: {
    id: 'searchbar_recent_searches_title',
    defaultMessage: 'Recent searches',
  },
}
