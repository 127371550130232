
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { mapGetters } from 'vuex'

import authenticate from '@auth/util'
import Error4XX from '@core/pages/errors/4XX.vue'
import Error500 from '@core/pages/errors/500.vue'
import { ROUTES } from '@router'
import { trackError } from '@tracking/events'

import translations from './error.translations'

export default {
  components: {
    RevButton,
    RevContainer,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    const { statusCode, path } = this.error

    return {
      htmlAttrs: {
        lang: this.locale,
      },
      title: statusCode,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${statusCode} | ${path}`,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      locale: 'config/locale',
    }),
    ROUTES: () => ROUTES,
    translations: () => translations,
    is4XX() {
      return (
        this.error.statusCode === 404 ||
        this.error.statusCode === 410 ||
        this.error.statusCode === 422
      )
    },
    component() {
      if (this.is4XX) {
        return Error4XX
      }

      return Error500
    },

    ctaLink() {
      return { name: ROUTES.HOME }
    },
  },

  beforeMount() {
    authenticate({
      store: this.$store,
      config: this.$config,
    })
  },

  mounted() {
    trackError({ status: this.error.statusCode, message: this.error.message })
  },
}
