export const METHODS = {
  GET: 'GET',
  POST: 'POST',
}

export default class PaymentRedirection {
  /**
   * @param {'GET'|'POST'} method
   * @param {import('@router').Link} link
   * @param {Object} [data]
   */
  constructor(method, link, data) {
    if (process.server) {
      throw new Error('Cannot proceed to a payment redirection on server-side!')
    }
    if (!METHODS[method]) {
      throw new TypeError(`Invalid redirection method: ${method}`)
    }
    if (data !== undefined && typeof data !== 'object') {
      throw new TypeError(`Invalid redirection data: ${data}`)
    }

    this.link = link
    this.method = method
    this.data = data || {}
  }

  /**
   * @param {Window} window
   * @private
   */
  proceedPost({ document }) {
    const form = document.createElement('form')
    try {
      form.method = METHODS.POST
      form.action = this.link.href

      Object.entries(this.data).forEach(([name, value]) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      })

      document.body.appendChild(form)

      form.submit()
    } finally {
      form.remove()
    }
  }

  /**
   * Apply the redirection to the global window object.
   * @param {Object} context
   * @param {Object} context.$navigation Vuex router, required for 'GET' method.
   * @throws {Error} If this function is called server-side
   * @throws {Error} If method is unknown
   */
  proceed({ $navigation } = {}) {
    switch (this.method) {
      case METHODS.GET:
        $navigation.push(this.link)

        return

      case METHODS.POST:
        this.proceedPost(window)

        return

      default:
        throw new Error(`Unknown redirection type: ${this.method}`)
    }
  }
}
