
import { RevLink } from '@ds/components/Link'

import ClickOutside from '@core/directives/ClickOutside'
import { trackClick, trackHoverOver } from '@tracking/events'

import Menu from './Menu.vue'

export default {
  components: {
    RevLink,
    Menu,
  },

  directives: {
    ClickOutside,
  },
  props: {
    navigationItems: {
      required: true,
      type: Array,
      default: () => [],
    },
    headerVisible: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedId: -1,
      timeout: null,
      withTransition: false,
    }
  },
  watch: {
    headerVisible(next) {
      if (!next) this.close()
    },
  },
  methods: {
    select(item, timeout = 150, tracking = 'impression') {
      if (this.selectedId === -1) {
        this.timeout = setTimeout(() => {
          this.withTransition = true
          this.selectedId = item.id
          const route = this.$route
          const data = {
            zone: 'header',
            name: 'department',
            value: item.title,
            // /!\ Page Type is not a term in the ubiquitous language
            page_type: route.name,
            position: item.position,
          }
          if (tracking === 'impression') trackHoverOver(data)
          if (tracking === 'click') trackClick(data)
        }, timeout)
      } else {
        this.withTransition = false
        this.selectedId = item.id
      }
    },
    reset() {
      clearTimeout(this.timeout)
    },
    close() {
      this.reset()
      this.withTransition = true
      this.selectedId = -1
    },
  },
}
