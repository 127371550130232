// @ts-check
import isEmpty from 'lodash/isEmpty'

import { fetchEndpointsFromOpenIdConfig } from '@auth-oauth2/core/endpoints'
import { getPkceChallenge } from '@auth-oauth2/core/pkce'
import { retry } from '@core/helpers/retry'
import { getAuthGateway } from '@http/endpoints'

const getRandomAlphaNumericString = (length = 9) => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((x) => charset[x % charset.length])
    .join('')
}

export class AuthOauth2 {
  /** @type { import('@auth-oauth2/types').OauthTokens | null}  */
  tokens = null

  /** @type { import('@auth-oauth2/types').OauthEndpoints | null } */
  endpoints = null

  /** @type {ReturnType<typeof import('@auth-oauth2/core/pkce')['getPkceChallenge']>} */
  pkce = null

  get pkceVerifier() {
    return this.browserStorage.getItem(
      `${this.options.storage.prefix}pkceVerifier`,
    )
  }

  get oauthState() {
    return this.browserStorage.getItem(
      `${this.options.storage.prefix}oauthState`,
    )
  }

  get loginEndpoint() {
    const loginPath = this.options.redirectPath

    return this.getUrl(loginPath).toString()
  }

  get logoutEndpoint() {
    const { logoutPath } = this.options

    return new URL(logoutPath, this.options.domainBaseUrl).toString()
  }

  /**
   * @param {import('@auth-oauth2/types').OauthModuleOptions} options
   * @param {Object} ctx - Nuxt context
   * @param {Storage} tabStorage - Temporal storage, linked to the tab
   * @param {Storage} browserStorage - Persistent storage, linked to the whole browsing context
   */
  constructor(options, ctx, tabStorage, browserStorage) {
    if (isEmpty(options)) {
      throw new Error('[AuthOauth2] options are required in $auth')
    }

    /** @type {import('@auth-oauth2/types').OauthModuleOptions} */
    this.options = options

    this.ctx = ctx

    /** @type {Storage} */
    this.tabStorage = tabStorage

    /** @type {Storage} */
    this.browserStorage = browserStorage
  }

  async getEndpoints() {
    const { domainBaseUrl } = this.options
    const FALLBACK = {
      token: new URL('/oauth2/token', domainBaseUrl).toString(),
      revocation: new URL('/oauth2/revoke', domainBaseUrl).toString(),
    }

    const remotelyFetchEndpoints = async () => {
      let endpoints
      try {
        endpoints = await fetchEndpointsFromOpenIdConfig(domainBaseUrl)
      } catch (error) {
        // fallback to hardcoded endpoints
        endpoints = FALLBACK
      } finally {
        // we could get a partial response from the server
        if (isEmpty(endpoints)) {
          endpoints = FALLBACK
        }

        // store endpoints only after fetching
        this.tabStorage.setItem(
          `${this.options.storage.prefix}endpoints`,
          JSON.stringify(endpoints),
        )
      }

      return endpoints
    }

    this.endpoints =
      this.endpoints ||
      JSON.parse(
        this.tabStorage.getItem(`${this.options.storage.prefix}endpoints`),
      )

    if (isEmpty(this.endpoints)) {
      this.endpoints = await remotelyFetchEndpoints()
    }
  }

  getUrl(path, baseUrl = this.ctx.store.getters['config/baseURL']) {
    return new URL(path, baseUrl)
  }

  getApiUrl(path, apiBaseUrl = this.ctx.store.getters['config/apiBaseUrl']) {
    return new URL(path, apiBaseUrl)
  }

  generatePkce() {
    this.pkce = getPkceChallenge()

    this.browserStorage.setItem(
      `${this.options.storage.prefix}pkceVerifier`,
      this.pkce.code_verifier,
    )
  }

  generateState() {
    const oauthState = getRandomAlphaNumericString()

    this.browserStorage.setItem(
      `${this.options.storage.prefix}oauthState`,
      oauthState,
    )
  }

  /**
   * @note Called just in client side
   * @see https://backmarket.atlassian.net/wiki/spaces/IDENTITY/pages/2799272036/Glossary#Authorization-Request
   */
  initiateAuthFlow({ bmJourney = null, info = null } = {}) {
    const url = this.getUrl(
      this.options.authorizationEndpoint,
      this.options.domainBaseUrl,
    )

    this.generatePkce()

    this.generateState()

    const state = this.oauthState

    if (state === null) {
      throw new Error('[AuthOauth2] null state when starting authentication')
    }

    const query = new URLSearchParams({
      // disabling camelcase rule for HTTP request
      // because we need the parameters to be like that
      /* eslint-disable camelcase */
      response_type: 'code',
      scope: 'offline_access',
      code_challenge_method: 'S256',
      client_id: this.options.clientId,
      state,
      code_challenge: this.pkce.code_challenge,
      redirect_uri: this.loginEndpoint,
      /* eslint-enable camelcase */
    })

    // $navigation does not support passing a {query: {}} object
    url.search = query.toString()

    // Add custom bm_platform parameter
    url.searchParams.append('bm_platform', 'web')

    // Add custom info parameter to display an info toast
    if (info) {
      url.searchParams.append('info', info)
    }

    // Add custom bm_journey parameter
    if (bmJourney) {
      url.searchParams.append('bm_journey', bmJourney)
    }

    // Add custom bm_experiments parameter
    if (this.options.socialLoginValidationStepExperiment) {
      url.searchParams.append(
        'bm_experiments',
        this.options.socialLoginValidationStepExperiment,
      )
    }

    this.ctx.$navigation.push({
      type: 'external',
      href: url.toString(),
    })
  }

  /**
   * After the user has been redirected back to the app, we need to exchange the
   * code for access and refresh tokens.
   * @note Called just in client-side
   *
   * @param {string} code - Authorization code from the query params
   * @param {string} oauthState - state parameter returned by the authorization server
   * @returns {Promise<import('@auth-oauth2/types').OauthTokens>}
   */
  async requestToken(code, oauthState) {
    if (!code) {
      throw new Error('[AuthOauth2] code is required')
    }

    const storedState = this.oauthState
    if (oauthState !== storedState) {
      throw new Error('[AuthOauth2] Invalid state')
    }

    await this.getEndpoints()

    const body = new URLSearchParams({
      // disabling camelcase rule for HTTP request
      // because we need the parameters to be like that
      /* eslint-disable camelcase */
      grant_type: 'authorization_code',
      code,
      client_id: this.options.clientId,
      redirect_uri: this.loginEndpoint,
      code_verifier: this.pkceVerifier,
      /* eslint-enable camelcase */
    })

    // Send request to get access and refresh tokens
    const response = await fetch(this.endpoints.token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body.toString(),
    })

    const tokens = await response.json()

    if (tokens.error) {
      throw new Error(tokens.error_description)
    }

    this.tokens = tokens

    return this.tokens
  }

  async tokenToSession() {
    if (isEmpty(this.tokens) || this.tokens.access_token === undefined) {
      throw new Error('[AuthOauth2] no access token to convert to session.')
    }

    return retry(
      () =>
        this.ctx.store.dispatch(
          'http/request',
          {
            request: getAuthGateway,
            headers: {
              Authorization: `Bearer ${this.tokens.access_token}`,
            },
          },
          {
            root: true,
          },
        ),
      3,
    )
  }

  async revokeToken() {
    if (isEmpty(this.tokens) || this.tokens.refresh_token === undefined) {
      throw new Error("[AuthOauth2] can't revoke undefined refresh token.")
    }

    const body = new URLSearchParams({
      // disabling camelcase rule for HTTP request
      // because we need the parameters to be like that
      /* eslint-disable camelcase */
      token: this.tokens.refresh_token,
      token_type_hint: 'refresh_token',
      client_id: this.options.clientId,
      /* eslint-enable camelcase */
    })

    return retry(
      () =>
        fetch(this.endpoints.revocation, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: body.toString(),
        }),
      3,
    ).then(() => {
      this.tokens = null
    })
  }

  clearStorage() {
    this.browserStorage.removeItem(`${this.options.storage.prefix}oauthState`)
    this.browserStorage.removeItem(`${this.options.storage.prefix}pkceVerifier`)
    this.tabStorage.removeItem(`${this.options.storage.prefix}endpoints`)
  }

  async logout() {
    this.clearStorage()

    return retry(
      () =>
        fetch(this.logoutEndpoint, {
          credentials: 'include',
        }),
      3,
    )
  }
}
