import isEmpty from 'lodash/isEmpty'

/**
 * @typedef {Object} SwapAdvertisement
 * @property {Price} cartPriceAfterSwap
 *
 * @typedef {Object} SwapOfferDetail
 * @property {string} label
 * @property {string} value
 *
 * @typedef {Object} SwapOffer
 * @property {Price} price
 * @property {string} title
 * @property {SwapOfferDetail[]} details
 * @property {number} listingId
 *
 * @typedef {Object} Swap
 * @property {SwapAdvertisement|null} advertisement
 * @property {SwapOffer|null} offer
 */

export default {
  namespaced: true,

  /**
   * @typedef {Swap} State
   * @returns {State}
   */
  state: () => ({
    advertisement: null,
    offer: null,
  }),

  mutations: {
    reset(state) {
      state.advertisement = null
      state.offer = null
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {{ cart_price_with_swap_discount: number }} [payload.ad]
     * @param {Object} [payload.offer]
     * @param {string} payload.offer.title
     * @param {number} payload.offer.price
     * @param {number} payload.offer.sourcing_listing
     * @param {SwapOfferDetail[]} payload.offer.diagnostic_summary
     * @param {Object} payload.offer.diagnostic_payload
     * @param {Object} payload.currency
     */
    setSwap(state, { ad, offer, currency }) {
      state.offer = isEmpty(offer)
        ? null
        : {
            title: offer.title,
            price: {
              currency,
              amount: String(offer.price),
            },
            details: offer.diagnostic_summary,
            listingId: offer.sourcing_listing,
          }

      state.advertisement = isEmpty(ad)
        ? null
        : {
            cartPriceAfterSwap: {
              currency,
              amount: String(ad.cart_price_with_swap_discount),
            },
          }
    },
  },

  getters: {
    /**
     * @param {State} state
     * @returns {boolean}
     */
    hasOffer: (state) => !isEmpty(state.offer),

    /**
     * @param {State} state
     * @returns {SwapOffer|null}
     */
    offer: (state) => state.offer,

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {boolean}
     */
    hasAdvertisement: (state, { hasOffer }) =>
      !isEmpty(state.advertisement) && !hasOffer,

    /**
     * @param {State} state
     * @returns {SwapAdvertisement|null}
     */
    advertisement: (state) => state.advertisement,
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('../modules/common').Cart} payload
     * @returns
     */
    reset({ commit }) {
      commit('reset')
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('../modules/common').Cart} payload
     * @returns
     */
    async set({ commit, rootGetters }, { swap }) {
      commit('setSwap', {
        ad: swap.ad,
        offer: swap.offer,
        currency: rootGetters['config/currencyCode'],
      })
    },
  },
}
