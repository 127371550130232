import isEmpty from 'lodash/isEmpty'

import {
  getCurrencyFromCountryCode,
  getMarketsFromMarketplace,
} from '@core/helpers/countries'
import { fetchMerchantData, getSellerFeaturesV2 } from '@http/endpoints'

export const getMerchantMarket = (countryCode) => ({
  countryCode,
  currency: getCurrencyFromCountryCode(countryCode),
})

export default {
  namespaced: true,

  state() {
    return {
      info: {
        activeMarkets: [],
        boInChineseEnabled: false,
        canOpenNewMarkets: false,
        company: null,
        countryOfOrigin: null,
        email: null,
        featureFlags: {},
        fetched: false,
        hasKybVerified: false,
        hasOnlyOneMarket: false,
        id: null,
        isAdminUser: false,
        isBackshipEnabled: false,
        isBuybackEnabled: false,
        isImpersonated: false,
        isNewBatteryListingsEnabled: false,
        isListingFilteringOptimizationEnabled: false,
        markets: [],
        needToSignNewCgu: false,
        onboardingCompleted: false,
        permissions: [],
        potentialMarkets: [],
        referenceMarket: null,
        shopLink: null,
        vat: {},
        isPremiumGradeListingsEnabled: false,
        deliveryMethodsApiVersion: '0',
      },
      features: {},
    }
  },

  getters: {
    fetched: (state) => state.info.fetched,
    activeMarkets: (state) => state.info.activeMarkets.map(getMerchantMarket),
    boInChineseEnabled: (state) => state.info.boInChineseEnabled,
    canOpenNewMarkets: (state) => state.info.canOpenNewMarkets,
    company: (state) => state.info.company,
    countryOfOrigin: (state) => state.info.countryOfOrigin,
    email: (state) => state.info.email,
    featureFlags: (state) => state.info.featureFlags,
    hasKybVerified: (state) => state.info.hasKybVerified,
    hasOnlyOneMarket: (state) => {
      // to get all markets, we need to add the amount of potentialMarkets and activeMarkets
      // for example, this is a way to share to the store a boolean that help us
      // know if we should display components related to markets
      return (
        state.info.potentialMarkets.length + state.info.activeMarkets.length ===
        1
      )
    },
    id: (state) => state.info.id,
    isAdminUser: (state) => state.info.isAdminUser,
    isBackshipEnabled: (state) => state.info.isBackshipEnabled,
    isBuybackEnabled: (state) => state.info.isBuybackEnabled,
    isImpersonated: (state) => state.info.isImpersonated,
    isListingFilteringOptimizationEnabled: (state) =>
      state.info.isListingFilteringOptimizationEnabled,
    isNewBatteryListingsEnabled: (state) =>
      state.info.isNewBatteryListingsEnabled,
    isOffline: (state) => isEmpty(state.info.activeMarkets),
    isPremiumGradeListingsEnabled: (state) =>
      state.info.isPremiumGradeListingsEnabled,
    marketsOfMarketplace: (state, getters, rootState, rootGetters) =>
      getMarketsFromMarketplace(rootGetters['config/marketplace']).map(
        (market) => ({
          countryCode: market.country,
          currency: getCurrencyFromCountryCode(market.country),
        }),
      ),
    needToSignNewCgu: (state) => state.info.needToSignNewCgu,
    shopLink: (state) => state.info.shopLink,
    onboardingCompleted: (state) => state.info.onboardingCompleted,
    permissions: (state) => state.info.permissions,
    potentialMarkets: (state) =>
      state.info.potentialMarkets.map(getMerchantMarket),
    referenceMarket: (state) =>
      state.info.referenceMarket
        ? getMerchantMarket(state.info.referenceMarket)
        : {},
    vat: (state) => {
      return state.info.vat.isConcernedByVatCollection
        ? state.info.vat
        : {
            ...state.info.vat,
            defaultVatType: null,
          }
    },
    /**
     * The hasFeatureEnabled function check if a feature is available.
     * It checks if the featureName correspond to an actual feature, and if yes,
     * it checks in the state if this feature is activated.
     * If it does not correspond to any feature, it returns true (kind of whitelisting everything by default)
     */
    hasFeatureEnabled: (state) => (featureName) => {
      return state.features[featureName] !== undefined
        ? !!state.features[featureName]
        : true
    },
    hasAccessToNewShippingPage: (state) =>
      state.info.deliveryMethodsApiVersion?.toString() === '1',
  },

  mutations: {
    setInfo(
      state,
      {
        activeMarkets,
        boInChineseEnabled,
        canOpenNewMarkets,
        company,
        countryOfOrigin,
        deliveryMethodsApiVersion,
        featureFlags,
        hasKybVerified,
        id,
        isBackshipEnabled,
        isBuybackEnabled,
        isImpersonated,
        isListingFilteringOptimizationEnabled,
        isNewBatteryListingsEnabled,
        isPremiumGradeListingsEnabled,
        needToSignNewCgu,
        onboardingCompleted,
        potentialMarkets,
        referenceMarket,
        sellerUser,
        shopLink,
        vat,
      },
    ) {
      state.info.activeMarkets = activeMarkets
      state.info.boInChineseEnabled = boInChineseEnabled
      state.info.canOpenNewMarkets = canOpenNewMarkets
      state.info.company = company
      state.info.countryOfOrigin = countryOfOrigin
      state.info.email = sellerUser.email
      state.info.featureFlags = featureFlags
      state.info.fetched = true
      state.info.hasKybVerified = hasKybVerified
      state.info.id = id
      state.info.isAdminUser = sellerUser.isAdmin
      state.info.isBackshipEnabled = isBackshipEnabled
      state.info.isBuybackEnabled = isBuybackEnabled
      state.info.isImpersonated = isImpersonated
      state.info.isListingFilteringOptimizationEnabled =
        isListingFilteringOptimizationEnabled
      state.info.isNewBatteryListingsEnabled = isNewBatteryListingsEnabled
      state.info.needToSignNewCgu = needToSignNewCgu
      state.info.onboardingCompleted = onboardingCompleted
      state.info.permissions = sellerUser.permissions
      state.info.potentialMarkets = potentialMarkets
      state.info.referenceMarket = referenceMarket
      state.info.shopLink = shopLink
      state.info.vat = vat
      state.info.deliveryMethodsApiVersion = deliveryMethodsApiVersion
      state.info.isPremiumGradeListingsEnabled = isPremiumGradeListingsEnabled
    },
    setFeatures(state, features) {
      state.features = features
    },
  },

  actions: {
    async fetchInfo({ commit, dispatch }) {
      const { payload } = await dispatch(
        'http/request',
        { request: fetchMerchantData },
        { root: true },
      )

      commit('setInfo', payload)
    },
    async fetchFeatures({ commit, dispatch }) {
      const { payload } = await dispatch(
        'http/request',
        { request: getSellerFeaturesV2 },
        { root: true },
      )

      commit('setFeatures', payload)
    },
  },
}
