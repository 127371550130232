import axios from 'axios'
import { stringify } from 'query-string'

import { replaceUrlParameters } from '@core/helpers'
import { createCorrelationId } from '@tracking/correlation-ids'

import {
  BUNDLE_ID,
  DEFAULT_STATUS_ERROR,
  DEFAULT_TIMEOUT,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_PATCH,
  METHOD_POST,
  METHOD_PUT,
  PLATFORM,
  UNKNOWN_ERROR,
} from './apiConstants'
import APILogger from './apiLogger'
import { HTTP_AGENT_KEEP_ALIVE } from './apiServerConstants'
import { DEFAULT_ERROR_MESSAGES } from './errorCodes'
import {
  HEADER_ACCEPT,
  HEADER_APP_BUNDLE_ID,
  HEADER_APP_PLATFORM,
  HEADER_APP_VERSION,
  HEADER_CONTENT_TYPE,
  HEADER_MODIFIED_SINCE,
  HEADER_REQUEST_ID,
  HEADER_VISITOR_ID,
} from './headers'
import { formatErrorResponse } from './helpers/errorFormat'
import { ON_ATTEMPT, ON_CANCEL, ON_ERROR, ON_SUCCESS } from './hooks/names'

const payloadMapping = (payload, maps) => {
  return maps.reduce((acc, curr) => curr(acc), payload)
}

export const { isCancel } = axios

export const formatAPIErrorMessage = (errorParameters) => {
  const errorToThrow = new Error(errorParameters.message)

  Object.entries(errorParameters).forEach(([paramName, paramValue]) => {
    errorToThrow[paramName] = paramValue
  })

  return errorToThrow
}

class API {
  static get = API.createRequest(METHOD_GET)

  static post = API.createRequest(METHOD_POST)

  static patch = API.createRequest(METHOD_PATCH)

  static put = API.createRequest(METHOD_PUT)

  static delete = API.createRequest(METHOD_DELETE)

  static createRequest(method) {
    return ({
        name,
        path,
        defaultQueryParams = {},
        headers: defaultHeaders,
        apiBaseUrl,
        ...transformers
      }) =>
      (config = {}) => {
        const { queryParams, headers, ...apiConfig } = config
        const request = new API({
          method,
          path,
          name,
          queryParams: {
            ...defaultQueryParams,
            ...queryParams,
          },
          headers: {
            ...defaultHeaders,
            ...headers,
          },
          ...transformers,
          ...apiConfig,
          apiBaseUrl: apiBaseUrl || apiConfig.apiBaseUrl,
        })

        return request.call()
      }
  }

  /**
   * @param {Object} config
   * @param {string} config.name Name of the endpoint
   * @param {string} config.apiBaseUrl BaseUrl of the API to call
   * @param {string} config.method API Method to use
   * @param {string} config.path Endpoint path
   * @param {Object} config.hooks Hooks available for the SDK
   * @param {Object} config.pathParams Values for endpoint `path` parameters.
   * Ex: '/todos/:id` + `{ 'id': 'abc' }` => '/todos/abc`
   * @param {Object} [config.queryParams] Request URL query parameters
   * @param {Object} [config.body] Request body
   * @param {abortSignal} [config.abortSignal] Allows cancelling the request based on AbortController
   * @param {Object} [config.headers] Overwrite default headers (see #headers), or adding new ones
   * @param {Function[]} [config.transformRequest] Allows changes to the request data before it is sent to the server
   * @param {Function[]} [config.transformResponse] Allows changes to the response before it is returned
   * @param {boolean} [config.withCredentials] Allow disabling withCredentials, ex: for external URLs
   * @param {boolean} [config.withTrackingHeaders] Allow omitting tracking headers, ex: for external URLs
   * @param {number} [config.timeout] timeout value in milliseconds
   */
  constructor({
    method,
    path,
    name,
    apiBaseUrl,
    pathParams,
    queryParams,
    body,
    hooks = {},
    abortSignal,
    transformRequest = [],
    transformResponse = [],
    headers = {},
    withCredentials = true,
    withTrackingHeaders = true,
    timeout = DEFAULT_TIMEOUT,
    debug = false,
  }) {
    this.debug = debug
    this.name = name
    this.apiBaseUrl = apiBaseUrl
    this.customHeaders = headers
    this.method = method
    this.queryParams = queryParams || {}
    this.pathParams = pathParams || {}
    this.data = body
    this.hooks = hooks
    this.abortSignal = abortSignal
    // Generate a unique request ID to trace the request.
    this.requestID = createCorrelationId()
    this.endpointPattern = path
    this.endpoint = replaceUrlParameters(path, pathParams)
    this.transformRequest = transformRequest
    this.transformResponse = transformResponse
    this.withCredentials = withCredentials
    this.withTrackingHeaders = withTrackingHeaders
    this.timeout = timeout
  }

  get headers() {
    return {
      [HEADER_CONTENT_TYPE]: 'application/json',
      [HEADER_ACCEPT]: 'application/json',
      ...(this.withTrackingHeaders
        ? {
            [HEADER_APP_PLATFORM]: PLATFORM,
            [HEADER_APP_VERSION]: process.env.VERSION || 'unknown',
            [HEADER_APP_BUNDLE_ID]: BUNDLE_ID,
            [HEADER_REQUEST_ID]: this.requestID,
          }
        : null),
      // By default, axios passes all headers defined from the initial caller
      // through, without altering them. This causes issues on CloudFront side,
      // since they return a 304 (Not Modified) HTTP response, if the
      // `If-Modified-Since` header was given, and that the file was indeed not
      // modified since.
      [HEADER_MODIFIED_SINCE]: false,
      ...this.customHeaders,
    }
  }

  get apiConfig() {
    return {
      requestHeaders: this.headers,
      platform: PLATFORM,
      bundleId: BUNDLE_ID,
      baseUrl: this.apiBaseUrl,
      endpointName: this.name,
      endpointPattern: this.endpointPattern,
      endpoint: this.endpoint,
      method: this.method,
      queryParams: this.queryParams,
      pathParams: this.pathParams,
      request_id: this.requestID,
      bm: { visitor_id: this.headers[HEADER_VISITOR_ID] },
    }
  }

  loggerMessage(status) {
    const defaultMessage = `${this.apiConfig.method} ${this.apiConfig.endpointPattern}`
    if (status) {
      return `${defaultMessage} - ${status}`
    }

    return defaultMessage
  }

  performRequest() {
    const axiosConfig = {
      withCredentials: this.withCredentials,
      baseURL: this.apiBaseUrl,
      timeout: this.timeout,
    }

    // Note: on the server side we use HTTP1 (wireguard does encryption at layer 3 level)
    if (process.server) {
      // when doing SSR we want to keep alive tcp connection as mush as we can
      // doing keep alive will reuse tcp connections and avoid doing dns lookup for each
      // request.
      axiosConfig.httpAgent = HTTP_AGENT_KEEP_ALIVE
    }

    const http = axios.create(axiosConfig)

    const requestParams = {
      url: this.endpoint,
      params: this.queryParams,
      method: this.method,
      headers: this.headers,
      transformRequest: [
        ...this.transformRequest,
        ...axios.defaults.transformRequest,
      ],
      // Since our API expects a different formatting strategy, arrays need to
      // be passed as `?colors=blue&colors=red` instead of
      // `?colors[]=blue&colors[]=red`.
      paramsSerializer: stringify,
      signal: this.abortSignal,
    }
    if (this.data !== undefined && this.data !== null) {
      requestParams.data = this.data
    }

    if (this.debug) {
      console.log(requestParams)
    }

    return http.request(requestParams)
  }

  async fireHook(hookName, payload) {
    if (hookName in this.hooks) {
      if (!Array.isArray(this.hooks[hookName])) {
        throw new Error('Hooks has to be an Array')
      }

      await Promise.all(this.hooks[hookName].map((hook) => hook(payload)))
    }
  }

  async call() {
    const apiLogger = new APILogger(this.apiConfig)
    apiLogger.attempt()

    try {
      await this.fireHook(ON_ATTEMPT, { config: this.apiConfig })

      const response = await this.performRequest()

      await this.fireHook(ON_SUCCESS, { config: this.apiConfig, response })

      apiLogger.success({
        message: this.loggerMessage(response.status),
        status_code: response.status,
        responseHeaders: response.headers,
      })

      return {
        ...this.apiConfig,
        status_code: response.status,
        responseHeaders: response.headers,
        // Axios transformResponse tries transforming even if the request fails.
        // We put it out of axios to map only if request is succesfull.
        payload: payloadMapping(response.data, this.transformResponse),
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        await this.fireHook(ON_CANCEL, { config: this.apiConfig, error })
        apiLogger.cancel({ message: this.loggerMessage() })
        throw error
      }

      const errorVersionFormatted = formatErrorResponse(error)

      const { response = {}, message, code, config = {} } = error

      const statusCode = response.status || DEFAULT_STATUS_ERROR

      const deprecatedFormatError = {
        message: this.loggerMessage(statusCode),
        errorMessage: message,
        status_code: statusCode,
        code: code || DEFAULT_ERROR_MESSAGES[statusCode] || UNKNOWN_ERROR,
        requestHeaders: config.headers,
        // TODO: mocked api doesn't allow us to test this part yet As mock API
        // will be re-work, we will need to add test at this moment.
        responseHeaders: response.headers,
        payload: response.data,
      }

      // The error Object contains the deprecated formatted error by default and
      // the latest formatted error if the type of the error response received is v3.
      // TODO - The error Object should contain only the formatted error returned by
      // formatError when all the endpoints will be migrated to error response v3 used.

      const legacyError = { ...deprecatedFormatError, ...errorVersionFormatted }

      await this.fireHook(ON_ERROR, {
        body: this.data,
        error: legacyError,
        config: this.apiConfig,
      })

      apiLogger.fail(legacyError)

      throw formatAPIErrorMessage({
        ...this.apiConfig,
        ...legacyError,
        message,
      })
    }
  }
}

export default API
