export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
    }
  },
  getters: {
    get: (state) => state.isLoading,
  },
  mutations: {
    set: (state, data) => {
      state.isLoading = data
    },
  },
  actions: {
    set: ({ commit }, datas) => commit('set', datas),
  },
}
