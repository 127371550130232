import flatMap from 'lodash/flatMap'

import { META_TAGS } from './constants'

/**
 * @typedef Meta
 * @type {object}
 * @property {string} hid
 * @property {string} name
 * @property {string} content
 *
 * @param {string} tag
 * @param {string} value
 *
 * @example
 * buildMetaObject({ tag: 'og:title', value: 'About us'})
 * // returns
 * {
 *    hid: 'og:title',
 *    name: 'og:title',
 *    content: 'About us',
 * }
 *
 * @returns {Meta} Meta object used to build meta tag
 */
const buildMetaObject = ({ tag, value }) => {
  return {
    hid: tag,
    name: tag,
    content: value,
  }
}

/**
 * Builds the list of meta objects for a given type of meta
 * @param {string} type Type of the meta (e.g. description)
 * @param {string} value Value of the meta
 *
 * @example
 * buildMetaForType({ type: 'title', value: 'About us'})
 * // returns
 * [
 *   {
 *     hid: 'og:title',
 *     name: 'og:title',
 *     content: 'About us',
 *   },
 *   {
 *     hid: 'twitter:title',
 *     name: 'twitter:title',
 *     content: 'About us',
 *   },
 * ]
 *
 * @returns {Meta[]} List of meta objects for a given type
 */
const buildMetaForType = ({ type, value }) =>
  META_TAGS[type].map((tag) => buildMetaObject({ tag, value }))

/**
 * Builds the list of meta objects for each type of meta
 * This list is used on the Nuxt function head() to generate the meta tags
 *
 * @typedef MetaTagType
 * @type {object}
 * @property {string} title
 * @property {string} description
 * @property {string} image
 * @property {string} url
 *
 * @param {Object<MetaTagType, string>} tags Object containing the meta type and it's associated value
 *
 * @example
 * getMetas({ title: 'About us', image: 'https://domain.com/img/relovethepreloved.png'})
 * // returns
 * [
 *   {
 *     hid: 'og:title',
 *     name: 'og:title',
 *     content: 'About us',
 *   },
 *   {
 *     hid: 'twitter:title',
 *     name: 'twitter:title',
 *     content: 'About us',
 *   },
 *   {
 *     hid: 'og:image',
 *     name: 'og:image',
 *     content: 'https://domain.com/img/relovethepreloved.png',
 *   },
 *   {
 *     hid: 'twitter:image',
 *     name: 'twitter:image',
 *     content: 'https://domain.com/img/relovethepreloved.png',
 *   },
 * ]
 *
 * @returns {Meta[]} List of meta objects
 */
export const getMetas = (tags = {}) => {
  return flatMap(Object.entries(tags), ([type, value]) =>
    META_TAGS[type]
      ? buildMetaForType({ type, value })
      : buildMetaObject({ tag: type, value }),
  )
}
