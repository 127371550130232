import logger from '@logger'

/**
 * @param {string} baseUrl - Base URL, should be the same as the auth issuer
 * @returns {Promise<import('@auth-oauth2/types').OauthEndpoints>}
 */
export const fetchEndpointsFromOpenIdConfig = async (baseUrl) => {
  const url = new URL('/.well-known/openid-configuration', baseUrl)

  try {
    const response = await fetch(url)
    const data = await response.json()

    // disabling camelcase rule for HTTP response
    // because this is what the response from the server
    // looks like
    /* eslint-disable camelcase */
    const { revocation_endpoint, token_endpoint } = data

    return {
      token: token_endpoint,
      revocation: revocation_endpoint,
    }
    /* eslint-enable camelcase */
  } catch (error) {
    logger.warn('[AuthOauth2] Failed to fetch endpoints from OpenID config', {
      error,
    })

    return {}
  }
}
