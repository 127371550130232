import { dynamicImport } from '@core/helpers'
import logger from '@logger'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const isDiagnosisFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_DIAGNOSIS?.includes(country)

  const isResolutionFlowFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_FLOW?.includes(
      country,
    )

  const isResolutionContactConfirmationFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_CONTACT_CONFIRMATION?.includes(
      country,
    )

  const isResolutionReturnConfirmationFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_RETURN_CONFIRMATION?.includes(
      country,
    )

  const isResolutionStraightRefundConfirmationFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESOLUTION_STRAIGHT_REFUND_CONFIRMATION?.includes(
      country,
    )

  const resolutionFlowRoute = [
    {
      path: 'dashboard/resolution/new/:orderlineId/:customerIssues',
      name: ROUTES.DASHBOARD.RESOLUTION.SELF_FROM_DIAGNOSIS_V2,
      component: () => dynamicImport(import('@diagnosis/ResolutionFlow')),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: isResolutionFlowFSMEnabled,
      },
    },
    // Contact form confirmation (remote assistance)
    {
      path: 'dashboard/resolution/confirmation/:resolutionId?',
      name: ROUTES.DASHBOARD.RESOLUTION.RESOLUTION_CONTACT_CONFIRMATION,
      component: () =>
        dynamicImport(
          import('@diagnosis/ResolutionFlow/ContactConfirmationPage.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: isResolutionContactConfirmationFSMEnabled,
      },
    },
    {
      path: 'dashboard/resolution/straight-refund/confirmation',
      name: ROUTES.DASHBOARD.RESOLUTION.RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
      component: () =>
        dynamicImport(
          import(
            '@diagnosis/ResolutionFlow/confirmationPages/StraightRefundConfirmation/StraightRefundConfirmation.vue'
          ),
        ),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation:
          isResolutionStraightRefundConfirmationFSMEnabled,
      },
    },
    // Return funnel confirmation
    {
      path: 'dashboard/resolution/return/confirmation/:resolutionId',
      name: ROUTES.DASHBOARD.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
      component: () =>
        dynamicImport(
          import(
            '@diagnosis/ResolutionFlow/confirmationPages/ReturnConfirmation/ReturnConfirmationPage.vue'
          ),
        ),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: isResolutionReturnConfirmationFSMEnabled,
      },
    },
    // After launch of Diagno v2 there were still customers coming to this route
    // from Diagno v1 or old links. We deal with these gracefully by sending
    // them into Diagno v2.
    {
      path: 'dashboard/claim/new/:orderlineId/:tilesIds',
      name: ROUTES.DASHBOARD.RESOLUTION.SELF,
      redirect: () => {
        logger.info('[ResolutionFlow] Redirect: Resolutionflow v1 -> Diagno v2')

        return 'dashboard/diagnosis/new/:orderlineId'
      },
    },
    {
      path: 'dashboard/diagnosis/new/:orderlineId',
      name: ROUTES.DASHBOARD.DIAGNOSIS.SELF,
      component: () =>
        dynamicImport(import('@diagnosis/DiagnosisFlow/DiagnosisFlow.vue')),
      meta: {
        auth: {
          required: true,
        },

        disableClientSideNavigation: isDiagnosisFSMEnabled,
      },
    },
  ]

  return resolutionFlowRoute
}
