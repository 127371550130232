const CONFIGS = [
  { code: 'AUD', precision: 2 },
  { code: 'EUR', precision: 2 },
  { code: 'GBP', precision: 2 },
  { code: 'JPY', precision: 0 },
  { code: 'SEK', precision: 2 },
  { code: 'USD', precision: 2 },
]

const CODES = CONFIGS.reduce(
  (acc, config) => ({ ...acc, [config.code]: config.code }),
  {},
)

export default CODES

// Prefer 'getCurrencyPrecision' helper that throws on unknown currency.
export const CURRENCIES_PRECISIONS = CONFIGS.reduce(
  (acc, config) => ({ ...acc, [config.code]: config.precision }),
  {},
)
