const middleware = {}

middleware['auth'] = require('../app/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['buybackOrCareBpo'] = require('../app/middleware/buybackOrCareBpo.js')
middleware['buybackOrCareBpo'] = middleware['buybackOrCareBpo'].default || middleware['buybackOrCareBpo']

middleware['canViewCustomers'] = require('../app/middleware/canViewCustomers.js')
middleware['canViewCustomers'] = middleware['canViewCustomers'].default || middleware['canViewCustomers']

middleware['canViewServiceRequests'] = require('../app/middleware/canViewServiceRequests.js')
middleware['canViewServiceRequests'] = middleware['canViewServiceRequests'].default || middleware['canViewServiceRequests']

middleware['countryConfig'] = require('../app/middleware/countryConfig.js')
middleware['countryConfig'] = middleware['countryConfig'].default || middleware['countryConfig']

middleware['guest'] = require('../app/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['layout'] = require('../app/middleware/layout.js')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['logger'] = require('../app/middleware/logger.js')
middleware['logger'] = middleware['logger'].default || middleware['logger']

middleware['notSeller'] = require('../app/middleware/notSeller.js')
middleware['notSeller'] = middleware['notSeller'].default || middleware['notSeller']

middleware['redirectToFrontApps'] = require('../app/middleware/redirectToFrontApps.js')
middleware['redirectToFrontApps'] = middleware['redirectToFrontApps'].default || middleware['redirectToFrontApps']

middleware['seller'] = require('../app/middleware/seller.js')
middleware['seller'] = middleware['seller'].default || middleware['seller']

middleware['staff'] = require('../app/middleware/staff.js')
middleware['staff'] = middleware['staff'].default || middleware['staff']

export default middleware
