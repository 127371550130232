
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevToast } from '@ds/components/Toast'
import { mapGetters } from 'vuex'

import { TOAST_LOGOUT } from '@config/constants/toasts'
import { logout } from '@http/endpoints'
import { ROUTES } from '@router'

import translations from './Logout.translations'

export default {
  components: {
    RevButtonBase,
    RevToast,
  },

  props: {
    nextUrl: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      isToastOpened: false,
    }
  },

  computed: {
    ...mapGetters({
      isStaff: 'user/isStaff',
      isShadowingClient: 'user/isShadowingClient',
      isShadowingMerchant: 'user/isShadowingMerchant',
      isNewAuthEnabled: 'auth/isNewAuthEnabled',
    }),

    TOAST_LOGOUT: () => TOAST_LOGOUT,

    translations: () => translations,

    isAdmin() {
      return this.isStaff || this.isShadowingClient || this.isShadowingMerchant
    },

    buttonProps() {
      // Currently, the only way to be logged out as an admin is to use the Badoom route.
      if (this.isAdmin) {
        return {
          ...this.$attrs,
          to: { name: ROUTES.AUTH.LOGOUT },
        }
      }

      return this.$attrs
    },

    eventListeners() {
      if (this.isAdmin) {
        return this.$listeners
      }

      return {
        ...this.$listeners,
        click: this.logout,
      }
    },
  },

  methods: {
    async logout() {
      this.closeToast()

      try {
        await this.$store.dispatch('http/request', {
          request: logout,
        })

        await this.$auth?.logout()

        this.redirectAfterLogout()
      } catch (err) {
        const userIsNotAuthenticated =
          err.status_code === 401 || err.status_code === 403
        if (userIsNotAuthenticated && this.isNewAuthEnabled && !this.isAdmin) {
          await this.$store.dispatch('user/fetch').catch()

          // navigate regardless
          const { href } = this.$navigation.resolve({ name: ROUTES.HOME })
          // External link used in order to trigger a reload
          this.$navigation.push({ type: 'external', href })
        } else {
          this.isToastOpened = true
        }
      }
    },

    redirectAfterLogout() {
      const query = this.nextUrl
        ? {
            next: encodeURI(this.nextUrl),
          }
        : {}

      let nextPage = { name: ROUTES.AUTH.REGISTER, query }

      if (this.isNewAuthEnabled) {
        nextPage = this.nextUrl
          ? { name: ROUTES.AUTH_OAUTH2.LOGIN, query }
          : { name: ROUTES.HOME }
      }

      const { href } = this.$navigation.resolve(nextPage)

      // External link used in order to trigger a reload
      this.$navigation.push({ type: 'external', href })
    },

    closeToast() {
      this.isToastOpened = false
    },
  },
}
