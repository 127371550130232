
import { mapGetters } from 'vuex'

import { priceToMinorUnits } from '@core/helpers/price'
import logger from '@logger'

import { PAYMENT_ADVERTISING_LOG_TYPES } from '../config/constants'
import {
  KLARNA_LIBRARY_RENDERING_TIMEOUT,
  KLARNA_MODAL_SETTINGS,
  KLARNA_MODAL_TYPES,
} from '../config/klarna'
import { getKlarnaOnSiteMessagingConfig, getKlarnaSdk } from '../helpers/klarna'

import KlarnaModalLegacy from './KlarnaModalLegacy.vue'

export default {
  name: 'KlarnaModal',

  components: {
    KlarnaModalLegacy,
  },

  props: {
    name: {
      type: String,
      default: 'KlarnaModal',
    },

    /** @type {import('vue').PropOptions<import('@config/constants/types').Price>} */
    basePrice: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<import('@payment').PaymentMethod[]>} */
    paymentMethods: {
      type: Array,
      default: null,
    },

    variant: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      klarna: null,
      cancelKlarnaInitialization: null,
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      locale: 'config/locale',
      countryPaymentMethods: 'countryConfig/paymentMethods',
    }),

    settings() {
      return KLARNA_MODAL_SETTINGS[this.variant] || {}
    },

    amount() {
      return this.basePrice ? priceToMinorUnits(this.basePrice) : undefined
    },

    placementKey() {
      return this.settings?.placementKey ?? KLARNA_MODAL_TYPES.SITEWIDE
    },

    klarnaLibraryConfig() {
      const allMethods = this.paymentMethods || this.countryPaymentMethods

      return getKlarnaOnSiteMessagingConfig(allMethods)
    },
  },

  watch: {
    basePrice() {
      this.$nextTick(() => {
        this.klarna?.OnsiteMessaging?.refresh()
      })
    },
  },

  methods: {
    async tryInitializeKlarnaSdk() {
      try {
        logger.info('[PAYMENT-ADVERTISING] Try initialize Klarna library', {
          type: PAYMENT_ADVERTISING_LOG_TYPES.LIBRARY_INITIALIZATION_START,
          modal: this.name,
        })

        if (!this.klarna) {
          this.klarna = await getKlarnaSdk(this.klarnaLibraryConfig)
        }

        await this.waitForKlarnaInitialization()

        return true
      } catch (error) {
        logger.error(
          '[PAYMENT-ADVERTISING] Unable to initialize Klarna library',
          {
            error,
            type: PAYMENT_ADVERTISING_LOG_TYPES.LIBRARY_INITIALIZATION_ERROR,
            modal: this.name,
            // PAYIN-2571: Additional information to help understand the state of the DOM when an error occurs
            klarnaDebug: {
              html: this.$refs.klarnaPlaceholder?.outerHTML,
              isKlarnaSdkLoaded: Boolean(window.Klarna),
            },
          },
        )

        return false
      }
    },

    /** Monitor the DOM to know when the klarna placement button is ready */
    async waitForKlarnaInitialization() {
      // After Klarna first inititalization, we need to refresh to populate the placements
      // Let's do it systematically to make sure "klarna-placement" components are always present in the DOM
      this.klarna.OnsiteMessaging?.refresh()

      if (this.klarnaPlacementButton()) {
        return Promise.resolve()
      }

      return new Promise((resolve, reject) => {
        let mutationObserver = null
        let interval = null
        let timeout = null

        const clean = () => {
          mutationObserver?.disconnect()
          clearInterval(interval)
          clearTimeout(timeout)
        }

        // Timeout after 30 second, to prevent infinite monitoring
        timeout = setTimeout(() => {
          clean()
          reject(Error('Klarna initialization timeout'))
        }, KLARNA_LIBRARY_RENDERING_TIMEOUT)

        mutationObserver = new MutationObserver(() => {
          if (this.klarnaPlacementButton()) {
            clean()
            resolve()
          }
        })

        mutationObserver.observe(this.$refs.klarnaPlaceholder, {
          childList: true,
          subtree: true,
        })

        // Sadly, the MutationObserver doesn't work in all cases
        // This interval is a fallback to make sure we don't miss the button
        interval = setInterval(() => {
          if (this.klarnaPlacementButton()) {
            clean()
            resolve()
          }
        }, 500)

        this.cancelKlarnaInitialization = () => {
          clean()
          reject(Error('Klarna initialization canceled'))
        }
      })
    },

    klarnaPlacementButton() {
      const button = this.$refs.klarnaPlaceholder
        ?.querySelector('*')
        ?.shadowRoot?.querySelector('button')

      // The Klarna lib produces an empty button when it's not ready
      // We need to wait for the button to have a text content
      // before we can click it
      if (button?.innerText?.trim()) {
        return button
      }

      return undefined
    },

    async open() {
      if (!this.klarnaLibraryConfig || this.isLoading) {
        return
      }
      this.isLoading = true
      if (await this.tryInitializeKlarnaSdk()) {
        this.klarnaPlacementButton()?.click()
      }
      this.isLoading = false
    },

    beforeDestroy() {
      this.cancelKlarnaInitialization?.()
    },
  },
}
