import { handleFileUpload, poll } from '@backmarket/front-upload'
import isEmpty from 'lodash/isEmpty'

import { computeNextPagePath } from '@core/helpers'
import API from '@http/api'
import {
  fetchCustomerRequestPlatformInvoiceRequests,
  provideCustomerRequestPlatformInvoiceV2,
} from '@http/endpoints'
import logger from '@logger'

async function onInvoiceSuccessfullyUploaded(
  { commit, dispatch, rootGetters },
  { orderId, uploadedInvoice },
) {
  await dispatch(
    'http/request',
    {
      request: provideCustomerRequestPlatformInvoiceV2,
      pathParams: { orderId },
      body: {
        handle: uploadedInvoice.handle,
      },
    },
    {
      root: true,
    },
  )

  return poll(
    `${rootGetters['config/apiBaseUrl']}/services/uploads`,
    uploadedInvoice.handle,
    {
      onError: (error) => {
        logger.error('Polling error', {
          step: 'polling-error',
          subService: 'front-upload',
          timestamp: Date.now(),
          error,
          uploadEntry: uploadedInvoice,
        })
      },
      onFailure: ({ status, statusReason }) => {
        commit('update', {
          orderId,
          updates: {
            processed: false,
            processedStatus: status,
            processedStatusReason: statusReason,
          },
          params: { moveToBottom: false },
        })
      },
      onSuccess: ({ status }) => {
        commit('update', {
          orderId,
          updates: { processed: true, processedStatus: status },
          params: { moveToBottom: false },
        })
      },
    },
  )
}

function onInvoiceUploadFail({ commit }, { orderId, uploadedInvoice }) {
  const [error] = uploadedInvoice.errors

  const err = new Error('Upload failed')
  err.uploadServiceError = error

  commit('update', {
    orderId,
    updates: {
      processed: false,
      processedStatus: 'failed',
      processedStatusReason: null,
    },
    params: { moveToBottom: false },
  })

  throw err
}

export default {
  namespaced: true,

  state() {
    return {
      // TODO [Clément]: Replace this by a Map when Vue supports it.
      invoiceRequestsOrdered: [],
      invoiceRequests: {},
      count: 0,
      nextInvoiceRequestsPage: null,
    }
  },
  getters: {
    count(state) {
      return state.count
    },
    hasMoreInvoiceRequests(state) {
      return !isEmpty(state.nextInvoiceRequestsPage)
    },
    list(state) {
      return state.invoiceRequestsOrdered.map(
        (orderId) => state.invoiceRequests[orderId],
      )
    },
    nextInvoiceRequestsPage(state) {
      return state.nextInvoiceRequestsPage
    },
  },
  mutations: {
    addInvoiceRequests(state, { next, results: invoiceRequests }) {
      state.nextInvoiceRequestsPage = computeNextPagePath(next)

      invoiceRequests.forEach((invoiceRequest) => {
        const { orderId } = invoiceRequest

        state.invoiceRequests[orderId] = {
          ...invoiceRequest,
          id: orderId,
        }

        const index = state.invoiceRequestsOrdered.indexOf(orderId)
        if (index > -1) {
          state.invoiceRequestsOrdered.splice(index, 1)
        }
        state.invoiceRequestsOrdered.push(orderId)
      })
    },
    removeInvoiceRequest(state, orderId) {
      delete state.invoiceRequests[orderId]

      const index = state.invoiceRequestsOrdered.indexOf(orderId)
      if (index > -1) {
        state.invoiceRequestsOrdered.splice(index, 1)
      }

      state.count -= 1
    },
    setInvoiceRequests(
      state,
      { next = null, results: invoiceRequests = [] } = {},
    ) {
      state.nextInvoiceRequestsPage = computeNextPagePath(next)

      state.invoiceRequestsOrdered = []
      state.invoiceRequests = invoiceRequests.reduce(
        (previousInvoiceRequests, invoiceRequest) => {
          const { orderId } = invoiceRequest

          state.invoiceRequestsOrdered.push(orderId)

          return {
            ...previousInvoiceRequests,
            [orderId]: { ...invoiceRequest, id: orderId },
          }
        },
        {},
      )
    },
    setCount(state, count) {
      state.count = count
    },
    update(state, { orderId, updates, params = { moveToBottom: false } }) {
      const invoiceRequest = state.invoiceRequests[orderId]

      const index = state.invoiceRequestsOrdered.indexOf(orderId)
      if (params.moveToBottom) {
        if (index > -1) {
          state.invoiceRequestsOrdered.splice(index, 1)
        }

        state.invoiceRequestsOrdered.push(orderId)
      } else if (index > -1) {
        state.invoiceRequestsOrdered.splice(index, 1, orderId)
      }

      state.invoiceRequests[orderId] = {
        ...invoiceRequest,
        ...updates,
      }
    },
  },
  actions: {
    empty({ commit }) {
      commit('setCount', null)
      commit('setInvoiceRequests', { next: null, results: [] })
    },
    async fetchInvoiceRequests({ commit, dispatch }, filters = {}) {
      dispatch('customerRequestPlatform/conversations/empty', '', {
        root: true,
      })

      const filtersToUse = {
        ...filters,
        // We really want `undefined` here as we don't want the parameter to be
        // sent.
        processed: filters.processed === 'all' ? undefined : filters.processed,
      }

      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformInvoiceRequests,
          queryParams: filtersToUse,
        },
        { root: true },
      )

      commit('setInvoiceRequests', payload)
      commit('setCount', payload.count)
    },
    async fetchNextInvoiceRequests({ commit, dispatch, getters }) {
      if (!getters.hasMoreInvoiceRequests) {
        return
      }

      const config = {
        request: API.get({
          name: 'fetchNextInvoiceRequestsPage',
          path: getters.nextInvoiceRequestsPage,
        }),
      }
      const { payload } = await dispatch('http/request', config, {
        root: true,
      })

      commit('addInvoiceRequests', payload)
    },
    async provide({ commit, dispatch, rootGetters }, { orderId, invoice }) {
      let cancel

      const uploadedInvoice = await handleFileUpload(
        `${rootGetters['config/apiBaseUrl']}/services/uploads`,
        'care_merchant_invoice',
        { orderId },
        invoice,
        logger,
      )

      commit('update', {
        orderId,
        updates: { processed: false, processedStatus: 'pending' },
        params: { moveToBottom: false },
      })

      switch (uploadedInvoice.status) {
        case 'success':
          cancel = await onInvoiceSuccessfullyUploaded(
            { commit, dispatch, rootGetters },
            { orderId, uploadedInvoice },
          )
          break

        case 'failed':
          onInvoiceUploadFail({ commit }, { orderId, uploadedInvoice })
          break

        default:
          commit('update', {
            orderId,
            updates: { processed: false, processedStatus: 'failed' },
            params: { moveToBottom: false },
          })

          throw new Error('Upload failed, unknown status')
      }

      // Update the global counts (those displayed in the sidebar)
      dispatch(
        'customerRequestPlatform/information/fetchCounts',
        {},
        {
          root: true,
        },
      )

      return cancel
    },
    remove({ commit, dispatch }, orderId) {
      commit('removeInvoiceRequests', orderId)

      // Update the global counts (those displayed in the sidebar)
      dispatch(
        'customerRequestPlatform/information/fetchCounts',
        {},
        {
          root: true,
        },
      )
    },
    update({ commit }, { orderId, updates, params = {} }) {
      commit('update', { orderId, updates, params })
    },
  },
}
