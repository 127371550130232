import isEmpty from 'lodash/isEmpty'

import { APP_NAME, CHECKOUT_STEP_IDS, COOKIES } from '@config/constants'
import { insertIf, isNode, removeEmptyValuesInObject } from '@core/helpers'
import { isDateValid } from '@core/helpers/date'
import { COUNTRIES_WITHOUT_MODAL } from '@legal/constants'

import * as DEFINITIONS from './definitions'
import { productModel, productModelCollection, promotionModel } from './models'

const buffer = []
let collected = false

const pushGAEvent = (event) => {
  // Since the events can be used outside of nuxt, we should not use process.server here.
  if (isNode()) {
    return
  }

  if (window.dataLayer) {
    if (collected) {
      // GTM mutates the event provided to add a parameter: gtm.uniqueEventId
      window.dataLayer.push({ ...event })
    } else {
      buffer.push(event)
    }
  }
}

export const pageView = ({ pageType }) => {
  const referrer = process.client ? document.referrer : ''
  pushGAEvent({
    event: DEFINITIONS.PAGE_VIEW,
    pageType,
    referrer,
  })
}

export const gdpr = (settings) => {
  collected = true

  pushGAEvent({
    event: DEFINITIONS.GDPR,
    ...settings,
  })

  while (buffer.length) {
    pushGAEvent(buffer.shift())
  }
}

export const context = (ctx) => {
  // When there is no cookie modal in the country, the gdpr event is not triggered.
  // We do have to consider the user choice to have been collected.
  collected = COUNTRIES_WITHOUT_MODAL.includes(ctx.country)
    ? true
    : Object.values(COOKIES).every((cookie) =>
        document.cookie.split(';').find((value) => value.includes(cookie)),
      )
  if (collected) {
    while (buffer.length) {
      pushGAEvent(buffer.shift())
    }
  }

  pushGAEvent({
    event: DEFINITIONS.CONTEXT,
    platform: APP_NAME,
    ...ctx,
  })
}

export const resize = ({ breakpointName, breakpointRule }) => {
  pushGAEvent({
    event: DEFINITIONS.RESIZE,
    breakpointName,
    breakpointRule,
  })
}
export const click = ({ name, zone, value, position }) => {
  pushGAEvent({
    event: DEFINITIONS.CLICK,
    name,
    zone,
    value,
    position,
  })
}

export const userContext = ({
  userId,
  clientId,
  emailHashed,
  merchantId,
  isStaff,
  lastOrderDate,
  numberOfOrders,
  lifetimeRevenue,
}) => {
  let date = null
  if (isDateValid(lastOrderDate)) {
    const [datetime] = lastOrderDate.toISOString().split('T')
    if (datetime) {
      date = datetime.replace(/-/g, '')
    }
  }

  pushGAEvent({
    event: DEFINITIONS.USER_CONTEXT,
    userId,
    clientId,
    emailHashed,
    merchantId,
    isStaff,
    lastOrderDate: date,
    numberOfOrders,
    lifetimeRevenue,
  })
}

/* LEGACY EVENTS FORMAT BELOW */
// TODO: Remove as part of GNL-2044
export const clickCarousel = (promotion) => {
  pushGAEvent({
    event: DEFINITIONS.PROMOTION_CLICK,
    ecommerce: {
      promoClick: {
        promotions: [promotionModel(promotion)],
      },
    },
  })
}

// TODO: Remove as part of GNL-2044
export const viewCarousel = (promotion) => {
  pushGAEvent({
    event: DEFINITIONS.PROMOTION_IMPRESSION,
    ecommerce: {
      promoView: {
        promotions: [promotionModel(promotion)],
      },
    },
  })
}

export const submit = (ctx) => {
  pushGAEvent({
    event: DEFINITIONS.FORM_SUBMIT,
    ...ctx,
  })
}

export const error = ({ status, message }) => {
  pushGAEvent({
    event: DEFINITIONS.ERROR,
    status,
    message,
  })
}

export const funnel = ({ products, routeName, swap } = {}) => {
  pushGAEvent({
    event: DEFINITIONS.FUNNEL,
    ecommerce: {
      checkout: {
        actionField: {
          step: CHECKOUT_STEP_IDS[routeName],
        },
        products: productModelCollection(products),
      },
    },
    swap,
  })
}

export const paymentSuccess = ({ payment, products }) => {
  pushGAEvent({
    event: DEFINITIONS.PAYMENT_SUCCESS,
    ecommerce: {
      purchase: {
        actionField: {
          coupon: payment.discount,
          id: payment.orderId,
          revenue: payment.revenue,
          shipping: payment.shipping,
        },
        products: productModelCollection(products),
      },
    },
    code_parrain: payment.code_parrain,
    commission: payment.commission,
    '10Pct_FEE': payment['10Pct_FEE'],
    conversionValue: payment.revenue,
    months_since_last_purchase: payment.monthsSinceLastPurchase,
    orderId: payment.orderId,
    paymentMethod: payment.paymentMethod,
    swap_details: payment.swap_details,
    swap: payment.swap,
    totalQuantity: payment.totalQuantity,
    transactionDate: payment.transactionDate,
    transactionId: payment.transactionId,
    'customer-type': payment.customerType,
  })
}

export const paymentSuccessTmp = ({ payment, products }) => {
  pushGAEvent({
    event: DEFINITIONS.PAYMENT_SUCCESS_TMP,
    ecommerce: {
      purchase: {
        actionField: {
          coupon: payment.discount,
          id: payment.orderId,
          revenue: payment.revenue,
          shipping: payment.shipping,
        },
        products: productModelCollection(products),
      },
    },
    code_parrain: payment.code_parrain,
    commission: payment.commission,
    conversionValue: payment.revenue,
    orderId: payment.orderId,
    paymentMethod: payment.paymentMethod,
    swap_details: payment.swap_details,
    swap: payment.swap,
    totalQuantity: payment.totalQuantity,
    transactionDate: payment.transactionDate,
    transactionId: payment.transactionId,
  })
}

export const paymentFail = ({ paymentMethod, errorCode, errorReason }) => {
  pushGAEvent({
    event: DEFINITIONS.PAYMENT_FAIL,
    paymentMethod,
    errorCode,
    errorReason,
  })
}

export const product = ({
  name = DEFINITIONS.PRODUCT,
  product: p,
  listings = [],
}) => {
  pushGAEvent({
    event: name,
    ecommerce: {
      detail: {
        actionField: {
          list: p.list,
        },
        products: [productModel(p)],
        listings,
      },
    },
  })
}

export const landing = ({
  type,
  highlights = [],
  name = DEFINITIONS.LANDING,
  ...rest
}) => {
  const ecommerce = {
    ecommerce: {
      impressions: [...productModelCollection(highlights)].filter(Boolean),
    },
  }
  pushGAEvent({
    event: name,
    ...insertIf(!isEmpty(type), { landingType: type }),
    ...insertIf(!isEmpty(highlights), ecommerce),
    ...rest,
  })
}

export const removeFromCart = (p) => {
  pushGAEvent({
    event: DEFINITIONS.REMOVE_FROM_CART,
    ecommerce: {
      remove: {
        products: [productModel(p)],
      },
    },
  })
}

const addToCartPush = (products, list = null) => {
  const actionField = removeEmptyValuesInObject({
    list,
    action: 'add',
  })

  pushGAEvent({
    event: DEFINITIONS.ADD_TO_CART,
    ecommerce: {
      add: {
        actionField,
        products,
      },
    },
  })
}

export const addToCart = ({ list, product: p }) => {
  addToCartPush([productModel(p)], list)
}

export const addToCartBatch = ({ list, products }) => {
  addToCartPush(productModelCollection(products), list)
}

export const swap = ({ label, action, ...rest }) => {
  pushGAEvent({
    event: DEFINITIONS.SWAP,
    modal_name: 'swap',
    bm_action: action || 'funnel > Step 1',
    bm_label: label,
    ...rest,
  })
}

export const modal = ({ status, name }) => {
  pushGAEvent({
    event: DEFINITIONS.MODAL,
    status,
    name,
  })
}

export const toast = ({ status, name }) => {
  pushGAEvent({
    event: DEFINITIONS.TOAST,
    status,
    name,
  })
}

export const carousel = ({ currentSlide, name }) => {
  pushGAEvent({
    event: DEFINITIONS.CAROUSEL_IMPRESSION,
    name,
    position: currentSlide,
  })
}

export const productImpression = (p) => {
  pushGAEvent({
    event: DEFINITIONS.PRODUCT_IMPRESSION,
    ecommerce: {
      impressions: [productModel(p)],
    },
  })
}

export const productImpressionBatched = (p) => {
  pushGAEvent({
    event: DEFINITIONS.PRODUCT_IMPRESSION,
    ecommerce: {
      impressions: productModelCollection(p),
    },
  })
}

export const productClick = ({ widgetId = null, list, product: p }) => {
  pushGAEvent({
    widget_id: widgetId,
    event: DEFINITIONS.PRODUCT_CLICK,
    ecommerce: {
      click: {
        actionField: {
          list,
        },
        products: [productModel(p)],
      },
    },
  })
}

export const buybackConfirmation = ({
  buybackType,
  buybackOrderId,
  commission,
  conversionValue,
  conversionCurrency,
}) => {
  pushGAEvent({
    event: DEFINITIONS.BUYBACK_CONFIRMATION,
    buybackType,
    buybackOrderId,
    commission,
    conversionValue,
    conversionCurrency,
  })
}

export const buybackNoOffer = ({ value }) => {
  pushGAEvent({
    event: DEFINITIONS.BUYBACK_NO_OFFER,
    name: 'buybackScarabee',
    value,
  })
}

/* ⚠️ The events below are legacy and shall be migrated */

// TODO: Should be RevModal generic event?
export const swapModal = ({ label, action, ...rest }) => {
  pushGAEvent({
    event: DEFINITIONS.SWAP_MODAL,
    modal_name: 'swap',
    bm_action: action,
    bm_label: label,
    ...rest,
  })
}

// TODO: Should be tracked using the trackPI
export const resetPassword = () => {
  pushGAEvent({ event: DEFINITIONS.RESET_PASSWORD })
}

// TODO: Should be tracked using the trackPI
export const resetPasswordSuccess = () => {
  pushGAEvent({ event: DEFINITIONS.RESET_PASSWORD_SUCCESS })
}

// TODO: Should be tracked using the trackPI
export const signIn = () => {
  pushGAEvent({ event: DEFINITIONS.SIGN_IN })
}

// TODO: Should be tracked using the trackPI + need rework to provide payload infos to GA
export const signUp = ({ optinNewsletter }) => {
  pushGAEvent({
    event: DEFINITIONS.SIGN_UP,
    optinNewsletter,
  })
}

export const landingBannerClick = (promotion) => {
  pushGAEvent({
    event: DEFINITIONS.PROMOTION_CLICK,
    ecommerce: {
      promoClick: {
        promotions: [promotionModel(promotion)],
      },
    },
  })
}

export const landingBannerImpression = (promotion) => {
  pushGAEvent({
    event: DEFINITIONS.PROMOTION_IMPRESSION,
    ecommerce: {
      promoView: {
        promotions: [promotionModel(promotion)],
      },
    },
  })
}

export const promptNotificationImpression = ({ zone }) => {
  pushGAEvent({
    event: DEFINITIONS.PROMPT_NOTIFICATION_IMPRESSION,
    zone,
  })
}

export const megaMenuImpression = (payload) => {
  pushGAEvent({ event: DEFINITIONS.MEGA_MENU_IMPRESSION, ...payload })
}
