import { BREAKPOINTS } from '@config/constants'
import { isNode } from '@core/helpers'
import { ROUTES } from '@router'

const PAGE_TYPES = {
  HOME: 'home',
  LANDING: 'landing',
  PRODUCT: 'product',
  CMS_DEFAULT_LAYOUT: 'cms',
  PAYMENT_RESULT: 'payment_result',
  ARTICLE: 'article',
  MODELS_COMPARISON_CATEGORY: 'models_comparison_category',
  MODELS_COMPARISON_RESULTS: 'models_comparison_results',
  SELLER_LISTINGS: 'seller_listings',
  SELLER_DASHBOARD: 'seller_dashboard',
  SELLER_INVOICES: 'seller_invoices',
  SELLER_ORDERS: 'seller_orders',
  HELP_CENTER_HOME: 'help_center_home',
  MY_PROFILE: 'my_profile',
  REVIEWS: 'reviews',
  FAVORITES: 'my_favorites',
  ABOUT_US: 'about_us',
}

export const TRACKING_PROVIDERS = {
  NOODLE: 'NOODLE',
  GA: 'GA',
  AMPLITUDE: 'AMPLITUDE',
  BRAZE: 'BRAZE',
  LOGGER: 'LOGGER',
}

export const METRICS_WEB_PERFORMANCE = {
  CLS: 'cls',
  FCP: 'fcp',
  FID: 'fid',
  LCP: 'lcp',
  TTFB: 'ttfb',
  INP: 'inp',
}

export const PRODUCT_SOURCE_TYPE = {
  HIGHLIGHTS: 'highlights',
  RESULTS: 'results',
  HOME_RECOS: 'home page featured products',
  LP_RECOS: 'landing page featured products',
  PP_RECOS_1: 'product page cousins 1',
  PP_RECOS_2: 'product page cousins 2',
  CROSS_SELL: 'cross sell',
  CROSS_SELL_MODAL: 'cross sell modal',
  HIGHLIGHTED_PRODUCT: 'landing page highlighted product',
  CROSS_SELL_BUNDLE: 'cross sell bundle',
}

export const PAGE_TYPE_BY_ROUTE_NAME = {
  [ROUTES.HOME]: PAGE_TYPES.HOME,
  [ROUTES.LANDING.MERCHANT]: PAGE_TYPES.LANDING,
  [ROUTES.LANDING.SEARCH]: PAGE_TYPES.LANDING,
  [ROUTES.LANDING.PRODUCT]: PAGE_TYPES.LANDING,
  [ROUTES.PRODUCT.HOME]: PAGE_TYPES.PRODUCT,
  [ROUTES.CHECKOUT.PAYMENT_RESULT]: PAGE_TYPES.PAYMENT_RESULT,
  [ROUTES.CMS.BRAND_STORE]: PAGE_TYPES.CMS_DEFAULT_LAYOUT,
  [ROUTES.CMS.EVENT]: PAGE_TYPES.CMS_DEFAULT_LAYOUT,
  [ROUTES.CMS.ARTICLE]: PAGE_TYPES.ARTICLE,
  [ROUTES.MODELS_COMPARISON.CATEGORY]: PAGE_TYPES.MODELS_COMPARISON_CATEGORY,
  [ROUTES.MODELS_COMPARISON.COMPARISON_RESULTS]:
    PAGE_TYPES.MODELS_COMPARISON_RESULTS,
  [ROUTES.BO_MERCHANT.LISTINGS.ACTIVE]: PAGE_TYPES.SELLER_LISTINGS,
  [ROUTES.BO_MERCHANT.HOME]: PAGE_TYPES.SELLER_DASHBOARD,
  [ROUTES.BO_MERCHANT.INVOICES.WALLET]: PAGE_TYPES.SELLER_INVOICES,
  [ROUTES.BO_MERCHANT.ORDERS.ALL]: PAGE_TYPES.SELLER_ORDERS,
  [ROUTES.HELP_CENTER.HOME]: PAGE_TYPES.HELP_CENTER_HOME,
  [ROUTES.DASHBOARD.KYC.PROFILE]: PAGE_TYPES.MY_PROFILE,
  [ROUTES.DASHBOARD.FAVORITES]: PAGE_TYPES.FAVORITES,
  [ROUTES.REVIEWS.PAGES.ALL]: PAGE_TYPES.REVIEWS,
  [ROUTES.REVIEWS.PAGES.LANDING]: PAGE_TYPES.REVIEWS,
  [ROUTES.REVIEWS.PAGES.MERCHANT]: PAGE_TYPES.REVIEWS,
  [ROUTES.REVIEWS.PAGES.PRODUCT]: PAGE_TYPES.REVIEWS,
  [ROUTES.ABOUT_US]: PAGE_TYPES.ABOUT_US,
}

export const EVENT_TYPE = {
  CLICK: 'click',
  CONVERSION: 'conversion',
}

const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

export const getDevice = () => {
  // only works on client side, so we are mobile first on server side
  if (isNode()) {
    return DEVICES.MOBILE
  }
  const matchMedia = (media) => window.matchMedia(media).matches
  const breakpoint = BREAKPOINTS.length - BREAKPOINTS.findIndex(matchMedia) - 1

  return breakpoint < 2 ? DEVICES.MOBILE : DEVICES.DESKTOP
}

export const getConnection = () => {
  if (isNode()) {
    return 'unknown'
  }

  return window.navigator?.connection?.effectiveType || 'unknown'
}

export const CUSTOMER_SIDE_ROUTES = [
  ROUTES.ABOUT_US,
  ROUTES.AUTH,
  ROUTES.BUYBACK.CATEGORIES,
  ROUTES.BUYBACK.DEVICE_FORM,
  ROUTES.BUYBACK.OFFER,
  ROUTES.BUYBACK.NO_OFFER,
  ROUTES.BUYBACK.BANK,
  ROUTES.BUYBACK.ADDRESS,
  ROUTES.BUYBACK.SHIPPING,
  ROUTES.BUYBACK.CONFIRMATION,
  ROUTES.BUYING_GUIDE,
  ROUTES.CHECKOUT,
  ROUTES.CHECKOUT_INSURANCE,
  ROUTES.CMS,
  ROUTES.CREDIT_CARD,
  ROUTES.DASHBOARD,
  ROUTES.HELP_CENTER,
  ROUTES.HOME,
  ROUTES.LANDING,
  ROUTES.LEGAL,
  ROUTES.MILITARY_DISCOUNT,
  ROUTES.MODELS_COMPARISON,
  ROUTES.PRODUCT,
  ROUTES.REVIEWS,
  ROUTES.STUDENT_DISCOUNT,
]
