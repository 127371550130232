/* eslint-disable camelcase */
import { removeEmptyValuesInObject } from '@core/helpers'

export const favoritesModel = ({
  list = 'my_favorites',
  isDisabled = false,
  model,
  price,
  productUuid,
  position,
  title,
  brand,
  category3Name,
  color,
}) => {
  return removeEmptyValuesInObject({
    product_list: list,
    product_available: !isDisabled,
    product_id: productUuid,
    product_model: model,
    product_name: title,
    product_position: position,
    product_price: price,
    product_brand: brand,
    product_category: category3Name,
    product_color: color,
  })
}

export const favoritesModelCollection = (favorites = []) => {
  const favoritesList = Array.isArray(favorites) ? favorites : [favorites]

  return favoritesList.map((favorite, index) => ({
    ...favoritesModel({
      ...favorite,
      position: favorite.position || index + 1,
    }),
  }))
}
