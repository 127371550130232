import { DEFAULT_DURATION, STATUS } from '../constants'

let timeout

export default {
  namespaced: true,
  state() {
    return {
      shown: false,
      message: '',
      status: '',
      unlimitedDuration: false,
    }
  },
  getters: {
    get: (state) => state,
  },
  mutations: {
    show: (state, { message, status }) => {
      state.shown = true
      state.message = message
      state.status = status
    },
    hide: (state) => {
      if (timeout) {
        clearTimeout(timeout)
      }

      state.shown = false
    },
    setUnlimitedDuration: (state) => {
      state.unlimitedDuration = true
    },
  },
  actions: {
    show: (
      { commit },
      { duration = DEFAULT_DURATION, message, status = STATUS.SUCCESS },
    ) => {
      if (!message) {
        return
      }

      commit('show', { message, status })

      if (timeout) {
        clearTimeout(timeout)
      }

      if (duration <= 0) {
        commit('setUnlimitedDuration')

        return
      }

      timeout = setTimeout(commit, duration, 'hide')
    },
    hide: ({ commit }) => commit('hide'),
  },
}
