const CANCELLED = 'cancelled'
const REFUND = 'refund'

export const OrderlineState = {
  CANCELLED: 4,
  REFUNDED_PRE_DELIVERY: 5,
  REFUNDED_POST_DELIVERY: 6,
}

export const getRefundState = (orderline) => {
  if (
    !orderline.refunded &&
    orderline.history.orderId &&
    orderline.history.type
  ) {
    return {
      replaceOrderId: orderline.history.orderId,
      type: orderline.history.type,
    }
  }

  if (orderline.state === OrderlineState.CANCELLED) {
    return {
      date: orderline.refundDate,
      type: CANCELLED,
    }
  }

  if (
    orderline.state === OrderlineState.REFUNDED_PRE_DELIVERY ||
    orderline.state === OrderlineState.REFUNDED_POST_DELIVERY
  ) {
    return {
      date: orderline.refundDate,
      type: REFUND,
    }
  }

  return false
}
