import { getOrderlineInfo, getOrderlineWarranties } from '@http/endpoints'

const getDefaultState = () => {
  return {
    orderlines: {},
    warranties: {},
  }
}

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getOrderline: (state) => (orderlineId) =>
      state.orderlines[orderlineId] || {},
    getWarranties: (state) => (orderlineId) =>
      state.warranties[orderlineId] || {},
  },
  mutations: {
    setOrderline: (state, { orderline }) => {
      state.orderlines[orderline.id] = orderline
    },
    setWarranties: (state, { orderlineId, warranties }) => {
      state.warranties[orderlineId] = warranties
    },
  },
  actions: {
    fetchOrderline: async ({ dispatch, commit }, { orderlineId }) => {
      try {
        const { payload: orderline } = await dispatch(
          'http/request',
          {
            request: getOrderlineInfo,
            pathParams: { orderlineId },
          },
          {
            root: true,
          },
        )

        commit('setOrderline', { orderline })
      } catch {
        // Nothing to do here.
      }
    },
    fetchWarranties: async ({ dispatch, commit }, { orderlineId }) => {
      try {
        const { payload: warranties } = await dispatch(
          'http/request',
          {
            request: getOrderlineWarranties,
            pathParams: { orderlineId },
          },
          {
            root: true,
          },
        )

        commit('setWarranties', { orderlineId, warranties })
      } catch {
        // Nothing to do here.
      }
    },
  },
}
