import { BREAKPOINTS } from '@config/constants'
import { matchMedia } from '@core/helpers/dom'
import { trackResize } from '@tracking/events'

const NAV_STATE = {
  OPENED: 'opened',
  CLOSED: 'closed',
}

export default {
  state() {
    return {
      breakpoint: 0,
      rule: BREAKPOINTS[BREAKPOINTS.length - 1],
      navState: NAV_STATE.CLOSED,
    }
  },
  mutations: {
    setBreakpoint(state, payload) {
      state.breakpoint = payload.breakpoint
      state.rule = payload.rule
    },
    setNavigationState(state, payload) {
      state.navState = payload.navState
    },
  },
  getters: {
    breakpoint: (state) => state.breakpoint,

    isNavigationOpened: (state) => state.navState === NAV_STATE.OPENED,
  },
  actions: {
    toggleNavigation({ commit, state }) {
      const navState =
        state.navState === NAV_STATE.OPENED
          ? NAV_STATE.CLOSED
          : NAV_STATE.OPENED
      commit('setNavigationState', { navState })
    },

    updateBreakpoint({ commit, state }) {
      const rule = BREAKPOINTS.find(matchMedia)
      // Because of the max & min width, we can't just reverse the array to do that
      const breakpoint =
        BREAKPOINTS.length - BREAKPOINTS.findIndex(matchMedia) - 1
      commit('setBreakpoint', { breakpoint, rule })

      trackResize({
        breakpointName: state.breakpoint,
        breakpointRule: state.rule,
      })
    },
  },
}
