export default {
  title: {
    id: '404_page_title',
    defaultMessage: '"Gottferdam"',
  },
  subtitle: {
    id: '404_page_subtitle',
    defaultMessage: 'This link has not yet been refurbished.',
  },
  beCool: {
    id: '404_page_be_cool',
    defaultMessage: 'Stay cool, everything’s going to be ok.',
  },
  relaxed: {
    id: '404_page_you_are_totally_relaxed',
    defaultMessage: 'See, you’re totally relaxed now.',
  },
}
