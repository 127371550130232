import { COUNTRIES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import minimalLayout from '@core/layouts/minimal.vue'
import { ROUTES } from '@router'

const unrestrictedBuybackMerchantRoutes = [
  {
    name: ROUTES.BUYBACK.MERCHANT.UPDATE_CREDIT_CARD,
    path: 'update-credit-card',
    component: () =>
      dynamicImport(
        import('@buyback/refurbisher/onboarding/pages/UpdateCreditCard'),
      ),
    meta: {
      auth: {
        required: true,
      },
    },
  },
  {
    name: ROUTES.BUYBACK.MERCHANT.UPDATE_CREDIT_CARD_SUCCESS,
    path: 'update-credit-card/success',
    component: () =>
      dynamicImport(
        import('@buyback/refurbisher/onboarding/pages/UpdateCreditCardSuccess'),
      ),
  },
  {
    name: ROUTES.BUYBACK.MERCHANT.UPDATE_CREDIT_CARD_FAIL,
    path: 'update-credit-card/failure',
    component: () =>
      dynamicImport(
        import('@buyback/refurbisher/onboarding/pages/UpdateCreditCardFailure'),
      ),
  },
  {
    name: ROUTES.BUYBACK.MERCHANT.ACH_UPDATE,
    path: 'update-ach',
    component: () =>
      dynamicImport(import('@buyback/refurbisher/onboarding/pages/UpdateAch')),
    meta: {
      auth: {
        required: true,
      },
    },
  },
]

const restrictedBuybackMerchantRoutes = [
  {
    name: ROUTES.BUYBACK.MERCHANT.SEPA_UPDATE,
    path: 'update-sepa',
    component: () =>
      dynamicImport(import('@buyback/refurbisher/onboarding/pages/UpdateSepa')),
    meta: {
      auth: {
        required: true,
      },
    },
  },
  {
    name: ROUTES.BUYBACK.MERCHANT.SEPA_UPDATE_SUCCESS,
    path: 'update-sepa/success',
    component: () =>
      dynamicImport(
        import('@buyback/refurbisher/onboarding/pages/UpdateSepaSuccess'),
      ),
  },
  {
    name: ROUTES.BUYBACK.MERCHANT.SEPA_UPDATE_FAIL,
    path: 'update-sepa/failure',
    component: () =>
      dynamicImport(
        import('@buyback/refurbisher/onboarding/pages/UpdateSepaFailure'),
      ),
  },
  {
    name: ROUTES.BUYBACK.MERCHANT.BACS_UPDATE,
    path: 'update-bacs',
    component: () =>
      dynamicImport(import('@buyback/refurbisher/onboarding/pages/UpdateBacs')),
    meta: {
      auth: {
        required: true,
      },
    },
  },
]

export default ({ country }) => {
  const shouldAddBuybackMerchantRoutes = [
    COUNTRIES.DE,
    COUNTRIES.FR,
    COUNTRIES.GB,
    COUNTRIES.ES,
  ].includes(country)

  return [
    {
      name: ROUTES.BUYBACK.MERCHANT.HOME,
      path: 'buyback-merchant',
      // Apply the minimal layout
      component: minimalLayout,
      children: [
        ...unrestrictedBuybackMerchantRoutes,
        ...insertIf(
          shouldAddBuybackMerchantRoutes,
          restrictedBuybackMerchantRoutes,
        ),
      ],
    },
  ]
}
