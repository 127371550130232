/**
 * Returns the list of feature flags
 *
 * Transforms the country lists into booleans using the provided country parameter
 *
 * @param {Object} flags list of flags
 * @param {String} country country code ISO 3166-1 alpha-2
 * @returns
 */
export const flagModel = (flags, country) => {
  return Object.entries(flags).reduce((acc, [key, value]) => {
    // if the flag includes a comma, it is a list of countries (FR, SK, IT...)
    const isValueACountryList =
      typeof value === 'string' &&
      // 'FR, IT, ES', or 'FR' or ''
      (value.includes(',') || value.length === 2 || value.length === 0)

    return {
      ...acc,
      [key]: isValueACountryList ? value.includes(country) : value,
    }
  }, {})
}
