import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ config }) => {
  return {
    requiresSellerAuthentication: [],
    other: [
      {
        name: ROUTES.BO_MERCHANT.REGISTER,
        path: 'subscribe',
        component: () => dynamicImport(import('./pages/register')),
        meta: {
          notSeller: {
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.HOME },
          },
          ...insertIf(config.FF_ENABLE_NEW_SELLER_REGISTER_PAGE, {
            redirectToFrontApps: { name: ROUTES.BO_SELLER.REGISTER },
          }),
        },
      },
      {
        name: ROUTES.BO_MERCHANT.LOGIN,
        path: 'login',
        component: () => dynamicImport(import('./pages/login')),
        meta: {
          notSeller: {
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.HOME },
          },
          ...insertIf(config.FF_ENABLE_NEW_SELLER_LOGIN_PAGE, {
            redirectToFrontApps: { name: ROUTES.BO_SELLER.LOGIN },
          }),
        },
      },
    ],
  }
}
