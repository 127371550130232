
import { RevLink } from '@ds/components/Link'
import isEmpty from 'lodash/isEmpty'

import translations from './HomeNavigation.translations'

export default {
  name: 'HomeNavigation',
  components: {
    RevLink,
  },
  props: {
    navigationSeo: {
      type: Array,
      required: true,
    },
  },
  computed: {
    translations: () => translations,
    navigationWithUrl() {
      const hasUrl = (item) => !isEmpty(item.link)

      return this.navigationSeo.filter(
        (item) => hasUrl(item) && !isEmpty(item.children.filter(hasUrl)),
      )
    },
    navigationFromLevelTwo() {
      return this.navigationWithUrl
        .flatMap((item) => item.children)
        .map(this.formatChildren)
    },
    hasNavigation() {
      return !isEmpty(this.navigationSeo)
    },
  },
  methods: {
    formatChildren({
      id,
      link,
      titleDisplay,
      children = [],
      maxChildren = 10,
    }) {
      return {
        id,
        link,
        titleDisplay,
        hasChildren: !isEmpty(children),
        children: children
          .slice(0, maxChildren)
          .filter((child) => child.titleDisplay),
      }
    },
  },
}
