import { ROUTES } from '@router'

import { E2E_CONFIG } from '../e2e/content-service.config'

const GIFT_FINDER_PAGE_TYPE = 'gift-finder'

export const PAGE_TYPE_BY_ROUTE_NAME = {
  [ROUTES.CMS.ARTICLE]: 'article',
  [ROUTES.CMS.BACK_STORIES]: 'back-stories',
  [ROUTES.CMS.BACK_STORIES_CATEGORY]: 'back-stories',
  [ROUTES.CMS.BRAND_STORE]: 'brand-store',
  [ROUTES.CMS.BRAND_STORE_CATEGORY]: 'brand-store',
  [ROUTES.CMS.BUSINESS]: 'business-page',
  [ROUTES.CMS.BUYBACK]: 'buyback',
  [ROUTES.CMS.BUYBACK_CATEGORY]: 'buyback',
  [ROUTES.CMS.EVENT]: 'event-page',
  [ROUTES.CMS.LEGAL_PREVIOUS_VERSION]: 'legal-document',
  [ROUTES.CMS.LEGAL_PAGE]: 'legal-page',
  [ROUTES.CMS.SELLER]: 'seller',
  [ROUTES.CMS.STATEMENT]: 'core',
  [ROUTES.HOME]: 'core',
  [ROUTES.CMS.GIFT_FINDER_EVENT]: GIFT_FINDER_PAGE_TYPE,
  [ROUTES.CMS.GIFT_FINDER_MAIN]: GIFT_FINDER_PAGE_TYPE,
  [ROUTES.LANDING.UNIVERSE]: 'universe-page',
  ...E2E_CONFIG,
}

export const PAGE_NAME_BY_ROUTE_NAME = {
  [ROUTES.CMS.STATEMENT]: 'accessibility-statement',
  [ROUTES.HOME]: 'home',
}

export const PAGE_NAME_BY_PLP_SLUG = {
  'macbook-and-laptops': 'laptops',
  smartphones: 'smartphones',
}

export const PAGE_NAMES_WITH_NO_PARAMS_SANITIZATION = [
  ROUTES.CMS.LEGAL_PAGE,
  ROUTES.CMS.LEGAL_PREVIOUS_VERSION,
]
