/* eslint-disable no-undef */
/**
 * @typedef State
 * @property {String} [name]
 * @property {String} [csrf]
 */

export default {
  namespaced: true,

  /**
   * Current module state.
   *
   * @return {State}
   */
  state() {
    return {
      id: null,
      csrf: null,
      encryptedId: null,
      confirmationEmailActivated: null,
      isNewAuthEnabled: false,
      // Todo: remove this when the FF is removed
      authorizationEndpoint: 'oauth2/auth',
      // Todo: remove this when the FF is removed
      socialLoginValidationStepExperiment: null,
    }
  },

  getters: {
    isNewAuthEnabled: (state) => {
      return state.isNewAuthEnabled
    },
    // Todo: remove this when the FF is removed
    authorizationEndpoint: (state) => {
      return state.authorizationEndpoint
    },
    // Todo: remove this when the FF is removed
    socialLoginValidationStepExperiment: (state) => {
      return state.socialLoginValidationStepExperiment
    },
    /**
     * Return the CSRF token.
     *
     * @param {State} state
     * @return {String}
     */
    csrf: (state) => state.csrf,

    /**
     * Return the authenticated user id.
     *
     * @param {State} state
     * @returns {Number}
     */
    id: (state) => state.id,

    /**
     * Return the authenticated user id encrypted.
     *
     * @param {State} state
     * @returns {Number}
     */
    encryptedId: (state) => state.encryptedId,

    /**
     * Is the user authenticated ?
     *
     * @param {State} state
     * @return {Boolean}
     */
    status: (state) => Boolean(state.id),

    /**
     * Was the authentication status API call fired already ?
     *
     * Checking the `csrf` value is the best way to know that, since it'll
     * always be defined after the authentication request were fired.
     *
     * @param {State} state
     * @return {Boolean}
     */
    fetched: (state) => Boolean(state.csrf),

    /**
     * Is the confirmation email feature activated ?
     *
     * @param {State} state
     * @return {Boolean}
     */
    confirmationEmailActivated: (state) =>
      Boolean(state.confirmationEmailActivated),
  },

  mutations: {
    /**
     * Store the given user id.
     *
     * @param {State} state
     * @param {Number} payload.id
     */
    setId(state, { id }) {
      state.id = id
    },

    /**
     * Store the given user encrypted id.
     *
     * @param {State} state
     * @param {Number} payload.encryptedId
     */
    setEncryptedId(state, { encryptedId }) {
      state.encryptedId = encryptedId
    },

    /**
     * Store the given CSRF token.
     *
     * @param {State} state
     * @param {String} payload.csrf
     */
    setCsrf(state, { csrf }) {
      state.csrf = csrf
    },

    /**
     * Store the given value of ConfirmationEmailActivated boolean.
     *
     * @param {State} state
     * @param {String} payload.confirmationEmailActivated
     */
    setConfirmationEmailActivated(state, { confirmationEmailActivated }) {
      state.confirmationEmailActivated = confirmationEmailActivated
    },
    setIsNewAuthEnabled(state, { isNewAuthEnabled }) {
      state.isNewAuthEnabled = isNewAuthEnabled
    },
    // Todo: remove this when the FF is removed
    setAuthorizationEndpoint(state, { authorizationEndpoint }) {
      state.authorizationEndpoint = authorizationEndpoint
    },
    // Todo: remove this when the FF is removed
    setSocialLoginValidationStepExperiment(
      state,
      { socialLoginValidationStepExperiment },
    ) {
      state.socialLoginValidationStepExperiment =
        socialLoginValidationStepExperiment
    },
  },
}
