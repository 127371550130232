import flatMap from 'lodash/flatMap'

import aboutUsRoutes from '@about-us/routes'
import adminToolsRoutes from '@admin-tools/routes'
import articleRoutes from '@article/routes'
import oAuth2Routes from '@auth-oauth2/routes'
import externalAuthRoutes from '@auth/externalRoutes'
import authRoutes from '@auth/routes'
import {
  buybackCustomerMinimalLayoutRoutes,
  buybackRefurbisherMinimalLayoutRoutes,
  buybackStaffRoutes,
} from '@buyback/routes'
import buybackRoutes from '@buyback/routes/customer-routes'
import buybackMerchantRoutes from '@buyback/routes/refurbisher-routes'
import { checkoutDefaultRoutes, checkoutEmptyRoutes } from '@checkout/routes'
import cmsRoutes from '@cms/routes'
import defaultLayout from '@core/layouts/default.vue'
import customerRequestsB2CRoutes from '@customer-requests/B2CRoutes'
import customerRequestsRoutes from '@customer-requests/routes'
import dashboardRoutes from '@dashboard/routes'
import diagnosisRoutes from '@diagnosis/routes'
import helpCenterRoutes from '@help-center/routes'
import homeRoutes from '@home/routes'
import insuranceRoutes from '@insurance/routes'
import {
  landingFiltersRoutes,
  landingRoutes,
  plpByCMSRoutes,
} from '@landing/routes'
import legalRoutes from '@legal/routes'
import militaryDiscountRoutes from '@military-discount/routes'
import modelsComparisonRoutes from '@models-comparison/routes'
import packageTrackingRoutes from '@package-tracking/routes'
import paymentRoutes from '@payment/routes'
import productRoutes from '@product/routes'
import repairRoutes from '@repair/routes'
import reviewsRoutes from '@reviews/routes'
import merchantRoutes from '@seller/routes'
import studentDiscountRoutes from '@student-discount/routes'

// Module routers to import
// Locale, default layout
const modulesRoutesB2CDefaultLayout = [
  buybackRoutes,
  checkoutDefaultRoutes,
  productRoutes,
  reviewsRoutes,
  landingRoutes,
]

// TODO: Move these pages to modulesRoutesB2CDefaultLayout
// if they used the default nuxt layout
// Locale, empty layout
const modulesRoutesB2CEmptyLayout = [
  buybackMerchantRoutes,
  aboutUsRoutes,
  articleRoutes,
  authRoutes,
  checkoutEmptyRoutes,
  cmsRoutes,
  customerRequestsB2CRoutes,
  dashboardRoutes,
  diagnosisRoutes,
  helpCenterRoutes,
  insuranceRoutes,
  legalRoutes,
  militaryDiscountRoutes,
  modelsComparisonRoutes,
  packageTrackingRoutes,
  paymentRoutes,
  repairRoutes,
  studentDiscountRoutes,
  buybackCustomerMinimalLayoutRoutes,
]

// Locale, empty layout injected first in the final routes table
const modulesRoutesB2CEmptyLayoutInjectedFirst = [
  plpByCMSRoutes,
  landingFiltersRoutes,
]

// No locale
const modulesRoutesBusinessToBusiness = [
  adminToolsRoutes,
  customerRequestsRoutes,
  externalAuthRoutes,
  merchantRoutes,
  buybackRefurbisherMinimalLayoutRoutes,
  buybackStaffRoutes,
]

const createRoutes = (routes, context) => {
  return flatMap(routes, (getModuleRoutes) => getModuleRoutes(context))
}

const createRoutesWithLocaleDefaultLayout = (routes, context) => {
  return [
    {
      // For now, we only support 1 locale per country
      path: `/:locale(${context.locale})`,
      // Apply the default layout
      component: defaultLayout,
      children: [...createRoutes(routes, context)],
    },
  ]
}
const createRoutesWithLocaleEmptyLayout = (routes, context) => {
  return [
    {
      path: `/:locale(${context.locale})`,
      // Render nothing, these components have their own layout
      component: { render: (h) => h('router-view') },
      children: [...createRoutes(routes, context)],
    },
  ]
}

export const getModulesRoutes = (context) => {
  const routes = [
    // Home has to be initialized separately to remove the trailing slash
    ...homeRoutes(context),
    ...createRoutes(modulesRoutesBusinessToBusiness, context),
    // OAuth2 routes need no locale param and no layout
    // because they are used to redirect to the OAuth2 provider
    // or back to the app, with the locale param
    ...createRoutes([oAuth2Routes], context),
  ]

  return [
    ...routes,
    ...createRoutesWithLocaleEmptyLayout(
      modulesRoutesB2CEmptyLayoutInjectedFirst,
      context,
    ),
    ...createRoutesWithLocaleDefaultLayout(
      modulesRoutesB2CDefaultLayout,
      context,
    ),
    ...createRoutesWithLocaleEmptyLayout(modulesRoutesB2CEmptyLayout, context),
  ]
}
