
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { mapGetters } from 'vuex'

import { parsedHash } from '@core/helpers'

import translations from './Header.translations'

export default {
  components: {
    RevLink,
    RevIllustration,
  },
  computed: {
    ...mapGetters({
      country: 'config/country',
      isEnabled: 'precipitation/isEnabled',
    }),

    isPrecipitationEnabled() {
      return this.$config.FF_PRECIPITATION_ENABLED_BY_COUNTRY?.includes(
        this.country,
      )
    },
    altTitle() {
      if (this.isEnabled) {
        return this.$t(translations.snowAltDisable)
      }

      return this.$t(translations.snowAltEnable)
    },
  },
  mounted() {
    const hashParams = parsedHash(this.$route.hash)

    if (hashParams.snow) {
      this.togglePrecipitations()
    }
  },
  methods: {
    togglePrecipitations() {
      this.$store.dispatch('precipitation/toggle')
    },
  },
}
