import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  fetchActionsCustomerRequestDiscussion,
  fetchCareFolder,
  fetchInformationCustomerRequestDiscussion,
  fetchInformationResolutionDiscussion,
  postCustomerRequestPlatformCustomerMessagesVisibility,
} from '@http/endpoints'
import { getApiPathFromRole } from '@sav-common/helpers'

export default {
  namespaced: true,

  state() {
    return {
      actions: {},
      customerRequest: {},
    }
  },
  getters: {
    information(state) {
      return state.customerRequest
    },
    isAllowed(state) {
      return (action, defaultValue = false) =>
        get(state.actions, action, defaultValue)
    },
    customerMessagesVisibleToSeller(state) {
      return Boolean(state.customerRequest.customerMessagesVisibleToSeller)
    },
  },
  mutations: {
    clear(state) {
      state.actions = {}
      state.customerRequest = {}
    },
    set(state, { customerRequest }) {
      state.customerRequest = customerRequest
    },
    setActions(state, { actions }) {
      state.actions = actions
    },
    setCustomerMessagesVisibility(state, { customerMessagesVisibleToSeller }) {
      state.customerRequest = {
        ...state.customerRequest,
        customerMessagesVisibleToSeller,
      }
    },
    setProductReturns(state, { productReturns }) {
      if (isEmpty(state.customerRequest)) return

      state.customerRequest = {
        ...state.customerRequest,
        productReturns,
      }
    },
    update(state, { updates }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      state.customerRequest = {
        ...state.customerRequest,
        ...updates,
      }
    },
  },
  actions: {
    async changeCustomerMessagesVisibility(
      { commit, dispatch },
      { customerRequestId, role, customerMessagesVisibleToSeller },
    ) {
      await dispatch(
        'http/request',
        {
          request: postCustomerRequestPlatformCustomerMessagesVisibility,
          pathParams: { customerRequestId, role: getApiPathFromRole(role) },
          body: {
            customerMessagesVisibleToSeller,
          },
        },
        { root: true },
      )
      commit('setCustomerMessagesVisibility', {
        customerMessagesVisibleToSeller,
      })
    },
    clear({ commit }) {
      commit('clear')
    },
    async fetchCareFolder({ commit, dispatch }, { customerRequestId }) {
      const { payload: careFolder } = await dispatch(
        'http/request',
        {
          request: fetchCareFolder,
          pathParams: { careFolderId: customerRequestId },
        },
        {
          root: true,
        },
      )

      commit('set', { customerRequest: careFolder })

      return careFolder
    },
    async fetchInformation(
      { commit, dispatch },
      { customerRequestId, resolutionId },
    ) {
      const dispatchPayload = customerRequestId
        ? {
            request: fetchInformationCustomerRequestDiscussion,
            pathParams: { customerRequestId },
          }
        : {
            request: fetchInformationResolutionDiscussion,
            pathParams: { resolutionId },
          }

      const { payload: customerRequest } = await dispatch(
        'http/request',
        dispatchPayload,
        {
          root: true,
        },
      )

      commit('set', { customerRequest })

      return customerRequest
    },
    async fetchInformationToGetCustomerRequestId(
      { dispatch },
      { resolutionId },
    ) {
      const dispatchPayload = {
        request: fetchInformationResolutionDiscussion,
        pathParams: { resolutionId },
      }

      const { payload: customerRequest } = await dispatch(
        'http/request',
        dispatchPayload,
        {
          root: true,
        },
      )

      return customerRequest
    },
    async fetchActions({ commit, dispatch }, { customerRequestId }) {
      const { payload: actions } = await dispatch(
        'http/request',
        {
          request: fetchActionsCustomerRequestDiscussion,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      commit('setActions', { actions })
    },
    update({ commit }, { customerRequestId, updates }) {
      commit('update', { customerRequestId, updates })
    },
  },
}
