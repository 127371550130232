const getDefaultState = () => ({
  fields: [],
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getComment: (state, { getMainReview }) => {
      if (!getMainReview || !getMainReview.comment) {
        return ''
      }

      return getMainReview.comment
    },
    getCategoriesReviews: (state) => state.fields.slice(1),
    getMainReview: (state) => state.fields[0],
    get: (state) => state.fields,
  },
  mutations: {
    setComment: (state, data) => {
      state.fields[0].comment = data
    },
    set: (state, data) => {
      state.fields = data
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    set: ({ commit }, datas) => commit('set', datas),
    setComment: ({ commit }, datas) => commit('setComment', datas),
    reset: ({ commit }) => commit('reset'),
  },
}
