import { fetchProductReviews, submitProductReview } from '@http/endpoints'

import error from './modules/error'
import info from './modules/info'
import loading from './modules/loading/loading'
import rate from './modules/rate'
import reviews from './modules/reviews'
import step from './modules/step'

export default {
  namespaced: true,
  state() {
    return {
      credentials: {},
      isEditing: false,
    }
  },
  modules: {
    reviews,
    loading,
    info,
    rate,
    step,
    error,
  },
  getters: {
    getCredentials: (state) => state.credentials,
    getIsEditing: (state) => state.isEditing,
  },
  mutations: {
    setCredentials: (state, { token, productId }) => {
      state.credentials = {
        token,
        productId,
      }
    },
    setIsEditing: (state, rootGetters) => {
      state.isEditing =
        rootGetters['reviewform/info/getInitialRate'] > 0 &&
        rootGetters['reviewform/step/get'] === 1
    },
  },
  actions: {
    setCredentials: ({ commit }, datas) => commit('setCredentials', datas),
    fetch: async ({ dispatch, commit, getters, rootGetters }) => {
      const { token, productId } = getters.getCredentials

      dispatch('loading/set', true)

      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchProductReviews,
          pathParams: { productId },
          queryParams: { token },
        },
        { root: true },
      )

      const { reviews: reviewsData, ...infoData } = payload

      dispatch('loading/set', false)
      dispatch('info/set', infoData)
      dispatch('reviews/set', reviewsData)

      commit('setIsEditing', rootGetters)

      if (getters.getIsEditing) {
        commit('step/next')
      }
    },
    post: async ({ dispatch, state, getters }) => {
      const { token, productId } = getters.getCredentials

      dispatch('loading/set', true)

      const { payload } = await dispatch(
        'http/request',
        {
          request: submitProductReview,
          pathParams: { productId },
          queryParams: { token },
          body: {
            ...state.info,
            rate: getters['rate/get'],
            reviews: state.reviews.fields,
          },
        },
        { root: true },
      )

      const { reviews: reviewsData, ...infoData } = payload

      dispatch('loading/set', false)
      dispatch('info/set', infoData)
      dispatch('reviews/set', reviewsData)
    },
    reset: ({ dispatch }) => {
      dispatch('error/reset')
      dispatch('info/reset')
      dispatch('loading/reset')
      dispatch('reviews/reset')
      dispatch('step/reset')
    },
  },
}
