import { COUNTRIES } from '@config/constants'

import { PAYMENT_ADVERTISING_TYPES } from './constants'

export const KLARNA_GENERIC_WORDING_COUNTRIES = [COUNTRIES.US]
export const KLARNA_LIBRARY_RENDERING_TIMEOUT = 30000

export const KLARNA_MODAL_TYPES = {
  PRODUCT: 'credit-promotion-auto-size',
  SITEWIDE: 'top-strip-promotion-auto-size',
}

export const KLARNA_MODAL_SETTINGS = {
  [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
    placementKey: KLARNA_MODAL_TYPES.PRODUCT,
  },
  [PAYMENT_ADVERTISING_TYPES.CART]: {
    placementKey: KLARNA_MODAL_TYPES.PRODUCT,
  },
  [PAYMENT_ADVERTISING_TYPES.LANDING]: {
    placementKey: KLARNA_MODAL_TYPES.SITEWIDE,
  },
  [PAYMENT_ADVERTISING_TYPES.ORDERS]: {
    placementKey: KLARNA_MODAL_TYPES.SITEWIDE,
  },
  [PAYMENT_ADVERTISING_TYPES.SEARCH]: {
    placementKey: KLARNA_MODAL_TYPES.SITEWIDE,
  },
}
