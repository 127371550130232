export const specUrl =
  'https://www.notion.so/New-Link-Model-b72a667ace454131af05985e38f7baeb'

export const Methods = {
  Push: 'push',
  Replace: 'replace',
  Resolve: 'resolve',
}

export const LinkType = {
  Internal: 'internal',
  External: 'external',
}

export const ErrorType = {
  Hash: 'hash',
  Href: 'href',
  Link: 'link',
}

export const GDPR_SETTINGS_ANCHOR = 'settings'

export const HEADER_TRACKING_ZONE = 'header'

export const HEADER_TRACKING_NAME = {
  ABOUT_US: 'trustedLink',
  BUYBACK: 'buyback',
  CART: 'cart',
  DASHBOARD: 'dashboard',
  HELP: 'helpContact',
  LOGOUT: 'subnav_logout',
  MY_ACCOUNT: 'subnav_my_account',
  MY_ORDERS: 'subnav_my_orders',
  MY_FAVORITES: 'subnav_my_favorites',
  MY_SALES: 'subnav_my_sales',
  NAVIGATION: 'nav',
  REGISTER: 'register',
}

export const SEARCH_BAR_LIGHT = 'light'
export const HEADER_ANIMATION_THRESHOLD = 300
export const HEADER_SCROLLUP_BEFORE_DISPLAY = 10
