export default {
  namespaced: true,
  state() {
    return {
      page: 1,
    }
  },
  getters: {
    get: (state) => state.page,
  },
  mutations: {
    increment: (state) => {
      state.page += 1
    },
    reset: (state) => {
      state.page = 1
    },
  },
  actions: {
    increment: ({ commit }) => commit('increment'),
    reset: ({ commit }) => commit('reset'),
  },
}
