import auth from '@auth-oauth2/store/auth'
import user from '@auth-oauth2/store/user'
import swap from '@buyback/customer/swap/store'
import { checkoutStore as checkout } from '@checkout'
import { store as toast } from '@core/components/Toast'
import flags from '@flags/store/flags'
import http from '@http/store'
import { store as insurance } from '@insurance'
import reviewform from '@internal-reviews/components/Form/store'
import { landing, search } from '@landing/store'
import gdpr from '@legal/store'
import { store as myOrders } from '@my-orders'
import payment from '@payment/modules/form-legacy/store'
import precipitation from '@precipitation/store'
import { store as customerRequestDiscussion } from '@sav-discussion'
import { store as customerRequestPlatform } from '@sav-platform'
import seller from '@seller/store'
import listings from '@seller/sub-modules/onboarded/pages/listings/store'
import pricingTool from '@seller/sub-modules/onboarded/shared/PricingTool/store'
import appsflyer from '@tracking/appsflyer/store'
import braze from '@tracking/braze/store'

import config from './modules/config'
import countryConfig from './modules/countryConfig'
import layout from './modules/layout'

export const modules = {
  appsflyer,
  auth,
  braze,
  checkout,
  config,
  countryConfig,
  customerRequestDiscussion,
  customerRequestPlatform,
  gdpr,
  http,
  insurance,
  landing,
  layout,
  listings,
  seller,
  myOrders,
  precipitation,
  pricingTool,
  payment,
  reviewform,
  swap,
  toast,
  user,
  // We register the same module twice because we need the same features in different places. Instead of managing every
  // possible context from the Vuex module itself, we create a context-less module, and use it several times. #SOLID
  landingSearch: search,
  searchBar: search,

  // Note that touching this key will break the experiments worker. If you must
  // touch it, please get in sync with the Core Services squad to get some help.
  //
  // Technical reasons are that on its way back to the browser, the HTML that
  // Pastrami generated is updated on-the-fly by the worker. We inject some
  // piece of JavaScript code that updates the state of the `flags` store
  // with a different list from what Pastrami received via the HTTP request.
  //
  // In order to do that, the injected JavaScript code must update the special
  // variable `__NUXT__.state.flags`. As you can see, the value of this key is
  // included into that object path that we touch, so it must stay the same.
  flags,
}
