import { ROUTES } from '@router'
import { CUSTOMER_SIDE_ROUTES } from '@tracking/constants'

export const ALLOWED_ROUTES = CUSTOMER_SIDE_ROUTES

export const DO_NOT_DISTURB_ROUTES = [
  ROUTES.BUYBACK,
  ROUTES.CHECKOUT,
  ROUTES.AUTH,
]
