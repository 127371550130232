import { dynamicImport } from '@core/helpers'
import logger from '@logger'
import { ROUTES } from '@router'

const logAttempt = (to, from, next) => {
  logger.info(`[Checkout] Attempting to load ${to.name}`)

  next()
}

export default () => {
  return [
    {
      path: 'checkout',
      name: ROUTES.CHECKOUT_INSURANCE.SESSION,
      component: () =>
        dynamicImport(
          import(
            '@insurance/modules/layout/pages/InsurancePageErrorBoundaryWrapper.vue'
          ),
        ),
      children: [
        {
          path: 'authentication',
          name: ROUTES.CHECKOUT_INSURANCE.AUTHENTICATION,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import(
                '@insurance/modules/layout/pages/InsuranceAuthenticationPage.vue'
              ),
            ),
          meta: {
            guest: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT_INSURANCE.PAYMENT },
            },
          },
        },
        {
          path: 'insurance/:sessionId',
          name: ROUTES.CHECKOUT_INSURANCE.PAYMENT,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('@insurance/modules/payment/pages/PaymentPage.vue'),
            ),
          meta: {
            auth: {
              required: true,
            },
          },
        },
        {
          path: 'insurance/billing/:sessionId',
          name: ROUTES.CHECKOUT_INSURANCE.BILLING,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('@insurance/modules/billing/pages/BillingAddressPage.vue'),
            ),
          meta: {
            auth: {
              required: true,
            },
          },
        },
      ],
    },
    {
      path: 'checkout/expired',
      name: ROUTES.CHECKOUT_INSURANCE.EXPIRED,
      beforeEnter: logAttempt,
      component: () =>
        dynamicImport(
          import('@insurance/modules/layout/pages/PostPurchaseExpiredPage.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
      },
    },
  ]
}
