
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

export default {
  components: {
    RevLink,
    RevIllustration,
  },
  props: {
    text: { type: String, default: undefined },
    href: { type: String, required: true },
    icons: { type: Array, required: true },
    variant: { type: String, default: () => 'icon' },
  },
}
