import { decamelizeKeys } from 'humps'
import isEmpty from 'lodash/isEmpty'

import { APP_NAME, MODAL_STATUS } from '@config/constants'
import { insertIf, removeEmptyValuesInObject } from '@core/helpers'
import logger from '@logger'

import { PRODUCT_SOURCE_TYPE } from '../constants'

import { sendEvent, setOptOut, setUserId, setUserProperties } from './amplitude'
import { USER_GROUP_NAME, USER_GROUP_VALUES } from './constants'
import * as DEFINITIONS from './definitions'
import {
  favoritesModel,
  favoritesModelCollection,
  flagModel,
  globalRatingModel,
  productModel,
  productModelCollection,
  promotionModel,
  reviewModel,
} from './models'

// Checking if there is a race condition on the page view & context event
let contextLoaded = false
export const context = ({ flags = {}, abtest = {}, ...ctx }) => {
  contextLoaded = true
  setUserProperties({
    flags: flagModel(flags, ctx.country),
    abtest: flagModel(abtest, ctx.country),
    ...decamelizeKeys(ctx),
  })
}

export const pageView = (to, from) => {
  if (!contextLoaded) {
    logger.error('[RACE CONDITION] The context should be initialized first')
  }

  const event = {
    service: APP_NAME,
    current_page: {
      page_type: to.pageType,
      params: to.params,
    },
  }

  // Navigating on the SPA
  if (from.pageType) {
    event.referrer = {
      page_type: from.pageType,
      params: from.params,
    }
    // First load
  } else {
    event.referrer = {
      url: document.referrer,
    }
  }

  sendEvent(DEFINITIONS.PAGE_VIEW, event)
}

export const screenView = (ctx) => {
  sendEvent(DEFINITIONS.SCREEN_VIEW, decamelizeKeys(ctx))
}

export const resize = ({ breakpointName }) => {
  setUserProperties({ breakpoint: breakpointName })
}

export const gdpr = ({ analytics }) => {
  setOptOut(!analytics)
}

export const userContext = ({
  userId,
  merchantId,
  isStaff,
  lastOrderDate,
  numberOfOrders,
  lifetimeRevenue,
  merchantCompanyName,
  userMarketplace,
  accountCreationDate,
}) => {
  let groupValue = USER_GROUP_VALUES.LOGGED_CUSTOMER

  if (merchantId !== null) {
    groupValue = USER_GROUP_VALUES.LOGGED_MERCHANT
  }

  if (isStaff) {
    groupValue = USER_GROUP_VALUES.LOGGED_STAFF
  }

  setUserId(userId)

  setUserProperties(
    decamelizeKeys({
      lastOrderDate,
      numberOfOrders,
      lifetimeRevenue,
      [USER_GROUP_NAME]: groupValue,
      accountCreationDate,
      account_created: true,
      ...insertIf(merchantId !== null, {
        sellerId: merchantId + userMarketplace,
        sellerName: merchantCompanyName,
      }),
    }),
  )
}

export const paymentSuccess = ({ payment, products }) => {
  let productRevenue = 0
  let quantity = 0

  products.forEach((product) => {
    productRevenue += Number(product.price) * Number(product.quantity)
    quantity += Number(product.quantity)

    sendEvent(
      DEFINITIONS.PURCHASE_PRODUCT,
      decamelizeKeys({
        order_id: payment.orderId,
        ...productModel(product),
      }),
    )
  })

  sendEvent(DEFINITIONS.PURCHASE_ORDER, {
    shipping_revenue: payment.shipping,
    swap_eligible: payment.swap,
    sponsorship: payment.code_parrain,
    commission: payment.commission,
    '10Pct_FEE': payment['10Pct_FEE'],
    months_since_last_purchase: payment.monthsSinceLastPurchase,
    order_id: payment.orderId,
    product_revenue: productRevenue,
    product_quantity: quantity,
    payment_method: payment.paymentMethod,
    // Calculation of the product prices only (no fees etc.)
    $revenue: payment.revenue,
    coupon: payment.discount,
  })
}

export const submit = (ctx) => {
  sendEvent(DEFINITIONS.FORM_SUBMIT, ctx)
}

export const click = (ctx) => {
  sendEvent(DEFINITIONS.CLICK, decamelizeKeys(ctx))
}

export const hoverOver = (ctx) => {
  sendEvent(DEFINITIONS.HOVER_OVER, decamelizeKeys(ctx))
}

export const change = (ctx) => {
  sendEvent(DEFINITIONS.CHANGE, decamelizeKeys(ctx))
}

export const errors = ({ zone, message }) => {
  sendEvent(DEFINITIONS.ERRORS, {
    zone,
    message,
  })
}

export const webPerformanceAll = (payload) => {
  sendEvent(DEFINITIONS.WEB_PERFORMANCE_ALL, payload)
}

export const productImpression = (product) => {
  const productInfos = productModel(product)
  // Due to heavy volumetry on this sourceType, and this is not planed for analysis yet,
  // Let's disable this source for now.
  if (productInfos.product_list === PRODUCT_SOURCE_TYPE.RESULTS) {
    return
  }

  sendEvent(DEFINITIONS.PRODUCT_IMPRESSION, productInfos)
}

export const productImpressionBatched = (p) => {
  // Due to heavy volumetry on this sourceType, and this is not planed for analysis yet,
  // Let's disable this source for now.
  const productsFiltered = p.filter(
    (product) => product.list !== PRODUCT_SOURCE_TYPE.RESULTS,
  )

  if (!isEmpty(productsFiltered)) {
    sendEvent(DEFINITIONS.PRODUCT_IMPRESSION, {
      products: productModelCollection(productsFiltered),
    })
  }
}

export const reviewImpression = ({ pageType, ...ctx }) => {
  const productInfo = productModel(ctx)
  const reviewInfo = reviewModel(ctx)
  const payload = { ...productInfo, ...reviewInfo, page_type: pageType }

  sendEvent(DEFINITIONS.REVIEW_IMPRESSION, payload)
}

export const reviewBlockImpression = ({ pageType, ...ctx }) => {
  const productInfo = productModel(ctx)
  const ratingInfo = globalRatingModel(ctx)
  const payload = { ...productInfo, ...ratingInfo, page_type: pageType }

  sendEvent(DEFINITIONS.REVIEW_BLOCK_IMPRESSION, payload)
}

export const ecoBlockImpression = ({ pageType, ...ctx }) => {
  const productInfo = productModel(ctx)
  const payload = { ...productInfo, page_type: pageType }

  sendEvent(DEFINITIONS.ECO_BLOCK_IMPRESSION, payload)
}

export const videosBlockImpression = (ctx) => {
  sendEvent(DEFINITIONS.VIDEOS_BLOCK_IMPRESSION, ctx)
}

export const videoImpression = (ctx) => {
  sendEvent(DEFINITIONS.VIDEO_IMPRESSION, ctx)
}

export const addToCart = ({ product }) => {
  sendEvent(DEFINITIONS.ADD_TO_CART, productModel(product))
}

export const addToCartBatch = ({ products }) => {
  sendEvent(DEFINITIONS.ADD_TO_CART, {
    products: productModelCollection(products),
  })
}

export const productClick = ({ product }) => {
  const productInfos = productModel(product)

  sendEvent(DEFINITIONS.PRODUCT_CLICK, productInfos)
}

export const productBlockImpression = ({
  block_name,
  position,
  product_model,
}) => {
  sendEvent(
    DEFINITIONS.PRODUCT_BLOCK_IMPRESSION,
    removeEmptyValuesInObject({ block_name, position, product_model }),
  )
}

export const product = ({ product: p }) => {
  sendEvent(DEFINITIONS.PRODUCT, productModel(p))
}

export const funnel = ({ products, routeName, swap } = {}) => {
  sendEvent(DEFINITIONS.CHECKOUT_PAGE_VIEW, {
    page_type: routeName,
    products: productModelCollection(products),
    swap,
  })
}

export const addToMyFavorites = (payload) => {
  sendEvent(DEFINITIONS.ADD_TO_MY_FAVORITES, favoritesModel(payload))
}

export const favoritesPageView = ({ favorites, pageType }) => {
  const payload = {
    pageType,
    products: favoritesModelCollection(favorites),
  }

  sendEvent(DEFINITIONS.FAVORITES_PAGE_VIEW, decamelizeKeys(payload))
}

/* LEGACY EVENTS FORMAT BELOW */
// TODO: Remove as part of GNL-2044
export const clickCarousel = (promotion) => {
  sendEvent(DEFINITIONS.PROMOTION_CLICK, promotionModel(promotion))
}

export const modal = ({ status, ...ctx }) => {
  if (status === MODAL_STATUS.OPENED) {
    sendEvent(DEFINITIONS.MODAL, ctx)
  }
}

export const landingBannerClick = (promotion) => {
  sendEvent(DEFINITIONS.PROMOTION_CLICK, promotionModel(promotion))
}

export const landingBannerImpression = (promotion) => {
  sendEvent(DEFINITIONS.PROMOTION_IMPRESSION, promotionModel(promotion))
}

export const promptNotificationImpression = (payload) => {
  sendEvent(DEFINITIONS.PROMPT_NOTIFICATION_IMPRESSION, decamelizeKeys(payload))
}

export const createListing = (payload) => {
  sendEvent(DEFINITIONS.CREATE_LISTING, decamelizeKeys(payload))
}

export const sellerBoFiltersUsed = (payload) => {
  sendEvent(DEFINITIONS.BACKOFFICE_FILTERS_USED, decamelizeKeys(payload))
}

export const sellerBoActionClicked = (payload) => {
  sendEvent(DEFINITIONS.DASHBOARDS_ACTIONS_CLICKED, decamelizeKeys(payload))
}

export const sellItem = (payload) => {
  sendEvent(DEFINITIONS.SELL_ITEM_CLICKED, decamelizeKeys(payload))
}

export const postPurchaseProduct = (payload) => {
  sendEvent(DEFINITIONS.POST_PURCHASE_PRODUCT, decamelizeKeys(payload))
}

export const toastView = (payload) => {
  sendEvent(DEFINITIONS.TOAST_VIEWED, decamelizeKeys(payload))
}

export const paginationClicked = (payload) => {
  sendEvent(DEFINITIONS.PAGINATION_CLICKED, decamelizeKeys(payload))
}

export const megaMenuImpression = (payload) => {
  sendEvent(DEFINITIONS.MEGA_MENU_IMPRESSION, decamelizeKeys(payload))
}
