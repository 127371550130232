import { fetchInsurancePolicy } from '@http/endpoints'
import { trackFunnel } from '@tracking/events'

import payment from './payment'

export const mapAddress = (address = {}) => {
  return {
    street: address.addressLine1 || '',
    street2: address.addressLine2 || '',
    city: address.city || '',
    company: address.company || '',
    country: address.country || '',
    firstName: address.firstName || '',
    lastName: address.lastName || '',
    phoneNumber: address.phoneNumber || '',
    postalCode: address.postalCode || '',
    stateOrProvince: address.stateOrProvince || '',
  }
}
const POST_PURCHASE_ROUTE = 'insurance_post_purchase'

export default {
  namespaced: true,

  modules: {
    payment,
  },

  state: () => ({
    billingAddress: {},
    coverage: {},
    insurance: {},
    isLoaded: false,
    userInformation: {},
    itemId: '',
  }),

  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded
    },
    setBillingAddress(state, address) {
      state.billingAddress = mapAddress(address)
    },
    setCoverage(state, coverage) {
      state.coverage = coverage
    },
    setInsurance(state, insurance) {
      state.insurance = insurance
    },
    setUserInformation(state, userInformation) {
      state.userInformation = userInformation
    },
    setItemId(state, itemId) {
      state.itemId = itemId
    },
  },

  getters: {
    billingAddress: (state) => state.billingAddress,
    coverage: (state) => state.coverage,
    insurance: (state) => state.insurance,
    isLoaded: (state) => state.isLoaded,
    itemId: (state) => state.itemId,
    userInformation: (state) => state.userInformation,
  },

  actions: {
    async fetch({ commit, dispatch }, { sessionId } = {}) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchInsurancePolicy,
          pathParams: { sessionId },
        },
        { root: true },
      )

      dispatch('payment/setConfig', {
        signifydFingerprint: payload.signifyd_fingerprint,
        adyenEncryption: {
          key: payload.adyen_encryption_token || '',
          time: payload.generationtime || '',
        },
      })

      commit('setItemId', payload.itemId)
      commit('setCoverage', payload.selectedCoverage)
      commit('setInsurance', payload.insurance)
      commit('setUserInformation', payload.userInformation)
      commit('setBillingAddress', payload.billingAddress)

      await dispatch('payment/fetchMethods', {
        totalPrice: payload.selectedCoverage?.totalPrice?.amount,
      })

      commit('setIsLoaded', true)

      const products = {
        name: payload.insurance?.orderline?.title,
        purchaseDate: payload.insurance?.orderline?.purchaseDate,
        listingId: payload.insurance?.orderline?.listingId,
        orderlineId: payload.insurance?.orderline?.orderLineId,
        insuranceTitle: payload.selectedCoverage?.title,
        insurancePrice: payload.selectedCoverage?.totalPrice?.amount,
      }

      trackFunnel({ routeName: POST_PURCHASE_ROUTE, products })
    },

    disableLoader({ commit }) {
      commit('setIsLoaded', true)
    },
  },
}
