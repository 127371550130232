
import { RevLink } from '@ds/components/Link'
import { mapGetters } from 'vuex'

import AdminMenu from '@navigation/components/AdminMenu.vue'
import {
  HEADER_TRACKING_NAME,
  HEADER_TRACKING_ZONE,
} from '@navigation/constant'
import { logLanguageMismatch } from '@recommendation/helpers'
import { ROUTES } from '@router'
import SearchBar from '@search/SearchBar'
import { trackClick } from '@tracking/events'

import BurgerMenuIcon from './BurgerMenuIcon.vue'
import CartIconAndBubble from './CartIconAndBubble.vue'
import translations from './Header.translations'
import Logo from './Logo/Logo.vue'
import MenuBar from './Menu/MenuBar.vue'
import PrecipitationIcon from './PrecipitationIcon.vue'
import TopSalesBar from './TopSalesBar.vue'
import UserIconAndSubMenu from './UserIconAndSubMenu/UserIconAndSubMenu.vue'

export default {
  components: {
    CartIconAndBubble,
    Logo,
    RevLink,
    SearchBar,
    UserIconAndSubMenu,
    PrecipitationIcon,
    BurgerMenuIcon,
    BurgerMenu: () => import('@navigation/components/StoreNav/StoreNav.vue'),
    MenuBar,
    TopSalesBar,
    AdminMenu,
  },
  data() {
    return {
      scrollPosition: 0,
      headerVisible: true,
    }
  },
  computed: {
    ...mapGetters({
      isBuyBackEnabled: 'config/isBuyBackEnabled',
      isNavigationOpened: 'isNavigationOpened',
      isShadowingClient: 'user/isShadowingClient',
      isShadowingMerchant: 'user/isShadowingMerchant',
      isStaff: 'user/isStaff',
      navigationItems: 'countryConfig/navigation',
      experiments: 'flags/getExperiments',
      locale: 'config/locale',
    }),
    mainLinks() {
      return [
        {
          name: ROUTES.ABOUT_US,
          title: translations.aboutUs,
          trackingName: HEADER_TRACKING_NAME.ABOUT_US,
        },
        ...[
          this.isBuyBackEnabled
            ? {
                name: ROUTES.CMS.BUYBACK,
                params: {
                  pageName: 'home',
                },
                title: translations.sellProduct,
                trackingName: HEADER_TRACKING_NAME.BUYBACK,
              }
            : undefined,
        ],
        {
          name: ROUTES.HELP_CENTER.HOME,
          title: translations.help,
          trackingName: HEADER_TRACKING_NAME.HELP,
        },
      ].filter(Boolean)
    },
    isAdmin() {
      return this.isStaff || this.isShadowingClient || this.isShadowingMerchant
    },
    isNewHeaderOrganisationExperiment() {
      return this.experiments.logoHeader === 'headerLeft'
    },
    universeNavigation() {
      return this.experiments.universeNavigation === 'universeNavigation'
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)

    logLanguageMismatch(
      this.navigationItems[0]?.link,
      this.locale,
      'navigation',
    )
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    trackClick(name) {
      trackClick({
        zone: HEADER_TRACKING_ZONE,
        name,
      })
    },
    openSubMenu() {
      this.$store.dispatch('toggleNavigation')
      trackClick({
        zone: HEADER_TRACKING_NAME.NAVIGATION,
        name: 'all_product',
      })
    },
    handleScroll() {
      window.requestAnimationFrame(() => {
        const currentScrollPosition = window.scrollY
        const shouldBeVisibleAgain =
          this.scrollPosition - currentScrollPosition > 10
        const isScrollingDown = this.scrollPosition < currentScrollPosition

        if (isScrollingDown && window.scrollY > 300) {
          this.headerVisible = false
        }

        if (shouldBeVisibleAgain) {
          this.headerVisible = true
        }

        this.scrollPosition = currentScrollPosition
      })
    },
  },
}
