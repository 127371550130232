import { fetchPaymentMethods } from '@http/endpoints'
import { filterABTestedPaymentMethods } from '@payment'

export default {
  namespaced: true,

  /**
   * @typedef {Object} State
   * @property {import('@payment').PaymentMethod[]} methods
   * @property {import('@payment').PaymentConfig} config
   *
   * @returns {State}
   */
  state: () => ({
    methods: [],
    config: {
      adyenEncryption: { key: '', time: '' },
      signifydFingerprint: null,
    },
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {import('@payment').PaymentConfig} config
     */
    setConfig(state, config) {
      state.config = config
    },

    /**
     * @param {State} state
     * @param {import('@payment').PaymentMethod[]} methods
     */
    setMethods(state, methods) {
      state.methods = methods
    },
  },

  getters: {
    /**
     * @param {State} state
     * @param {Object} getters
     * @param {rootState} state
     * @param {Object} rootGetters
     * @returns {import('@payment').PaymentMethod[]}
     */
    methods: (state, getters, rootState, rootGetters) => {
      return filterABTestedPaymentMethods(
        rootGetters['flags/getExperiments'],
        state.methods,
      )
    },

    /**
     * @param {State} state
     * @param {Object} getters
     * @param {rootState} state
     * @param {Object} rootGetters
     * @returns {import('@payment').PaymentConfig}
     */
    config: (state) => {
      return state.config
    },
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('@payment').PaymentConfig & Object} payload
     */
    async setConfig({ commit }, { adyenEncryption, signifydFingerprint }) {
      commit('setConfig', { adyenEncryption, signifydFingerprint })
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {string[]} payload.cartItemsIds
     * @param {Price} payload.cartPrice
     */
    async fetchMethods({ commit, dispatch, rootGetters }, { totalPrice }) {
      const response = await dispatch(
        'http/request',
        {
          request: fetchPaymentMethods,
          queryParams: {
            country_code: rootGetters['config/country'],
            hide_unavailable: 1,
            bag_price: totalPrice,
          },
        },
        { root: true },
      )

      commit('setMethods', response.payload?.results)
    },
  },
}
