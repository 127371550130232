import get from 'lodash/get'

import { COUNTRY_KEY, MARKETPLACE_KEY } from '@server/request/constants'
import { getUserContextFromRequest } from '@server/utils'

export const getCountry = (ssrContext) => {
  if (ssrContext) {
    return getUserContextFromRequest(ssrContext.req, 'country')
  }

  return window[COUNTRY_KEY]
}

export const getMarketplace = (ssrContext) => {
  if (ssrContext) {
    return getUserContextFromRequest(ssrContext.req, 'marketplace')
  }

  return window[MARKETPLACE_KEY]
}

export const reloadForRoute = (route) => {
  return (
    get(route, 'meta.disableClientSideNavigation', false) ||
    // If the route doesn't exist in Pastrami, it can still exist in front-apps ==> force reload from server
    get(route, 'matched.length', 0) === 0
  )
}
