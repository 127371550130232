
import logger from '@logger'

import {
  COMPONENT_FROM_CONTENT_TYPE,
  HIDE_ON_DEVICE_CLASSES,
} from '../helpers/constants'
import { isHiddenOnAllDevices } from '../helpers/devices'
import { formatQaAttribute } from '../helpers/qa/formatQaAttribute'
import { trackingData } from '../helpers/tracking'
import { pageParamsFromRoute } from '../service'

// Instantiate available component based on type and props to render blocks
export default {
  props: {
    /** Component name need to be found in the index. Ex: ContentMedia */
    type: {
      type: String,
      default: '',
    },
    /** Block position on the page, needed for tracking */
    blockPosition: {
      type: Number,
      required: true,
    },
    /** Component properties to instantiate the component. */
    props: {
      type: Object,
      required: true,
    },
    /** Block id generated on contentful side */
    id: {
      type: String,
      required: true,
    },
    /** Hide block depending on the device */
    hiddenDevices: {
      type: Array,
      required: true,
    },
    withBottomMargin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasEmittedAnError: false,
    }
  },
  computed: {
    dataQaAttribute() {
      return formatQaAttribute(this.type)
    },

    block() {
      return COMPONENT_FROM_CONTENT_TYPE[this.type]
    },

    isAvailable() {
      const { type } = this
      const isAvailable = Object.keys(COMPONENT_FROM_CONTENT_TYPE).includes(
        type,
      )

      if (!isAvailable) {
        logger.error(
          `The dynamic block "${type}" configured in the CMS is unknown`,
          {
            type,
          },
        )
      }

      return isAvailable
    },

    canBeRendered() {
      return (
        !this.hasEmittedAnError &&
        this.isAvailable &&
        !isHiddenOnAllDevices({ devices: this.hiddenDevices })
      )
    },
    hiddenOnDeviceClasses() {
      return this.hiddenDevices.map((device) => HIDE_ON_DEVICE_CLASSES[device])
    },

    tracking() {
      const componentsWithDifferentTrackingNeed = ['Carousel']

      if (!componentsWithDifferentTrackingNeed.includes(this.type)) {
        const pageInfo = pageParamsFromRoute({ route: this.$route })

        return trackingData({ ...pageInfo, blockName: this.type })
      }

      return undefined
    },
  },

  methods: {
    handleError() {
      this.hasEmittedAnError = true
    },
  },
}
