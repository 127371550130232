export const SHIPPING_ALERTS = {
  /**
   * Due to the Covid-19 crisis, carriers are not delivering to certain areas.
   */
  COVID19: 'covid19',
  FLOODING: 'FLOODING',
  PAYMENT_DOWN: 'payment_down',
}

export const CART_ITEM_OPTION_TYPES = {
  DELIVERY: 'delivery',

  UNKNOWN: 'unknown',
}

export const CART_ITEM_OPTION_KNOWN_TYPES = [CART_ITEM_OPTION_TYPES.DELIVERY]

export const CHECKOUT_LOG_TYPES = {
  ADDRESS_AUTOCOMPLETE: 'CHECKOUT_ADDRESS_AUTOCOMPLETE',
  ADDRESS_SUBMIT_SHIPPING: 'CHECKOUT_ADDRESS_SUBMIT_SHIPPING',
  ADDRESS_SUBMIT_BILLING: 'CHECKOUT_ADDRESS_SUBMIT_BILLING',
  ADDRESS_SUBMIT_CONFIRMATION: 'CHECKOUT_ADDRESS_SUBMIT_CONFIRMATION',
  ADDRESS_SUBMIT_COLLECTION_POINT: 'CHECKOUT_ADDRESS_SUBMIT_COLLECTION_POINT',
}
