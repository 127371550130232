import { APP_NAME } from '@config/constants'
import { isNode } from '@core/helpers'

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'
export const DEFAULT_STATUS_ERROR = 499
export const DEFAULT_TIMEOUT = isNode() ? 10_000 : 20_000
export const FILE_TRANSFER_TIMEOUT = 60_000
export const PLATFORM = 'web'
export const BUNDLE_ID = isNode() ? `${APP_NAME}-server` : `${APP_NAME}-client`
export const METHOD_POST = 'POST'
export const METHOD_GET = 'GET'
export const METHOD_PATCH = 'PATCH'
export const METHOD_PUT = 'PUT'
export const METHOD_DELETE = 'DELETE'

export const ERROR_RESPONSE_VERSIONS = {
  unknown: 'ErrorResponseVersionUnknown',
  v1: 'ErrorResponseVersionV1',
  v2: 'ErrorResponseVersionV2',
  v3: 'ErrorResponseVersionV3',
}
