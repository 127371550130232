import isEmpty from 'lodash/isEmpty'

import {
  landingBreadcrumbFetch,
  landingDetailsFetch,
  landingNavigationSeoFetch,
  sellerDetailsFetch,
} from '@http/endpoints'
import { getGlobalRateRequest } from '@internal-reviews/helpers'
import { ROUTES } from '@router'

import translations from './landing.translations'

const productModelTypes = ['BRAND', 'CATEGORY']

export const getDefaultState = () => ({
  id: null,
  breadcrumb: [],
  marketingContent: {},
  tagline: '',
  company: '',
  address: {},
  slug: '',
  type: null,
  ebType: null,
  title: '',
  subtitle: '',
  tags: [],
  seo: {
    title: '',
    description: '',
  },
  urls: {},
  links: {},
  link: {},
  listViewStructure: {},
  landingSeoNavigation: {},
  landingGlobalRate: {},
  buyingGuide: {},
  isLoading: false,
  ratings: {},
})

export default {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    saveLanding(state, payload) {
      Object.assign(state, payload)
    },
    setBreadcrumb(state, payload) {
      state.breadcrumb = payload
    },
    setLandingSeoNavigation(state, payload) {
      state.landingSeoNavigation = payload
    },
    setLandingGlobalRate(state, payload) {
      state.landingGlobalRate = payload
    },
    reset(state) {
      Object.assign(state, {
        ...getDefaultState(),
      })
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
  },

  getters: {
    isLoading: (state) => {
      return state.isLoading
    },
    landingId: (state) => {
      return state.id
    },

    title: (state) => {
      return state.title
    },

    subtitle: (state) => {
      return state.subtitle
    },

    seoTitle: (state) => {
      return state.seo.title
    },

    seoText: (state) => {
      return state.seo.text
    },

    type: (state) => {
      return state.type
    },

    breadcrumb: (state) => {
      return state.breadcrumb
    },

    landingSeoNavigation: (state) => {
      return state.landingSeoNavigation
    },

    ratings: (state) => {
      return state.landingGlobalRate
    },

    tagline: (state) => {
      return state.tagline
    },

    company: (state) => {
      return state.company
    },

    slug: (state) => {
      return state.slug
    },

    urls: (state) => {
      return state.urls
    },

    links: (state) => {
      return state.links
    },

    marketingContent: (state) => {
      return state.marketingContent ?? {}
    },

    cardMarketingContent: (state) => {
      return state.marketingContent?.card ?? {}
    },

    listMarketingContent: (state) => {
      return state.marketingContent?.list ?? {}
    },

    buyingGuide: (state) => {
      return state.buyingGuide
    },

    tags: (state) => {
      return state.tags || []
    },

    isListView: (state) => {
      return !isEmpty(state.listViewStructure)
    },

    listViewStructure: (state) => {
      return state.listViewStructure
    },

    isProductModelLevel: (state) => {
      return productModelTypes.includes(state.type)
    },
  },

  actions: {
    reset({ commit }) {
      commit('reset')
    },
    isLoadingProductLanding({ commit }, value) {
      commit('setIsLoading', value)
    },
    async fetchProductLanding({ dispatch, commit }, { id }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: landingDetailsFetch,
          queryParams: {
            id,
          },
        },
        { root: true },
      )

      commit('saveLanding', { ...payload })
    },

    async fetchLandingBreadcrumb({ dispatch, commit }, { id }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: landingBreadcrumbFetch,
          queryParams: { id },
        },
        { root: true },
      )

      const breadcrumb = [
        { slug: { name: ROUTES.HOME }, title: this.$t(translations.home) },
        ...payload,
      ]

      commit('setBreadcrumb', breadcrumb)
    },
    clearLandingSeoNavigation({ commit }) {
      commit('setLandingSeoNavigation', {})
    },

    async fetchLandingSeoNavigation({ dispatch, commit }, { id }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: landingNavigationSeoFetch,
          queryParams: { id },
        },
        { root: true },
      )

      commit('setLandingSeoNavigation', payload)
    },

    async fetchLandingGlobalRate({ dispatch, commit }, { id, type }) {
      const { payload } = await dispatch(
        'http/request',
        getGlobalRateRequest({
          type,
          params: { id },
        }),
        { root: true },
      )

      commit('setLandingGlobalRate', payload)
    },

    async fetchSellerLanding({ dispatch, commit }, sellerId) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: sellerDetailsFetch,
          pathParams: { id: sellerId },
        },
        { root: true },
      )

      commit('saveLanding', payload)
    },
  },
}
