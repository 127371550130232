
import { RevLink } from '@ds/components/Link'

import { TYPES } from './footerConfig'

export default {
  components: {
    RevLink,
  },
  props: {
    link: { required: true, type: Object },
  },
  computed: {
    target() {
      return [TYPES.LINK_EXTERNAL, TYPES.FILE].includes(this.link.type)
        ? '_blank'
        : '_self'
    },
    name() {
      return this.$t(this.link.key) ?? this.link.name
    },
    text() {
      return this.$t(this.link.key) ?? this.link.title
    },
    url() {
      const { type, name, params, url, file } = this.link
      if (type === TYPES.LINK_INTERNAL) {
        return { name, params }
      }

      if (url) {
        return url
      }

      if (file) {
        return this.$static(`/../pdf/${file}`)
      }

      return ''
    },
  },
}
