import isEmpty from 'lodash/isEmpty'

import { formatOrderlinesFromOrders } from '@care-commons/helpers/order/formatOrderlinesFromOrders'
import { getOrderlines } from '@http/endpoints'

import info from './modules/info'
import loading from './modules/loading'
import page from './modules/page'

export default {
  namespaced: true,
  modules: {
    info,
    loading,
    page,
  },
  state() {
    return {
      orders: undefined,
      maxOrderReached: false,
    }
  },
  getters: {
    get: (state) => state.orders,
    isMaxOrderReached: (state) => state.maxOrderReached,
    hasOrder: (state) => !isEmpty(state.orders),
  },
  mutations: {
    set: (state, orders) => {
      state.orders = orders
    },
    setMore: (state, orders) => {
      state.orders = (state.orders || []).concat(orders)
    },
    maxOrderReached: (state, value) => {
      state.maxOrderReached = value
    },
  },
  actions: {
    fetch: async ({ dispatch, rootGetters, commit }) => {
      const config = {
        request: getOrderlines,
        pathParams: { id: rootGetters['user/clientId'] },
      }

      const { payload } = await dispatch('http/request', config, { root: true })

      commit('set', formatOrderlinesFromOrders(payload.results))
      commit('maxOrderReached', !payload.next)
    },
    fetchMore: async ({ dispatch, rootGetters, getters, commit }) => {
      if (getters.isMaxOrderReached) {
        return
      }

      /* As we call the dispatch action to increment the page number in our store after the
       * http request succeed we need to manually increment the nextPage queryParams to be able to
       * perform the correct API call.
       */

      const nextPage = getters['page/get'] + 1

      const config = {
        request: getOrderlines,
        pathParams: { id: rootGetters['user/clientId'] },
        queryParams: { page: nextPage },
      }

      const { payload } = await dispatch('http/request', config, { root: true })

      commit('setMore', formatOrderlinesFromOrders(payload.results))
      commit('maxOrderReached', !payload.next)
    },
  },
}
