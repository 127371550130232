export const ALLOWED_EXTERNAL_REDIRECTION_DOMAINS = [
  'offers.sheerid.com',
  'services.sheerid.com',
]

export const CHECK_AUTH_REDIRECTION = 'custom_redirection_handler'

export const BM_JOURNEYS = {
  BUYBACK: 'buyback',
  CHECKOUT: 'checkout',
  HELP_CENTER: 'help_center',
  STUDENT_DISCOUNT: 'student_discount',
  MILITARY_DISCOUNT: 'military_discount',
  FAVORITES: 'favorites',
}
