import { isMobileDevice } from '@core/helpers/client'

import { isIncludedInRoutes } from '../helpers'

export function canLoadAppsflyer({
  country,
  $config,
  userAgent,
  isMobileAppAvailable,
  allowedRoutes,
  currentRoute,
}) {
  const isRealMobileDevice = isMobileDevice(userAgent)

  const isEnabledInCurrentCountry =
    $config.FF_APPSFLYER_ENABLED_BY_COUNTRY?.includes(country)
  const isKilled = $config.KILL_APPSFLYER

  const isAllowedOnCurrentRoute = isIncludedInRoutes({
    currentRoute,
    routes: allowedRoutes,
  })

  return (
    isRealMobileDevice &&
    isMobileAppAvailable &&
    isEnabledInCurrentCountry &&
    isAllowedOnCurrentRoute &&
    !isKilled
  )
}

export function canDisplayBanner({
  isCookieModalOpen,
  isNavigationOpen,
  currentRoute,
  doNotDisturbRoutesCollections,
}) {
  return (
    !isCookieModalOpen &&
    !isNavigationOpen &&
    !isIncludedInRoutes({
      currentRoute,
      routes: doNotDisturbRoutesCollections,
    })
  )
}
