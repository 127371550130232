import translations from './schema.translations'

/**
 * Simply returns the schema object for all the Homepages of all countries.
 * https://schema.org/Organization
 *
 * @return {SchemaObject} Organization schema object
 */
export const getHomeSchemas = ({ i18nPlugin: $t }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Back Market',
    url: 'https://www.backmarket.com/en-us',
    logo: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/30b8688f15232cca88b28b990b343193/share-link-logo-BM.png',
    description: $t(translations.schemaOrgaDescription),
    founder: [
      {
        '@type': 'Person',
        name: 'Thibaud Hug de Larauze',
        gender: 'Male',
        jobTitle: $t(translations.schemaThibaudTitle),
        image:
          'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/enn8o0bkcrbmog0tbm98',
        sameAs: [
          'https://fr.linkedin.com/in/thibaud-hug-de-larauze-a9a9b160',
          'https://twitter.com/thibaudelarauze',
        ],
      },
      {
        '@type': 'Person',
        name: 'Quentin Le Brouster',
        gender: 'Male',
        jobTitle: $t(translations.schemaQuentinTitle),
        image:
          'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/ymvblntd67xfvkgdua7i',
        sameAs: 'https://fr.linkedin.com/in/quentin-le-brouster-b1a02184',
      },
      {
        '@type': 'Person',
        name: 'Vianney Vaute',
        gender: 'Male',
        jobTitle: $t(translations.schemaVianneyTitle),
        image:
          'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/fikykrgbg1s9whaynlru',
        sameAs: [
          'https://fr.linkedin.com/in/vianney-vaute-03ba2b62',
          'https://twitter.com/vinnyvandev',
        ],
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: $t(translations.schemaOrgaStreetAddress),
      addressLocality: $t(translations.schemaOrgaAddressLocality),
      addressRegion: $t(translations.schemaOrgaAddressRegion),
      postalCode: $t(translations.schemaOrgaPostalCode),
      addressCountry: $t(translations.schemaOrgaAddressCountry),
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'contact',
      telephone: $t(translations.schemaOrgaTelephone),
      email: 'press@backmarket.com',
    },
    sameAs: [
      'https://www.twitter.com/backmarket',
      'https://linkedin.com/company/back-market',
      'https://www.facebook.com/BackMarketCom',
      'https://www.instagram.com/backmarket',
      'https://play.google.com/store/apps/details?id=com.backmarket',
      'https://apps.apple.com/us/app/back-market-shop-online/id1458344336',
      'https://fr.wikipedia.org/wiki/Back_Market',
      'https://www.wikidata.org/wiki/Q56316472',
      'https://www.bbb.org/us/ny/brooklyn/profile/online-retailer/back-market-inc-0121-176814',
      'https://www.trustpilot.com/review/www.backmarket.com',
    ],
  }
}
