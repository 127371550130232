export const MAPPING_PASTRAMI_LOCALE_TO_ZD_LOCALE = {
  'de-at': 'de-at',
  'de-de': 'de',
  'el-gr': 'el',
  'en-gb': 'en-gb',
  'en-ie': 'en-ie',
  'en-us': 'en-us',
  'en-au': 'en-au',
  'es-es': 'es',
  'fi-fi': 'fi',
  'fr-be': 'fr-be',
  'fr-ca': 'fr-ca',
  'fr-fr': 'fr-fr',
  'it-it': 'it',
  'nl-be': 'nl-be',
  'nl-nl': 'nl',
  'pt-pt': 'pt',
  'sk-sk': 'sk',
  'sv-se': 'sv',
  'ja-jp': 'ja',
}

export const getZendeskLocale = (pastramilocale) => {
  return MAPPING_PASTRAMI_LOCALE_TO_ZD_LOCALE[pastramilocale] || 'en-us'
}

export const formatFaqUrl = (baseUrl, locale) => {
  return baseUrl.concat(`hc/${getZendeskLocale(locale)}`)
}
