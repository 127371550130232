
import { RevIllustration } from '@ds/components/Illustration'

import translations from './500.translations'

export default {
  components: {
    RevIllustration,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    translations: () => translations,
  },
}
