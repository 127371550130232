// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jBjn21Ynv2Kye67ARf418g\\=\\=::-ms-clear,.jBjn21Ynv2Kye67ARf418g\\=\\=::-ms-reveal{appearance:none;height:0;width:0}.jBjn21Ynv2Kye67ARf418g\\=\\=::-webkit-search-cancel-button,.jBjn21Ynv2Kye67ARf418g\\=\\=::-webkit-search-decoration,.jBjn21Ynv2Kye67ARf418g\\=\\=::-webkit-search-results-button,.jBjn21Ynv2Kye67ARf418g\\=\\=::-webkit-search-results-decoration{appearance:none}.ItcfCI5tXbHTBGNfP9SiSg\\=\\=::-moz-placeholder{color:var(--text-primary)}.ItcfCI5tXbHTBGNfP9SiSg\\=\\=:-ms-input-placeholder{color:var(--text-primary)}.ItcfCI5tXbHTBGNfP9SiSg\\=\\=::placeholder{color:var(--text-primary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-x": "jBjn21Ynv2Kye67ARf418g==",
	"placeholder": "ItcfCI5tXbHTBGNfP9SiSg=="
};
module.exports = ___CSS_LOADER_EXPORT___;
