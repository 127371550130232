import {
  PAYMENT_GROUPS,
  PAYMENT_METHODS,
  paymentGroupIcon,
  paymentMethodIcon,
} from '../../methods'
import AffirmModal from '../components/AffirmModal.vue'
import KlarnaModal from '../components/KlarnaModal.vue'
import KlarnaPaymentSummaryModal from '../components/KlarnaPaymentSummaryModal.vue'
import KlarnaSizeableIcon from '../components/KlarnaSizeableIcon.vue'
import OneyModal from '../components/OneyModal.vue'
import PaypalModal from '../components/PaypalModal.vue'
import {
  availableWhenAllOf,
  availableWhenAnyOf,
  availableWith,
} from '../helpers/config'

import translations from './advertising.translations'
import { PAYMENT_ADVERTISING_TYPES } from './constants'

export const PAYMENT_ADVERTISING_IDS = /** @type {const} */ ({
  PAYPAL: 'PAYPAL',
  AFFIRM: 'AFFIRM',
  KLARNA_ALL: 'KLARNA_ALL',
  KLARNA_SLICE_IT: 'KLARNA_SLICE_IT',
  KLARNA_PAY_LATER: 'KLARNA_PAY_LATER',
  ONEY_GENERIC: 'ONEY_GENERIC',
})

/**
 * @typedef {Object} PaymentAdvertisingConfig
 * @property {keyof typeof PAYMENT_ADVERTISING_IDS} id
 * @property {import('@payment').PaymentMethod[]} methods
 * @property {PaymentAdvertisingCartConfig} [cart]
 * @property {PaymentAdvertisingLandingConfig} [landing]
 * @property {PaymentAdvertisingOrdersConfig} [orders]
 * @property {PaymentAdvertisingProductConfig} [product]
 * @property {PaymentAdvertisingSearchConfig} [search]
 *
 * @typedef {Omit<PaymentAdvertisingConfig, 'methods' | 'id'> & { isAvailableFor: import('../helpers/config').IsAvailableForPredicate }} RawPaymentAdvertisingConfig
 */

/**
 * @type {Record<keyof typeof PAYMENT_ADVERTISING_IDS, RawPaymentAdvertisingConfig>}
 *
 * @typedef {Object} PaymentAdvertisingCartConfig
 * @property {Translation} available
 * @property {Translation} unavailable
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingLandingConfig
 * @property {Translation} label
 * @property {string} icon
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingMyOrderConfig
 * @property {Translation} label
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingProductConfig
 * @property {Translation} label
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingSearchConfig
 * @property {Translation} heading
 * @property {Translation} body
 * @property {string} label
 * @property {{ component: import('vue').default, props: Object }} largeIcon
 * @property {string} smallIcon
 * @property {import('vue').default | null} modal
 *
 * @typedef {{ id: string, defaultMessage: string }} Translation
 *
 * Note: order matters as sometimes we will advertise only the fist one we find
 * here.
 */
export const PAYMENT_METHODS_ADVERTISING = {
  [PAYMENT_ADVERTISING_IDS.ONEY_GENERIC]: {
    isAvailableFor: availableWhenAnyOf(
      PAYMENT_METHODS.ONEY3X,
      PAYMENT_METHODS.ONEY4X,
      PAYMENT_METHODS.ONEY6X,
      PAYMENT_METHODS.ONEY10X,
      PAYMENT_METHODS.ONEY12X,
    ),
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.oneyPayInInstallments,
      unavailable: translations.installmentPaymentUnavailable,
      modal: OneyModal,
    },
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.oneyPayInInstallments,
      icon: paymentMethodIcon(PAYMENT_METHODS.CARD),
      modal: OneyModal,
    },
    [PAYMENT_ADVERTISING_TYPES.ORDERS]: {
      label: translations.paidWithOney,
      modal: null,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.oneyPayInInstallments,
      description: translations.asLowAs,
      subDescription: translations.splitPayment,
      modal: OneyModal,
    },
  },
  [PAYMENT_ADVERTISING_IDS.AFFIRM]: {
    isAvailableFor: availableWith(PAYMENT_METHODS.AFFIRM),
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.affirmPayInInstallments,
      unavailable: translations.installmentPaymentUnavailable,
      modal: AffirmModal,
    },
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.affirmPayInInstallments,
      icon: paymentMethodIcon(PAYMENT_METHODS.CARD),
      modal: AffirmModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.affirmPayInInstallments,
      description: null,
      subDescription: null,
      modal: AffirmModal,
    },
  },
  [PAYMENT_ADVERTISING_IDS.KLARNA_ALL]: {
    isAvailableFor: availableWhenAllOf(
      PAYMENT_METHODS.KLARNA_PAY_LATER,
      PAYMENT_METHODS.KLARNA_SLICE_IT,
    ),
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.payLaterOrInInstallments,
      icon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.SEARCH]: {
      heading: translations.klarnaSimplyPay,
      body: translations.klarnaPaySafely,
      smallIcon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      largeIcon: {
        component: KlarnaSizeableIcon,
        props: { groupId: PAYMENT_GROUPS.KLARNA_PAY_LATER },
      },
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.payLaterOrInInstallments,
      unavailable: translations.installmentPaymentUnavailable,
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.payLaterOrInInstallments,
      description: null,
      subDescription: null,
      modal: KlarnaModal,
    },
  },
  [PAYMENT_ADVERTISING_IDS.KLARNA_PAY_LATER]: {
    isAvailableFor: availableWith(PAYMENT_METHODS.KLARNA_PAY_LATER),
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.klarnaPayLater,
      icon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.ORDERS]: {
      label: translations.paidWithKlarnaPayLater,
      modal: KlarnaPaymentSummaryModal,
    },
    [PAYMENT_ADVERTISING_TYPES.SEARCH]: {
      heading: translations.klarnaSimplyPay,
      body: translations.klarnaPaySafely,
      smallIcon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      largeIcon: {
        component: KlarnaSizeableIcon,
        props: { groupId: PAYMENT_GROUPS.KLARNA_PAY_LATER },
      },
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.klarnaPayLater,
      unavailable: translations.payLaterUnavailable,
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.klarnaPayLater,
      description: null,
      subDescription: null,
      modal: KlarnaModal,
    },
  },
  [PAYMENT_ADVERTISING_IDS.KLARNA_SLICE_IT]: {
    isAvailableFor: availableWith(PAYMENT_METHODS.KLARNA_SLICE_IT),
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.klarnaPayInInstallments,
      unavailable: translations.installmentPaymentUnavailable,
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.klarnaPayInInstallments,
      icon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.ORDERS]: {
      label: translations.paidWithKlarnaSliceIt,
      modal: KlarnaPaymentSummaryModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.klarnaPayInInstallments,
      description: null,
      subDescription: null,
      modal: KlarnaModal,
    },
  },
  [PAYMENT_ADVERTISING_IDS.PAYPAL]: {
    isAvailableFor: availableWith(PAYMENT_METHODS.PAYPAL),
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.paypalPaymentAvailable,
      unavailable: translations.paypalPaymentUnavailable,
      modal: PaypalModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.paypalPaymentAvailable,
      description: null,
      subDescription: null,
      modal: PaypalModal,
    },
  },
}
