import { camelizeKeys } from 'humps'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import { DEFAULT_ADDRESS_STATE } from '@address/config/address'
import { fieldErrorTranslator } from '@address/helpers/fields'
import { mapValues } from '@core/helpers'
import {
  setClientAddress,
  updateCollectionPointCustomerDetails,
  updateUserInformation,
} from '@http/endpoints'

import { FORM_TYPES } from './userInformation'

/**
 * @param {Object<string,string[]>} errors Errors codes or messages, by field names.
 * @param {(error: string, fieldName: string) => string} mapper Error mapper
 * @returns {Object<string,string[]>} Translated error messages, by field names.
 */
const mapErrors = (errors, mapper) =>
  mapValues(errors, (fieldErrors, fieldName) =>
    fieldErrors.map((error) => mapper(error, fieldName)),
  )

export default {
  namespaced: true,

  /**
   * @typedef {Object} State
   * @property {import('../modules/common').CheckoutAddress} shipping
   * @property {import('../modules/common').CheckoutAddress} billing
   * @property {import('../modules/common').CollectionPointAddress} collectionPoint
   * @property {import('../modules/common').Country[]} shippableCountries
   * @property {import('../modules/common').Country[]} billableCountries
   * @returns {State}
   */
  state: () => ({
    shipping: cloneDeep(DEFAULT_ADDRESS_STATE),
    billing: cloneDeep(DEFAULT_ADDRESS_STATE),
    collectionPoint: {},
    shippableCountries: [],
    billableCountries: [],
  }),

  getters: {
    /**
     * @param {State} state
     * @returns {import('../modules/common').CheckoutAddress}
     */
    shipping: (state) => state.shipping,

    /**
     * @param {State} state
     * @returns {import('../modules/common').CheckoutAddress}
     */
    billing: (state) => state.billing,

    /**
     * @param {State} state
     * @returns {import('../modules/common').CollectionPointAddress}
     */
    collectionPoint: (state) => state.collectionPoint,

    /**
     * @param {State} state
     * @returns {import('../modules/common').Country[]}
     */
    shippableCountries: (state) => state.shippableCountries,

    /**
     * @param {State} state
     * @returns {import('../modules/common').Country[]}
     */
    billableCountries: (state) => state.billableCountries,

    /**
     * @param {State} state
     * @returns {boolean}
     */
    isShippingAddressComplete: (state) => {
      const { shipping } = state

      return (
        !isEmpty(`${shipping.firstName} ${shipping.lastName}`.trim()) &&
        !isEmpty(shipping.street.trim()) &&
        !isEmpty(shipping.postalCode.trim()) &&
        !isEmpty(shipping.city.trim()) &&
        !isEmpty(shipping.country) &&
        !isEmpty(shipping.countryDialInCode) &&
        !isEmpty(shipping.phone)
      )
    },

    /**
     * @param {State} state
     * @returns {boolean}
     */
    isBillingAddressComplete: (state) => {
      const { billing } = state

      return (
        !isEmpty(`${billing.firstName} ${billing.lastName}`.trim()) &&
        !isEmpty(billing.street.trim()) &&
        !isEmpty(billing.postalCode.trim()) &&
        !isEmpty(billing.city.trim()) &&
        !isEmpty(billing.country)
      )
    },

    /**
     * @param {State} state
     * @returns {boolean}
     */
    hasCollectionPoint: (state) => !isEmpty(state.collectionPoint),

    /**
     * @param {State} state
     * @returns {boolean}
     */
    isCollectionPointAddressComplete: (state) => {
      const { collectionPoint } = state

      return (
        !isEmpty(collectionPoint) &&
        !isEmpty(collectionPoint.firstName) &&
        !isEmpty(collectionPoint.lastName) &&
        !isEmpty(collectionPoint.countryDialInCode) &&
        !isEmpty(collectionPoint.phone)
      )
    },
  },

  mutations: {
    /**
     * @param {State} state
     * @param {import('../modules/common').CheckoutAddress} shipping
     */
    setShipping(state, shipping) {
      state.shipping = shipping
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').CheckoutAddress} billing
     */
    setBilling(state, billing) {
      state.billing = billing
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').CollectionPointAddress} collectionPoint
     */
    setCollectionPoint(state, collectionPoint) {
      state.collectionPoint = collectionPoint
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').Country[]} shippableCountries
     */
    setShippableCountries(state, shippableCountries) {
      state.shippableCountries = shippableCountries
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').Country[]} billableCountries
     */
    setBillableCountries(state, billableCountries = []) {
      state.billableCountries = billableCountries
    },
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('../modules/common').CartAddressConfig} payload
     */
    set({ commit }, payload) {
      commit('setShipping', {
        ...payload.shippingAddress,
        customerIdNumber: payload.billingAddress.customerIdNumber,
      })

      commit('setBilling', payload.billingAddress)
      commit('setCollectionPoint', payload.collectionPointAddress)
      commit('setShippableCountries', payload.shippableCountries)
      commit('setBillableCountries', payload.billableCountries)
    },

    async save({ dispatch }, { isShipping, isBilling, formType, ...address }) {
      try {
        // 1. Shipping and/or billing address (ensure we have a valid address for swap/insurance)
        const body = { isShipping, isBilling, ...address }
        await dispatch(
          'http/request',
          { request: setClientAddress, body },
          { root: true },
        )

        // 2. User information (if needed)
        if (!isEmpty(formType) && formType !== FORM_TYPES.NONE) {
          const userInformationBody =
            formType === FORM_TYPES.INSURANCE_ONLY
              ? {
                  birthdate: address.birthdate,
                  ...(address.customerIdNumber && {
                    taxId: address.customerIdNumber,
                  }),
                }
              : {
                  birthdate: address.birthdate,
                  nationality: address.nationality,
                  ...(address.customerIdNumber && {
                    taxId: address.customerIdNumber,
                  }),
                  sourcingAddress: address,
                }

          await dispatch(
            'http/request',
            {
              request: updateUserInformation,
              body: { ...userInformationBody, formType },
            },
            { root: true },
          )
        }
      } catch (error) {
        if (error.payload && typeof error.payload.error === 'object') {
          await Promise.reject(
            camelizeKeys(
              mapErrors(error.payload.error, fieldErrorTranslator(this.$t)),
            ),
          )
        }
        throw error
      }
    },

    async saveCollectionPoint({ dispatch }, { formType, ...address }) {
      try {
        await dispatch(
          'http/request',
          {
            request: updateCollectionPointCustomerDetails,
            body: {
              countryDialInCode: address.countryDialInCode,
              firstName: address.firstName,
              lastName: address.lastName,
              phone: address.phone,
            },
          },
          { root: true },
        )

        // 2. User information (if needed)
        if (!isEmpty(formType) && formType !== FORM_TYPES.NONE) {
          const userInformationBody =
            formType === FORM_TYPES.INSURANCE_ONLY
              ? { birthdate: address.birthdate }
              : {
                  birthdate: address.birthdate,
                  nationality: address.nationality,
                  sourcingAddress: address,
                }

          await dispatch(
            'http/request',
            {
              request: updateUserInformation,
              body: { ...userInformationBody, formType },
            },
            { root: true },
          )
        }
      } catch (error) {
        if (error.payload && typeof error.payload.error === 'object') {
          await Promise.reject(error.payload.error)
        }
        throw error
      }
    },
  },
}
