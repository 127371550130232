import throttle from 'throttleit'

const THROTTLE_WAIT = 700

export const initBreakpointObserver = (store) => {
  const update = () => {
    store.dispatch('updateBreakpoint')
  }

  const delayUpdateBp = throttle(function throttledUpdateBreakpoint() {
    update()
  }, THROTTLE_WAIT)

  window.addEventListener('resize', delayUpdateBp)
  update()
}
