export default {
  // TODO: Rename key to `listings_active` when refacto is done and listings page replaced
  active: {
    id: 'listings_link_active',
    defaultMessage: 'Active',
  },
  activeListingsCount: {
    id: 'listings_active_offers_count_new',
    defaultMessage: `{offersTotalCount, plural,
      one {# active listing}
      other {# active listings}
    }`,
  },
  // TODO: Rename key to `listings_archived` when refacto is done and listings page replaced
  archived: {
    id: 'listings_link_archived',
    defaultMessage: 'Archived',
  },
  archivedListingsCount: {
    id: 'listings_archived_offers_count',
    defaultMessage: `{offersTotalCount, plural,
      one {# archived listing}
      other {# archived listings}
    }`,
  },
  // TODO: Rename key to `listings_missing_infos` when refacto is done and listings page replaced
  missingInfos: {
    id: 'listings_link_missing_infos',
    defaultMessage: 'Missing information',
  },
  missingInfosListingsCount: {
    id: 'listings_missing_infos_offers_count',
    defaultMessage: `{offersTotalCount, plural,
      one {# listing with missing information}
      other {# listings with missing information}
    }`,
  },
  sortBy: {
    id: 'listings_sort_order_by',
    defaultMessage: 'Sort by',
  },
  pageDescription: {
    id: 'listings_page_description',
    defaultMessage: 'Listings | Back Market',
  },
  pageTitle: {
    id: 'listings_page_title',
    defaultMessage: 'Listings',
  },
  // TODO: Rename key to `listings_in_validation` when refacto is done and listings page replaced
  inValidation: {
    id: 'listings_link_in_validation',
    defaultMessage: 'In validation',
  },
  inValidationListingsCount: {
    id: 'listings_in_validation_offers_count',
    defaultMessage: `{offersTotalCount, plural,
      one {# listing in validation}
      other {# listings in validation}
    }`,
  },
  offlineInfoTitle: {
    id: 'listings_offline_info_title',
    defaultMessage: 'You are currently offline',
  },
  offlineInfoDescription: {
    id: 'listings_offline_info_description',
    defaultMessage:
      'As you are offline, all your listings are under the Missing Informations tab of the listing page. Once your merchant account will be validated by our team, you will be able to access all the listing page features and your listings will be pushed online.',
  },
  sortStockAscending: {
    id: 'listings_sort_stock_ascending',
    defaultMessage: 'Stock (ascending)',
  },
  sortStockDescending: {
    id: 'listings_sort_stock_descending',
    defaultMessage: 'Stock (descending)',
  },
  sortAlphabeticalAscending: {
    id: 'listings_sort_alphabetical_order_ascending',
    defaultMessage: 'Alphabetical order (A-Z)',
  },
  sortAlphabeticalDescending: {
    id: 'listings_sort_alphabetical_order_descending',
    defaultMessage: 'Alphabetical order (Z-A)',
  },
  mainMarket: {
    id: 'listings_main_market',
    defaultMessage: 'Main market',
  },
  display: {
    id: 'listings_display',
    defaultMessage: 'Display',
  },
  simple: {
    id: 'listings_display_simple',
    defaultMessage: 'Simple view',
  },
  detailed: {
    id: 'listings_display_detailed',
    defaultMessage: 'Detailed view',
  },
  loading: {
    id: 'listings_loading_screen_sentence',
    defaultMessage: 'Your listings are loading',
  },
  toastClose: {
    id: 'listings_toast_close',
    defaultMessage: 'Close',
  },
  paginationCount: {
    id: 'listings_pagination_count',
    defaultMessage: '{start} - {end} out of {count} items',
  },
  listingCountLabel: {
    id: 'listings_view_select_number_of_listing_label',
    defaultMessage: 'Number of listings',
  },
  onboardingModalTitle: {
    id: 'listings_view_onboarding_modal_title',
    defaultMessage: 'Welcome to Listings',
  },
  onboardingModalDescription: {
    id: 'listings_view_onboarding_modal_done_section',
    defaultMessage:
      'Make yourself at home. This is where you’ll create and manage listings, update prices and inventory, and more.',
  },
  onboardingModalToDoSection: {
    id: 'listings_view_onboarding_modal_to_do_section',
    defaultMessage:
      'Start by searching Back Market’s catalog for items you’re ready to sell.',
  },
  onboardingModalDoneSection: {
    id: 'listings_view_onboarding_modal_back_button_label',
    defaultMessage: 'Head back to the Onboarding page to continue.',
  },
  onboardingModalButtonLabel: {
    id: 'listings_view_onboarding_modal_button_label',
    defaultMessage: 'Create listings',
  },
  title: {
    id: 'listings_title',
    defaultMessage: 'My listings',
  },
}
