export default {
  oneyPayInInstallments: {
    id: 'payment_advertising_oney_pay_in_installments',
    defaultMessage: 'Pay in installments',
  },
  affirmPayInInstallments: {
    id: 'payment_advertising_affirm_pay_in_installments',
    defaultMessage: 'Pay in installments',
  },
  klarnaPayInInstallments: {
    id: 'payment_advertising_klarna_pay_in_installments',
    defaultMessage: 'Pay in installments',
  },
  // TODO: [PAYIN-3629] remove generic translations when all the specific ones are translated
  payInInstallments: {
    id: 'payment_advertising_pay_in_installments',
    defaultMessage: 'Pay in installments',
  },
  payLaterOrInInstallments: {
    id: 'payment_advertising_pay_later_or_in_installments',
    defaultMessage: 'Pay later or in installments',
  },
  klarnaPayLater: {
    id: 'payment_advertising_pay_later',
    defaultMessage: 'Pay later',
  },
  installmentPaymentAvailable: {
    id: 'payment_advertising_installment_payment_available',
    defaultMessage: 'Installment payment available',
  },
  installmentPaymentUnavailable: {
    id: 'payment_advertising_installment_payment_unavailable',
    defaultMessage: 'Installment payment unavailable',
  },
  paidWithKlarnaPayLater: {
    id: 'payment_advertising_paid_with_klarna_pay_later',
    defaultMessage: 'This product was paid for with Klarna.',
  },
  paidWithKlarnaSliceIt: {
    id: 'payment_advertising_paid_with_klarna_slice_it',
    defaultMessage: 'This order has been paid in installments with Klarna.',
  },
  paidWithOney: {
    id: 'payment_advertising_paid_with_oney',
    defaultMessage: 'This product has been paid in installments using Oney.',
  },
  payLaterAvailable: {
    id: 'payment_advertising_pay_later_available',
    defaultMessage: 'Pay later available',
  },
  payLaterUnavailable: {
    id: 'payment_advertising_pay_later_unavailable',
    defaultMessage: 'Pay later unavailable',
  },
  paypalPaymentAvailable: {
    id: 'payment_advertising_paypal_payment_available',
    defaultMessage: 'Pay in 4 interest-free payments',
  },
  paypalPaymentUnavailable: {
    id: 'payment_advertising_paypal_payment_unavailable',
    defaultMessage: 'Installment payment unavailable',
  },
  klarnaSimplyPay: {
    id: 'klarna_product_ad_heading',
    defaultMessage: 'Simply pay with Klarna!',
  },
  klarnaPaySafely: {
    id: 'klarna_product_ad_body',
    defaultMessage:
      'Pay safely & easily with Klarna pay later or in up to 24 flexible monthly installments.',
  },
  asLowAs: {
    id: 'payment_advertising_as_low_as',
    defaultMessage: 'As low as {installment, html}.',
  },
  splitPayment: {
    id: 'payment_advertising_split_payment',
    defaultMessage: '{amount}/mo',
  },
}
