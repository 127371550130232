import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { CURRENCIES } from '@config/constants'
import { sortByProperty } from '@core/helpers'
import { getCurrencyPrecision } from '@core/helpers/currency'
import { getPlugin as getTranslationPlugin } from '@i18n/utils'
import { getProductInfos } from '@tracking'

import {
  CART_ITEM_OPTION_KNOWN_TYPES,
  CART_ITEM_OPTION_TYPES,
  SHIPPING_ALERTS,
} from '../config/constants'

import translations from './http.translations'

/**
 * @typedef {Object} CollectionPointPostalAddress
 * @property {string} street
 * @property {string} street2
 * @property {string} postalCode
 * @property {string} city
 * @property {string} country
 */

/**
 * @typedef {Object} CollectionPoint
 * @property {string} id
 * @property {string} name
 * @property {CollectionPointPostalAddress} address
 */

/**
 * @typedef {Object} CollectionPointAddress
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 * @property {string} countryDialInCode
 * @property {CollectionPoint} collectionPoint
 */

/**
 * @param {string|null} countryCode
 * @returns {string}
 */
const mapCountry = (countryCode) => (countryCode || '').toUpperCase()

/**
 * @typedef {Object} Country
 * @property {string} label
 * @property {string} value
 *
 * @param {Object} data
 * @returns {Country}
 */
const mapFromCountry = (data) => ({
  label: data.name,
  value: mapCountry(data.country_code),
})

/**
 * @typedef {LegacyAddress} CheckoutAddress
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} company
 * @property {string} customerIdNumber
 * @property {string} countryDialInCode Ex: '+1'
 * @property {string} phone
 * @property {string} birthdate DD/MM/YYYY
 * @property {string} nationality ISO 3166-1 alpha2, capitalized
 * @property {string[]} alerts See `SHIPPING_ALERTS` for possible values.
 *
 * @param {Object} data
 * @returns {CheckoutAddress}
 */
export const mapFromAddress = (data = {}) => ({
  firstName: data.first_name || '',
  firstNamePronunciation: data.first_name_pronunciation || '',
  lastName: data.last_name || '',
  lastNamePronunciation: data.last_name_pronunciation || '',
  company: data.company || '',
  street: data.street || '',
  street2: data.street2 || '',
  city: data.city || '',
  postalCode: data.postal_code || '',
  stateOrProvince: data.state_or_province || '',
  country: mapCountry(data.country || ''),
  customerIdNumber: data.customer_id_number || '',
  countryDialInCode: data.country_dial_in_code || '',
  phone: data.phone || '',
  birthdate: data.birthdate || '',
  nationality: data.nationality || '',
  alerts: (data.alerts || data.warnings || []).filter((alert) =>
    Object.values(SHIPPING_ALERTS).includes(alert),
  ),
})

/**
 * @typedef {Object} CartItemOptionChoice
 * @property {string} id
 * @property {string} shortTitle
 * @property {string} longTitle
 * @property {string} earliestArrivalDate
 * @property {string} latestArrivalDate
 * @property {string} shipper
 * @property {string} shippingDelay
 * @property {Price} sumPrice
 * @property {string} formattedPrice Either a price (ex: '3.99 $/month') or a label (ex: 'included')
 * @property {boolean} isSelected
 * @property {boolean} isCollectionPoint
 *
 * @returns {CartItemOptionChoice}
 */
const mapFromCartItemOptionChoice = (data, currency, quantity) => {
  const dataToProcess = data || {}

  return {
    id: String(dataToProcess.id),
    shortTitle: dataToProcess.title || '',
    longTitle: `${dataToProcess.shipping_delay_label} • ${dataToProcess.price_with_currency}`,
    earliestArrivalDate: dataToProcess.earliestArrivalDate,
    latestArrivalDate: dataToProcess.latestArrivalDate,
    shipper: dataToProcess.shipper_display || '',
    sumPrice: {
      currency,
      amount: String(quantity * dataToProcess.price),
    },
    formattedPrice: dataToProcess.price_with_currency || '',
    isSelected: !!dataToProcess.selected,
    isCollectionPoint: !!dataToProcess.is_collection_point,
  }
}

/**
 * @typedef {Object} CartItemOption
 * @property {'delivery'|'trustpack'|'chillpack'} type
 * @property {string} title
 * @property {CartItemOptionChoice[]} choices
 *
 * @returns {CartItemOption}
 */
const mapFromCartItemOption = (data, currency, quantity) => {
  const dataToProcess = data || {}

  return {
    type: CART_ITEM_OPTION_KNOWN_TYPES.includes(dataToProcess.type)
      ? dataToProcess.type
      : CART_ITEM_OPTION_TYPES.UNKNOWN,
    title: dataToProcess.title || '',
    choices: (dataToProcess.choices || []).map((choice) =>
      mapFromCartItemOptionChoice(choice, currency, quantity),
    ),
  }
}

const mapFromCartItemShipping = ({
  selectedShipping: { shipping_fee_pk: id, ...data } = {},
  currency,
}) => ({
  id,
  title: `${data.shipping_delay_label} - ${data.shipper_display}`,
  sumPrice: {
    amount: data.sum_shipping_fee_price,
    currency,
  },
})

// Very ghetto stuff, I'm sorry. I asked not to backport this, but got denied.
// TODO Migrate to Checkout API, where this should be taken into account as a
// business rule on back-end side.
function hasCartItemGrade(item) {
  const SPECIAL_OFFER_TYPE_APPLE_CERTIFIED_PRE_OWNED = 2
  const $t = getTranslationPlugin()

  return (
    !isEmpty(item.grade_name) &&
    item.special_offer_type !== SPECIAL_OFFER_TYPE_APPLE_CERTIFIED_PRE_OWNED &&
    item.category !== $t(translations.responseCategoryCases)
  )
}

/**
 * @param {Object} payload
 * @param {number} payload.shipperId
 * @param {number} payload.delay In hours
 * @param {Object} payload.price
 * @param {string} payload.price.amount
 * @param {string} payload.price.currency
 * @returns {string}
 */
export function formatShippingOptionTracking({ shipperId, delay, price }) {
  return [
    shipperId,
    delay,
    Number(price.amount).toFixed(getCurrencyPrecision(price.currency)),
  ].join(' - ')
}

/**
 * @typedef {Object} CartItem
 * @property {string} id
 * @property {Object} link
 * @property {string} title
 * @property {boolean} hasGrade
 * @property {string} gradeId
 * @property {string} gradeName
 * @property {string[]} gradeDescription
 * @property {string} image
 * @property {string} category
 * @property {Price} unitPrice
 * @property {Price} marketUnitPrice
 * @property {Price} sumPrice
 * @property {number} quantity
 * @property {number} availableQuantity
 * @property {boolean} isAvailable
 * @property {CartItemOption[]} options
 * @property {Object} shipping
 * @property {Object[]} insuranceOffers
 * @property {string} url
 * @property {string} sku
 * @property {string} color
 * @property {string} model
 * @property {string} brand
 * @property {string} merchant
 * @property {string} merchantId
 * @property {string} productId
 * @property {Object} productTrackingData
 *
 * @returns {CartItem}
 */
export const mapFromCartItem = (item, currency) => {
  const itemToProcess = item || {}

  const insuranceOffers = get(itemToProcess, 'insurance_offers', []) || []
  const insurance = insuranceOffers.find(
    (offer) => !offer.defaultOffer && offer.selected,
  )

  const deliveryOption = itemToProcess.options.find(
    (option) => option.type === CART_ITEM_OPTION_TYPES.DELIVERY,
  )
  const deliveryOptionChoices = deliveryOption?.choices || []

  return {
    id: itemToProcess.listing_id || '',
    link: itemToProcess.link || {},
    title: itemToProcess.title || '',
    hasGrade: hasCartItemGrade(itemToProcess),
    hasNewBattery: itemToProcess.gradeExtended?.hasNewBattery ?? false,
    gradeId: get(itemToProcess, 'grade_id', ''),
    gradeName: itemToProcess.grade_name || '',
    gradeDescription: itemToProcess.grade_description || [],
    image: itemToProcess.image || '',
    category: itemToProcess.category || '',
    categoryId: itemToProcess.category_id || '',
    marketplaceCategoryId: itemToProcess.marketplace_category_id || '',
    unitPrice: {
      amount: String(itemToProcess.price),
      currency,
    },
    marketUnitPrice: {
      amount: String(itemToProcess.former_price),
      currency,
    },
    sumPrice: {
      amount: String(itemToProcess.sum_price),
      currency,
    },
    quantity: itemToProcess.quantity,
    availableQuantity: itemToProcess.quantity_max,
    // Really?!
    isAvailable: itemToProcess.available === 'yes',
    subtitleElements: itemToProcess.subtitleElements,
    bestVariant: itemToProcess.bestVariant,
    options: sortByProperty(
      (itemToProcess.options || []).map((option) =>
        mapFromCartItemOption(option, currency, itemToProcess.quantity),
      ),
      'type',
      CART_ITEM_OPTION_KNOWN_TYPES,
    ),
    installmentMonthlyPaymentPlan: itemToProcess.installmentMonthlyPaymentPlan,
    insuranceOffers: itemToProcess.insurance_offers || [],
    shipping: mapFromCartItemShipping({
      selectedShipping: itemToProcess.shipping_selected,
      currency,
    }),
    sku: itemToProcess.sku || '',
    color: itemToProcess.color || '',
    model: itemToProcess.model || '',
    brand: itemToProcess.brand || '',
    merchant: itemToProcess.merchant || '',
    merchantId: itemToProcess.merchant_id || '',
    productId: itemToProcess.product_id || '',
    productTrackingData: {
      available: itemToProcess.available === 'yes',
      brand: get(itemToProcess, 'brand'),
      category: get(itemToProcess, 'category', ''),
      color: get(itemToProcess, 'color', ''),
      currency,
      id: get(itemToProcess, 'product_id', ''),
      insurancePrice: get(insurance, 'price.amount'),
      insuranceTitle: get(insurance, 'title'),
      list: getProductInfos(itemToProcess.product_id).source,
      listingId: get(itemToProcess, 'listing_id', ''),
      merchantId: get(itemToProcess, 'merchant_id', ''),
      model: get(itemToProcess, 'model', ''),
      name: get(itemToProcess, 'title', ''),
      specialOfferType: get(itemToProcess, 'special_offer_type'),
      price: get(itemToProcess, 'price', ''),
      quantity: get(itemToProcess, 'quantity', ''),
      shipper: formatShippingOptionTracking({
        shipperId: get(itemToProcess, 'shipping_selected.shipper_id', ''),
        delay: get(itemToProcess, 'shipping_selected.shipping_fee_delay', '0'),
        price: {
          amount: get(
            itemToProcess,
            'shipping_selected.shipping_fee_price',
            '0',
          ),
          currency,
        },
      }),
      shippingDelay: get(
        itemToProcess,
        'shipping_selected.shipping_fee_delay',
        '',
      ),
      shippingPrice: get(
        itemToProcess,
        'shipping_selected.shipping_fee_price',
        '',
      ),
      shippingOptions: deliveryOptionChoices.map((choice) =>
        formatShippingOptionTracking({
          shipperId: choice.shipper_id,
          delay: choice.shipping_delay,
          price: {
            amount: String(choice.price),
            currency,
          },
        }),
      ),
      uuid: get(itemToProcess, 'akeneoId', ''),
      variant: get(itemToProcess, 'grade_id', ''),
      warrantyDuration: get(itemToProcess, 'warranties[0].delay', ''),
    },
    providedAccessories: itemToProcess.providedAccessories,
    uuid: get(itemToProcess, 'akeneoId', ''),
  }
}

/**
 * @typedef {{
 *   search?: { address: LegacyAddress },
 *   selectedPoint?: CollectionPoint,
 * }} LastCollectionPoint
 * @typedef {{
 *   price: Price,
 *   tax: Price,
 *   serviceFee: Price | null,
 *   totalShippingPrice: Price,
 *   totalPriceIncludesTax: boolean,
 *   totalPriceWithoutShipping: Price,
 *   totalListingsPrice: Price,
 *   swap: Object,
 *   items: CartItem[],
 *   lastCollectionPoint: LastCollectionPoint,
 * }} Cart
 * @typedef {{
 *   shippingAddress: CheckoutAddress,
 *   billingAddress: CheckoutAddress,
 *   collectionPointAddress: CollectionPointAddress,
 *   shippableCountries: Country[],
 *   billableCountries: Country[],
 * }} CartAddressConfig
 *
 * @returns {Cart & CartAddressConfig & import('@payment').PaymentConfig}
 */
export const mapFromCart = (payload = {}) => {
  // TODO Migrate to Checkout API once available.
  // Read more: https://www.notion.so/Checkout-API-a6ed0bf046844edb862d33ede412f7cc
  const currency = get(
    payload,
    'summary.total_price_without_addon_service.currency',
    CURRENCIES.USD,
  )

  const { summary = {} } = payload

  return {
    price: {
      currency,
      amount: String(summary.total_price || 0),
    },
    serviceFee: isEmpty(summary.service_fee) ? null : summary.service_fee,
    tax: summary.tax,
    totalPriceIncludesTax: summary.total_price_includes_tax,
    totalShippingPrice: summary.total_delivery,
    // TODO: Cleanup once total_price_without_delivery is available in API
    totalPriceWithoutShipping: {
      currency,
      amount: String(
        summary.total_price_without_delivery ||
          summary.total_price - summary.delivery_total ||
          0,
      ),
    },
    totalListingsPrice: summary.total_listings_price,
    totalGrossPriceWithoutAddonService:
      summary.total_gross_price_without_addon_service,
    totalGrossPriceWithoutBmFee: summary.total_gross_price_without_bm_fee,
    items: (payload.cart_items || []).map((item) =>
      mapFromCartItem(item, currency),
    ),
    signifydFingerprint: payload.signifyd_fingerprint,
    adyenEncryption: {
      key: payload.adyen_encryption_token || '',
      time: payload.generationtime || '',
    },
    // Note: address_list can be { deliver: null, bill: null, deliverCollectionPoint: null }
    shippingAddress: mapFromAddress((payload.address_list || {}).deliver || {}),
    billingAddress: mapFromAddress((payload.address_list || {}).bill || {}),
    collectionPointAddress: (payload.address_list || {}).deliverCollectionPoint,
    swap: payload.swap || {},
    shippableCountries: (payload.shippable_countries || []).map(mapFromCountry),
    billableCountries: (payload.billable_countries || []).map(mapFromCountry),
    lastCollectionPoint: payload.lastCollectionPoint,
    userInformation: payload.userInformation,
    isInsuranceARBoostAvailable: payload.isInsuranceARBoostAvailable,
  }
}
