import get from 'lodash/get'

import {
  fetchPaymentMethods,
  filterABTestedPaymentMethods,
  isPaymentMethodEnabled,
  paymentAdvertising,
} from '@payment'

export default {
  namespaced: true,

  /**
   * @typedef {Object} State
   * @property {import('@payment').PaymentMethod[]} methods
   * @property {import('@payment').PaymentConfig} config
   * @property {string[]} advertisedMethodsId
   * @property {import('@Payment'.PaymentMethod) | null} selectedMethod
   *
   * @returns {State}
   */
  state: () => ({
    methods: [],
    config: {
      adyenEncryption: { key: '', time: '' },
      signifydFingerprint: null,
    },
    selectedMethod: null,
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {import('@payment').PaymentMethod[]} methods
     */
    setMethods(state, methods) {
      state.methods = methods
    },

    /**
     * @param {State} state
     * @param {import('@payment').PaymentConfig} config
     */
    setConfig(state, config) {
      state.config = config
    },

    /**
     * @param {State} state
     * @param {import('@payment').PaymentMethod} method
     */
    setSelectedMethod(state, method) {
      state.selectedMethod = method
    },
  },

  getters: {
    /**
     * @param {State} state
     * @param {Object} getters
     * @param {rootState} state
     * @param {Object} rootGetters
     * @returns {import('@payment').PaymentMethod[]}
     */
    methods: (state, getters, rootState, rootGetters) => {
      return filterABTestedPaymentMethods(
        rootGetters['flags/getExperiments'],
        state.methods,
      )
    },

    /**
     * @param {State} state
     * @param {Object} getters
     */
    enabledMethods: (state, { methods }) =>
      methods.filter(isPaymentMethodEnabled),

    /**
     * @param {State} state
     * @param {Object} getters
     * @param {Object} rootGetters
     */
    advertisedMethodsId: (state, { methods }, rootGetters) =>
      get(
        paymentAdvertising('product', methods, { rootGetters }),
        'methods',
        [],
      )
        .map((method) => method.bmCode)
        .join('_') || null,

    /**
     * @param {State} state
     */
    selectedMethod: (state) => state.selectedMethod,

    /**
     * @param {State} state
     * @returns {import('@payment').PaymentConfig}
     */
    config: (state) => state.config,
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('@payment').PaymentConfig & Object} payload
     */
    async set({ commit }, { adyenEncryption, signifydFingerprint }) {
      commit('setConfig', { adyenEncryption, signifydFingerprint })
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {string[]} payload.cartItemsIds
     * @param {Price} payload.cartPrice
     */
    async fetchMethods(context, { cartItemsIds, cartPrice }) {
      const payload = await fetchPaymentMethods(context, {
        listings: cartItemsIds,
        bagPrice: cartPrice.amount,
      })

      context.commit('setMethods', payload)
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {import('@payment').PaymentMethod | null} payload.paymentMethod
     */
    setSelectedMethod({ commit }, { paymentMethod }) {
      commit('setSelectedMethod', paymentMethod)
    },
  },
}
