
import { RevButtonBase } from '@ds/components/ButtonBase'

import { COMPANY_NAME } from '@config/constants'
import { ROUTES } from '@router'

import BmLogo from './Logo.svg'

export default {
  components: {
    RevButtonBase,
    BmLogo,
  },
  computed: {
    COMPANY_NAME: () => COMPANY_NAME,
    ROUTES: () => ROUTES,
  },
}
