// These values are generated by the Identity team
// and are used to identify the client in the OAuth2 flow.
// They can be public and included in the build.
const CLIENT_ID_BY_REGION = {
  preprod: {
    EU: '1da9128a-ab6c-44ec-97fe-470a1a9542f8',
    NA: '9ac2d4df-be32-4f27-9ead-a59f6d88948a',
    AP: '8891cd58-9762-401d-8d3c-8b8e5b0d42d7',
  },
  prod: {
    EU: '1dbf1601-67d2-4674-a28c-b3411c09ee6e',
    NA: '062f4f62-0732-45d0-b828-09623bb89d91',
    AP: '4c4393c3-146a-461b-971e-9fb878bf769d',
  },
}

/**
 * @param {string} apiBaseUrl
 *
 * @param {string} region
 * @see config/constants/marketplaces.js
 *
 * @returns {string}
 *
 * @example
 * getClientId('https://preprod.backmarket.fr', 'EU')
 * // => '1da9128a-ab6c-44ec-97fe-470a1a9542f8'
 */
export const getClientId = (apiBaseUrl, region) => {
  const isDevelopment = process.env.NODE_ENV === 'development'
  // in development, we use a client id
  // that will work with the local fr site:
  // https://local.backmarket.fr/fr-fr
  if (isDevelopment) {
    return '708ef570-e88e-4293-869e-f4af11f30dd4'
  }

  const { hostname } = new URL(apiBaseUrl)
  const [subdomain] = hostname.split('.').slice()

  if (subdomain === 'preprod') {
    return CLIENT_ID_BY_REGION.preprod[region]
  }

  return CLIENT_ID_BY_REGION.prod[region]
}
