
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { mapGetters } from 'vuex'

import { COMPANY_NAME } from '@config/constants'

import { getSocialNetWorksFor } from './footerConfig'

export default {
  components: {
    RevLink,
    RevIllustration,
  },
  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    socials() {
      return getSocialNetWorksFor(this.country)
    },
    COMPANY_NAME: () => COMPANY_NAME,
  },
}
