import isEmpty from 'lodash/isEmpty'

/**
 * Used by our image optimisation system.
 *
 * @param {String} path
 * @param {Object} optimizations
 * @param {Number} optimizations.width
 *
 * @return {String}
 */
export const provider = (path, { width }) => {
  if (isEmpty(path)) {
    return ''
  }

  // The `11ty/eleventy-image` library does not support `.gif` as output. And
  // processing animated `.gif` to `.webp` would lose the animations.
  // See: https://www.11ty.dev/docs/plugins/image
  if (path.endsWith('.gif')) {
    return path
  }

  // Let's keep the `.svg` format since it's vectorial already, and would
  // properly render on any device size (and pixels density) anyway.
  if (path.endsWith('.svg')) {
    return path
  }

  // Local paths usually start with a slash, which cause issues. Let's drop it.
  const pathNormalized = path.startsWith('/') ? path.substr(1) : path

  const params = encodeURIComponent(`format=webp,width=${width}`)

  return `/images/${params}/${pathNormalized}`
}
