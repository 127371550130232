export default {
  ABOUT_US: {
    BEHIND_THE_SCENES_VIDEO: 'BEHIND_THE_SCENES_VIDEO',
    BACK_LABEL_VIDEO: 'BACK_LABEL_VIDEO',
  },
  AUTH: {
    RESET_PASSWORD_EXPLANATION: 'RESET_PASSWORD_EXPLANATION',
  },
  BO_ADMIN: {
    SALES_LIMITATIONS: {
      CREATE_LIMITATION: 'CREATE_LIMITATION',
      DELETE_LIMITATION: 'DELETE_LIMITATION',
      EDIT_LIMITATION: 'EDIT_LIMITATION',
    },
    SALES: {
      UPDATE_PRODUCT: {
        PUT_ON_HOLD: 'PUT_ON_HOLD',
      },
    },
    RESTRICTED_CATEGORIES: {
      CREATE_RESTRICTED_CATEGORY: 'CREATE_RESTRICTED_CATEGORY',
      DELETE_RESTRICTED_CATEGORY: 'DELETE_RESTRICTED_CATEGORY',
      UPDATE_RESTRICTED_CATEGORY: 'UPDATE_RESTRICTED_CATEGORY',
    },
    CUSTOMERS: {
      ADDRESSES: 'ADDRESSES',
      ADD_CREDIT: 'ADD_CREDIT',
      CREDIT_REQUESTS: 'CREDIT_REQUESTS',
      DELETE_CUSTOMERS_DATA: 'DELETE_CUSTOMERS_DATA',
      GOODWILL_GESTURE: 'GOODWILL_GESTURE',
      ORDERS: 'ORDERS',
      BUYBACK_ORDERS: 'BUYBACK_ORDERS',
      BLOCK_FRAUDSTERS: 'BLOCK_FRAUDSTERS',
      UPDATE_CUSTOMER_EMAIL: 'UPDATE_CUSTOMER_EMAIL',
    },
    FINANCE: {
      CREDIT_REQUESTS: {
        CHANGE_STATUS: 'CHANGE_STATUS',
        IMPORT: 'IMPORT',
      },
      REASONS: {
        DETAIL: 'REASON_DETAIL',
      },
      SELLER_COMPENSATIONS: {
        CREATE_SELLER_COMPENSATION: 'CREATE_SELLER_COMPENSATION',
      },
    },
    BACKSHIP: {
      TRADELINES: {
        TRADELINES_DETAILS: 'TRADELINES_DETAILS',
        CREATE_TRADELINE: 'CREATE_TRADELINE',
        EDIT_TRADELINE: 'EDIT_TRADELINE',
      },
      BILLING: {
        INVOICE: {
          INVOICE_DETAILS: 'INVOICE_DETAILS',
          APPROVE_INVOICES: 'APPROVE_INVOICES',
          CREATE_SELLER_CHARGES: 'CREATE_SELLER_CHARGES',
          DELETE_INVOICES: 'DELETE_INVOICES',
          IMPORT_SELLER_CHARGE_ADJUSTMENT: 'IMPORT_SELLER_CHARGE_ADJUSTMENT',
        },
        MARGINS_CONFIGURATION: {
          CREATE_TRADELINE_BACKSHIP_MARGIN: 'CREATE_TRADELINE_BACKSHIP_MARGIN',
          EDIT_TRADELINE_BACKSHIP_MARGIN: 'EDIT_TRADELINE_BACKSHIP_MARGIN',
          CREATE_SELLER_BACKSHIP_MARGIN: 'CREATE_SELLER_BACKSHIP_MARGIN',
          EDIT_SELLER_BACKSHIP_MARGIN: 'EDIT_SELLER_BACKSHIP_MARGIN',
          CREATE_CARRIER_BACKSHIP_MARGIN: 'CREATE_CARRIER_BACKSHIP_MARGIN',
          EDIT_CARRIER_BACKSHIP_MARGIN: 'EDIT_CARRIER_BACKSHIP_MARGIN',
        },
        SELLER_CHARGE_DETAILS: 'SELLER_CHARGE_DETAILS',
      },
    },
    PICSOU: {
      CREATE_BALANCE_ADJUSTMENT: 'CREATE_BALANCE_ADJUSTMENT',
      IMPORT_BALANCE_ADJUSTMENTS: 'IMPORT_BALANCE_ADJUSTMENTS',
    },
    CCBM_FEE_RULES: {
      IMPORT_CCBM_FEE_RULES: 'IMPORT_CCBM_FEE_RULES',
    },
    REFUNDS: {
      MANUAL_REFUNDS_CONFIRM_PAYMENT: 'MANUAL_REFUNDS_CONFIRM_PAYMENT',
    },
  },
  CHECKOUT: {
    CART_TRUST_ELEMENTS_ADYEN: 'CART_TRUST_ELEMENTS_ADYEN',
    CART_COLLECTION_POINTS: 'CART_COLLECTION_POINTS',
    CART_INCLUDED_SERVICES: 'CART_INCLUDED_SERVICES',
    CART_INSURANCE_OFFER_CATCHUP: 'CART_INSURANCE_OFFER_CATCHUP',
    CART_INSURANCE_OFFER_EXPLANATIONS: 'CART_INSURANCE_OFFER_EXPLANATIONS',
    CART_SHIPPING: 'CART_SHIPPING',
    PAYMENT_METHODS: 'PAYMENT_METHODS',
    SUMMARY_SERVICE_FEE: 'SUMMARY_SERVICE_FEE',
    SATISFACTION_CART: 'SATISFACTION_CART',
    WARRANTY_CART: 'WARRANTY_CART',
  },
  BUYBACK: {
    // these are all the same modal but requires different id's
    // to avoid double openings (in the cart especcially)
    SWAP_PRODUCT: 'SWAP_PRODUCT',
    SWAP_ATC: 'SWAP_ATC',
    SWAP_CART: 'SWAP_CART',
    SWAP_CHECKOUT_SUMMARY: 'SWAP_CHECKOUT_SUMMARY',
    MY_SALES_SHIP_ITEM: 'MY_SALES_SHIP_ITEM',
    MY_SALES_PAYMENT_DETAILS: 'MY_SALES_PAYMENT_DETAILS',
    MY_SALES_COUNTER_OFFER: 'MY_SALES_COUNTER_OFFER',
    MY_SALES_CONFIRM_COUNTER_OFFER: 'MY_SALES_CONFIRM_COUNTER_OFFER',
    REFURBISHER_ORDER_SEND_PAYMENT: 'REFURBISHER_ORDER_SEND_PAYMENT',
    REFURBISHER_ORDER_ON_HOLD: 'REFURBISHER_ORDER_ON_HOLD',
    REFURBISHER_WISHLIST_CSV: 'REFURBISHER_WISHLIST_CSV',
    REFURBISHER_WISHLIST_FILTERS: 'REFURBISHER_WISHLIST_FILTERS',
    REFURBISHER_FILTER_PRODUCTS: 'REFURBISHER_FILTER_PRODUCTS',
    VIEW_COUNTER_OFFER: 'VIEW_COUNTER_OFFER',
    AGENT_UPDATE_CUSTOMER_INFO: 'AGENT_UPDATE_CUSTOMER_INFO',
    AGENT_KYC_ID: 'AGENT_KYC_ID',
    AGENT_KYC_BANK: 'AGENT_KYC_BANK',
    AGENT_KYC_IDENTITY_INFORMATIONS: 'AGENT_KYC_IDENTITY_INFORMATIONS',
    AGENT_PAYMENT: 'AGENT_PAYMENT',
    AGENT_CANCEL_ORDER: 'AGENT_CANCEL_ORDER',
  },
  KNOW_YOUR_CUSTOMER: {
    PROFILE_ADDRESS: 'PROFILE_ADDRESS',
    PROFILE_ADDRESS_EDIT: 'PROFILE_ADDRESS_EDIT',
    PROFILE_BANK_DETAILS_INFO_UPLOAD: 'PROFILE_BANK_DETAILS_INFO_UPLOAD',
    PROFILE_INFO_EDIT: 'PROFILE_INFO_EDIT',
    PROFILE_PASSWORD_EDIT: 'PROFILE_PASSWORD_EDIT',
    PROFILE_RIB_DELETE: 'PROFILE_RIB_DELETE',
  },
  MERCHANT: {
    ADDRESS_FORM: 'ADDRESS_FORM',
    ADDRESS_RULE: 'ADDRESS_RULE',
    DELETE_ADDRESS: 'DELETE_ADDRESS',
    DELETE_ADDRESS_RULE: 'DELETE_ADDRESS_RULE',
    DELETE_INTEGRATOR: 'DELETE_INTEGRATOR',
    DELETE_SHIPPING_METHOD: 'DELETE_SHIPPING_METHOD',
    INTERNATIONAL_ONBOARDING: 'INTERNATIONAL_ONBOARDING',
    OFFERS: {
      FILTERS: 'OFFERS_FILTERS',
      DRAWER: 'OFFERS_DRAWERS',
    },
    OPPORTUNITIES: {
      FILTERS: 'OFFERS_FILTERS',
    },
    LISTINGS_ACTION_PANEL: 'LISTINGS_ACTION_PANEL',
    LISTINGS_LISTING_DETAIL: 'LISTINGS_LISTING_DETAIL',
    LISTINGS_MOBILE_FILTERS: 'LISTINGS_MOBILE_FILTERS',
    LISTINGS_MOBILE_SORT: 'LISTINGS_MOBILE_SORT',
    LISTINGS_NEW_GRADES: 'LISTINGS_NEW_GRADES',
    LISTINGS_PRICE_ALERT: 'LISTINGS_PRICE_ALERT',
    LISTINGS_SELECT_MARKET: 'LISTINGS_SELECT_MARKET',
    MAKE_AN_OFFER: 'MAKE_AN_OFFER',
    MARKET_LIST_SELECT_MARKET: 'MARKET_LIST_SELECT_MARKET',
    NEW_INTEGRATORS: 'NEW_INTEGRATORS',
    OPPORUNITY_PICTURE_MODAL: 'OPPORUNITY_PICTURE_MODAL',
    PAYPAL_SETTINGS: 'PAYPAL_SETTINGS',
    KONBINI_SETTINGS: 'KONBINI_SETTINGS',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
    ONBOARDING: {
      WELCOME_SELLER: 'WELCOME_SELLER',
      EDIT_BUSINESS_DETAILS: 'EDIT_BUSINESS_DETAILS',
      EDIT_LEGAL_DETAILS: 'EDIT_LEGAL_DETAILS',
      EDIT_TAX_DETAILS: 'EDIT_TAX_DETAILS',
      EDIT_EPR_DETAILS: 'EDIT_EPR_DETAILS',
      DELETE_EPR_DETAILS: 'DELETE_EPR_DETAILS',
      COPY_LEVY: 'COPY_LEVY',
      EDIT_BANKING_DETAILS: 'EDIT_BANKING_DETAILS',
      INFORMATION_MODAL: 'INFORMATION_MODAL',
      EDIT_TAX_IDENTIFICATION_NUMBER: 'EDIT_TAX_IDENTIFICATION_NUMBER',
      EDIT_MAIN_TAX_RESIDENCE_COUNTRY: 'EDIT_MAIN_TAX_RESIDENCE_COUNTRY',
      EDIT_ADDITIONAL_TAX_RESIDENCE_COUNTRIES:
        'EDIT_ADDITIONAL_TAX_RESIDENCE_COUNTRIES',
    },
    USER_PERMISSIONS: {
      ACTIVATE_OR_DEACTIVATE_USER: 'ACTIVATE_OR_DEACTIVATE_USER',
      USER_FORM: 'USER_FORM',
    },
    FILE_LIBRARY: 'FILE_LIBRARY',
    ORDERS: {
      BULK_ACTIONS_CONFIRMATION: 'BULK_ACTIONS_CONFIRMATION',
    },
    REVIEWS: {
      FILTERS: 'REVIEWS_FILTERS',
    },
    CUSTOMER_CARE: {
      FILTERS: 'CUSTOMER_CARE_FILTERS',
      RETURN_DETAILS: 'RETURN_DETAILS',
    },
  },
  MY_ORDERS: {
    ORDERS_ASK_BILL_MODAL: 'ORDERS_ASK_BILL_MODAL',
    ORDERS_BILLS_CHILLPACK_MODAL: 'ORDERS_BILLS_CHILLPACK_MODAL',
    ORDERS_BILLS_TRUSTPACK_MODAL: 'ORDERS_BILLS_TRUSTPACK_MODAL',
  },
  PAYMENT: {
    CREDIT_CARD_SECURITY_CODE: 'CREDIT_CARD_SECURITY_CODE',
  },
  REFER_FRIENDS: {
    HISTORY: 'REFER_FRIENDS_HISTORY',
    GENERATE_PROMO_CODE: 'REFER_FRIENDS_GENERATE_PROMO_CODE',
  },
  SAV_PLATFORM: {
    SOURCING_ORDERS_LIST_HELP: 'SOURCING_ORDERS_LIST_HELP',
    HELP_MODAL_NAME: 'HELP_MODAL_NAME',
    VISIBILITY_CONFIRMATION_MODAL: 'VISIBILITY_CONFIRMATION_MODAL',
    SELLER_ONBOARDING_MODAL: 'SELLER_ONBOARDING_MODAL',
    AGENT_ONBOARDING_MODAL: 'AGENT_ONBOARDING_MODAL',
  },
  VANISHING_DEALS: {
    PICKLE_PORTRAIT: 'PICKLE_PORTRAIT',
    GOOD_DEALS: 'GOOD_DEALS',
    BAD_DEALS: 'BAD_DEALS',
  },
  PRODUCT: {
    ADD_TO_CART: 'ADD_TO_CART',
    BUNDLE: 'BUNDLE',
    CARRIERS_COMPATIBILITY: 'CARRIERS_COMPATIBILITY',
    COPY_LEVY: 'COPY_LEVY',
    RENEWED_IMPACT: 'RENEWED_IMPACT',
    EXCLUDED_ACCESSORIES: 'EXCLUDED_ACCESSORIES',
    FAQ: 'FAQ',
    GRADES: 'GRADES_DETAILS',
    HIGHLIGHTED_PICKERS: 'HIGHLIGHTED_PICKERS',
    MEDIA_VIEWER: 'MEDIA_VIEWER',
    NEW_BATTERY: 'NEW_BATTERY',
    PERKS: 'PERKS',
    SERVICE_FEE: 'SERVICE_FEE',
    SIM: 'SIM',
    TECH_SPECS: 'TECH_SPECS',
    REASSURANCE: 'REASSURANCE',
    TRADE_IN: 'TRADE_IN',
    VERIFIED_REFURBISHED: 'VERIFIED_REFURBISHED',
  },
  REASSURANCE: {
    WARRANTY: 'WARRANTY',
    SATISFACTION: 'SATISFACTION',
  },
  REVIEWS: {
    REVIEWS_LEGAL_INFORMATION: 'REVIEWS_LEGAL_INFORMATION',
  },
  LEGAL: {
    LEGAL_DOCUMENTS_LIST: 'LEGAL_DOCUMENTS_LIST',
  },
}

export const MODAL_STATUS = {
  OPENED: 'opened',
  CLOSED: 'closed',
}
