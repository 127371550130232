import { PRODUCT_RETURN_STATES } from '@customer-requests/Platform/constants'
import { isPending, isReturns } from '@sav-common/helpers'

export default {
  namespaced: true,

  actions: {
    async refunded({ dispatch }, { context, customerRequestId, isFullRefund }) {
      if (isPending(context)) {
        // In the pending context, we must also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      } else if (isReturns(context) && isFullRefund) {
        // On the other hand, fully refunding an order when in the product
        // returns context should remove the product return from the list as
        // it's not pending anymore (as the merchant fully refunded it).
        dispatch(
          'customerRequestPlatform/conversations/remove',
          { customerRequestId },
          { root: true },
        )
      }
    },
    async oneWayReturnCreated({ dispatch }, { context, customerRequestId }) {
      if (isPending(context)) {
        // In the pending context, we should also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      }

      // Finally update the status of the product return in the selected
      // conversation
      dispatch(
        'customerRequestDiscussion/information/update',
        {
          customerRequestId,
          updates: {
            productReturnState: PRODUCT_RETURN_STATES.returnCreated,
          },
        },
        { root: true },
      )
    },
    async twoWayReturnCreated({ dispatch }, { context, customerRequestId }) {
      if (isPending(context)) {
        // In the pending context, we should also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      }

      if (isReturns(context)) {
        // In the product returns context should remove the product return from
        // the list as it's not pending anymore (as the merchant returned the
        // product back to the customer).
        dispatch(
          'customerRequestPlatform/conversations/remove',
          { customerRequestId },
          { root: true },
        )
      }

      // Only update the status of the product return in the selected
      // conversation when we are not in product returns context as it's
      // unselected anyway in this context.
      dispatch(
        'customerRequestDiscussion/information/update',
        {
          customerRequestId,
          updates: {
            productReturnState: PRODUCT_RETURN_STATES.returnBackCreated,
          },
        },
        { root: true },
      )
    },
  },
}
