import { getCurrencyPrecision } from './currency'

/**
 * Convert an price object amount to a minor unit amount.
 * This helper is made to avoid some calculations errors due to JavaScript.
 * For example: 160.9 * 100 = 16090 but 160.8 * 100 = 16080.0000000002.
 * So instead, split the integer and the decimal part,
 * multiply the integer part by 100 and the decimal part by 10 and add them together.
 * The 100 factor actually depends on the currency precision. For example, the
 * factor is 1 for currencies without decimals.
 *
 * @param  {Price} price The price to convert into minor units.
 * @return {number} The amount converted to minor units.
 *
 * @throws When the provided amount is not a string.
 */
export const priceToMinorUnits = ({ currency, amount }) => {
  if (typeof amount !== 'string') {
    throw new Error(
      `You should provide a string amount (${amount}, ${typeof amount} given)!`,
    )
  }

  const [integer = '0', decimal = '0'] = amount.split('.')

  const precision = getCurrencyPrecision(currency)

  let result = parseInt(integer, 10) * 10 ** precision

  for (let i = 1; i <= Math.min(precision, decimal.length); i += 1) {
    result += parseInt(decimal.charAt(i - 1), 10) * 10 ** (precision - i)
  }

  return result
}

/**
 * Convert a price object amount to an amount with the right currency precision.
 *
 * @param  {Price} price The price to convert into minor units.
 * @return {string} The amount written with the correct precision.
 *
 * @throws  When the provided amount is not a string.
 */
export const getRightPrecisionAmount = ({ currency, amount }) => {
  if (typeof amount !== 'string') {
    throw new Error(
      `You should provide a string amount (${amount}, ${typeof amount} given)!`,
    )
  }

  const precision = getCurrencyPrecision(currency)

  const result = Number.parseFloat(amount).toFixed(precision)

  return result.toString()
}

/**
 * Get the formatted amount of the given price for text
 * inputs only allowing numbers, dots, and commas.
 * It removes "." and "," if currency doesn't have subunits.
 *
 * @param  {Price} price Price that have the amount to format
 * @return {string} Formatted amount
 */
export function getFormattedAmount(price) {
  const hasSubunit = getCurrencyPrecision(price.currency) !== 0

  const formattedAmount = price.amount
    .toString()
    .replace(/[^\d.,]/g, '')
    .replace(/^(\.|,)/g, '')

  return hasSubunit ? formattedAmount : formattedAmount.replace(/(\.|,)/g, '')
}
