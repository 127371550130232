import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

const Product = () => dynamicImport(import('./pages/product.vue'))

export default () => {
  return [
    {
      name: ROUTES.PRODUCT.HOME,
      path: 'p/:slugV2/:uuid',
      component: Product,
      meta: {
        disableClientSideNavigation: true,
      },
    },
  ]
}
