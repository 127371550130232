import { MARKET_STATUS, TRUSTMARKS } from '@config/constants'
import { convertToYear } from '@core/helpers/date'
import { formatFaqUrl } from '@core/helpers/helpCenter'

export default {
  namespaced: true,
  state() {
    return {
      countryCode: null,
      currency: '',
      country: '',
      marketplace: '',
      locale: '',
      lang: '',
      baseURL: '',
      faqUrl: null,
      warranty: {},
      showCarrier: null,
      showInsuranceCatchupModal: false,
      video404: null,
      trustmark: TRUSTMARKS.NONE,
      trustmarkConfig: {},
      visitorId: null,
      sessionId: null,
      cgvModalLegals: false,
      apiBaseUrlClient: '',
      apiBaseUrlServer: '',
      buyback: {
        refurbisher: false,
        enabled: false,
        swapAvailable: false,
        hasRib: false,
        faq: {},
      },
      aboutUs: {
        bCorpLogo: {},
        pressLogos: [],
        hasImpactArticle: false,
      },
    }
  },
  getters: {
    userRequestHeaders: (state) => state.headers || {},
    baseURL: (state) => state.baseURL,
    getFaqUrl: (state) => state.faqUrl,
    apiBaseUrl: (state) =>
      process.server ? state.apiBaseUrlServer : state.apiBaseUrlClient,
    visitorId: (state) => state.visitorId,
    sessionId: (state) => state.sessionId,
    locale: (state) => state.locale,
    lang: (state) => state.lang,
    country: (state) => state.country,
    marketplace: (state) => state.marketplace,
    countryStatus: (state) => state.countryStatus,
    currencyCode: (state) => state.currency,
    warrantyDuration: (state) => {
      const { displayInYear, duration } = state.warranty

      return displayInYear ? convertToYear(duration) : duration
    },
    vatIncluded: (state) => state.vatIncluded,
    showCarrier: (state) => state.showCarrier,
    showInsuranceCatchupModal: (state) => state.showInsuranceCatchupModal,
    video404: (state) => state.video404,
    trustmark: (state) => state.trustmark,
    useTrustedShops: (state) =>
      state.trustmark === TRUSTMARKS.TRUSTED_SHOPS ||
      state.trustmark === TRUSTMARKS.CONFIANZA_ONLINE,
    useTrustpilot: (state) => state.trustmark === TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: (state) => state.trustmarkConfig,
    coolingOffDays: (state) => state.coolingOffDays,
    isMobileAppAvailable: (state) => state.mobileAppAvailable,
    isBuyBackEnabled: (state) => state.buyback.enabled,
    isBuyBackRefurbisherEnabled: (state) => state.buyback.refurbisher,
    isSwapAvailable: (state) => state.buyback.swapAvailable,
    hasRib: (state) => state.buyback.hasRib,
    buybackFaqLinks: (state) => state.buyback.faq,
    buybackRecyclingLink: (state) => state.buyback.recyclingLink,
    cgvModalLegals: (state) => state.cgvModalLegals,
    userAgent: (state) => state.userAgent,
    aboutUs: (state) => state.aboutUs,
    dataLayerContext: (state, getters) => {
      return {
        country: getters.country,
        locale: getters.locale,
        currencyCode: getters.currencyCode,
        trustmark: getters.trustmark,

        // Active features
        isCountryRollout: state.countryStatus === MARKET_STATUS.OPEN,
        hasBuyBack: state.buyback.enabled,
        hasVATIncluded: getters.vatIncluded,
        hasCarrierFilters: getters.showCarrier,
      }
    },
  },
  mutations: {
    setConfig(state, payload) {
      const faqUrl = this.$config
        ? formatFaqUrl(
            this.$config.ZENDESK_BASE_URL,
            process.server ? payload.locale : state.locale,
          )
        : ''

      Object.assign(state, payload, { faqUrl })
    },
  },
  actions: {
    load({ commit }, payload) {
      commit('setConfig', payload)
    },
  },
}
