import { getHttpFromRoute } from './utils'

import logger from './index'

/**
 * Append HTTP-related information into the logger global context.
 *
 * We rely on a middleware because it ensures that this piece of code will
 * be executed before any route transition happens, both on the client and
 * on the server. Thanks to that, any log fired during the HTML generation
 * process, or during a client-side navigation will be linked to the route
 * being processed, not the one we're leaving.
 *
 * The only caveat is that for the first page load, it will run on the server
 * but won't run again on the client. So, we have to use a client-side plugin
 * to overcome this issue, and fill the logger context on the first load.
 *
 * @see https://nuxtjs.org/docs/concepts/nuxt-lifecycle
 * @see https://nuxtjs.org/docs/components-glossary/middleware
 *
 * @param {Object} context
 * @param {import('vue-router').Route} context.route
 * @param {import('express').Request} context.req
 */
export function loggerMiddleware({ route, req }) {
  logger.appendGlobalContext({
    http: getHttpFromRoute(route, req),
  })
}
