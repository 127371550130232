// Braze SDK documentation: https://js.appboycdn.com/web-sdk/latest/doc/modules/braze.html

import localStorage from '@core/helpers/localStorage'

import { getDeviceId as getAmplitudeDeviceId } from '../amplitude/amplitude'

import { SDK_ENDPOINT, SERVICE_WORKER_LOCATION } from './constants'

const state = {
  apiKey: '',
  safariWebsitePushId: '',
  brazeSdk: null,
  setAvailable: null,
  getUserCrmId: null,
}

function isSDKDisabled() {
  return Boolean(state.brazeSdk?.isDisabled())
}

const hasStoredAmplitudeDeviceId = () => {
  const amplitudeDeviceId = localStorage.getItem('amplitudeDeviceId')

  return !!amplitudeDeviceId
}

const setAmplitudeDeviceId = () => {
  const amplitudeDeviceId = getAmplitudeDeviceId()

  if (isSDKDisabled() || !amplitudeDeviceId) return

  localStorage.setItem('amplitudeDeviceId', amplitudeDeviceId)

  state.brazeSdk
    .getUser()
    .setCustomUserAttribute('amplitude_device_id', amplitudeDeviceId)
}

export const setGoogleAdCustomAttributes = ({ hasUserConsent }) => {
  if (isSDKDisabled()) return

  const userHandle = state.brazeSdk.getUser()

  userHandle.setCustomUserAttribute('$google_ad_user_data', hasUserConsent)
  userHandle.setCustomUserAttribute(
    '$google_ad_personalization',
    hasUserConsent,
  )
}

export const changeUser = async () => {
  if (isSDKDisabled()) return

  const { brazeSdk, getUserCrmId } = state

  try {
    const {
      payload: { crmId },
    } = await getUserCrmId()

    if (!crmId) {
      return
    }

    brazeSdk.changeUser(crmId)

    if (!hasStoredAmplitudeDeviceId()) {
      setAmplitudeDeviceId()
    }
  } catch {
    // fail silently
  }
}

export const updateSdk = ({ optIn }) => {
  if (!state.brazeSdk) return

  const { apiKey, safariWebsitePushId, brazeSdk, setAvailable } = state

  if (!optIn) {
    brazeSdk.disableSDK()

    return
  }

  brazeSdk.enableSDK()

  // Activate a flag in Braze store so components know they can request it.
  // Unfortunately we can not store the actual instance
  // because it can modify itself (which is forbiden outside of Vuex mutations)
  setAvailable()

  const initOptions = {
    enableLogging: false,
    baseUrl: SDK_ENDPOINT,
    serviceWorkerLocation: SERVICE_WORKER_LOCATION,
    safariWebsitePushId,
  }

  brazeSdk.initialize(apiKey, initOptions)
}

export const initializeSdk = async ({
  apiKey,
  safariWebsitePushId,
  getBrazeSdk,
  setAvailable,
  getUserCrmId,
  optIn,
}) => {
  const brazeSdk = await getBrazeSdk()

  state.apiKey = apiKey
  state.safariWebsitePushId = safariWebsitePushId
  state.brazeSdk = brazeSdk
  state.setAvailable = setAvailable
  state.getUserCrmId = getUserCrmId

  updateSdk({ optIn })

  return state.brazeSdk
}

export const getSdk = () => {
  return state.brazeSdk
}

export function importBrazeSdk() {
  return import('@braze/web-sdk')
}

export function registerBrazeServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(SERVICE_WORKER_LOCATION)
    })
  }
}
