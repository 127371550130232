import { COUNTRIES } from '@config/constants'
import { LEGAL_PAGE_NAME } from '@legal/constants'
import { ROUTES } from '@router'

import translations from './Footer.translations'

const INSTAGRAM_URL = 'https://www.instagram.com/'
const FACEBOOK_URL = 'https://www.facebook.com/'
const TWITTER_URL = 'https://www.twitter.com/'

export const TYPES = {
  LINK_INTERNAL: 'link-internal',
  LINK: 'link',
  LINK_EXTERNAL: 'link-external',
  FILE: 'file',
  PAYMENT_NETWORKS: 'payment-networks',
  SOCIAL_NETWORKS: 'social-networks',
  ICONS: 'icons',
  LOGOS: 'logos',
}

/*
key: translation key on Lokalise
title: direct label to display (no key provided)
name: name of the link, if the key does not exist (when we only have a title)
prefetch: If the page behind the link should be prefetched. Defaults to true
url: url of the link. Usually provided as a function since the links are different regarding the locale.
type:
- link: classic link on BM
- link-external: target blank on a link to open another website
- file: link to a file with target blank
- payment-methods: display the different payment methods available in the current country
*/

const LINKS = {
  ABOUT_US: {
    key: translations.aboutUs,
    name: ROUTES.ABOUT_US,
    type: TYPES.LINK_INTERNAL,
  },
  ACCESSIBILITY: {
    key: translations.accessibilityStatement,
    name: ROUTES.CMS.STATEMENT,
    type: TYPES.LINK_INTERNAL,
  },
  MODELS_COMPARISON: {
    key: translations.modelsHomeComparison,
    name: ROUTES.MODELS_COMPARISON.HOME,
    prefetch: false,
    type: TYPES.LINK_INTERNAL,
  },
  BUY_BACK: {
    key: translations.buyback,
    name: ROUTES.CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
    type: TYPES.LINK_INTERNAL,
  },
  BUY_BACK_B2B: (url) => ({
    key: translations.buybackB2B,
    url,
    type: TYPES.LINK_EXTERNAL,
  }),
  BUYING_GUIDES: {
    key: translations.buyingGuides,
    name: ROUTES.BUYING_GUIDE.ROOT_HUB,
    type: TYPES.LINK_INTERNAL,
  },
  STUDENTS_GOOD_DEALS: {
    key: translations.studentGoodDeals,
    name: ROUTES.STUDENT_DISCOUNT,
    type: TYPES.LINK_INTERNAL,
  },
  MILITARY_PROGRAM: {
    key: translations.militaryProgram,
    name: ROUTES.MILITARY_DISCOUNT,
    type: TYPES.LINK_INTERNAL,
  },
  HIRE: (url) => ({ key: translations.hire, url, type: TYPES.LINK_EXTERNAL }),
  BLOG: (url) => ({ key: translations.blog, url, type: TYPES.LINK_EXTERNAL }),
  GIFT_FINDER: {
    key: translations.giftFinder,
    name: ROUTES.CMS.GIFT_FINDER_MAIN,
    type: TYPES.LINK_INTERNAL,
    params: {
      pageName: 'finder',
    },
  },
  PRESS: (url) => ({ key: translations.press, url, type: TYPES.LINK }),
  PRO: (url) => ({ key: translations.pro, url, type: TYPES.LINK_EXTERNAL }),
  BACKMARKET_WARRANTY: {
    key: translations.backmarketWarranty,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.WARRANTY,
    },
    type: TYPES.LINK_INTERNAL,
  },
  BECOME_REFURBISHER: (url) => ({
    key: translations.becomeRefurbisher,
    url,
    type: TYPES.LINK_EXTERNAL,
  }),
  BO_MERCHANT: {
    key: translations.boMerchant,
    url: '/bo_merchant',
    type: TYPES.LINK,
  },
  PAYMENT: (url) => ({ key: translations.payment, url, type: TYPES.LINK }),
  RETURN: (url) => ({ key: translations.return, url, type: TYPES.LINK }),
  SHIPPING: (url) => ({ key: translations.shipping, url, type: TYPES.LINK }),
  HELP: {
    key: translations.help,
    name: ROUTES.HELP_CENTER.HOME,
    type: TYPES.LINK_INTERNAL,
  },
  INSURANCES: {
    key: translations.insurances,
    name: ROUTES.CMS.EVENT,
    params: {
      pageName: 'backup',
    },
    type: TYPES.LINK_INTERNAL,
  },
  TEL: (url, title) => ({ name: 'footer_tel', title, url, type: TYPES.LINK }),
  // Category: Law
  TERMS_USE: {
    key: translations.terms,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_USE,
    },
    type: TYPES.LINK_INTERNAL,
  },
  TERMS_SERVICE: {
    key: translations.terms,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SERVICE,
    },
    type: TYPES.LINK_INTERNAL,
  },
  TERMS_SALE: {
    key: translations.termsSale,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SALE,
    },
    type: TYPES.LINK_INTERNAL,
  },
  TERMS_BUYBACK: {
    key: translations.termsBuyback,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.BUYBACK,
    },
    type: TYPES.LINK_INTERNAL,
  },
  DATA_PROTECTION: {
    key: translations.dataProtection,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
    },
    type: TYPES.LINK_INTERNAL,
  },
  COOKIE_POLICY: {
    key: translations.cookies,
    name: ROUTES.LEGAL.COOKIES,
    type: TYPES.LINK_INTERNAL,
  },
  CCPA: {
    key: translations.doNotSell,
    name: ROUTES.LEGAL.COOKIES,
    type: TYPES.LINK_INTERNAL,
  },
  INFOS_CONSUMER: {
    key: translations.infosConsumer,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.OTHER,
    },
    type: TYPES.LINK_INTERNAL,
  },
  LEGAL: {
    key: translations.legal,
    name: ROUTES.CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.LEGAL_MENTIONS,
    },
    type: TYPES.LINK_INTERNAL,
  },
  ILLEGAL_CONTENT: (url) => ({
    url,
    key: translations.illegalContent,
    type: TYPES.LINK,
  }),
  // Category: Hello
  TRUST_PILOT: (url) => ({
    url,
    key: translations.trustpilot,
    type: TYPES.LINK_EXTERNAL,
  }),
  TRUSTED_SHOPS: (url) => ({
    url,
    key: translations.trustedShops,
    type: TYPES.LINK_EXTERNAL,
  }),
  GLASSDOOR: (url) => ({
    url,
    key: translations.glassdoor,
    type: TYPES.LINK_EXTERNAL,
  }),
  WELCOME_TO_THE_JUNGLE: {
    key: translations.welcomeToTheJungle,
    url: 'https://www.welcometothejungle.co/fr/companies/back-market',
    type: TYPES.LINK_EXTERNAL,
  },
  MEDIUM: {
    key: translations.medium,
    url: 'https://medium.com/@BackMarket',
    type: TYPES.LINK_EXTERNAL,
  },
  CELEBRITY: (url) => ({
    key: translations.celebrity,
    url,
    type: TYPES.LINK_EXTERNAL,
  }),
}

const DISPLAY = {
  PAYMENT_NETWORKS: {
    key: translations.paymentMethods,
    type: TYPES.PAYMENT_NETWORKS,
  },
  SOCIAL_NETWORKS: {
    type: TYPES.SOCIAL_NETWORKS,
  },
  ICONS: ({ url, icons, style }) => ({
    type: TYPES.ICONS,
    url,
    icons,
    style,
  }),
  LOGOS: (...keys) => ({
    type: TYPES.LOGOS,
    logos: [...keys],
  }),
}

export const LOGOS = {
  BCORP: 'bcorp',
  BBB: 'bbb',
  CUSTOMER_SERVICE_OF_THE_YEAR: 'customer-service-of-the-year',
  BEST_ECOMMERCE_WEBSITE: 'best-ecommerce-website',
}

const footerRules = {
  [COUNTRIES.BE]: {
    socials: {
      twitter: `${TWITTER_URL}back_market_be`,
      linkedin: 'https://be.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}pages/Back-Market/1497704560478521`,
      instagram: `${INSTAGRAM_URL}backmarket_fr`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRO('https://pro.backmarket.fr'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.be/fr-be/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/fr-be/articles/360026643854-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-sur-Back-Market',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/fr-be/articles/360026646054-Dans-quel-pays-puis-je-me-faire-livrer-et-combien-cela-me-co%C3%BBte',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/fr-be/categories/360002106194-Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.GIFT_FINDER,
          LINKS.BLOG('https://story.backmarket.fr'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/fr-be/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT('https://fr.trustpilot.com/review/backmarket.be'),
          LINKS.GLASSDOOR(
            'https://fr.glassdoor.be/Pr%C3%A9sentation/Travailler-chez-Back-Market-EI_IE1780208.16,27.htm',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://fr.wikipedia.org/wiki/Nana_Mouskouri'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.DE]: {
    socials: {
      twitter: `${TWITTER_URL}back_market_de`,
      linkedin: 'https://de.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}backmarketdeutschland`,
      instagram: `${INSTAGRAM_URL}backmarket_de`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.de/de-de/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/de/articles/360026643854-Welche-Zahlungsarten-gibt-es-auf-Back-Market',
          ),
          LINKS.HELP,
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/de/articles/360026646054-In-welches-Land-kann-ich-liefern-lassen-und-wie-viel-kostet-mich-das',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/de/categories/360002106194-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-refunds',
          ),
          LINKS.INSURANCES,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.GIFT_FINDER,
          LINKS.BLOG('https://story.backmarket.de'),
          DISPLAY.ICONS({
            url: 'https://help.backmarket.com/hc/de/articles/360026646054-In-welches-Land-kann-ich-liefern-lassen-und-wie-viel-kostet-mich-das?_ga=2.153616504.1908722119.1593102993-1001137500.1588685654',
            icons: [
              {
                src: 'delivery_companies/ups.svg',
                width: 52,
                height: 62,
              },
              {
                src: 'delivery_companies/dhl.svg',
                width: 2500,
                height: 1595,
              },
              {
                src: 'delivery_companies/dpd.svg',
                width: 300,
                height: 126,
              },
            ],
            style: 'iconSmall',
          }),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.TERMS_BUYBACK,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/de/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://de.trustpilot.com/review/www.backmarket.de',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.com/Overview/Working-at-Back-Market-EI_IE1780208.11,22.htm',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://de.wikipedia.org/wiki/Nana_Mouskouri'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [
          DISPLAY.LOGOS(LOGOS.BCORP, LOGOS.CUSTOMER_SERVICE_OF_THE_YEAR),
        ],
      },
    ],
  },
  [COUNTRIES.AT]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketat`,
      instagram: `${INSTAGRAM_URL}backmarket_at`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
          LINKS.STUDENTS_GOOD_DEALS,
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.at/de-at/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/de-at/articles/360026643854-Welche-Zahlungsarten-gibt-es-auf-Back-Market-',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/de-at/articles/360026646054-In-welches-Land-kann-ich-liefern-lassen-und-wie-viel-kostet-mich-das-',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/de-at/categories/360002106194-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-Refunds--Devoluciones-y-reembolsos--%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.BLOG('https://story.backmarket.at'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/de-at/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.GLASSDOOR(
            'https://www.glassdoor.at/%C3%9Cberblick/Arbeit-bei-Back-Market-EI_IE1780208.11,22.htm?countryRedirect=true',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://de.wikipedia.org/wiki/DJ_%C3%96tzi'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [
          DISPLAY.LOGOS(LOGOS.BCORP, LOGOS.CUSTOMER_SERVICE_OF_THE_YEAR),
        ],
      },
    ],
  },
  [COUNTRIES.ES]: {
    socials: {
      twitter: `${TWITTER_URL}back_market_es`,
      linkedin: 'https://es.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}Back-Market-España-196051144097157`,
      instagram: `${INSTAGRAM_URL}backmarket_es`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.BUY_BACK,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
          DISPLAY.ICONS({
            url: 'https://www.confianzaonline.es/empresas/backmarket.htm',
            icons: [
              {
                src: 'footer/confianza-online.svg',
                width: 150,
                height: 150,
              },
              {
                src: 'footer/confianza-europe.svg',
                width: 150,
                height: 150,
              },
            ],
            style: 'icon',
          }),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.es/es-es/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/es/articles/360026643854--Qu%C3%A9-m%C3%A9todos-de-pago-se-aceptan-en-Back-Market',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/es/articles/360026646054--En-qu%C3%A9-pa%C3%ADs-me-pueden-entregar-y-cu%C3%A1nto-me-cuesta',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/es/categories/360002106194-Devoluciones-y-reembolsos--%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.GIFT_FINDER,
          LINKS.BLOG('https://story.backmarket.es'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.TERMS_BUYBACK,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/es/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://es.trustpilot.com/review/www.backmarket.es',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.es/Resumen/Trabajar-en-Back-Market-EI_IE1780208.12,23.htm',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://es.wikipedia.org/wiki/Ángela_Ruiz_Robles'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.FR]: {
    socials: {
      twitter: `${TWITTER_URL}back_market`,
      linkedin: 'https://fr.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}pages/Back-Market/1497704560478521`,
      instagram: `${INSTAGRAM_URL}backmarket_fr/`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.BUY_BACK,
          LINKS.PRO('https://pro.backmarket.fr'),
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.fr/fr-fr/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/fr-fr/articles/360026643854-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-sur-Back-Market',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/fr-fr/articles/360026646054-Dans-quel-pays-puis-je-me-faire-livrer-et-combien-cela-me-co%C3%BBte',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/fr-fr/categories/360002106194-Retours-Remboursements',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.GIFT_FINDER,
          LINKS.BLOG('https://story.backmarket.fr'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.TERMS_BUYBACK,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/fr-fr/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://fr.trustpilot.com/review/www.backmarket.fr',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.com/Overview/Working-at-Back-Market-EI_IE1780208.11,22.htm',
          ),
          LINKS.WELCOME_TO_THE_JUNGLE,
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://fr.wikipedia.org/wiki/Nana_Mouskouri'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP, LOGOS.BEST_ECOMMERCE_WEBSITE)],
      },
    ],
  },
  [COUNTRIES.FI]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketfi`,
      instagram: `${INSTAGRAM_URL}backmarket_fi`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.fi/fi-fi/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/fi/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/fi/articles/360026646054',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/fi/categories/360002106194-Palautukset-ja-hyvitykset-Vr%C3%A1tenie-objedn%C3%A1vky-a-pe%C5%88az%C3%AD-%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.BLOG('https://story.backmarket.fi'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/fi/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://fi.trustpilot.com/review/backmarket.fi?languages=all',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.com/Reviews/Back-Market-Reviews-E1780208_P3.html',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://fi.wikipedia.org/wiki/M%C3%A4mmi'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.GR]: {
    socials: {
      twitter: `${TWITTER_URL}back_market`,
      linkedin: 'https://fr.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}backmarketgr`,
      instagram: `${INSTAGRAM_URL}backmarket_gr/`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.gr/el-gr/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/el/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/el/articles/360034275533',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/el/categories/360002106194-%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          // TODO reactivate when blog is ready
          // LINKS.BLOG('https://story.backmarket.gr'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/el-gr/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT('https://gr.trustpilot.com/review/backmarket.gr'),
          LINKS.CELEBRITY(
            'https://el.wikipedia.org/wiki/%CE%9D%CF%84%CE%AD%CE%BC%CE%B7%CF%82_%CE%A1%CE%BF%CF%8D%CF%83%CF%83%CE%BF%CF%82',
          ),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.IT]: {
    socials: {
      twitter: `${TWITTER_URL}back_market_it`,
      linkedin: 'https://it.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}backmarketitalia`,
    },

    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.it/it-it/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/it/articles/360026643854-Quali-sono-i-metodi-di-pagamento-accettati-da-Back-Market',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/it/articles/360026646054-In-quale-paese-posso-essere-consegnato-e-quanto-costa',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/it/categories/360002106194-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.BLOG('https://story.backmarket.it'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/it/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUSTED_SHOPS('https://it.trustpilot.com/review/backmarket.it'),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.it/Panoramica/Lavorando-in-Back-Market-EI_IE1780208.13,24.htm',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://it.wikipedia.org/wiki/Raffaella_Carr%C3%A0'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.US]: {
    socials: {
      twitter: `${TWITTER_URL}backmarket`,
      linkedin: 'https://linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}BackMarketCom`,
      instagram: `${INSTAGRAM_URL}backmarket`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.BUY_BACK,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.MILITARY_PROGRAM,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.com/en-us/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/en-us/articles/360026643854-What-payment-methods-are-accepted',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/en-us/articles/360033250174-When-should-I-expect-my-order-to-arrive',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/en-us/categories/360002106194-Returns-refunds-',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.ACCESSIBILITY,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.GIFT_FINDER,
          LINKS.BLOG('https://story.backmarket.com'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_SERVICE,
          LINKS.TERMS_BUYBACK,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.CCPA,
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://www.trustpilot.com/review/www.backmarket.com',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.com/Overview/Working-at-Back-Market-EI_IE1780208.11,22.htm',
          ),
          LINKS.MEDIUM,
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP, LOGOS.BBB)],
      },
    ],
  },
  [COUNTRIES.GB]: {
    socials: {
      linkedin: 'https://linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}backmarketuk`,
      instagram: `${INSTAGRAM_URL}backmarket`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.BUY_BACK,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER(
            'https://backmarket.co.uk/en-gb/seller/home',
          ),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/en-gb/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/en-gb/articles/360026646054-Where-can-I-get-delivered-and-how-much-does-it-cost-me',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/en-gb/categories/360002106194-Returns-refunds--Vr%C3%A1tenie-objedn%C3%A1vky-a-pe%C5%88az%C3%AD-%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.GIFT_FINDER,
          LINKS.BLOG('https://story.backmarket.co.uk'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.TERMS_BUYBACK,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/en-gb/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://www.trustpilot.com/review/www.backmarket.co.uk',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.co.uk/Overview/Working-at-Back-Market-EI_IE1780208.11,22.htm',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://en.wikipedia.org/wiki/Deep-fried_Mars_bar'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.NL]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketnl`,
      instagram: `${INSTAGRAM_URL}backmarket_nl`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.nl/nl-nl/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/nl/articles/360026643854-Hoe-kan-ik-op-Back-Market-betalen-',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/nl/articles/360033250174-Wanneer-wordt-mijn-bestelling-bezorgd-',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/nl/categories/360002106194-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.INSURANCES,
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.BLOG('https://story.thebackmarket.nl'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/nl/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://www.trustpilot.com/review/www.backmarket.nl',
          ),
          LINKS.GLASSDOOR(
            'https://www.glassdoor.nl/Overzicht/Werken-bij-Back-Market-EI_IE1780208.11,22.htm',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://nl.wikipedia.org/wiki/K3_(muziekgroep)'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.PT]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketpt`,
      instagram: `${INSTAGRAM_URL}backmarket_pt/`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.pt/pt-pt/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/pt/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/pt/articles/360026646054',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/pt/categories/360002106194-Devolu%C3%A7%C3%B5es-Reembolsos-Returns-refunds-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-refunds',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          LINKS.BLOG('https://story.backmarket.pt'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/pt/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT(
            'https://pt.trustpilot.com/review/backmarket.pt?languages=all',
          ),
          LINKS.MEDIUM,
          LINKS.CELEBRITY(
            'https://fr.wikipedia.org/wiki/Am%C3%A1lia_Rodrigues',
          ),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.IE]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketie`,
      instagram: `${INSTAGRAM_URL}backmarket_ie`,
      linkedin: 'https://linkedin.com/company/back-market',
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.ie/en-ie/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/en-ie/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market-',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/en-ie/articles/360026646054-Where-can-I-get-delivered-and-how-much-does-it-cost-me-',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/en-ie/categories/360002106194-Returns-refunds-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-refunds',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          // TODO reactivate when blog is ready
          // LINKS.BLOG('https://story.backmarket.ie'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/en-ie/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          // TODO: Missing link for trust pilot: LINKS.TRUST_PILOT()
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://en.wikipedia.org/wiki/Dustin_the_Turkey'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.SK]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketsk`,
      instagram: `${INSTAGRAM_URL}backmarket_sk`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.sk/sk-sk/seller/home'),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/sk/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/sk/articles/360026646054',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/sk/categories/360002106194-Vr%C3%A1tenie-objedn%C3%A1vky-a-pe%C5%88az%C3%AD-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          // TODO reactivate when blog is ready
          // LINKS.BLOG('https://story.backmarket.sk'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/sk/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.MEDIUM,
          LINKS.CELEBRITY('https://sk.wikipedia.org/wiki/Peter_Sagan'),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.SE]: {
    socials: {
      facebook: `${FACEBOOK_URL}backmarketse`,
      instagram: `${INSTAGRAM_URL}backmarket_se`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.STUDENTS_GOOD_DEALS,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER('https://backmarket.se/sv-se/seller/home '),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/sv/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/sv/articles/360034275533',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/sv/categories/360002106194-Returns-Refunds--Devoluciones-y-reembolsos--%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
          // TODO reactivate when blog is ready
          // LINKS.BLOG('https://story.backmarket.se'),
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.TERMS_SALE,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.INFOS_CONSUMER,
          LINKS.LEGAL,
          LINKS.ILLEGAL_CONTENT(
            'https://help.backmarket.com/hc/sv/requests/new?ticket_form_id=12226993197084',
          ),
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.TRUST_PILOT('https://www.trustpilot.com/review/backmarket.se'),
          LINKS.MEDIUM,
          LINKS.CELEBRITY(
            'https://sv.wikipedia.org/wiki/Zlatan_Ibrahimovi%C4%87',
          ),
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.JP]: {
    socials: {
      twitter: `${TWITTER_URL}backmarket_jp`,
      linkedin: 'https://jp.linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}backmarketjapan`,
      instagram: `${INSTAGRAM_URL}backmarket_jp`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('/ja-jp/e/press'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER(
            'https://backmarket.co.jp/ja-jp/seller/home',
          ),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/ja/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/ja/articles/360033250174',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/ja/categories/360002106194-%E8%BF%94%E5%93%81-%E8%BF%94%E9%87%91%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_USE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          LINKS.LEGAL,
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [LINKS.MEDIUM, DISPLAY.SOCIAL_NETWORKS],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
  [COUNTRIES.AU]: {
    socials: {
      twitter: `${TWITTER_URL}backmarket`,
      linkedin: 'https://linkedin.com/company/back-market',
      facebook: `${FACEBOOK_URL}backmarketaus`,
      instagram: `${INSTAGRAM_URL}backmarket`,
    },
    categories: [
      {
        title: translations.categoryAbout,
        content: [
          LINKS.ABOUT_US,
          LINKS.HIRE('https://jobs.backmarket.com'),
          LINKS.PRESS('mailto:press@backmarket.com'),
        ],
      },
      {
        title: translations.categoryHelp,
        content: [
          LINKS.BECOME_REFURBISHER(
            'https://backmarket.com.au/en-au/seller/home',
          ),
          LINKS.BO_MERCHANT,
          LINKS.PAYMENT(
            'https://help.backmarket.com/hc/en-au/articles/360026643854',
          ),
          LINKS.SHIPPING(
            'https://help.backmarket.com/hc/en-au/articles/360033250174',
          ),
          LINKS.RETURN(
            'https://help.backmarket.com/hc/en-au/categories/360002106194-Returns-refunds',
          ),
          LINKS.HELP,
          LINKS.BUYING_GUIDES,
          LINKS.MODELS_COMPARISON,
        ],
      },
      {
        title: translations.categoryLaw,
        content: [
          LINKS.TERMS_SERVICE,
          LINKS.BACKMARKET_WARRANTY,
          LINKS.DATA_PROTECTION,
          LINKS.COOKIE_POLICY,
          DISPLAY.PAYMENT_NETWORKS,
        ],
      },
      {
        title: translations.categoryHello,
        content: [
          LINKS.GLASSDOOR(
            'https://www.glassdoor.com.au/Overview/Working-at-Back-Market-EI_IE1780208.11,22.htm',
          ),
          LINKS.MEDIUM,
          DISPLAY.SOCIAL_NETWORKS,
        ],
      },
      {
        content: [DISPLAY.LOGOS(LOGOS.BCORP)],
      },
    ],
  },
}

export const getFooterConfigFor = (country) => footerRules[country]
export const getSocialNetWorksFor = (country) =>
  getFooterConfigFor(country).socials || {}
