import { camelizeKeys } from 'humps'
import isEmpty from 'lodash/isEmpty'

import { sortByAlphabetical } from '@core/helpers'
import {
  getCountryCodeFromLocale,
  getCurrencyFromCountryCode,
} from '@core/helpers/countries'

export const mapFromLandingDetails = (payload) => ({
  slug: payload.slug,
  // Product specific
  id: String(payload.id),
  urls: payload.urls,
  links: payload.links,
  link: payload.link,
  tagline: payload.tagline,
  seo: {
    title: payload.title_seo,
    text: payload.text_seo,
  },
  buyingGuide: payload.buying_guide,
  subtitle: payload.displayed_title,
  title: payload.displayed_title_plural,
  marketingContent: payload.marketing_content,
  type: payload.landing_type,
  listView: payload.list_view,
  listViewStructure: payload.list_view_structure,
  // Merchant specific
  address: payload.address,
  company: payload.company,
  deltaAged: payload.delta_aged,
  legalName: payload.merchant_legal_name,
  shopCoverPicture: payload.shop_cover_picture,
  description: payload.description,
  vatNumber: payload.vat_number,
  siretNumber: payload.siret_number,
  isShownMerchantInfo: Boolean(payload.display_merchant_infos),
  country: payload.country,
  isVanishingDeals: payload.isVanishingDeals,
  konbini: payload.custom_data?.japan
    ? {
        storeOperationManager:
          payload.custom_data.japan.store_operation_manager,
        storeSecurityOfficer: payload.custom_data.japan.store_security_officer,
        konbiniPhoneNumber: payload.custom_data.japan.konbini_phone_number,
        konbiniEmail: payload.custom_data.japan.konbini_email,
        secondhandDealerNumber:
          payload.custom_data.japan.secondhand_dealer_number,
      }
    : null,
  australia: payload.custom_data?.australia
    ? {
        secondhandDealerNumber:
          payload.custom_data.australia.secondhand_dealer_number,
      }
    : null,
})

/* Payment */

export const DISCOUNT_TYPES = {
  NOT_VALID: 0,
  WITHOUT_REFERRAL: 1,
  WITH_REFERRAL: 3,
  REFERRAL_BASE: 5,
}

/**
 * TODO: Migrate to Price model
 * @typedef Discount
 * @property {string} id
 * @property {number} deductionMax
 * @property {number} deduction
 * @property {number} deductedPrice
 * @property {number} referralBase
 *
 * @param {Object} payload
 * @returns {Discount}
 */
const mapFromValidDiscount = (payload) => {
  const amount = Number(payload.amount)

  return {
    id: payload.promotion_code_pk,
    deductionMax: amount,
    deduction: amount,
    deductedPrice: Number(payload.new_price),
    referralBase: DISCOUNT_TYPES.REFERRAL_BASE,
  }
}

/**
 * @param {Object} payload
 * @typedef {{ isValid: true } & Discount} ValidDiscount
 * @typedef {{ isValid: false, errorMessage: string }} InvalidDiscount
 * @returns {ValidDiscount|InvalidDiscount}
 * @throws {Error} If discount type returned by API is unknown.
 */
export const mapFromDiscount = (payload) => {
  const { valid: type, ...data } = payload

  switch (type) {
    case DISCOUNT_TYPES.WITH_REFERRAL:
    case DISCOUNT_TYPES.WITHOUT_REFERRAL:
      return {
        isValid: true,
        ...mapFromValidDiscount(data),
      }

    case DISCOUNT_TYPES.NOT_VALID:
      return {
        isValid: false,
        errorMessage: data.message,
      }

    default:
      throw new Error(`Unknown discount type: ${type}`)
  }
}

/* Customer Request (SAV) CMS Templates */

export const mapFromBuyingGuidesHub = (payload) => {
  return {
    ...payload,
    countryCode: getCountryCodeFromLocale(payload.country_code),
  }
}

/* Reviews */
export const mapReviewsBreadcrumbFromApi = (payload) =>
  payload.map(({ title, link }) => (link ? { title, slug: link } : { title }))

export const mapReviewsListFromApi = ({ results = [], ...data }) => ({
  ...data,
  results: results.map((review) => {
    return {
      reviewId: review.reviewOrderLineId,
      reviewInfo: {
        rate: Number(review.rate),
        comment: review.comment,
        date: review.date,
        sourceCountry: review.sourceCountry,
        purchaseDate: review.purchaseDate,
      },
      characteristics: review.reviews || [],
      userInfo: {
        firstName: review.firstName,
        lastName: review.lastName,
        nbClientOrders: review.nbClientOrders,
      },
      productInfo: {
        imageUrl: review.imageUrl,
        productUrl: review.productUrl,
        merchantName: review.merchantName,
        productTitle: review.productTitle,
        modelTitle: review.modelTitle || review.product?.title || '',
        link: review.product?.link || {},
      },
    }
  }),
})

export const mapRateDistributionFromApi = ({ rateDistribution = [] } = {}) => ({
  statsInfo: rateDistribution || [],
})

export const mapFromAllCategories = ({ results: categories }) =>
  camelizeKeys(categories)
    .filter(({ categoryName }) => !isEmpty(categoryName))
    .sort((a, b) => {
      if (a.categoryName < b.categoryName) return -1
      if (a.categoryName > b.categoryName) return 1

      return 0
    })

// TODO remove when CMS API is available
export const mapFromProductBanner = (data) => ({
  currency: data.listing.currency,
  image: data.image,
  originalPrice: data.price_original,
  price: data.listing.price,
  cta: { link: data.link },
  title: data.model_name,
})

export const mapProductReviews = (data) => ({
  ...data,
  rate: Number(data.rate),
})

export const mapFromInternationalOnboarding = (payload) => {
  const finalPayload = { ...payload }

  if (!isEmpty(payload.metaData)) {
    // @TO-DO: should be remove after https://backmarket.atlassian.net/browse/GM-4630
    if (payload.metaData.shippers) {
      finalPayload.metaData.carriers = payload.metaData.shippers.map(
        (shipper) => {
          return {
            id: shipper.id,
            name: shipper.shipperDisplayName,
            backship: shipper.isBackship,
            collectionPoint: shipper.collectionPoint,
            restrictedToMarkets: shipper.restrictedToMarkets,
          }
        },
      )
    }
  }

  return finalPayload
}

// TODO: clean the mapFromGetListings once the API endpoint applies the changes (MCC-2650)
const GRADES = ['shiny', 'gold', 'silver', 'bronze', 'stallone']

export const transformListing = ({ markets, state, quantity, ...listing }) => {
  const marketsWithCountryCodes = Object.entries(markets).map(
    ([countryCode, market]) => {
      return {
        ...market,
        countryCode,
        // FIXME: GM-5112 - Move currency fallack logic into page or store.
        currency: getCurrencyFromCountryCode(countryCode),
      }
    },
  )

  // FIXME: GM-5112 - Move sort logic into page or store.
  const marketsWithCountryCodesOrdered = sortByAlphabetical(
    marketsWithCountryCodes,
    'countryCode',
  )

  return {
    ...listing,
    // FIXME: GM-5112 - Move sort logic into page or store.
    markets: marketsWithCountryCodesOrdered,
    // FIXME: GM-5113 - Legacy "grades" (Int), use aesthericGrade.code instead
    state: GRADES[state],
    // FIXME: GM-5112 - Use "quantity" instead of "stock" top avoid renaming
    stock: quantity,
  }
}

export const mapFromUpdateListing = (payload) => transformListing(payload)

export const mapFromGetListings = (payload) => ({
  ...payload,
  results: payload.results.map(transformListing),
})

export const mapFromBalanceAjustements = (payload) => ({
  ...payload,
  results: payload.results.map((result) => ({
    price: { amount: result.amount, currency: result.currency },
    action: result.action,
    seller: { id: result.merchant_id, company: result.merchant_company },
    mention: result.mention,
    initiatorName: result.initiator_name,
    date: result.date,
    forcePsp: result.force_psp,
  })),
})

export const mapFromPicsouConfig = (payload) => {
  const actionOptions = {}
  payload.options_action.forEach((actionOption) => {
    actionOptions[actionOption.name] = actionOption.value
  })

  const mentionOptions = {}
  payload.options_mention.forEach((mentionOption) => {
    mentionOptions[mentionOption.name] = mentionOption.value
  })

  return {
    actionOptions,
    mentionOptions,
    currencyOptions: payload.options_currencies,
    defaultCurrency: payload.default_currency,
    allowedCurrenciesPerPsp: payload.allowed_currencies_per_psp,
  }
}

export const rewordSellerCompensationsResponse = (payload) => {
  const transformCompensation = (compensation) => {
    // TODO [Finance Tools] : Reword API when old compensations tool will have been killed to avoid applying this method
    const { amountTaxExcl: amountTaxExcluded, ...rest } = compensation

    return { ...rest, amountTaxExcluded }
  }

  if (payload.results !== undefined) {
    return {
      ...payload,
      results: payload.results.map(transformCompensation),
    }
  }

  return transformCompensation(payload)
}

export const transformReasons = (payload) => {
  const transformReason = (reason) => {
    // TODO [Finance Tools] : Temp reword for reasons management until we finish migration. (do this to avoid duplicating lots of code)
    const { publicId: id, ...rest } = reason

    return { ...rest, id }
  }

  if (payload.results !== undefined) {
    return {
      ...payload,
      results: payload.results.map(transformReason),
    }
  }

  return transformReason(payload)
}

export const mapFromVatInformation = (payload) => ({
  expeditionCountry: payload.expeditionCountry ?? '',
  hasCompletedVatInfos: payload.hasCompletedVatInfos ?? null,
  hasEstablishmentInEu: payload.hasEstablishmentInEu ?? null,
  intraCommunityVatNumber: payload.intraCommunityVatNumber ?? '',
  isExemptFromVat: payload.isExemptFromVat ?? null,
  isOssRegistered: payload.isOssRegistered ?? null,
  legalArea: payload.legalArea ?? '',
  taxIdNumber: payload.taxIdNumber ?? '',
  vatMarginScheme:
    payload.vatMarginScheme?.length > 0 ? payload.vatMarginScheme : null,
  vatNumbersByCountry: payload.vatNumbersByCountry ?? null,
  vatNumberCheckStatus: payload.vatNumberCheckStatus ?? null,
})

export const mapFromKonbiniSettings = (payload) => ({
  storeOperationManager: payload.storeOperationManager ?? '',
  storeSecurityOfficer: payload.storeSecurityOfficer ?? '',
  konbiniEmail: payload.konbiniEmail ?? '',
  konbiniPhoneNumber: payload.konbiniPhoneNumber ?? '',
  secondhandDealerNumber: payload.secondhandDealerNumber ?? '',
})
