import {
  PAYMENT_ARCHITECTURES,
  PAYMENT_METHODS,
  isPaymentMethodEnabled,
  paymentMethodName,
} from '../../methods'
import {
  PAYMENT_ADVERTISING_IDS,
  PAYMENT_METHODS_ADVERTISING,
} from '../config/advertising'
import { PAYMENT_ADVERTISING_TYPES } from '../config/constants'

/**
 * @typedef {Object} PaymentAdvertisingOptions
 * @property {Object} rootGetters
 * @property {boolean} includeDisabledMethods
 */

/**
 * TODO [PAYIN-3619] Remove this when the BNPL AB tests are over
 * @param {PaymentMethod[]} paymentMethods
 * @param {PaymentAdvertisingOptions} options
 */
const filterABTestedPaymentAdvertising = (paymentMethods, options) => {
  const experiments = options.rootGetters?.['flags/getExperiments'] ?? {}

  const isPaypalAvailable = !!paymentMethods.find(
    ({ bmCode, architecture, config }) =>
      bmCode === PAYMENT_METHODS.PAYPAL &&
      architecture === PAYMENT_ARCHITECTURES.FINPAY &&
      config?.paypalClientId,
  )
  const paypalIncompatibleFilter = (id) =>
    id !== PAYMENT_ADVERTISING_IDS.PAYPAL || isPaypalAvailable

  function getBnplAdvertisingFilter(variant) {
    switch (variant) {
      case 'klarna':
        return (id) =>
          [
            PAYMENT_ADVERTISING_IDS.KLARNA_ALL,
            PAYMENT_ADVERTISING_IDS.KLARNA_PAY_LATER,
            PAYMENT_ADVERTISING_IDS.KLARNA_SLICE_IT,
          ].includes(id)

      case 'paypal':
        return (id) => id === PAYMENT_ADVERTISING_IDS.PAYPAL

      case 'noVariation':
      default:
        return () => true
    }
  }

  return Object.keys(PAYMENT_METHODS_ADVERTISING)
    .filter(paypalIncompatibleFilter)
    .filter(getBnplAdvertisingFilter(experiments?.esBnplAdvertising))
    .filter(getBnplAdvertisingFilter(experiments?.deBnplAdvertising))
    .filter(getBnplAdvertisingFilter(experiments?.frBnplAdvertising))
    .filter(getBnplAdvertisingFilter(experiments?.ukBnplAdvertising))
    .filter(getBnplAdvertisingFilter(experiments?.usBnplAdvertising))
}

/**
 * Take the vue component instance 'this' and construct and return the advertising options
 * TODO [PAYIN-3619] Remove this when the BNPL AB tests are over
 * @param {import('vue').default} vueComponentInstance
 */
export const getAdvertisingOptionsFromComponent = (vueComponentInstance) => {
  return {
    rootGetters: vueComponentInstance.$store.getters,
  }
}

/**
 * @param {string} type See PAYMENT_ADVERTISING_TYPES
 * @param {PaymentMethod[]} paymentMethods
 * @param {PaymentAdvertisingOptions} options
 * @returns {import('../config/advertising').PaymentAdvertisingConfig | null}
 */
const findAvailablePaymentAdvertising = (type, paymentMethods, options) => {
  const methods = options.includeDisabledMethods
    ? paymentMethods
    : paymentMethods.filter(isPaymentMethodEnabled)

  const advertisingIds = filterABTestedPaymentAdvertising(methods, options)

  // Using for-const-of instead of double `.find`
  // eslint-disable-next-line no-restricted-syntax
  for (const advertisingId of advertisingIds) {
    const advertisingConfig = PAYMENT_METHODS_ADVERTISING[advertisingId]
    if (advertisingConfig[type]) {
      const { isAvailable, forMethods } =
        advertisingConfig.isAvailableFor(methods)
      if (isAvailable) {
        return {
          ...advertisingConfig,
          id: advertisingId,
          methods: forMethods,
        }
      }
    }
  }

  return null
}

/**
 * @param {string} type See PAYMENT_ADVERTISING_TYPES
 * @param {PaymentMethod[]} paymentMethods
 * @param {PaymentAdvertisingOptions} options
 * @returns {null | ({ variant: string, method: PaymentMethod, methodName: string } & Object)}
 */
export function paymentAdvertising(type, paymentMethods, options) {
  if (!Object.values(PAYMENT_ADVERTISING_TYPES).includes(type)) {
    throw new Error(`Invalid payment advertising type: ${type}`)
  }

  const config = findAvailablePaymentAdvertising(type, paymentMethods, options)

  if (!config) {
    return null
  }

  const method = config.methods[0]

  return {
    variant: type,
    method,
    methods: config.methods,
    methodName: paymentMethodName(method.bmCode),
    ...config[type],
  }
}

/**
 * @param {PaymentMethod[]} paymentMethods
 * @param {PaymentAdvertisingOptions} options
 * @returns {boolean}
 */
export const hasPaymentAdvertising = (type, paymentMethods, options) =>
  paymentAdvertising(type, paymentMethods, options) !== null
