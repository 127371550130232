import { APP_COUNTRIES, getCountryConfig } from '@core/helpers/countries'
import { getLink } from '@router/links'

export const generateAlternateLink = async ({ link, baseUrl, country }) => {
  const linkObject = await getLink({ link, baseUrl, country })

  if (linkObject === null) {
    return null
  }

  return {
    rel: 'alternate',
    hreflang: linkObject.locale,
    href: linkObject.href,
  }
}

export const generateAlternateLinks = ({
  link,
  baseUrl,
  countries = APP_COUNTRIES,
}) => {
  return Promise.all(
    countries
      .filter((country) => getCountryConfig(country).showAlternateLinks)
      .map((country) => generateAlternateLink({ link, baseUrl, country })),
  )
}
