export const deepValuesIn = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return []
  }

  const collections = Object.values(obj).reduce((acc, current) => {
    const currentValue =
      typeof current !== 'object' ? current : deepValuesIn(current)

    return [...acc, currentValue]
  }, [])

  return collections.flat()
}
