
import { SCHEMA_SEARCH_NAME } from '@core/helpers/head'

import translations from './Combobox.translations'
import Listbox from './Listbox.vue'
import Textbox from './Textbox.vue'

export default {
  components: {
    Textbox,
    Listbox,
  },
  props: {
    popularSearches: {
      type: Array,
      default: () => [],
    },
    recentSearches: {
      type: Array,
      default: () => [],
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    inputId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      selectedIndex: -1,
      isListboxOpen: false,
      inputValue: '',
    }
  },

  computed: {
    inputName: () => SCHEMA_SEARCH_NAME,

    hasQuery() {
      return this.inputValue !== ''
    },

    popularSearchesTitle() {
      return this.$t(translations.popularSearches)
    },

    recentSearchesTitle() {
      return this.$t(translations.recentSearches)
    },

    items() {
      const { addSource } = this
      const suggestions = addSource(this.suggestions, 'suggestions')
      const recentSearches = addSource(this.recentSearches, 'recentSearches')
      const popularSearches = addSource(this.popularSearches, 'popularSearches')

      return this.inputValue
        ? suggestions
        : recentSearches.concat(popularSearches)
    },

    selectedIndexForPopularSearches() {
      return this.selectedIndex < this.recentSearches.length - 1
        ? -1
        : this.selectedIndex - this.recentSearches.length
    },

    selectedIndexForRecentSearches() {
      return this.selectedIndex >= this.recentSearches.length
        ? -1
        : this.selectedIndex
    },
  },

  beforeMount() {
    const input = document.querySelector(`#${this.inputId}`)
    if (input) {
      this.changeHandler(input.value)
    }
  },

  methods: {
    addSource: (items, source) => items.map((item) => ({ ...item, source })),

    clearHandler(listName) {
      this.$emit('clear', listName)
    },

    changeHandler(value) {
      this.inputValue = value
      this.selectedIndex = -1
      this.$emit('change', this.inputValue)
    },

    selectIndexForPopularSearches(index) {
      this.selectIndex(index + this.recentSearches.length)
    },

    selectIndex(index) {
      this.selectedIndex = index
      this.submit()
    },

    changeSelectedIndex(offset) {
      const { length } = this.items
      if (length !== 0) {
        this.selectedIndex = (length + this.selectedIndex + offset) % length
      }
    },

    closeListbox() {
      this.isListboxOpen = false
    },

    openListbox() {
      this.selectedIndex = -1
      this.isListboxOpen = true
    },

    submit() {
      this.$refs.textbox.blur()

      this.$emit('submit', {
        query: this.inputValue,
        item: this.items?.[this.selectedIndex],
        index: this.selectedIndex,
      })
    },
  },
}
