export const FORM_TYPES = {
  INSURANCE_ONLY: 'INSURANCE_ONLY_FORM',
  NONE: 'NONE',
  SWAP_AND_INSURANCE: 'SWAP_AND_INSURANCE_FORM',
  SWAP_ONLY: 'SWAP_ONLY_FORM',
}

export default {
  namespaced: true,

  state: () => ({
    birthdate: '',
    formType: FORM_TYPES.NONE,
    missingData: false,
    nationality: '',
  }),

  mutations: {
    setUserInformation(
      state,
      { birthdate, formType, missingData, nationality, taxId: nationalId } = {},
    ) {
      state.birthdate = birthdate || ''
      state.formType = formType
      state.missingData = missingData
      state.nationality = nationality || ''

      if (nationalId !== undefined) state.nationalId = nationalId || ''
      else delete state.nationalId
    },
  },

  getters: {
    isNationalityRequired: (state) =>
      state.formType === FORM_TYPES.SWAP_AND_INSURANCE ||
      state.formType === FORM_TYPES.SWAP_ONLY,

    // If the nationalId comes from the cart request as null or with a value it means it's required.
    // If the key does not come at all, it means it's not required
    // It is also returning a function since we don't want to cache the result of it.
    isNationalIdRequired: (state) => () => {
      return 'nationalId' in state
    },

    isFormRequired: (state) => state.formType !== FORM_TYPES.NONE,

    isMissingData: (state) => state.missingData,

    userInformation: (state) => state,
  },

  actions: {
    async set({ commit }, { userInformation }) {
      commit('setUserInformation', userInformation)
    },
  },
}
