import isEmpty from 'lodash/isEmpty'

import { paymentAuthorize } from '@http/endpoints'

import { PAYMENT_SERVICE_PROVIDERS, PaymentRedirection } from '../methods'

import { adyenEncrypt } from './modules/adyen'

/**
 * @typedef {Object} CreatePaymentPayload
 * @property {string} paymentId
 * @property {'GET'|'POST'} redirectMethod
 * @property {string?} redirectUrl
 * @property {Record<string,string>?} redirectData
 * @property {string?} gatewayReference
 */

/**
 * @typedef {Object} MakePaymentPayload
 * @property {() => Promise<CreatePaymentPayload & Object>} create
 * @property {import('../..').PaymentMethod} paymentMethod
 * @property {import('../..').PaymentConfig} paymentConfig
 * @property {boolean} requiresPayment False when no payment is required (ex: 100% discount)
 * @property {import('../..').PaymentData} [paymentData] Un-encrypted payment data
 */

export default {
  namespaced: true,

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<PaymentRedirection>}
     */
    async make({ dispatch }, payload) {
      if (!payload.requiresPayment) {
        return dispatch('create', payload)
      }

      switch (payload.paymentMethod.pspCode) {
        case PAYMENT_SERVICE_PROVIDERS.ADYEN_MARKETPAY:
        case PAYMENT_SERVICE_PROVIDERS.ADYEN_PAYIN_PAYOUT:
          return dispatch('makeAdyenPayment', payload)

        default:
          throw new Error(
            `Unsupported payment service provider: ${payload.paymentMethod.pspCode}`,
          )
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<{
     *  paymentId: string,
     *  gatewayReference?: string,
     *  redirection: PaymentRedirection?
     * }>} Payment id, redirection if available, and ProcessOut invoice id if available
     */
    async create(context, payload) {
      const {
        paymentId,
        gatewayReference,
        redirectData,
        redirectLink,
        redirectMethod,
      } = await payload.create({
        payment_method: { reference: payload.paymentMethod.reference },
        signifyd_fingerprint: payload.paymentConfig.signifydFingerprint,
      })

      return {
        paymentId,
        gatewayReference,
        ...(isEmpty(redirectLink)
          ? {}
          : {
              redirection: new PaymentRedirection(
                redirectMethod,
                redirectLink,
                redirectData,
              ),
            }),
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<{ paymentId: string, redirection: PaymentRedirection }>}
     */
    async makeAdyenPayment({ dispatch }, payload) {
      const { paymentId, redirection } = await dispatch('create', payload)

      // If we already have a redirection, proceed (ex: PayPal, Adyen + HPP)
      if (redirection instanceof PaymentRedirection) {
        return { paymentId, redirection }
      }

      return {
        paymentId,
        redirection: await dispatch('authorizeAdyen', {
          paymentId,
          paymentData: payload.paymentData,
          paymentConfig: payload.paymentConfig,
        }),
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {string} payload.paymentId
     * @param {import('@payment').PaymentConfig} payload.paymentConfig
     * @param {import('@payment').AdyenCardData} payload.paymentData
     * @return {Promise<PaymentRedirection>}
     */
    async authorizeAdyen(
      { dispatch },
      { paymentId, paymentConfig, paymentData },
    ) {
      const encryptedData = await adyenEncrypt(
        paymentData,
        paymentConfig.adyenEncryption.key,
        paymentConfig.adyenEncryption.time,
      )

      const { payload } = await dispatch(
        'http/request',
        {
          request: paymentAuthorize,
          pathParams: { paymentId },
          body: {
            encryptedData,
          },
        },
        { root: true },
      )

      // Internal link can be either payment KO, or payment OK without 3D Secure
      const {
        issuer_link: externalLink,
        link: internalLink,
        term_url: TermUrl,
        pa_req: PaReq,
        md: MD,
      } = payload

      if (isEmpty(internalLink) && isEmpty(externalLink)) {
        throw new Error(
          'Could not determine redirection after payment authorization',
        )
      }

      return isEmpty(internalLink)
        ? new PaymentRedirection('POST', externalLink, { TermUrl, PaReq, MD })
        : new PaymentRedirection('GET', internalLink)
    },
  },
}
