import { isArray, isUndefined } from '@core/helpers'
import { ROUTES } from '@router'

/**
 * Redirect the user if he's not seller.
 *
 * @param {Object} context
 * @param {Function} context.redirect
 * @param {Object} context.store
 * @return {Promise}
 */
export default async ({ redirect, route, store }) => {
  // Matches are listed starting from the first parent, and going through every route
  // until we hit the current route. For example, having three routes (A > B > C)
  // would generate a `matched` array [A, B, C]. Since the route defined latter
  // should take precedence over its parents, we reverse the whole array.
  const reversed = [...route.matched].reverse()

  // Since we reversed the array beforehand, we can now use `Array#find` to traverse
  // the whole array and return the _first_ route matching the given predicate.
  const match = reversed.find(
    (routeDefinition) => routeDefinition.meta && routeDefinition.meta.seller,
  )

  // We may request a routes tree not protected at all.
  if (isUndefined(match)) {
    return null
  }

  const {
    required = false,
    redirection = { name: ROUTES.BO_MERCHANT.ROOT },
    isSellerOnboarding,
  } = match.meta.seller

  if (required === false) {
    return null
  }

  // We don't want to fire the request each time this middleware is instanciated.
  // If the authentication status was previously fetched already, there is no
  // need to fetch it again, and we should use the data saved in our store.
  if (store.getters['seller/fetched'] === false) {
    await store.dispatch('seller/fetchInfo')
  }

  if (!store.getters['user/isStaff'] && store.getters['user/merchantId']) {
    // The isSellerOnboarding checks handles redirection from / to onboarding or onboarded sub-modules
    // depending on the onboarding status of the seller
    if (typeof isSellerOnboarding === 'boolean') {
      if (isSellerOnboarding) {
        if (store.getters['seller/onboardingCompleted']) {
          return redirect(redirection)
        }
      } else if (!store.getters['seller/onboardingCompleted']) {
        return redirect(redirection)
      }
    }

    return null
  }

  // The `redirect` function from Nuxt accepts either a path (as string), or a more
  // complexe configuration using multiple arguments. Since we want to allow the
  // developers to have full power over this, we also accept an array (tupple).
  if (isArray(redirection)) {
    return redirect(...redirection)
  }

  return redirect(redirection)
}
