import isEmpty from 'lodash/isEmpty'

import { insertIf } from '@core/helpers'

import { createAdyenEncryption } from './sdk'

/**
 * @param {import('@payment').AdyenCardData} paymentData
 * @param {string} key Adyen CSE key (provided server-side)
 * @param {string} time Adyen CSE key generation time
 * @throws {Error} If validation failed
 */
export async function adyenEncrypt(paymentData, key, time) {
  const encryption = await createAdyenEncryption(key)
  if (!encryption) {
    throw new Error(`createAdyenEncryption returned ${encryption}`)
  }

  const data = {
    number: paymentData.number,
    // Do not provide securityCode when empty.
    ...insertIf(!isEmpty(paymentData.securityCode), {
      cvc: paymentData.securityCode,
    }),
    holderName: paymentData.name,
    expiryMonth: paymentData.month,
    expiryYear: paymentData.year,
    generationtime: time,
  }

  const encrypted = encryption.encrypt(data)
  if (!encrypted) {
    const validation = encryption.validate(data)
    const invalidFields = Object.keys(data).reduce(
      (fields, field) => (validation[field] ? fields : [...fields, field]),
      [],
    )
    throw new Error(
      `Credit card encryption failed (${
        invalidFields.join(', ') || 'unknown reason'
      })`,
    )
  }

  return encrypted
}
