import { emitter } from '@ds/utils/tracking'
import isEmpty from 'lodash/isEmpty'

import { dangerouslyGetRuntimeConfig } from '@config/variables'
import {
  trackCarouselImpression,
  trackClick,
  trackComponentDeprecation,
  trackModal,
  trackToast,
} from '@tracking/events'

const clickListeners = ({ context }) => {
  if (!isEmpty(context.tracking) || !isEmpty(context.name)) {
    const tracker = {
      // If a name is set (for a button for example) we can automate the tracking using it
      name: context.name,
      // If some tracking properties has been set, let's forward it to the trackClick event
      ...context.tracking,
    }

    trackClick(tracker)
  }
}

const eventHandlers = {
  click: ({ context }) => clickListeners({ context }),
  modal: ({ context }) => trackModal(context),
  toast: ({ context }) => trackToast(context),
  'carousel-impression': ({ context }) => {
    const { currentSlide, tracking } = context

    if (!isEmpty(tracking)) {
      const { name, creatives } = tracking

      const creative = !isEmpty(creatives)
        ? creatives[currentSlide]?.creative
        : ''

      trackCarouselImpression({
        name,
        creative,
        position: `${name}_${currentSlide}`,
      })
    }
  },
}

eventHandlers['deprecation-warning'] = ({ context }) => {
  if (dangerouslyGetRuntimeConfig().DEV_REVOLVE_DEPRECATIONS) {
    trackComponentDeprecation(context)
  }
}

const listeners = (action, opts) => {
  eventHandlers[action]?.(opts)
}

// The design system exports a pub/sub (emitter) where we can use as hooks.
// These hooks allow us to define the tracking strategy once for all of the DS components.
export const trackDesignSystem = () => {
  emitter.on('*', listeners)
}

export const untrackDesignSystem = () => {
  emitter.off('*', listeners)
}
