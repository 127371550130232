import { CHECK_AUTH_REDIRECTION } from '@auth-oauth2/constants'
import { isArray, isUndefined } from '@core/helpers'
import { ROUTES } from '@router'

/**
 * Redirect the user if they're not authenticated.
 *
 * @param {Object} context
 * @param {Function} context.redirect
 * @param {Object} context.store
 * @return {Promise}
 */
export default async ({ redirect, route, store }) => {
  // Matches are listed starting from the first parent, and going through every route
  // until we hit the current route. For example, having three routes (A > B > C)
  // would generate a `matched` array [A, B, C]. Since the route defined latter
  // should take precedence over its parents, we reverse the whole array.
  const reversed = [...route.matched].reverse()

  // Since we reversed the array beforehand, we can now use `Array#find` to traverse
  // the whole array and return the _first_ route matching the given predicate.
  const match = reversed.find(
    (routeDefinition) => routeDefinition.meta && routeDefinition.meta.auth,
  )

  // We may request a routes tree not protected at all.
  if (isUndefined(match)) return null

  // We don't want to fire the request each time this middleware is instanciated.
  // If the authentication status was previously fetched already, there is no
  // need to fetch it again, and we should use the data saved in our store.
  if (store.getters['auth/fetched'] === false) {
    await store.dispatch('user/fetch')
  }

  // Default auth route
  const authRedirectionRoute = store.getters['auth/isNewAuthEnabled']
    ? { name: ROUTES.AUTH_OAUTH2.LOGIN }
    : { name: ROUTES.AUTH.REGISTER }

  const { required = false, redirection = authRedirectionRoute } =
    match.meta.auth

  if (required === false) return null
  if (store.getters['auth/status']) return null

  // The `redirect` function from Nuxt accepts either a path (as string), or a more
  // complexe configuration using multiple arguments. Since we want to allow the
  // developers to have full power over this, we also accept an array (tuple).
  if (isArray(redirection)) {
    const [redirectionPath, redirectionOptionsObject] = redirection

    /**
     * Special case for the auth redirection, due to a
     * Nuxt limitation, we cannot check in the routes config the `store`.
     * @see `buybackCustomerMinimalLayoutRoutes`
     * @link modules/buyback/routes/index.js
     *
     * The issue has been fixed in Nuxt, by no other
     * than Loick Le Digabel, former Back Maker.
     * - https://github.com/nuxt/nuxt/pull/9629
     * - https://github.com/nuxt-community/router-module/pull/105
     *
     * But unfortunately it's not yet available,
     * since the release of 2.16 hasn't been shipped yet:
     * - https://github.com/nuxt/nuxt/pull/10907
     *
     * @todo Remove this check after the new auth stack is
     * 100% released without the CloudBees flags check
     * @see `isNewAuthEnabled`
     */
    if (redirectionPath === CHECK_AUTH_REDIRECTION) {
      return redirect(authRedirectionRoute, {
        next: route.fullPath,
        ...redirectionOptionsObject,
      })
    }

    // There is the possibility to pass redirection = ['/my-path', { query: "myqueryParam" }]
    return redirect(redirectionPath, {
      next: route.fullPath,
      ...redirectionOptionsObject,
    })
  }

  return redirect(redirection, { next: route.fullPath })
}
