import isEmpty from 'lodash/isEmpty'

import { mapValues } from '@core/helpers'
import { fetchCartShippings } from '@http/endpoints'

export default {
  namespaced: true,

  /**
   * @typedef {{ shippingId: string }} Shipping
   * @typedef {{ country: string, shippings: Shipping[] }} CountryShippings
   * @typedef {{ listingId: string, countries: CountryShippings[] }} ListingShippings
   *
   * @typedef {Object} State
   * @property {Price} price
   * @property {Price} tax
   * @property {boolean} totalPriceIncludesTax
   * @property {Price | null} serviceFee
   * @property {Price} totalShippingPrice
   * @property {Price} totalPriceWithoutShipping
   * @property {Price} totalListingsPrice
   * @property {Price} totalGrossPriceWithoutAddonService
   * @property {Price} totalGrossPriceWithoutBmFee
   * @property {import('../modules/common').CartItem[]} items
   * @property {ListingShippings[]} shippings
   * @property {import('../modules/common').LastCollectionPoint} lastCollectionPoint
   * @returns {State}
   */
  state: () => ({
    price: {
      currency: '',
      amount: '0.00',
    },
    tax: {
      currency: '',
      amount: '0.00',
    },
    totalPriceIncludesTax: false,
    serviceFee: null,
    totalShippingPrice: {
      currency: '',
      amount: '0.00',
    },
    totalPriceWithoutShipping: {
      currency: '',
      amount: '0.00',
    },
    totalListingsPrice: {
      currency: '',
      amount: '0.00',
    },
    totalGrossPriceWithoutAddonService: {
      currency: '',
      amount: '0.00',
    },
    totalGrossPriceWithoutBmFee: {
      currency: '',
      amount: '0.00',
    },
    items: [],
    shippings: [],
    lastCollectionPoint: {},
    showCatchupModal: true,
    isInsuranceARBoostAvailable: false,
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {Object} payload
     * @param {Price} payload.price
     * @param {Price | null} payload.serviceFee
     * @param {Price} payload.tax
     * @param {Price} payload.totalShippingPrice
     * @param {Price} payload.totalPriceWithoutShipping
     * @param {Price} payload.totalListingsPrice
     * @param {Price} payload.totalGrossPriceWithoutAddonService
     * @param {Price} payload.totalGrossPriceWithoutBmFee
     * @param {boolean} payload.totalPriceIncludesTax
     */
    setPrice(
      state,
      {
        price,
        serviceFee,
        tax,
        totalShippingPrice,
        totalPriceWithoutShipping,
        totalGrossPriceWithoutAddonService,
        totalGrossPriceWithoutBmFee,
        totalListingsPrice,
        totalPriceIncludesTax,
      },
    ) {
      state.price = price
      state.serviceFee = serviceFee
      state.tax = tax
      state.totalShippingPrice = totalShippingPrice
      state.totalPriceWithoutShipping = totalPriceWithoutShipping
      state.totalGrossPriceWithoutAddonService =
        totalGrossPriceWithoutAddonService
      state.totalGrossPriceWithoutBmFee = totalGrossPriceWithoutBmFee
      state.totalListingsPrice = totalListingsPrice
      state.totalPriceIncludesTax = totalPriceIncludesTax
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').CartItem[]} items
     */
    setItems(state, items) {
      state.items = items
      state.shippings = []
    },

    /**
     * @param {State} state
     * @param {ListingShippings[]} shippings
     */
    setShippings(state, shippings) {
      state.shippings = shippings
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').LastCollectionPoint} lastCollectionPoint
     */
    setLastCollectionPoint(state, lastCollectionPoint) {
      state.lastCollectionPoint = isEmpty(lastCollectionPoint)
        ? {}
        : lastCollectionPoint
    },

    /**
     * @param {State} state
     * @param {Boolean} showCatchupModal
     */
    setShowCatchupModal(state, showCatchupModal) {
      state.showCatchupModal = showCatchupModal
    },

    setInsuranceARBoostAvailable(state, isInsuranceARBoostAvailable) {
      state.isInsuranceARBoostAvailable = isInsuranceARBoostAvailable
    },
  },

  getters: {
    /**
     * @param {State} state
     */
    price: (state) => state.price,

    /**
     * @param {State} state
     */
    serviceFee: (state) => state.serviceFee,

    /**
     * @param {State} state
     */
    tax: (state) => state.tax,

    /**
     * @param {State} state
     */
    totalPriceIncludesTax: (state) => state.totalPriceIncludesTax,

    /**
     * @param {State} state
     */
    hasServiceFee: (state) => !isEmpty(state.serviceFee),

    /**
     * @param {State} state
     */
    totalShippingPrice: (state) => state.totalShippingPrice,

    /**
     * @param {State} state
     */
    totalPriceWithoutShipping: (state) => state.totalPriceWithoutShipping,

    /**
     * @param {State} state
     */
    totalListingsPrice: (state) => state.totalListingsPrice,

    /**
     * @param {State} state
     */
    totalGrossPriceWithoutAddonService: (state) =>
      state.totalGrossPriceWithoutAddonService,

    /**
     * @param {State} state
     */
    totalGrossPriceWithoutBmFee: (state) => state.totalGrossPriceWithoutBmFee,

    /**
     * @param {State} state
     */
    items: (state) => state.items,

    /**
     * @param {State} state
     */
    itemsIds: (state) => state.items.map((item) => item.id),

    /**
     * @param {State} state
     */
    availableItems: (state) => state.items.filter((item) => item.isAvailable),

    /**
     * @param {State} state
     * @param {Object} getters
     */
    availableItemsById: (state, getters) =>
      getters.availableItems.reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {},
      ),

    /**
     * @param {State} state
     * @param {Object} getters
     */
    hasAvailableItems: (state, getters) => !isEmpty(getters.availableItems),

    /**
     * @param {State} state
     * @param {Object} getters
     */
    availableItemsCount: (state, getters) =>
      getters.availableItems.reduce((sum, item) => sum + item.quantity, 0),

    /**
     * @param {State} state
     */
    areShippingsLoaded: (state) => !isEmpty(state.shippings),

    /**
     * @param {State} state
     */
    shippingsByCountry: (state) => {
      const shippingsByCountry = {}
      state.shippings.forEach(({ listingId, countries }) => {
        countries.forEach(({ country, shippings }) => {
          if (!shippingsByCountry[country]) {
            shippingsByCountry[country] = {}
          }
          shippingsByCountry[country][listingId] = [...shippings]
          shippingsByCountry[country][listingId].sort((shippingA, shippingB) =>
            shippingA.shippingPrice > shippingB.shippingPrice ? 1 : -1,
          )
        })
      })

      return shippingsByCountry
    },

    /**
     * @typedef {Shipping & { listingIds: string[], country: string }} ExtendedShipping
     *
     * @param {State} state
     * @returns {Object<string, ExtendedShipping>}
     */
    shippingsById: (state) => {
      const shippingsById = {}
      state.shippings.forEach(({ listingId, countries }) => {
        countries.forEach(({ country, shippings }) => {
          shippings.forEach((shipping) => {
            if (shippingsById[shipping.shippingId]) {
              shippingsById[shipping.shippingId].listingIds.push(listingId)
            } else {
              shippingsById[shipping.shippingId] = {
                ...shipping,
                listingIds: [listingId],
                country,
              }
            }
          })
        })
      })

      return shippingsById
    },

    /**
     * @typedef {{
     *   option: import('../modules/common').CartItemOption,
     *   choice: import('../modules/common').CartItemOptionChoice,
     * }} CartSelectedOption
     */

    /**
     * @param {State} state
     * @returns {Object<string, CartSelectedOption[]>} A map containing all selected options,
     * indexed by item id.
     */
    selectedOptionsByItemId: (state) =>
      state.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.options
            .map((option) => ({
              option,
              choice: option.choices.find(({ isSelected }) => isSelected),
            }))
            .filter(
              // Ensure choice was found
              ({ choice }) => !isEmpty(choice),
            ),
        }),
        {},
      ),

    /**
     * @returns {Object<string, ExtendedShipping>} A map containing all selected
     * @param {Object} getters
     * shippings, indexed by item id.
     */
    selectedShippingsByAvailableItemId: (state, getters) =>
      mapValues(
        getters.availableItemsById,
        (item) => getters.shippingsById[item.shipping.id],
      ),

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {(country: string) => boolean} A function that returns `true` if
     * all items are shippable for a given country (ex: 'FR'). This means that
     * each selected shipping must match that country.
     */
    isShippableForCountry: (state, getters) => (country) => {
      return Object.values(getters.selectedShippingsByAvailableItemId).every(
        (shipping) => shipping && shipping.country === country,
      )
    },

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {Array} insurance offers that have been selected
     */
    selectedInsuranceOffers: (state, getters) => {
      return getters.availableItems
        .map((item) => item.insuranceOffers.find((offer) => offer.selected))
        .filter((offer) => offer && !offer.defaultOffer)
    },

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {Object<string, ExtendedShipping>}
     */
    shippingsByItemId: (state, getters) => {
      if (!getters.areShippingsLoaded) {
        return {}
      }

      return mapValues(
        getters.availableItemsById,
        (item) => getters.shippingsById[item.shipping.id],
      )
    },

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {(addressCountry: string) => Object<string, import('../modules/common').CartItem>}
     * A function that returns an object containing all items that are not
     * shippable, indexed by item id.
     */
    getUnshippableItems:
      (state, getters, rootState, rootGetters) => (addressCountry) => {
        if (addressCountry === '') {
          return {}
        }

        return Object.entries(getters.shippingsByItemId).reduce(
          (acc, [itemId, shipping]) =>
            shipping?.country === rootGetters['config/country'] &&
            addressCountry === rootGetters['config/country']
              ? acc
              : { ...acc, [itemId]: getters.availableItemsById[itemId] },
          {},
        )
      },

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {(country: string) => boolean}
     */
    hasUnshippableItemsForCountry: (state, getters) => (country) =>
      !isEmpty(getters.getUnshippableItems(country)),

    /**
     * @param {State} state
     * @returns {boolean}
     */
    hasCollectionPointShipping: (state, getters) =>
      Object.values(getters.selectedOptionsByItemId).some((options) =>
        options.some(({ choice }) => choice.isCollectionPoint),
      ),

    /**
     * @param {State} state
     * @returns {import('../modules/common').LastCollectionPoint}
     */
    lastCollectionPoint: (state) => state.lastCollectionPoint,

    /**
     * @param {State} state
     * @returns {import('../modules/common').CollectionPoint}
     */
    selectedCollectionPoint(state, getters) {
      return getters.lastCollectionPoint?.selectedPoint || {}
    },

    /**
     * @param {State} state
     * @returns {boolean}
     */
    hasSelectedCollectionPoint(state, getters) {
      return !isEmpty(getters.selectedCollectionPoint)
    },

    /**
     * @param {State} state
     * @returns {boolean}
     */
    isSelectedCollectionPointMissing: (state, getters) => {
      return (
        getters.hasCollectionPointShipping &&
        !getters.hasSelectedCollectionPoint
      )
    },

    /**
     * @param {State} state
     * @returns {boolean}
     */
    showCatchupModal: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['config/showInsuranceCatchupModal']) {
        return false
      }

      const hasAtLeastSomeInsurances = getters.availableItems.some((item) => {
        if (item.insuranceOffers.length > 1 && item.quantity === 1) {
          return true
        }

        return false
      })

      const hasAtLeastOneInsuranceSelected = getters.availableItems.some(
        (item) => {
          if (
            item.insuranceOffers.length > 1 &&
            item.quantity === 1 &&
            item.insuranceOffers.some(
              (offer) => offer.selected && !offer.defaultOffer,
            )
          ) {
            return true
          }

          return false
        },
      )

      return (
        state.showCatchupModal &&
        hasAtLeastSomeInsurances &&
        !hasAtLeastOneInsuranceSelected
      )
    },

    isInsuranceARBoostAvailable: (state) => state.isInsuranceARBoostAvailable,
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('../modules/common').Cart} payload
     * @return {Promise<void>}
     */
    async set({ commit }, payload) {
      commit('setPrice', payload)
      commit('setItems', payload.items)
      commit('setShippings', [])
      commit('setLastCollectionPoint', payload.lastCollectionPoint)

      commit(
        'setInsuranceARBoostAvailable',
        payload.isInsuranceARBoostAvailable,
      )
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     */
    async fetchShippings({ getters, dispatch, commit }) {
      if (!getters.areShippingsLoaded) {
        const { payload } = await dispatch(
          'http/request',
          { request: fetchCartShippings },
          { root: true },
        )
        commit('setShippings', payload)
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     */
    async skipCatchupModal({ commit }, skip = true) {
      commit('setShowCatchupModal', !skip)
    },
  },
}
