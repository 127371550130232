import { getEstimationSwapPrice } from '@http/endpoints'

const getDefaultState = () => ({
  estimations: [],
  enabled: true,
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    estimations(state) {
      return state.estimations.map((estimation) => ({
        price: estimation.price_with_swap_discount_with_currency,
        id: estimation.listing_id,
        model: estimation.model,
        isOffer: estimation.cart_has_swap_offer,
        label: estimation.cart_has_swap_offer
          ? 'swap_product_page_banner_offer_in_cart'
          : 'swap_product_page_banner_offer',
        offerPrice: estimation.swap_discount_with_currency,
      }))
    },
    isSwapEnabled: ({ enabled }) => enabled,
  },
  mutations: {
    resetEstimations(state) {
      state.estimations = []
    },
    saveEstimations(state, estimations) {
      state.estimations = estimations
    },
    setSwapEnabled(state, isSwapEnabled) {
      state.enabled = isSwapEnabled
    },
  },
  actions: {
    resetEstimations({ commit }) {
      commit('resetEstimations')
    },
    async getSwapEstimation({ commit, dispatch }, listings) {
      const { status_code: status, payload } = await dispatch(
        'http/request',
        {
          request: getEstimationSwapPrice,
          queryParams: {
            // eslint-disable-next-line camelcase
            listing_id: listings,
          },
        },
        { root: true },
      )

      // in case of a 204 response (no content)
      if (status === 204) {
        return
      }

      commit('saveEstimations', payload)
    },
    disableSwap: ({ commit }) => commit('setSwapEnabled', false),
  },
}
