/* eslint-disable camelcase */
import { removeEmptyValuesInObject } from '@core/helpers'

import { formatPrice } from '../../helpers'

export const productModel = ({
  brand,
  category,
  mainListing,
  model,
  name,
  price,
  currency,
  image,
  webUrl,
  mobileDeeplink,
  color,
  hasNewBattery,
} = {}) => {
  return removeEmptyValuesInObject({
    product_brand: brand,
    product_category: category,
    product_main_listing: mainListing,
    product_model: model,
    product_name: name,
    product_price: formatPrice({ amount: price, currency }),
    product_image: image,
    product_web_url: webUrl,
    product_mobile_deeplink: mobileDeeplink,
    product_color: color,
    product_hasNewBattery: hasNewBattery,
  })
}

export const productModelCollection = (products = []) => {
  return products.map((product) => ({
    ...productModel(product),
  }))
}
