
import { RevList } from '@ds/components/List'
import { RevListItem } from '@ds/components/ListItem'
import { IconClock } from '@ds/icons/IconClock'

import translations from './Listbox.translations'

export default {
  components: {
    IconClock,
    RevList,
    RevListItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    translations: () => translations,
  },
  watch: {
    selectedIndex(newIndex) {
      const item =
        newIndex === -1
          ? this.$refs.listbox?.querySelector('[role = "option"]')
          : this.$refs.listbox?.querySelector('[aria-selected = "true"]')
      if (item) {
        item.scrollIntoView({ block: 'center' })
      }
    },
  },
  methods: {
    clickHandler(index) {
      this.$emit('select', index, this.name)
    },
    cleanHandler(name) {
      this.$emit('clear', name)
    },
  },
}
