
import { mapGetters } from 'vuex'

import logger from '@logger'

import { PAYMENT_ADVERTISING_LOG_TYPES } from '../config/constants'
import { getPaypalLibrary, getPaypalLibraryConfig } from '../helpers/paypal'

export default {
  props: {
    name: {
      type: String,
      default: 'PaypalModal',
    },

    /** @type {import('vue').PropOptions<import('@config/constants/types').Price>} */
    basePrice: {
      type: Object,
      default: null,
    },

    /** @type {import('vue').PropOptions<import('@payment').PaymentMethod[]>} */
    paymentMethods: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      paypal: null,
      modal: null,
    }
  },

  computed: {
    ...mapGetters({
      countryPaymentMethods: 'countryConfig/paymentMethods',
      currency: 'config/currencyCode',
      country: 'config/country',
    }),

    paypalOptions() {
      // https://developer.paypal.com/limited-release/sdk-pay-later-messaging-cross-border/
      const buyerCountry = ['AU', 'DE', 'ES', 'FR', 'GB', 'IT', 'US'].find(
        (country) => country === this.country,
      )

      return this.basePrice
        ? {
            amount: parseFloat(this.basePrice.amount),
            currency: this.currency,
            buyerCountry,
          }
        : {
            currency: this.currency,
            buyerCountry,
          }
    },

    paypalLibraryConfig() {
      const allMethods = this.paymentMethods || this.countryPaymentMethods

      return getPaypalLibraryConfig(allMethods)
    },
  },

  watch: {
    basePrice() {
      this.modal?.updateProps(this.paypalOptions)
    },
  },

  methods: {
    async tryInitializePaypalSdk() {
      if (this.paypal) {
        return true
      }

      try {
        logger.info('[PAYMENT-ADVERTISING] Try initialize Paypal library', {
          type: PAYMENT_ADVERTISING_LOG_TYPES.LIBRARY_INITIALIZATION_START,
          modal: this.name,
        })

        if (!this.paypalLibraryConfig) {
          throw new Error('The paypal configuration is missing')
        }

        this.paypal = await getPaypalLibrary(this.paypalLibraryConfig)

        return true
      } catch (error) {
        logger.error(
          '[PAYMENT-ADVERTISING] Unable to initialize Paypal library',
          {
            error,
            type: PAYMENT_ADVERTISING_LOG_TYPES.LIBRARY_INITIALIZATION_ERROR,
            modal: this.name,
          },
        )

        return false
      }
    },

    async open() {
      if (await this.tryInitializePaypalSdk()) {
        if (!this.modal) {
          this.modal = this.paypal.MessagesModal(this.paypalOptions)
          // Update props systematically because MessagesModal args only work the first time the library is loaded
          this.modal.updateProps(this.paypalOptions)
        }
        this.modal.show()
      }
    },
  },
}
