import * as ROUTES from './names'

export { ROUTES }

/**
 * @typedef {Object} ExternalLink
 * @property {'external'} type
 * @property {string} href
 */

/**
 * @typedef {Object} InternalLink
 * @property {'internal'} type
 * @property {string} name
 * @property {string} [pattern]
 * @property {Record<string,string>} [params]
 * @property {Record<string,string>} [query]
 * @property {Record<string,string>} [hash]
 * @property {string} [href]
 */

/**
 * @typedef {InternalLink | ExternalLink} Link
 * @see https://github.com/BackMarket/api-models/blob/main/models/Link.yaml
 */
