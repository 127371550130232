import isEmpty from 'lodash/isEmpty'

import { removeEmptyValuesInObject } from '@core/helpers'
import sessionStorage from '@core/helpers/sessionStorage'

import { formatPrice } from '../../helpers'

// GNL-2832: replace EARLYBIRD by EARLYBIRDS and update all instances
export const PRODUCT_PROVIDERS = {
  ALGOLIA: 'algolia',
  EARLYBIRD: 'earlybirds',
}

export const PRODUCT_SOURCE = {
  RECOMMENDATIONS: 'recommendations',
  SEARCH: 'search',
}

export const PRODUCT_DEFAULT_SHIPPING_COST = '0.00'

export const PRODUCT_DEAL_TYPE = {
  NORMAL: 'normal',
}

export const saveProductInfos = ({ id, price, source, reviewRating }) => {
  sessionStorage.setItem(id, JSON.stringify({ price, source, reviewRating }))
}

export const getProductInfos = (id) => {
  const item = sessionStorage.getItem(id)
  if (!isEmpty(item)) {
    return JSON.parse(item)
  }

  return {}
}

export const productModel = ({
  brand,
  category,
  chillpackDuration,
  color = '',
  currency,
  dealType,
  id,
  insurancePrice,
  insuranceTitle,
  isSwapEnabled,
  list,
  listingId,
  merchantId,
  model,
  name,
  position = 1,
  price,
  provider,
  // number of products selected by the user (funnel)
  quantity,
  // available stock of products
  stock,
  shipper,
  shippingDelay,
  shippingOptions = [],
  shippingPrice,
  source,
  splitPayment,
  trustpackDuration,
  uuid,
  variant,
  warrantyDuration,
}) => {
  return removeEmptyValuesInObject({
    list,
    position,
    brand,
    category,
    name,
    price: formatPrice({ amount: price, currency }),
    id: id && String(id),
    quantity,
    // The next keys names are defined by Google Analytics
    variant,
    dimension14: color,
    dimension16: trustpackDuration,
    dimension17: shippingDelay,
    dimension20: merchantId && String(merchantId),
    dimension24: model,
    dimension31: isSwapEnabled,
    dimension39: dealType,
    dimension46: shipper,
    dimension47: source,
    dimension48: listingId && String(listingId),
    dimension49: chillpackDuration,
    dimension50: warrantyDuration,
    dimension60: formatPrice({ amount: shippingPrice, currency }),
    dimension63: splitPayment,
    dimension68: insuranceTitle,
    dimension69: insurancePrice,
    dimension81: provider,
    dimension82: shippingOptions.join('|'),
    dimension83: stock,
    dimension89: uuid,
  })
}

export const productModelCollection = (products = []) => {
  const productsList = Array.isArray(products) ? products : [products]

  return productsList.map((product, index) => ({
    ...productModel(product),
    position: product.position || index + 1,
  }))
}
