import flatMap from 'lodash/flatMap'

import { deepValuesIn } from '@core/helpers/collections'
import { getCurrencyPrecision } from '@core/helpers/currency'

export function isIncludedInRoutes({ currentRoute = '', routes = [] }) {
  const flattenRoutes = flatMap(routes, (route) => {
    const isParentRoute = typeof route === 'string'

    return isParentRoute ? route : deepValuesIn(route)
  })

  return flattenRoutes.includes(currentRoute)
}

export const formatPrice = ({ amount, currency }) => {
  if (!amount) {
    return amount
  }

  return parseFloat(amount).toFixed(getCurrencyPrecision(currency))
}
