
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import translations from '../StoreNav/StoreNav.translations'

export default {
  components: {
    RevLink,
    RevIllustration,
    RevButtonTiny,
  },
  props: {
    image: { type: String, default: '' },
    title: { type: String, default: '' },
    link: { type: Object, default: () => {} },
    navigationItems: { type: Array, default: () => [] },
  },
  computed: { translations: () => translations },
}
