
import { RevIllustration } from '@ds/components/Illustration'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import {
  isPaymentMethodEnabled,
  paymentMethodIcon,
  paymentMethodName,
  paymentNetworkIcon,
  paymentNetworkLabel,
} from '../helpers/methods'

export const TYPES = {
  METHODS: 'methods',
  NETWORKS: 'networks',
}

export default {
  components: {
    RevIllustration,
  },

  props: {
    methods: {
      type: Array,
      default: null,
    },

    type: {
      type: String,
      default: TYPES.NETWORKS,
      validator: (value) => Object.values(TYPES).includes(value),
    },

    showDisabledMethods: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      countryPaymentMethods: 'countryConfig/paymentMethods',
    }),

    displayedMethods() {
      const methods = this.methods || this.countryPaymentMethods || []

      return this.showDisabledMethods
        ? methods
        : methods.filter(isPaymentMethodEnabled)
    },

    methodIcons() {
      return this.displayedMethods.reduce((acc, { bmCode }) => {
        const icon = paymentMethodIcon(bmCode)

        return isEmpty(icon)
          ? acc
          : {
              ...acc,
              [bmCode]: {
                icon: paymentMethodIcon(bmCode),
                label: paymentMethodName(bmCode),
              },
            }
      }, {})
    },

    networkIcons() {
      return this.displayedMethods.reduce(
        (acc, { networks }) => ({
          ...acc,
          ...this.getNetworkIcons(networks),
        }),
        {},
      )
    },

    // Uses an object to guaranty only one icon per id is always returned, which
    // is useful with network icons, as 1 network can be shared accross methods.
    icons() {
      const iconTypes = {
        [TYPES.METHODS]: this.methodIcons,
        [TYPES.NETWORKS]: this.networkIcons,
      }

      return iconTypes[this.type] || {}
    },
  },

  methods: {
    getNetworkIcons(networks = []) {
      return networks.reduce((acc, networkId) => {
        const icon = paymentNetworkIcon(networkId)

        return isEmpty(icon)
          ? acc
          : {
              ...acc,
              [networkId]: {
                icon: paymentNetworkIcon(networkId),
                label: paymentNetworkLabel(networkId),
              },
            }
      }, {})
    },
  },
}
