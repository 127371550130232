import { dynamicImport } from '@core/helpers'

export const COLOR = {
  black: 'text-black',
  white: 'text-grey-100',
}

export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

export const HIDE_ON_DEVICE_CLASSES = {
  [DEVICE_TYPES.MOBILE]: 'hidden md:block',
  [DEVICE_TYPES.DESKTOP]: 'block md:hidden',
}

export const SHOW_ON_DEVICE_CLASSES = {
  [DEVICE_TYPES.MOBILE]: 'block md:hidden',
  [DEVICE_TYPES.DESKTOP]: 'hidden md:block',
}

export const getDataSelectorAttributeForSeo = (prefix) => `${prefix}-content`

export const OWNER_FROM_PAGE_TYPE = {
  'event-page': '[LIF]',
  'brand-store': '[LIF]',
  buyback: '[BBACK]',
  seller: '[SELLER]',
}

export const COMPONENT_FROM_CONTENT_TYPE = {
  Button: () => dynamicImport(import('@cms/components/Button/Button.vue')),
  Buttons: () => dynamicImport(import('@cms/components/Buttons')),
  ArticleCardsList: () =>
    dynamicImport(import('@cms/components/ArticleCardsList')),
  // TODO: remove 'BuyingGuides' key once naming migration has been completed

  BuyingGuides: () => dynamicImport(import('@cms/components/ArticleCardsList')),
  Carousel: () => dynamicImport(import('@cms/components/Carousel')),
  Categories: () => dynamicImport(import('@cms/components/Categories')),
  Content: () => dynamicImport(import('@cms/components/Content')),
  ContentMedia: () => dynamicImport(import('@cms/components/ContentMedia')),
  Countdown: () => dynamicImport(import('@cms/components/Countdown')),
  EmailCollector: () => dynamicImport(import('@cms/components/EmailCollector')),
  Faq: () => dynamicImport(import('@cms/components/Faq')),
  Quiz: () => dynamicImport(import('@cms/components/Quiz')),
  HeroBanner: () => dynamicImport(import('@cms/components/HeroBanner')),
  ListItem: () =>
    dynamicImport(import('@cms/components/ListItem/ListItem.vue')),
  Media: () => dynamicImport(import('@cms/components/Media')),
  ModularCardsCarousel: () =>
    dynamicImport(
      import('@cms/components/ModularCard/ModularCardsCarousel.vue'),
    ),
  PressCarousel: () =>
    dynamicImport(
      import('@cms/components/PressCarousel').then((m) => m.PressCarousel),
    ),
  Products: () => dynamicImport(import('@cms/components/Products')),
  Recommendations: () =>
    dynamicImport(import('@cms/components/Recommendations')),
  RefinedProducts: () =>
    dynamicImport(import('@cms/components/RefinedProducts')),
  // Warning: "Reinsurance" is the name of the content type configured on contentful
  Reinsurance: () => dynamicImport(import('@cms/components/Reassurance')),
  RelatedLinks: () => dynamicImport(import('@cms/components/RelatedLinks')),
  RichText: () => dynamicImport(import('@cms/components/RichTextEditorParser')),
  EmbeddedYoutubeVideo: () =>
    dynamicImport(import('@cms/components/VideoEmbed')),
  VideosBlock: () => dynamicImport(import('@cms/components/Videos')),

  // legacy homepage components
  AboutUsLink: () =>
    dynamicImport(
      import('../../home/legacy/components/AboutUsLink').then(
        (m) => m.AboutUsLink,
      ),
    ),
  Litterature: () =>
    dynamicImport(import('../../home/legacy/components/Litterature.vue')),
  MusicalQuote: () =>
    dynamicImport(import('../../home/legacy/components/MusicalQuote')),
  Sellback: () =>
    dynamicImport(import('../../home/legacy/components/Sellback.vue')),
}
