import { BM_JOURNEYS, CHECK_AUTH_REDIRECTION } from '@auth-oauth2/constants'
import { COUNTRIES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

const getBuybackRoutes = ({ config, country }) => {
  const isFrontAppsRouteOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_BUYBACK_FUNNEL.includes(
      country,
    )

  return [
    {
      name: ROUTES.BUYBACK.DEVICE_FORM,
      path: 'buyback-funnel/device/:slug/:id',
      component: () =>
        dynamicImport(import('@buyback/customer/funnel/pages/StepDevice')),
      meta: {
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
      },
    },
    {
      name: ROUTES.BUYBACK.OFFER,
      path: 'buyback-funnel/device/:slug/:id/offer/:offerId',
      component: () =>
        dynamicImport(import('@buyback/customer/funnel/pages/OfferStep')),
      props: true,
      meta: {
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
      },
    },
    {
      name: ROUTES.BUYBACK.NO_OFFER,
      path: 'buyback-funnel/device/:slug/:id/no-offer',
      component: () =>
        dynamicImport(import('@buyback/customer/funnel/pages/NoOffer')),
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      name: ROUTES.BUYBACK.BANK,
      path: 'buyback-funnel/device/:slug/:id/offer/:offerId/bank',
      component: () =>
        dynamicImport(import('@buyback/customer/funnel/pages/StepBank')),
      meta: {
        auth: {
          required: true,
          // CHECK_AUTH_REDIRECTION only works in an array redirection
          redirection: [
            CHECK_AUTH_REDIRECTION,
            // eslint-disable-next-line camelcase
            { bm_journey: BM_JOURNEYS.BUYBACK },
          ],
        },
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
      },
    },
    {
      name: ROUTES.BUYBACK.ADDRESS,
      path: 'buyback-funnel/device/:slug/:id/offer/:offerId/address',
      component: () =>
        dynamicImport(import('@buyback/customer/funnel/pages/StepAddress')),
      meta: {
        auth: {
          required: true,
          // CHECK_AUTH_REDIRECTION only works in an array redirection
          redirection: [
            CHECK_AUTH_REDIRECTION,
            // eslint-disable-next-line camelcase
            { bm_journey: BM_JOURNEYS.BUYBACK },
          ],
        },
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
      },
    },
    {
      name: ROUTES.BUYBACK.SHIPPING,
      path: 'buyback-funnel/device/:slug/:id/offer/:offerId/address/:addressId/shipping',
      component: () =>
        dynamicImport(import('@buyback/customer/funnel/pages/StepShipping')),
      meta: {
        auth: {
          required: true,
          // CHECK_AUTH_REDIRECTION only works in an array redirection
          redirection: [
            CHECK_AUTH_REDIRECTION,
            // eslint-disable-next-line camelcase
            { bm_journey: BM_JOURNEYS.BUYBACK },
          ],
        },
      },
    },
    {
      name: ROUTES.BUYBACK.CONFIRMATION,
      path: 'buyback-funnel/confirmation/:id',
      component: () =>
        dynamicImport(
          import('@buyback/customer/funnel/pages/StepConfirmation'),
        ),
    },
  ]
}

export default ({ country, config }) => {
  const buybackRoutes = getBuybackRoutes({ country, config })
  const shouldAddBuybackRoutes = [
    COUNTRIES.DE,
    COUNTRIES.FR,
    COUNTRIES.US,
    COUNTRIES.ES,
    COUNTRIES.GB,
  ].includes(country)

  return [...insertIf(shouldAddBuybackRoutes, buybackRoutes)]
}
