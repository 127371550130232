import { getCookie } from '@core/helpers/cookies'
import { createPlugin } from '@i18n/utils'

const DEFAULT_TRANSLATIONS_COOKIE_NAME = 'BM_USE_DEFAULT_TRANSLATIONS'

export default async ({ req, store, query }, inject) => {
  const locale = store.getters['config/locale']
  const currencyCode = store.getters['config/currencyCode']

  const plugin = createPlugin({
    locale,
    currencyCode,
    showTranslationKeys: query.showTranslationKeys,
    useDefaultMessage: getCookie(
      DEFAULT_TRANSLATIONS_COOKIE_NAME,
      req?.headers.cookie,
    ),
  })

  inject('t', plugin)
}
