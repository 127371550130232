import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const {
    FF_MILITARY_DISCOUNT_BY_COUNTRY,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MILITARY_DISCOUNT = '',
  } = config
  const isMilitaryDiscountEnabledForCurrentCountry =
    FF_MILITARY_DISCOUNT_BY_COUNTRY.includes(country)

  const isFrontAppsRouteOpenedInCountry =
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MILITARY_DISCOUNT.includes(country)

  if (!isMilitaryDiscountEnabledForCurrentCountry) {
    return []
  }

  return [
    {
      path: 'military-discount',
      name: ROUTES.MILITARY_DISCOUNT,
      component: () => dynamicImport(import('./MilitaryDiscount.vue')),
      meta: {
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
      },
    },
  ]
}
