import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { APP_LOCALES } from '@core/helpers/countries'

import { BROWSER_TRANSLATIONS_KEY } from './constants'

// Nuxt executes twice this file:
// - Once on runtime during the setupMiddleware.
// - Once on the server build during the render of the translations. For this reason, we need to use
// process.translations to store them, to share the data among these two envs.
export const translations = {
  init() {
    process.translations = {}
  },
  set(payload) {
    process.translations = payload
  },
  setForLocale(locale, payload) {
    process.translations[locale] = payload
  },
  get() {
    return process.translations
  },
  getFromLocale(locale) {
    // On the server, we have all of the translations loaded
    if (!this.isEmpty()) {
      return process.translations[locale]
    }

    // On the client, we only have 1 translation loaded
    if (process.client) {
      return window[BROWSER_TRANSLATIONS_KEY]
    }

    return {}
  },
  isEmpty() {
    return isEmpty(this.get())
  },
  isReady() {
    return APP_LOCALES.every((locale) => {
      return !isEmpty(this.getFromLocale(locale))
    })
  },
}

// The manifest is only used in 1 env: during the translations fetch.
// No need to use a process.XX variable here for it.
export const manifest = {
  value: {},
  reset() {
    this.value = {}
  },
  set(payload) {
    this.value = payload
  },
  get() {
    return this.value
  },
  isEqual(payload) {
    return isEqual(this.get(), payload)
  },
  isEmpty() {
    return isEmpty(this.get())
  },
  keys() {
    return Object.keys(this.get())
  },
  values() {
    return Object.values(this.get())
  },
}
