import present from 'present'

import { dangerouslyGetRuntimeConfig } from '@config/variables'
import { removeEmptyValuesInObject } from '@core/helpers'
import logger from '@logger'

const hideHeadersCookies = (headers = {}) => {
  return removeEmptyValuesInObject({
    ...headers,
    cookie: headers.cookie ? '[REDACTED]' : null,
    'set-cookie': headers['set-cookie'] ? '[REDACTED]' : null,
  })
}

// 429: Rate limiting, we don't want to flag this kind of logs as errors on the
// client side
// 404: Not Found, we don't want to flag this kind of logs as errors on the
// client side
const STATUS_AS_INFO = [429, 404]

const LOG_TYPE = 'API_REQUEST'

const CALL_TYPES = {
  ATTEMPT: 'ATTEMPT',
  SUCCESS: 'SUCCESS',
  CANCEL: 'CANCEL',
  FAIL: 'FAIL',
}

class APILogger {
  constructor(config) {
    const { track = true, ...conf } = config
    this.config = conf
    this.track = track

    if (config.requestHeaders) {
      this.config.requestHeaders = hideHeadersCookies(config.requestHeaders)
    }
  }

  get elapsedTime() {
    if (!this.startRequestTime) {
      return 0
    }

    const endRequestTime = present()

    return Math.floor(endRequestTime - this.startRequestTime)
  }

  /**
   * Start to log the API call duration
   */
  attempt() {
    this.startRequestTime = present()
  }

  formatLogType(type, params) {
    let loggerType = 'info'

    switch (type) {
      case CALL_TYPES.FAIL:
        if (!STATUS_AS_INFO.includes(params.status_code)) {
          loggerType = 'error'
        }
        break

      default:
        break
    }

    return {
      ...this.config,
      ...params,
      type: `${LOG_TYPE}_${type}`,
      message: `[${type}] ${params.message || ''}`,
      elapsedTime: this.elapsedTime,
      responseHeaders: hideHeadersCookies(params.responseHeaders),
      loggerType,
    }
  }

  success(callInfo) {
    const { loggerType, message, ...attributes } = this.formatLogType(
      CALL_TYPES.SUCCESS,
      callInfo,
    )

    if (!dangerouslyGetRuntimeConfig().DEV_API_LOG_SUCCESS_DISABLED) {
      logger.info(message, attributes)
    }
  }

  fail(callInfo) {
    const { loggerType, message, ...attributes } = this.formatLogType(
      CALL_TYPES.FAIL,
      callInfo,
    )

    if (!dangerouslyGetRuntimeConfig().DEV_API_LOG_FAIL_DISABLED) {
      if (loggerType === 'error') {
        logger.error(message, attributes)
      } else {
        logger.info(message, attributes)
      }
    }
  }

  cancel(callInfo) {
    if (dangerouslyGetRuntimeConfig().DEV_API_LOG_CANCEL_DISABLED) {
      return
    }

    const { loggerType, message, ...attributes } = this.formatLogType(
      CALL_TYPES.CANCEL,
      callInfo,
    )

    logger.info(message, attributes)
  }
}

export default APILogger
