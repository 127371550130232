import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const isRepairTrackingFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REPAIR_TRACKING?.includes(
      country,
    )

  const repairTrackingRoute = [
    {
      path: 'dashboard/repair/:repairId',
      name: ROUTES.REPAIR.TRACKING,
      component: () =>
        dynamicImport(
          import('@repair/pages/repairTracking/RepairTracking.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: isRepairTrackingFSMEnabled,
        keepHash: true,
      },
    },
  ]

  return repairTrackingRoute
}
