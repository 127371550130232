
import { RevLink } from '@ds/components/Link'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import { SpecialMenu } from '@navigation/components/SpecialMenu'
import { ROUTES } from '@router'

const MENU = {
  admin: {
    type: 'admin',
    bo: '/bo_merchant/admin_tools/dashboard',
    admin: true,
  },
  client: {
    type: 'client',
    bo: '/bo_merchant/admin_tools/dashboard/?cid=s',
    admin: false,
  },
  merchant: {
    type: 'merchant',
    bo: '/bo_merchant/admin_tools/merchants/?mid=s',
    admin: false,
  },
}

export default {
  components: {
    RevLink,
    SpecialMenu,
  },
  computed: {
    ROUTES: () => ROUTES,
    ...mapGetters({
      isStaff: 'user/isStaff',
      isShadowingClient: 'user/isShadowingClient',
      isShadowingMerchant: 'user/isShadowingMerchant',
    }),
    menu() {
      if (this.isStaff) {
        return MENU.admin
      }

      if (this.isShadowingClient) {
        return MENU.client
      }

      if (this.isShadowingMerchant) {
        return MENU.merchant
      }

      return null
    },
    hasMenu() {
      return !isEmpty(this.menu)
    },
  },
}
