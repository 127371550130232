import { localePlugin } from '@ds/plugins/locale'

import { getBcp47LocaleFrom } from '@core/helpers/countries'

const plugin = (app, store, inject) => {
  const locale = store.getters['config/locale']

  localePlugin(inject, { locale: getBcp47LocaleFrom(locale) })
}

// testing purpose
export const routerPlugin = {
  install(VueInstance, { app, store }) {
    const inject = (key, value) => {
      // eslint-disable-next-line no-param-reassign
      VueInstance.prototype[`$${key}`] = value
    }
    plugin(app, store, inject)
  },
}

export default ({ app, store }, inject) => {
  plugin(app, store, inject)
}
