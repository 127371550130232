export default {
  title: {
    id: '500_page_title',
    defaultMessage: 'Uh oh. We broke something.',
  },
  itIsUs: {
    id: '500_page_it_is_us',
    defaultMessage: 'It’s not you, it’s us.',
  },
  weTry: {
    id: '500_page_we_try',
    defaultMessage: 'We will do everything to try to fix it.',
  },
  retry: {
    id: '500_page_retry_please',
    defaultMessage: 'There’s not much we can ask you to do except to try again',
  },
  retryParenthesis: {
    id: '500_page_retry_please_parenthesis',
    defaultMessage: '(and to keep your fingers crossed).',
  },
  serverError: {
    id: '500_page_server_problem',
    defaultMessage:
      'The server is a computer that serves you what you want on the internet. In this case, you wanted something from Back Market but the server couldn’t find our kitchen. Sob. 😢',
  },
}
