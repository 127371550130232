import { insertIf } from '@core/helpers'
import { dangerouslyInjectScriptOnce } from '@core/helpers/dom'

import { PAYMENT_NETWORKS } from '../../methods'

/**
 * @typedef {Object} KlarnaOnSiteMessagingConfig
 * @property {string} clientId
 * @property {string} libraryUrl
 * @property {string} [environment]
 */

/**
 *
 * @param {import('./advertising').PaymentMethod[]} paymentMethods
 * @returns {KlarnaOnSiteMessagingConfig?}
 */
export function getKlarnaOnSiteMessagingConfig(paymentMethods) {
  const klarnaPaymentMethod = paymentMethods?.find(({ config, networks }) => {
    return (
      networks.includes(PAYMENT_NETWORKS.KLARNA) &&
      config.clientId &&
      config.libraryUrl
    )
  })

  return klarnaPaymentMethod?.config
}

/**
 * @param {KlarnaOnSiteMessagingConfig} config
 * @returns {Promise<Object>}
 * */
export async function getKlarnaSdk({ clientId, libraryUrl, environment }) {
  await dangerouslyInjectScriptOnce(libraryUrl, {
    attributes: {
      'data-client-id': clientId,
      ...insertIf(Boolean(environment), { 'data-environment': environment }),
    },
  })

  return window.Klarna
}
