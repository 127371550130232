import isEmpty from 'lodash/isEmpty'

import { BUNDLE_ID, PLATFORM } from '@http/apiConstants'
import APILogger from '@http/apiLogger'
import {
  searchConfigurationByScope,
  searchConfigurationByScopeId,
} from '@http/endpoints'
import logger from '@logger'

import { orderFacets, useAlgolia } from '../utils/algolia'

export const RANGE_BAR = 20

const doesGraphExist = (state, graphId) => {
  return graphId in state.graphResults
}

export default {
  namespaced: true,
  state() {
    return {
      ongoing: {
        contextId: null,
        graphId: null,
      },
      contexts: {},
      searchResult: {},
      graphResults: {},
      isLoading: false,
      apiKey: undefined,
    }
  },
  getters: {
    doesContextExist: (state) => {
      return state.ongoing.contextId in state.contexts
    },

    doesGraphExists: (state) => {
      return doesGraphExist(state, state.ongoing.graphId)
    },

    isCurrentContextId: (state) => (id) => state.ongoing.contextId === id,

    currentContext: (state, getters) => {
      return getters.doesContextExist
        ? state.contexts[state.ongoing.contextId]
        : {}
    },

    currentGraph: (state, getters) => {
      return getters.doesGraphExists
        ? state.graphResults[state.ongoing.graphId]
        : {}
    },

    currentSearch: (state) => {
      return state.searchResult
    },

    indexes: (_, getters) => {
      return getters.currentContext ? getters.currentContext.indexes : {}
    },

    sortList: (_, getters) => {
      if (!isEmpty(getters.indexes)) {
        return [getters.indexes.active, ...(getters.indexes.other ?? [])]
      }

      return []
    },

    minPrice: (_, getters) => {
      if ('price' in getters.currentSearch) {
        return getters.currentSearch.price.min
      }

      return 0
    },

    maxPrice: (_, getters) => {
      if ('price' in getters.currentSearch) {
        return getters.currentSearch.price.max
      }

      return null
    },

    avgPrice: (_, getters) => {
      if ('price' in getters.currentSearch) {
        return getters.currentSearch.price.avg
      }

      return null
    },

    sortDefaultValue: (_, getters) => {
      return getters.indexes?.active?.name || ''
    },

    results: (_, getters) => {
      return getters.currentSearch.results || []
    },

    nbResults: (_, getters) => {
      const { nbResults } = getters.currentSearch

      return nbResults ? Number(nbResults) : 0
    },

    queryID: (_, getters) => {
      return getters.currentSearch.queryID || ''
    },

    hasResults: (_, getters) => {
      return !isEmpty(getters.results)
    },

    nbPages: (_, getters) => {
      return Number(getters.currentSearch.nbPages)
    },

    currentPage: (_, getters) => {
      const { currentPage } = getters.currentSearch

      return currentPage + 1
    },

    graphData: (_, getters) => {
      if ('price' in getters.currentSearch) {
        const { max } = getters.currentSearch.price
        const { priceFacet, facetsSearch } = getters.currentGraph

        if (!isEmpty(priceFacet) && !isEmpty(facetsSearch)) {
          const correctScale = {}
          const orderedFacetsContext = [...priceFacet.scales].sort(
            (item1, item2) => item1.maxValue - item2.maxValue,
          )
          orderedFacetsContext.some((scale) => {
            if (scale.maxValue >= max) {
              correctScale.max = scale.maxValue
              correctScale.name = scale.facetName

              return true
            }

            return false
          })

          const correctRange = facetsSearch.find(
            (facet) => facet.name === correctScale.name,
          )

          if (!isEmpty(correctRange)) {
            const dataRange = Array(RANGE_BAR).fill(0)
            Object.entries(correctRange.data).forEach(([key, value]) => {
              dataRange[key] = value
            })

            return {
              range: Math.floor(correctScale.max / RANGE_BAR),
              values: dataRange,
              max: correctScale.max,
            }
          }
        }

        return {
          range: Math.floor(max / RANGE_BAR),
          values: [],
          max,
        }
      }

      return { range: 0, values: [], max: 0 }
    },

    values: (_, getters) => {
      return getters.graphData ? getters.graphData.values : []
    },

    range: (_, getters) => {
      return getters.graphData ? getters.graphData.range : 0
    },

    maxRange: (_, getters) => {
      return getters.graphData ? getters.graphData.max : 0
    },

    facets: (_, getters) => {
      const { facets: facetsContext } = getters.currentContext
      const { facets: facetsSearch } = getters.currentSearch

      if (!isEmpty(facetsContext) && !isEmpty(facetsSearch)) {
        return facetsContext.reduce((acc, current) => {
          const { name, isSortedByBusiness } = current

          const facetSearch = facetsSearch.find((f) => f.name === name) || {}

          const facetValues = facetSearch.data
            ? orderFacets(facetSearch.data, isSortedByBusiness)
            : []

          if (isEmpty(facetValues)) {
            return acc
          }

          return [
            ...acc,
            {
              ...current,
              isSortedByBusiness,
              facetName: name,
              facetValues,
            },
          ]
        }, [])
      }

      return []
    },

    filterWhitelist: (_, getters) => {
      return getters.facets.map((facet) => facet.name)
    },

    isLoading: (state) => {
      return state.isLoading
    },
  },
  mutations: {
    saveContextDetails(
      state,
      { searchParams, facets, indexes, complexFilter, priceFacet },
    ) {
      state.contexts = {
        ...state.contexts,
        [searchParams.contextId]: {
          indexes,
          facets,
          complexFilter,
          priceFacet,
        },
      }
    },

    saveSearch(
      state,
      { hits, facets, nbHits, nbPages, page, stats = {}, queryID },
    ) {
      state.searchResult = {
        results: hits,
        nbResults: nbHits,
        currentPage: page,
        nbPages,
        // Facets shown for this search
        facets,
        price: {
          min: stats.min || 0,
          max: stats.max || 0,
          avg: stats.avg || 0,
        },
        queryID,
      }
    },

    saveGraph(state, { searchParams, facetsSearch, priceFacet }) {
      state.graphResults = {
        ...state.graphResults,
        [searchParams.graphId]: {
          facetsSearch,
          priceFacet,
        },
      }
    },

    registerCurrentSearch(state, searchParams) {
      state.ongoing = {
        contextId: searchParams.contextId,
        graphId: searchParams.graphId,
      }
    },

    setIsLoading(state, value) {
      state.isLoading = value
    },

    saveApiKey(state, apiKey) {
      state.apiKey = apiKey
    },
  },
  actions: {
    async fetchResults({ commit, state, dispatch }, { searchParams }) {
      try {
        if (!(searchParams.contextId in state.contexts)) {
          const { id, source: scope } = searchParams.context
          const requester = id
            ? {
                request: searchConfigurationByScopeId,
                pathParams: { scope, id },
              }
            : { request: searchConfigurationByScope, pathParams: { scope } }

          const { payload } = await dispatch('http/request', requester, {
            root: true,
          })

          commit('saveContextDetails', {
            ...payload,
            searchParams,
          })
        }

        commit('setIsLoading', true)
        await dispatch('search', { searchParams })
        commit('setIsLoading', false)

        commit('registerCurrentSearch', searchParams)
      } catch (error) {
        logger.info('[S&R][Error][Search] Error in search.js', { error })
      }
    },
    async search({ state, commit, rootGetters }, { searchParams }) {
      // The context is not registered yet as ongoing, since we don't want to refresh the client
      // as long as we don't have the search call received
      const context = state.contexts[searchParams.contextId]
      const endpointName = 'algoliaSearch'
      const logMessage = `GET ${endpointName}`

      const apiLogger = new APILogger({
        endpointName,
        platform: PLATFORM,
        bundleId: BUNDLE_ID,
        context,
        bm: { visitor_id: rootGetters['config/visitorId'] },
        filters: searchParams.toFilters(),
      })

      apiLogger.attempt()

      try {
        const searchResults = await useAlgolia({
          ALGOLIA_ID: this.app.$config.ALGOLIA_ID,
          ALGOLIA_KEY: state.apiKey,
          context,
          searchParams,
        })

        apiLogger.success({
          message: logMessage,
          index: searchResults.index,
          searchProcessingTime: searchResults.searchProcessingTime,
        })

        commit('saveSearch', searchResults)
        if (!doesGraphExist(state, searchParams.graphId)) {
          const { facets: facetsSearch } = searchResults
          const { priceFacet } = context

          commit('saveGraph', { searchParams, facetsSearch, priceFacet })
        }
      } catch (error) {
        apiLogger.fail({
          message: logMessage,
          error,
        })
        throw error
      }
    },
  },
}
