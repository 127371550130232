import { BM_JOURNEYS, CHECK_AUTH_REDIRECTION } from '@auth-oauth2/constants'
import { CONVERSATION_USERS } from '@buyback/shared/constants'
import { COUNTRIES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import minimalLayout from '@core/layouts/minimal.vue'
import { ROUTES } from '@router'

const buybackEligibleCountries = [
  COUNTRIES.FR,
  COUNTRIES.DE,
  COUNTRIES.US,
  COUNTRIES.GB,
  COUNTRIES.ES,
]

export const buybackRefurbisherMinimalLayoutRoutes = ({ country, config }) => {
  if (buybackEligibleCountries.includes(country)) {
    return [
      {
        path: '/bo_merchant/buyback/orders/:id/conversation',
        component: minimalLayout,
        props: {
          fullWidth: true,
        },
        meta: {
          auth: {
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.LOGIN },
          },
          seller: {
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.LOGIN },
          },
        },
        children: [
          {
            name: ROUTES.BUYBACK.REFURBISHER.CONVERSATION,
            path: '',
            component: () =>
              dynamicImport(import('@buyback/shared/pages/OrderConversation')),
            props: {
              role: CONVERSATION_USERS.REFURBISHER,
            },
            meta: {
              ...insertIf(config.FF_ENABLE_NEW_BUYBACK_ORDER_DETAILS_PAGE, {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.BUYBACK.ORDERS.CONVERSATION,
                },
              }),
            },
          },
        ],
      },
    ]
  }

  return []
}

// No locale, layout defined here as we need flexibility
// all these routes must go in an empty layout collection
export const buybackStaffRoutes = () => {
  const baseLayoutRoutes = [
    {
      path: '/bo_admin/buyback/orders/:id',
      component: () =>
        dynamicImport(
          import('@admin-tools/shared/components/layouts/Base.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
        buybackOrCareBpo: {
          required: true,
        },
      },
      children: [
        {
          name: ROUTES.BUYBACK.STAFF.ORDER,
          path: '',
          component: () =>
            dynamicImport(import('@buyback/staff/orders/pages/Order')),
        },
      ],
    },
  ]

  const minimalLayoutRoutes = [
    {
      path: '/bo_admin/buyback/orders/:id/conversation',
      component: minimalLayout,
      props: {
        fullWidth: true,
      },
      meta: {
        auth: {
          required: true,
        },
        buybackOrCareBpo: {
          required: true,
        },
      },
      children: [
        {
          name: ROUTES.BUYBACK.STAFF.CONVERSATION,
          path: '',
          component: () =>
            dynamicImport(import('@buyback/shared/pages/OrderConversation')),
          props: {
            role: CONVERSATION_USERS.STAFF,
          },
        },
      ],
    },
  ]

  return [...baseLayoutRoutes, ...minimalLayoutRoutes]
}

export const buybackCustomerMinimalLayoutRoutes = ({ country, config }) => {
  const disableClientSideNavigation =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_CONVERSATION?.includes(country)
  if (buybackEligibleCountries.includes(country)) {
    return [
      {
        name: ROUTES.BUYBACK.CUSTOMER.RECYCLING_CATEGORY,
        path: 'recycling-funnel/category',
        component: minimalLayout,
        meta: {
          disableClientSideNavigation: true,
        },
      },
      {
        name: ROUTES.BUYBACK.CUSTOMER.RECYCLING_OFFER,
        path: 'recycling-funnel/device/:category/offer',
        component: minimalLayout,
        meta: {
          auth: {
            required: true,
            // CHECK_AUTH_REDIRECTION only works in an array redirection
            redirection: [
              CHECK_AUTH_REDIRECTION,
              // eslint-disable-next-line camelcase
              { bm_journey: 'recycling' },
            ],
          },
          disableClientSideNavigation: true,
        },
      },
      {
        path: 'dashboard/sales/:id/conversation',
        component: minimalLayout,
        props: {
          fullWidth: true,
        },
        meta: {
          auth: {
            required: true,
            // CHECK_AUTH_REDIRECTION only works in an array redirection
            redirection: [
              CHECK_AUTH_REDIRECTION,
              // eslint-disable-next-line camelcase
              { bm_journey: BM_JOURNEYS.BUYBACK },
            ],
          },
          disableClientSideNavigation,
        },
        children: [
          {
            name: ROUTES.BUYBACK.CUSTOMER.DASHBOARD.CONVERSATION,
            path: '',
            component: () =>
              dynamicImport(import('@buyback/shared/pages/OrderConversation')),
            props: {
              role: CONVERSATION_USERS.CUSTOMER,
            },
          },
        ],
      },
    ]
  }

  return []
}
