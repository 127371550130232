import { getHttpFromRoute } from './utils'

import logger from './index'

/**
 * Append HTTP-related information into the logger global context.
 *
 * While server-side HTML generations, and client-side navigations are covered
 * by the middleware, we still need a client-side plugin to fill the logger
 * context after the first load.
 *
 * @see https://nuxtjs.org/docs/concepts/nuxt-lifecycle
 * @see middleware.js in the same directory.
 *
 * @param {Object} context
 * @param {import('vue-router').Route} context.route
 */
export default function loggerPluginClient({ route }) {
  logger.appendGlobalContext({
    http: getHttpFromRoute(route),
  })
}
