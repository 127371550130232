import isEmpty from 'lodash/isEmpty'

/**
 * Used by our image optimisation system.
 *
 * @param {String} path
 * @param {Object} optimizations
 * @param {String} optimizations.format
 * @param {Number} optimizations.quality
 * @param {Number} optimizations.width
 *
 * @return {String}
 */
export const provider = (path, { format, quality, width }) => {
  if (isEmpty(path)) {
    return ''
  }

  // Cloudflare images does not support .svg and .gif files as input.
  if (path.endsWith('.svg') || path.endsWith('.gif')) {
    return path
  }

  const params = encodeURIComponent(
    `format=${format},quality=${quality},width=${width}`,
  )

  return `/cdn-cgi/image/${params}/${path}`
}
