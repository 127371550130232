import isEmpty from 'lodash/isEmpty'

export default ({ app }, inject) => {
  inject('static', (path) => {
    if (process.isDev && (isEmpty(path) || !path.startsWith('/'))) {
      throw new Error(
        `[$static plugin] Path should start with a slash. (${path})`,
      )
    }

    let pathToUse = path
    if (
      !path.startsWith('/img') &&
      !path.startsWith('/videos') &&
      !path.startsWith('/_nuxt')
    ) {
      pathToUse = `/../${path}`
    }

    return app.$config.STATICS_PATH + pathToUse
  })
}
