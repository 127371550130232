import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const isHelpCenterEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_HELP_CENTER?.includes(country)

  return [
    {
      name: ROUTES.HELP_CENTER.HOME,
      path: 'help',
      component: () => dynamicImport(import('./Home.vue')),
      meta: {
        auth: {
          required: false,
        },
        disableClientSideNavigation: isHelpCenterEnabled,
      },
    },
  ]
}
