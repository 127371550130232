export default {
  namespaced: true,

  /**
   * @typedef {Object} State
   * @property {boolean} isEnabled
   * @property {string} message
   *
   * @returns {State}
   */
  state: () => ({
    isEnabled: false,
    message: '',
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {boolean} isEnabled
     */
    setEnabled(state, isEnabled) {
      state.isEnabled = isEnabled
    },

    /**
     * @param {State} state
     * @param {boolean} isEnabled
     */
    setMessage(state, message) {
      state.message = message
    },
  },

  getters: {
    /**
     * @param {State} state
     * @returns {boolean}
     */
    isEnabled: (state) => state.isEnabled,

    /**
     * @param {State} state
     * @returns {string}
     */
    message: (state) => state.message,
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {string} payload.message
     */
    enable({ commit }, { message = '' } = {}) {
      commit('setEnabled', true)
      commit('setMessage', message)
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     */
    disable({ commit }) {
      commit('setEnabled', false)
    },
  },
}
