import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => [
  {
    path: 'credit-card',
    component: () => dynamicImport(import('@core/layouts/minimal.vue')),
    children: [
      {
        path: 'update',
        name: ROUTES.CREDIT_CARD.UPDATE,
        component: () =>
          dynamicImport(import('./pages/CreditCardUpdatePage.vue')),
        meta: {
          auth: { required: true },
        },
      },
      {
        path: 'update/success',
        name: ROUTES.CREDIT_CARD.UPDATE_SUCCESS,
        component: () =>
          dynamicImport(import('./pages/CreditCardUpdateSuccessPage.vue')),
      },
      {
        path: 'update/failure',
        name: ROUTES.CREDIT_CARD.UPDATE_FAILURE,
        component: () =>
          dynamicImport(import('./pages/CreditCardUpdateFailurePage.vue')),
      },
    ],
  },
]
