import {
  hideAFBanner,
  initScripts,
  showAFBanner,
} from './vendor/libraryWrapper'

let isInitialized = false

export function showBanner() {
  if (!isInitialized) return

  showAFBanner()
}

export function hideBanner() {
  if (!isInitialized) return

  hideAFBanner()
}

export function loadAppsflyer({ bannersApiKey }) {
  const params = { configs: { banners: { key: bannersApiKey } } }
  initScripts(params)

  isInitialized = true
}
