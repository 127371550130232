/**
 * Runtime configuration
 *
 * - On server, we import configuration directly. It is fine, as although it
 *   uses `process.env`, it is not compiled so values will be set at runtime.
 *
 * - On client, `process.env` is replaced by values at build time. To obtain
 *   actual runtime values, we need to retrieve config from Nuxt payload.
 *   Read more: https://nuxtjs.org/guide/runtime-config/
 *
 * ╔═══════════════════════════════════════════════════════════════════════════╗
 * ║ IMPORTANT: prefer using $config in Nuxt components, stores, or wherever   ║
 * ║            else available (plugins, etc). For this reason, it's called    ║
 * ║            "dangerously", so it catches attention during reviews.         ║
 * ║            For testing, call `setConfig` in `beforeAll` or `beforeEach`   ║
 * ║            to write to the config, and `restoreConfig` in `afterAll` or   ║
 * ║            `afterEach`, available in '@config/tests/utils'.               ║
 * ║            To only read the default use `config` from '@vue/test-utils'   ║
 * ║            or `$config` in VTU or VTL mock options, which should be       ║
 * ║            enough in most cases.                                          ║
 * ╚═══════════════════════════════════════════════════════════════════════════╝
 */
export const dangerouslyGetRuntimeConfig = () =>
  process.client
    ? // eslint-disable-next-line no-underscore-dangle
      window.__NUXT__.config
    : {
        // eslint-disable-next-line global-require
        ...require('./public').default,
        // eslint-disable-next-line global-require
        ...require('./private').default,
      }

export const dangerouslyGetRuntimeFeatureFlags = () => {
  const config = dangerouslyGetRuntimeConfig()

  return Object.entries(config)
    .filter(([nodeEnv]) => nodeEnv.startsWith('FF_'))
    .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {})
}
