import { fetchCustomerRequestPlatformRemainingHours } from '@http/endpoints'

export default {
  namespaced: true,
  actions: {
    // TODO: Move this actions in order.js
    async fetchRemainingHours(
      { dispatch },
      { customerRequestId, update = true },
    ) {
      const { payload: remainingHours = null } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformRemainingHours,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      if (update) {
        dispatch(
          'customerRequestPlatform/conversations/update',
          {
            customerRequestId,
            updates: {
              remainingHours,
            },
          },
          { root: true },
        )
      }
    },
  },
}
