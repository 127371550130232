export default {
  headline: {
    id: 'klarna_payment_summary_modal_headline',
    defaultMessage: 'Order paid with Klarna',
  },
  description: {
    id: 'klarna_payment_summary_modal_description',
    defaultMessage: `
      Your payment is managed by Klarna:
      <br />
      <br />
      For more information, check your email or sign in to your Klarna account:`,
  },
  button: {
    id: 'klarna_payment_summary_modal_button',
    defaultMessage: 'Access to Klarna',
  },
}
