export default {
  namespaced: true,
  state: () => ({
    isEnabled: false,
  }),
  getters: {
    isEnabled: (state) => state.isEnabled,
  },
  mutations: {
    set: (state) => {
      state.isEnabled = !state.isEnabled
    },
  },
  actions: {
    toggle: ({ commit }) => commit('set'),
  },
}
