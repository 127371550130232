import {
  LogLevels,
  createConsoleTransport,
  createDatadogTransport,
  createLogger,
} from '@backmarket/front-logger'

import { dangerouslyGetRuntimeConfig } from '@config/variables'
import { isBrowser, isNode } from '@core/helpers'
import { BUNDLE_ID } from '@http/apiConstants'
import { COUNTRY_KEY, MARKETPLACE_KEY } from '@server/request/constants'

import { DD_BROWSER_LOGS_CLIENT_TOKEN, DD_SERVICE, DD_SITE } from './constants'

const {
  DD_BROWSER_LOGS_ENABLED,
  DD_CONTINENT,
  DD_ENV,
  BROWSER_LOGS_TO_CONSOLE_ENABLED,
  BROWSER_LOGS_TO_CONSOLE_LEVEL,
  GTM_ENV,
  DD_SERVER_LOGS_ENABLED,
  IS_PRODUCTION,
} = dangerouslyGetRuntimeConfig()

const attributes = {
  bundleId: BUNDLE_ID,
  continent: DD_CONTINENT,
  country: isBrowser() ? window[COUNTRY_KEY] : undefined,
  env: DD_ENV,
  gtm: GTM_ENV,
  marketplace: isBrowser() ? window[MARKETPLACE_KEY] : undefined,
  service: {
    build: {
      datetime: process.env.IMAGE_DATETIME,
      timestamp: process.env.IMAGE_TIMESTAMP,
      sha: process.env.IMAGE_LONG_COMMIT,
    },
    name: DD_SERVICE,
    version:
      process.env.VERSION ||
      `${process.env.IMAGE_TIMESTAMP}#${process.env.IMAGE_SHORT_COMMIT}`,
  },
}

const isInDebugMode = isBrowser()
  ? window.location.search.includes('debug')
  : false
if (isInDebugMode) {
  window.BM_LOGGER_OVERRIDE_CONSOLE_LEVEL = LogLevels.DEBUG
}

const transports = []
if (
  (isBrowser() && (BROWSER_LOGS_TO_CONSOLE_ENABLED || isInDebugMode)) ||
  (isNode() && !DD_SERVER_LOGS_ENABLED)
) {
  transports.push(
    createConsoleTransport({ level: BROWSER_LOGS_TO_CONSOLE_LEVEL }),
  )
}

if (
  (isBrowser() && DD_BROWSER_LOGS_ENABLED) ||
  (isNode() && DD_SERVER_LOGS_ENABLED)
) {
  transports.push(
    createDatadogTransport({
      datadogConfiguration: {
        clientToken: DD_BROWSER_LOGS_CLIENT_TOKEN,
        sampleRate: 100,
        site: DD_SITE,
        beforeSend: (event) => {
          if (isBrowser()) {
            // The Android Facebook/Instagram apps were injecting scripts when
            // loading pages in webviews. These were doing console.info calls
            // which ended up in our DD.
            if (event.message?.startsWith('FBNav')) {
              return false
            }
          }

          return true
        },

        // We do not want Datadog SDK to automatically track errors reported
        // to the console. We already do that with our own custom logger,
        // and it generates noise on Datadog more than anything else.
        forwardErrorsToLogs: false,
      },
    }),
  )
}

const logger = createLogger({
  attributes,
  level: LogLevels.INFO,
  transports,
})

if (IS_PRODUCTION) {
  logger.wrapConsole({ logToNativeConsole: isBrowser() })
}

export default logger
