const getDefaultState = () => ({
  anonymousReview: null,
  productTitle: null,
  imageUrl: null,
  grade: null,
  quantity: 0,
  rate: null,
  merchantWarranty: null,
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getInitialRate: (state) => state.rate,
    getProduct: ({ productTitle, imageUrl, grade, quantity }) => {
      return {
        productTitle,
        imageUrl,
        grade,
        quantity,
      }
    },
    getMerchantWarranty: (state) => state.merchantWarranty,
    getAnonymous: (state) => state.anonymousReview,
  },
  mutations: {
    set: (state, data) => {
      Object.assign(state, { ...data })
    },
    setAnonymous: (state, data) => {
      state.anonymousReview = data
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    setAnonymous: ({ commit }, datas) => commit('setAnonymous', datas),
    set: ({ commit }, datas) => commit('set', datas),
    reset: ({ commit }) => commit('reset'),
  },
}
