export default async function QaPlugin({ app }) {
  if (!app.mixins) {
    // eslint-disable-next-line no-param-reassign
    app.mixins = []
  }

  app.mixins.push({
    mounted() {
      if (document) {
        document.body.setAttribute('data-qa-ready', 'true')
      }
    },
  })
}
