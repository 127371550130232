import { initNoodleClient } from '@backmarket/front-noodle'

import { APP_NAME } from '@config/constants'
import { removeEmptyValuesInObject } from '@core/helpers'
import { BUNDLE_ID, PLATFORM } from '@http/apiConstants'
import APILogger from '@http/apiLogger'

export const initNoodle = (
  { visitorId, sessionId, country, locale, userId, merchantId },
  { url, env },
) => {
  const endpointName = 'noodle'
  const message = `POST ${endpointName}`

  const apiLogger = new APILogger({
    endpointName,
    platform: PLATFORM,
    bundleId: BUNDLE_ID,
    bm: { visitor_id: visitorId },
    session_id: sessionId,
    // If we allow the tracking to the different providers, noodle would also send a request to report the API Call...
    // Which would lead to another noodle event, then a new API call... Infinite loop.
    track: false,
  })

  initNoodleClient({
    hooks: {
      attempt: () => {
        apiLogger.attempt()
      },
      success: () => {
        apiLogger.success({ message })
      },
      fail: () => {
        apiLogger.fail({ message })
      },
    },
    env,
    app: {
      name: APP_NAME,
      type: 'browser',
      version: process.env.VERSION,
    },
    url,
    userContext: removeEmptyValuesInObject({
      country,
      locale,
      userId,
      merchantId,
      visitorId,
      sessionId,
    }),
  })
}
