export default {
  namespaced: true,

  state() {
    return {
      isBrazeAvailable: false,
    }
  },

  getters: {
    isBrazeAvailable: (state) => state.isBrazeAvailable,
  },

  mutations: {
    setBrazeAvailable(state) {
      state.isBrazeAvailable = true
    },
  },
}
