import { isArray, isUndefined } from '@core/helpers'
import { ROUTES } from '@router'

/**
 * Redirect the user if he's not staff, Care bpo user or Care bpo supervisor.
 *
 * @param {Object} context
 * @param {Function} context.redirect
 * @param {Object} context.store
 * @return {Promise}
 */
export default async ({ redirect, route, store }) => {
  // Matches are listed starting from the first parent, and going through every route
  // until we hit the current route. For example, having three routes (A > B > C)
  // would generate a `matched` array [A, B, C]. Since the route defined latter
  // should take precedence over its parents, we reverse the whole array.
  const reversed = [...route.matched].reverse()
  // Since we reversed the array beforehand, we can now use `Array#find` to traverse
  // the whole array and return the _first_ route matching the given predicate.
  const match = reversed.find(
    (routeDefinition) =>
      routeDefinition.meta && routeDefinition.meta.canViewCustomers,
  )

  // We may request a routes tree not protected at all.
  if (isUndefined(match)) return null

  const {
    required = false,
    redirection = [{ name: ROUTES.ADMIN_TOOLS.HOME }],
  } = match.meta.canViewCustomers

  if (required === false) return null

  if (store.getters['user/isStaff'] || store.getters['user/canViewCustomers'])
    return null

  // The `redirect` function from Nuxt accepts either a path (as string), or a more
  // complexe configuration using multiple arguments. Since we want to allow the
  // developers to have full power over this, we also accept an array (tupple).
  if (isArray(redirection)) {
    return redirect(...redirection)
  }

  return redirect(redirection)
}
