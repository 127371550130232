import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals'

import { dangerouslyGetRuntimeFeatureFlags } from '@config/variables'
import { initBreakpointObserver } from '@core/breakpointsObserver'
import { fromEntries } from '@core/helpers'
import { getCookies } from '@core/helpers/cookies'
import { getUserCrmId } from '@http/endpoints'
import {
  trackContext,
  trackPageView,
  trackRumView,
  trackWebPerformance,
  trackWebPerformanceAll,
} from '@tracking/events'

import { initAmplitude } from './amplitude/amplitude'
import { loadAppsflyer } from './appsflyer/appsflyer'
import { ALLOWED_ROUTES as APPSFLYER_ALLOWED_ROUTES } from './appsflyer/constants'
import { canLoadAppsflyer } from './appsflyer/helpers'
import {
  importBrazeSdk,
  initializeSdk,
  registerBrazeServiceWorker,
} from './braze/braze'
import { ALLOWED_ROUTES as BRAZE_ALLOWED_ROUTES } from './braze/constants'
import {
  METRICS_WEB_PERFORMANCE,
  PAGE_TYPE_BY_ROUTE_NAME,
  getConnection,
  getDevice,
} from './constants'
import {
  refreshSessionIdCookie,
  refreshVisitorIdCookie,
} from './correlation-ids'
import { trackDesignSystem } from './design-system'
import { isIncludedInRoutes } from './helpers'
// Can't import it via the index, it would create a circular dependency
import { initNoodle } from './noodle/init'

function startMonitoringWebVitals({ store, route }) {
  const webPerformanceDetails = {
    pageType: PAGE_TYPE_BY_ROUTE_NAME[route.name],
    connection: getConnection(),
    deviceType: getDevice(),
    // We do not rely on the `event.meta.country` property here because our
    // lab monitoring system (from the `web-performance-tracking` repository)
    // starts a single client for all different countries, meaning that the
    // `event.meta.country` property is always the same, for all events.
    // So, we need to pass the `country` as an `event.data` property, and
    // we configured the Noodle to consume the `web-performance.value` event
    // that way. So, we also must follow that constraint here.
    country: store.getters['config/country'],
  }

  const queue = new Set()

  // We define this variable here to ensure that only one WebPerformanceAll event is pushed to Amplitude.
  let hasFlushed = false

  const composeTrackWebPerformance = (data) => {
    queue.add(data)
    trackWebPerformance({ ...webPerformanceDetails, ...data })
  }

  const flushQueue = () => {
    if (queue.size > 0 && !hasFlushed) {
      const formatedMetricsToSend = Array.from(queue).reduce(
        (acc, { metric, value }) => {
          return {
            ...acc,
            [metric]: value,
          }
        },
        {},
      )

      trackWebPerformanceAll({
        ...webPerformanceDetails,
        ...formatedMetricsToSend,
      })

      hasFlushed = true
      queue.clear()
    }
  }

  onCLS(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.CLS,
      value,
    }),
  )

  onFCP(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.FCP,
      value,
    }),
  )

  onFID(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.FID,
      value,
    }),
  )

  onLCP(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.LCP,
      value,
    }),
  )

  onTTFB(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.TTFB,
      value,
    }),
  )

  onINP(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.INP,
      value,
    }),
  )

  // Report all available metrics whenever the page is backgrounded or unloaded.
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushQueue()
    }
  })

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  window.addEventListener('pagehide', flushQueue)
}

// TODO [LIF-677] Remove this function after 10/20/2023
function unregisterLegacyBatchServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener(
      'load',
      async () => {
        try {
          const registrations =
            (await navigator.serviceWorker.getRegistrations()) || []

          registrations.forEach((registration) => {
            if (
              registration.active &&
              registration.active.scriptURL.includes(
                'batchsdk-worker-loader.js',
              )
            ) {
              registration.unregister()
            }
          })
        } catch {
          // fails silently
        }
      },
      { once: true },
    )
  }
}

export default ({ app, store, $config, route }) => {
  const userCookies = getCookies()
  const country = store.getters['config/country']
  const cookies = fromEntries(userCookies)

  const visitorId = refreshVisitorIdCookie(cookies)
  const sessionId = refreshSessionIdCookie(cookies)

  store.commit('config/setConfig', { visitorId, sessionId })

  if (!$config.KILL_NOODLE) {
    initNoodle(
      {
        visitorId,
        sessionId,
        country,
        locale: store.getters['config/locale'],
        merchantId: store.getters['config/merchantId'],
        userId: store.getters['config/clientId'],
      },
      { url: $config.NOODLE_URL, env: $config.ENVIRONMENT },
    )
  }

  const gdpr = store.getters['gdpr/cookies/all']
  initAmplitude({
    kill: $config.KILL_AMPLITUDE || !$config.FF_AMPLITUDE.includes(country),
    apiKey: $config.AMPLITUDE_API_KEY,
    version: process.env.VERSION,
    deferInitialization: !gdpr.analytics,
    sessionId,
  })

  // TODO [LIF-677] Remove this function call after 10/20/2023
  unregisterLegacyBatchServiceWorker()

  const canLoadBraze =
    !$config.KILL_BRAZE &&
    isIncludedInRoutes({
      currentRoute: route.name,
      routes: BRAZE_ALLOWED_ROUTES,
    })

  if (canLoadBraze) {
    const setAvailable = () => store.commit('braze/setBrazeAvailable')
    const fetchUserCrmId = () =>
      store.dispatch('http/request', { request: getUserCrmId })

    initializeSdk({
      apiKey: $config.BRAZE_API_KEY,
      safariWebsitePushId: $config.BRAZE_SAFARI_PUSH_ID,
      optIn: gdpr.advertising && gdpr.userExperience,
      getBrazeSdk: importBrazeSdk,
      setAvailable,
      getUserCrmId: fetchUserCrmId,
    })

    registerBrazeServiceWorker()
  }

  if (
    canLoadAppsflyer({
      country,
      $config,
      userAgent: store.getters['config/userAgent'],
      isMobileAppAvailable: store.getters['config/isMobileAppAvailable'],
      allowedRoutes: APPSFLYER_ALLOWED_ROUTES,
      currentRoute: route.name,
    })
  ) {
    const bannersApiKey = $config.APPSFLYER_BANNERS_API_KEY

    loadAppsflyer({ bannersApiKey })
  }

  // Initialize GTM
  const dataLayerContext = store.getters['config/dataLayerContext']
  const paymentMethodsAvailable =
    store.getters['countryConfig/paymentMethodsIds']
  const featureFlags = dangerouslyGetRuntimeFeatureFlags()
  const abtest = store.getters['flags/getAll']
  const isDefaultSegment = store.getters['flags/isDefaultSegment']

  // Calls breakpoints observer to track initial user breakpoint
  initBreakpointObserver(store)

  // Push the generic event
  trackContext({
    ...dataLayerContext,
    gdpr,
    visitorId,
    sessionId,
    release: process.env.VERSION,
    paymentMethodsAvailable,
    isDefaultSegment,
    abtest,
    flags: featureFlags,
  })

  trackDesignSystem()

  app.router.afterEach((to, from) => {
    // At the init, the path is equal (/ & /) while the name is different (pageName & null)
    // We check the path in order to support the pageView event when going on another link of the same pageName,
    // like going from a product A to a product B (would be the same pageName)
    if (to.name !== from.name || to.path !== from.path) {
      trackPageView(
        { ...to, pageType: to.name },
        { ...from, pageType: from.name },
      )
    }
  })

  // Make sure to track only configured pages to avoid null values on graphs side.
  if (route.name in PAGE_TYPE_BY_ROUTE_NAME) {
    startMonitoringWebVitals({ store, route })
    trackRumView({
      pageType: PAGE_TYPE_BY_ROUTE_NAME[route.name],
      connection: getConnection(),
      deviceType: getDevice(),
      // We do not rely on the `event.meta.country` property here because our
      // lab monitoring system (from the `web-performance-tracking` repository)
      // starts a single client for all different countries, meaning that the
      // `event.meta.country` property is always the same, for all events.
      // So, we need to pass the `country` as an `event.data` property, and
      // we configured the Noodle to consume the `web-performance.value` event
      // that way. So, we also must follow that constraint here.
      country,
    })
  }
}
