export default {
  toastTitle: {
    id: 'logout_error_toast_title',
    defaultMessage: 'Oops, something went wrong.',
  },
  toastMessage: {
    id: 'logout_error_toast_message',
    defaultMessage: 'Please try again.',
  },
  toastClose: {
    id: 'logout_error_toast_close',
    defaultMessage: 'Close',
  },
}
