import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { stringify } from 'query-string'

import {
  hashObjects,
  parsedHash,
  removeEmptyValuesInObject,
} from '@core/helpers'
import { ROUTES } from '@router'

import { FACET_PAGE, FACET_PRICE, FACET_QUERY, FACET_SORT } from './algolia'

export const getSourceFromRouteName = (name) => {
  switch (name) {
    case ROUTES.LANDING.PRODUCT:
    case ROUTES.LANDING.UNIVERSE:
      return 'product-landing'
    case ROUTES.LANDING.SEARCH:
      return 'search-landing'
    case ROUTES.LANDING.MERCHANT:
      return 'merchant-landing'
    case 'event_landing':
      return 'event-landing'
    case 'event_home':
      return 'event-home'
    default:
      return name
  }
}

const stringifyHashObject = (hash) => {
  const cleanedHash = Object.entries(hash).reduce((acc, [key, value]) => {
    if (value === null) {
      return acc
    }

    return { ...acc, [key]: value }
  }, {})

  const stringified = stringify(cleanedHash)

  return stringified ? `#${stringified}` : ''
}

export default class SearchParams {
  parameters = {
    [FACET_PAGE]: '1',
  }

  whitelist = [FACET_PRICE, FACET_PAGE, FACET_QUERY, FACET_SORT]

  static fromFilters({ context, page, q, paramsWhitelist, ...hashValues }) {
    const query = removeEmptyValuesInObject({ page, q })
    const hash = stringifyHashObject(hashValues)

    return new SearchParams({
      context,
      query,
      hash,
      paramsWhitelist,
      tags: [],
    })
  }

  constructor({
    context,
    query = {},
    hash = '',
    paramsWhitelist = [],
    tags = [],
  }) {
    this.context = { ...context }

    this.whitelist = [...this.whitelist, ...paramsWhitelist]

    this.parameters = {
      ...this.parameters,
      ...query,
      ...parsedHash(hash),
    }

    this.tags = tags
  }

  get whitelistedParameters() {
    return Object.entries(this.parameters).reduce((acc, [key, value]) => {
      if (this.whitelist.includes(key)) {
        return { ...acc, [key]: value }
      }

      return acc
    }, {})
  }

  get graphParameters() {
    const { [FACET_PRICE]: price, ...rest } = this.whitelistedParameters

    return rest
  }

  get hash() {
    const { page, q, ...hash } = this.whitelistedParameters

    return hash
  }

  get page() {
    return this.whitelistedParameters.page
  }

  toFilters() {
    return this.whitelistedParameters
  }

  hasUserFilters() {
    if (Number(this.page) > 1) {
      return true
    }

    return !isEmpty(this.hash)
  }

  // Based on Vue router push params API: https://router.vuejs.org/guide/essentials/navigation.html
  toQueryParams() {
    const { page, q, ...params } = this.whitelistedParameters
    const hasPagination = page && Number(page) > 1

    return {
      query: removeEmptyValuesInObject({
        // Don't add the page = 1 in the URL
        page: hasPagination ? encodeURIComponent(page) : null,
        q,
      }),
      hash: stringifyHashObject(params),
    }
  }

  hasHashParams() {
    return !isEmpty(this.toQueryParams().hash)
  }

  get activeFacets() {
    return omit(this.whitelistedParameters, [
      FACET_SORT,
      FACET_QUERY,
      FACET_PAGE,
    ])
  }

  get activeFacetsCount() {
    return Object.keys(this.activeFacets).length
  }

  get contextId() {
    return hashObjects(this.context)
  }

  get graphId() {
    return hashObjects(this.context, this.graphParameters)
  }

  get searchId() {
    return hashObjects(this.context, this.whitelistedParameters)
  }
}
