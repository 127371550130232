
import BBBLogo from './BBBLogo.vue'
import BCorpLogo from './BCorpLogo.vue'
import BestECommerceWebsiteLogo from './BestECommerceWebsiteLogo.vue'
import CustomerServiceOfTheYearLogo from './CustomerServiceOfTheYearLogo.vue'
import { LOGOS } from './footerConfig'

export default {
  components: {
    BCorpLogo,
    BBBLogo,
    CustomerServiceOfTheYearLogo,
    BestECommerceWebsiteLogo,
  },
  props: {
    logos: { type: Array, required: true },
  },
  data: () => ({
    BCORP: LOGOS.BCORP,
    BBB: LOGOS.BBB,
    CUSTOMER_SERVICE_OF_THE_YEAR: LOGOS.CUSTOMER_SERVICE_OF_THE_YEAR,
    BEST_ECOMMERCE_WEBSITE: LOGOS.BEST_ECOMMERCE_WEBSITE,
  }),
}
