/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty'

import { getDefaultLocaleFromCountry } from '@core/helpers/countries'
import { formatDateToYYYYMMDD } from '@core/helpers/date'
import { ALL_CATEGORIES } from '@seller/sub-modules/onboarded/pages/international-onboarding/InternationalOnboarding.constant'

export const mapToAddress = (address) => {
  const trim = (value) => (value || '').trim().replace(/\s/g, ' ')

  return {
    first_name: trim(address.firstName) || null,
    first_name_pronunciation: trim(address.firstNamePronunciation) || null,
    last_name: trim(address.lastName) || null,
    last_name_pronunciation: trim(address.lastNamePronunciation) || null,
    company: trim(address.company) || null,
    street: trim(address.street) || null,
    street2: trim(address.street2) || null,
    city: trim(address.city) || null,
    state_or_province: trim(address.stateOrProvince) || null,
    postal_code: trim(address.postalCode) || null,
    country: trim(address.country) || null,
    country_dial_in_code: trim(address.countryDialInCode) || null,
    phone: trim(address.phone) || null,
    birthdate: formatDateToYYYYMMDD(trim(address.birthdate)) || null,
    nationality: trim(address.nationality) || null,
    customer_id_number: trim(address.customerIdNumber) || null,
  }
}

export const mapToClientAddress = ({ isShipping, isBilling, ...address }) => ({
  ...(isShipping
    ? { is_shipping: true, same_as_shipping: !!isBilling }
    : { is_shipping: false }),
  ...mapToAddress(address),
})

export const mapToInternationalOnboarding = (payload) => {
  const finalPayload = { ...payload }

  if (!isEmpty(payload.shipping) && Array.isArray(payload.shipping)) {
    const categoryShipping = payload.shipping.filter(
      ({ category }) => category !== ALL_CATEGORIES,
    )

    const globalShipping = payload.shipping.filter(
      ({ category }) => category === ALL_CATEGORIES,
    )

    finalPayload.shipping = { globalShipping, categoryShipping }
  }

  return finalPayload
}

export const mapToDiscount = (payload) => ({
  ...payload,
  country_code: getDefaultLocaleFromCountry(payload.country_code),
})

export const mapToCreateBalanceAdjustment = (payload) => ({
  merchant_id: payload.merchantId,
  action: payload.action,
  mention: payload.mention,
  designation: payload.designation,
  amount: payload.amount,
  currency: payload.currency,
  comments: payload.comments,
  force_psp: payload.forcePsp,
})
