
import { RevButton } from '@ds/components/Button'
import { RevModal } from '@ds/components/Modal'

import KlarnaLogo from './KlarnaLogo.svg'
import translations from './KlarnaPaymentSummaryModal.translations'

const KLARNA_LOGIN_URL = 'https://app.klarna.com/login'

export default {
  components: {
    RevButton,
    KlarnaLogo,
    RevModal,
  },

  props: {
    name: {
      type: String,
      default: 'KlarnaPaymentSummaryModal',
    },
  },

  data: () => ({
    toKlarnaLogin: {
      type: 'external',
      href: KLARNA_LOGIN_URL,
    },
  }),

  computed: {
    translations: () => translations,
  },
}
