import { COOKIES, DEFAULT_STATE, TYPES } from '@config/constants'
import { isUndefined } from '@core/helpers'
import { setCookie } from '@core/helpers/cookies'
import { retry } from '@core/helpers/retry'
import { saveCookiesSettings } from '@http/endpoints'
import { COUNTRIES_WITHOUT_MODAL } from '@legal/constants'
import { trackGDPR } from '@tracking/events'

import { createConfig } from './config'

export default {
  namespaced: true,
  state() {
    return {
      ...DEFAULT_STATE,
      isOpen: false,
    }
  },
  getters: {
    all: ({ isOpen, ...state }) => state,
    isOpen: (state) => state.isOpen,
  },
  mutations: {
    set(state, { key, value }) {
      state[key] = value
    },
  },
  actions: {
    closeModal({ commit }) {
      commit('set', { key: 'isOpen', value: false })
    },

    load({ commit, rootGetters }, { legalCookies }) {
      // Update the state with the values from the session cookies
      legalCookies.forEach(({ key, value }) => {
        commit('set', { key, value })
      })

      const legalCookiesNames = legalCookies.map(({ key }) => key)

      // Find the cookies keys that are not in the session cookies
      const missingCookies = Object.keys(COOKIES).filter(
        (cookieName) => !legalCookiesNames.includes(cookieName),
      )

      const isOpen = missingCookies.length > 0

      if (COUNTRIES_WITHOUT_MODAL.includes(rootGetters['config/country'])) {
        missingCookies.forEach((cookieName) => {
          commit('set', { key: cookieName, value: true })
        })
      } else {
        commit('set', { key: 'isOpen', value: isOpen })
      }
    },

    setSessionCookies({ commit, rootGetters }, choices) {
      Object.entries(COOKIES).forEach(([key, cookie]) => {
        const defaultValue = COUNTRIES_WITHOUT_MODAL.includes(
          rootGetters['config/country'],
        )
        const value = isUndefined(choices[key]) ? defaultValue : choices[key]

        commit('set', {
          key,
          value,
        })

        setCookie(cookie, value, createConfig())
      })
    },

    async save(
      { state = DEFAULT_STATE, dispatch, rootGetters },
      settings = {},
    ) {
      try {
        const identifier = rootGetters['config/visitorId']

        const choices = Object.keys(COOKIES).reduce(
          (acc, key) => ({
            ...acc,
            [key]: isUndefined(settings[key]) ? state[key] : settings[key],
          }),
          { [TYPES.FUNCTIONAL]: true },
        )

        await retry(() =>
          dispatch(
            'http/request',
            {
              request: saveCookiesSettings,
              body: {
                identifier,
                choices,
              },
            },
            {
              root: true,
            },
          ),
        )

        trackGDPR(choices)

        dispatch('setSessionCookies', choices)
      } catch (cause) {
        throw new Error('Retry failed', { cause })
      }
    },
  },
}
