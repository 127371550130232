
import clamp from 'lodash/clamp'

import { priceToMinorUnits } from '@core/helpers/price'
import logger from '@logger'

import {
  AFFIRM_MAXIMAL_AMOUNT,
  AFFIRM_MINIMAL_AMOUNT,
  AFFIRM_MODAL_SETTINGS,
} from '../config/affirm'
import { PAYMENT_ADVERTISING_LOG_TYPES } from '../config/constants'
import { getAffirmSdk } from '../helpers/affirm'

export default {
  props: {
    name: {
      type: String,
      default: 'AffirmModal',
    },

    /**
     * @typedef {Price}
     */
    basePrice: {
      type: Object,
      required: true,
    },

    /**
     * See PAYMENT_ADVERTISING_TYPES
     */
    variant: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    affirm: null,
    opened: false,
  }),

  computed: {
    settings() {
      return AFFIRM_MODAL_SETTINGS[this.variant] || {}
    },

    amount() {
      return this.settings.requiresAmount
        ? clamp(
            priceToMinorUnits(this.basePrice),
            AFFIRM_MINIMAL_AMOUNT,
            AFFIRM_MAXIMAL_AMOUNT,
          )
        : undefined
    },
  },

  watch: {
    basePrice() {
      if (this.affirm) {
        this.affirm.ui.refresh()
      }
    },
  },

  methods: {
    async tryInitializeAffirmSdk() {
      if (this.affirm) {
        return true
      }

      logger.info('[PAYMENT-ADVERTISING] Try initialize Affirm library', {
        type: PAYMENT_ADVERTISING_LOG_TYPES.LIBRARY_INITIALIZATION_START,
        modal: this.name,
      })

      try {
        // Lazy-load Affirm SDK
        this.affirm = await getAffirmSdk(this.$config)

        this.affirm.events.on('prequal:close', () => {
          this.close()
        })
        this.affirm.ui.ready(async () => {
          this.affirm.ui.refresh()
        })

        return true
      } catch (error) {
        logger.error(
          '[PAYMENT-ADVERTISING] Unable to initialize Affirm library',
          {
            error,
            type: PAYMENT_ADVERTISING_LOG_TYPES.LIBRARY_INITIALIZATION_ERROR,
            modal: this.name,
          },
        )

        return false
      }
    },

    async open() {
      if (!this.opened && (await this.tryInitializeAffirmSdk())) {
        this.affirm.ui.refresh()
        this.opened = true
        this.$refs.link.click()
      }
    },

    close() {
      this.opened = false
    },
  },
}
