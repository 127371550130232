import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_STUDENT_DISCOUNT = '' } = config

  const isFrontAppsRouteOpenedInCountry =
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_STUDENT_DISCOUNT.includes(country)

  return [
    {
      path: 'student-discount',
      name: ROUTES.STUDENT_DISCOUNT,
      component: () => dynamicImport(import('./pages/StudentDiscount.vue')),
      meta: {
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
      },
    },
  ]
}
