import { events as amplitudeTrakers } from './amplitude'
import { events as brazeTrakers } from './braze'
import { TRACKING_PROVIDERS } from './constants'
import { events as gaTrackers } from './google-analytics'
import { events as loggerTrakers } from './logger'
import { events as noodleTrakers } from './noodle'

const trackers = {
  [TRACKING_PROVIDERS.NOODLE]: noodleTrakers,
  [TRACKING_PROVIDERS.GA]: gaTrackers,
  [TRACKING_PROVIDERS.AMPLITUDE]: amplitudeTrakers,
  [TRACKING_PROVIDERS.BRAZE]: brazeTrakers,
  [TRACKING_PROVIDERS.LOGGER]: loggerTrakers,
}

export const createTrackEvent =
  ({ providers = [], name }) =>
  (...args) => {
    providers.forEach((provider) => {
      if (!(provider in trackers)) {
        throw new Error(`missing provider ${provider} in the tracking module`)
      }

      if (!(name in trackers[provider])) {
        throw new Error(
          `missing tracker ${name} in the ${provider} tracking list`,
        )
      }

      trackers[provider][name](...args)
    })
  }
