import isEmpty from 'lodash/isEmpty'

import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

function preserveOriginQuery(to, from, next) {
  if (!isEmpty(from.query) && isEmpty(to.query)) {
    next({ ...to, query: from.query })
  } else next()
}

export default () => {
  return [
    {
      name: ROUTES.AUTH_OAUTH2.LOGIN,
      path: '/auth/login',
      beforeEnter: preserveOriginQuery,
      component: () => dynamicImport(import('./pages/auth/Login.vue')),
    },
  ]
}
