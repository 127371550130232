import { ROUTES } from '@router'

import { Home } from '.'

export default ({ locale }) => {
  return [
    {
      path: `/:locale(${locale})`,
      component: Home,
      name: ROUTES.HOME,
      meta: { disableClientSideNavigation: true },
    },
  ]
}
