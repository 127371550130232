import { AUTH_CONFIG } from '@auth-oauth2/config'
import { AuthOauth2 } from '@auth-oauth2/core/auth'
import { getClientId } from '@auth-oauth2/core/clientId'
import { getIssuerUrl } from '@auth-oauth2/core/issuerUrl'
import localStorageWithCookieFallback from '@core/helpers/localStorageWithCookieFallback'
import sessionStorage from '@core/helpers/sessionStorage'

export default function authOauthPluginClient(ctx, inject) {
  const options = { ...AUTH_CONFIG }

  // set auth issuer url dynamically depending on the host
  options.domainBaseUrl = getIssuerUrl(ctx.store.getters['config/apiBaseUrl'])

  // set client id dynamically depending on the region
  options.clientId = getClientId(
    ctx.store.getters['config/apiBaseUrl'],
    ctx.store.getters['config/marketplace'],
  )

  // set the authorizationEndpoint dynamically if the FF is enabled
  // Todo: remove this when the FF is removed
  options.authorizationEndpoint =
    ctx.store.getters['auth/authorizationEndpoint']

  // set the socialLoginValidationStepExperiment dynamically if the FF is enabled
  // Todo: remove this when the FF is removed
  options.socialLoginValidationStepExperiment =
    ctx.store.getters['auth/socialLoginValidationStepExperiment']

  const $auth = new AuthOauth2(
    options,
    ctx,
    sessionStorage,
    localStorageWithCookieFallback,
  )

  inject('auth', $auth)

  // if the new auth stack is not enabled, we don't need to fetch endpoints
  if (!ctx.store.getters['auth/isNewAuthEnabled']) {
    return
  }

  // fetch endpoints from issuer url when the browser is idle
  if (window.requestIdleCallback) {
    window.requestIdleCallback(() => {
      $auth.getEndpoints()
    })
  } else {
    $auth.getEndpoints()
  }
}
