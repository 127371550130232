export const BUYBACK_MIN_AGE = 18
export const BUYBACK_MIN_YEAR = 1901

export const BUYBACK_KIND = {
  REGULAR: 'regular',
  SWAP: 'swap',
}

export const SHIPPING_MODE = {
  KIT: 'BULLE',
  PREPAID_LABEL: 'COLISSIMO',
}

export const ACTION_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
}

export const TOAST_VARIANTS = {
  NEUTRAL: 'neutral',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const PDF_EXT = 'pdf'
export const MESSAGE_COUNTEROFFER_MAX_LENGTH = '200'
export const MESSAGE_CONVERSATION_MAX_LENGTH = '2000'
// File input validation rules, same for all buyback endpoints receiving files
export const ALLOWED_FILES_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.pdf']
export const CSV_FILE_EXTENSION = ['.csv']
export const MAX_FILE_SIZE = 5 * 1024 * 1024
export const MAX_FILES_COUNT = 5
export const ERROR_TYPES = {
  MAX_FILES_EXCEEDED: 'MAX_FILES_EXCEEDED',
  MAX_FILE_SIZE_EXCEEDED: 'MAX_FILE_SIZE_EXCEEDED',
  MAX_FILES_SIZE_EXCEEDED: 'MAX_FILES_SIZE_EXCEEDED',
  WRONG_FILE_EXTENSION: 'WRONG_FILE_EXTENSION',
}

export const CONVERSATION_USERS = {
  CUSTOMER: 'customer',
  REFURBISHER: 'refurbisher',
  STAFF: 'staff',
}

export const COUNTEROFFER_ROLES = {
  ADMIN: 'ADMIN',
  MERCHANT: 'MERCHANT',
}
