import { sleep } from '@core/helpers'

export async function retry(func, retriesAvailable = 5, interval = 200) {
  try {
    const response = await func()

    return response
  } catch (error) {
    if (retriesAvailable > 0) {
      await sleep(interval)

      return retry(func, retriesAvailable - 1, interval)
    }
    throw error
  }
}
