import flatMap from 'lodash/flatMap'

export const DESCRIPTION_TAGS = [
  'description',
  'og:description',
  'twitter:description',
]
export const TITLE_TAGS = ['og:title', 'twitter:title']
export const IMAGE_TAGS = ['og:image', 'twitter:image', 'logo']

export const NOINDEX_META_TAG = {
  hid: 'robots',
  name: 'robots',
  content: 'noindex',
}

const buildMeta = ({ tag, content }) => {
  return {
    hid: tag,
    name: tag,
    content,
  }
}

export const createMeta = ({ tags = [], content }) => {
  return tags.map((tag) => buildMeta({ tag, content }))
}

export const createMetaForGroupedTags = (args = []) => {
  return flatMap(args, ({ tags, content }) => {
    return createMeta({ tags, content })
  })
}

export const createSocialMediaTagsFor = (socialMedia) => {
  return Object.entries(socialMedia).map(([key, content]) => {
    const tag = `${key}:profile`

    return buildMeta({ tag, content })
  })
}
