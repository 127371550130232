import isEmpty from 'lodash/isEmpty'

/**
 * Applies the layout of the child page when it is nested under the locale prefix.
 */

const overrideLayout = ({ route, store }) => {
  const locale = store.getters['config/locale']
  // If the route is nested under the locale prefix
  const isRouteNotEmpty = route.matched.length
  const isParentRouteALocale = route.matched[0]?.path === `/:locale(${locale})`
  const isChildComponentPresent = !isEmpty(route.matched[1]?.components)

  if (isRouteNotEmpty && isParentRouteALocale && isChildComponentPresent) {
    const parent = route.matched[0]?.components
    const child = route.matched[1]?.components

    // Get the layout of the page component
    const pageLayout = child.default.options.layout

    // Set the layout to the parent locale component
    parent.default.options.layout = pageLayout
  }
}

export default overrideLayout
