
import { mapGetters } from 'vuex'

import FooterIcons from './FooterIcons.vue'
import FooterLink from './FooterLink.vue'
import FooterLogos from './FooterLogos.vue'
import FooterPayment from './FooterPayment.vue'
import FooterSocials from './FooterSocials.vue'
import { TYPES } from './footerConfig'

export default {
  components: {
    FooterLink,
    FooterLogos,
    FooterIcons,
    FooterSocials,
    FooterPayment,
  },
  props: {
    items: { type: Array, required: true },
  },
  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    TYPES: () => TYPES,
  },
}
