/* eslint-disable camelcase */
import { BRAZE_CONSENT_COOKIE } from '@config/constants/gdpr_cookies'
import { setCookie } from '@core/helpers/cookies'
import { createConfig } from '@legal/store/config'

import {
  changeUser,
  getSdk,
  setGoogleAdCustomAttributes,
  updateSdk,
} from './braze'
import { EVENT_PLATFORM } from './constants'
import * as DEFINITIONS from './definitions'
import { productModel, productModelCollection } from './models/product'

export const gdpr = ({ userExperience, advertising }) => {
  const brazeSDK = getSdk()
  if (!brazeSDK) return

  const hasUserConsent = userExperience && advertising

  // Order is important
  // Braze SDK needs to be enabled first (via `updateSdk` function),
  // before user's preferences can be sent
  if (hasUserConsent) {
    updateSdk({ optIn: hasUserConsent })
    setGoogleAdCustomAttributes({ hasUserConsent })

    // We're setting a cookie that will be used to evaluate if we need
    // to open the cookie modal to request users' consent for new Google consent policy
    // its value is 'true' regardless of user's choice,
    // it just records that the choice has been updated
    // We're using a cookie because the logic is done server-side where there's no localStorage
    // see https://www.google.com/about/company/user-consent-policy/,
    // see also https://www.braze.com/docs/partners/canvas_steps/google_audience_sync#collecting-consent-for-eea-and-uk-end-users)
    setCookie(BRAZE_CONSENT_COOKIE, 'true', createConfig())

    return
  }

  // User didn't give consent

  // ...but the SDK is already disabled anyway
  if (brazeSDK.isDisabled()) {
    return
  }

  // Order is important:
  // user's preferences need to be sent before disabling the SDK
  setGoogleAdCustomAttributes({ hasUserConsent })
  setCookie(BRAZE_CONSENT_COOKIE, 'true', createConfig())

  brazeSDK.requestImmediateDataFlush()
  updateSdk({ optIn: hasUserConsent })
}

export const userContext = async ({ clientId }) => {
  if (!getSdk() || !clientId) return

  changeUser()
}

export const product = ({ product: p }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.PRODUCT
  const payload = {
    ...productModel(p),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const addToCart = async ({ product: p }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.ADD_TO_CART
  const payload = {
    ...productModel(p),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const addToCartBatch = async ({ products }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.ADD_TO_CART
  const payload = {
    products: productModelCollection(products),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const buybackDeviceSpecifications = async ({ product: p }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.BUYBACK_DEVICE_SPECIFICATIONS
  const payload = {
    ...productModel(p),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const searchTerm = async (query) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.SEARCH_TERM
  const payload = {
    search_term: query,
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const favoritesPageView = async ({ favorites }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.FAVORITES_PAGE_VIEW
  const payload = {
    number_of_items_on_list: favorites.length,
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const addToMyFavorites = (favorite) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.ADDED_TO_FAVORITES

  const payload = {
    ...productModel(favorite),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}
