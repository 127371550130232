export default {
  downAmount: {
    id: 'installment_simulation_down_amount',
    defaultMessage: '{amount} contribution',
  },
  wording: {
    id: 'installment_simulation_wording_line',
    defaultMessage: 'Levy #{number} of {amount}',
  },
  total: {
    id: 'installment_simulation_total_only',
    defaultMessage: 'Total: {total}',
  },
  cost: {
    id: 'installment_simulation_cost',
    defaultMessage: '(financing cost: {cost})',
  },
  loading: {
    id: 'installment_simulation_loading',
    defaultMessage: 'Loading',
  },
}
