import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ config, country }) => {
  const areReviewsFrontAppsRoutesOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEWS.includes(country)

  const isReviewCollectionFrontAppsRouteOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEW_COLLECTION.includes(
      country,
    )

  const Reviews = () =>
    dynamicImport(
      import('@internal-reviews/pages/reviews-landing/ReviewsLanding'),
    )

  return [
    // Review form
    {
      name: ROUTES.REVIEWS.FORM,
      path: 'review/:id',
      component: () =>
        dynamicImport(
          import('@internal-reviews/components/Form/ConnectedForm.vue'),
        ),
      meta: {
        disableClientSideNavigation:
          isReviewCollectionFrontAppsRouteOpenedInCountry,
      },
    },
    // Review pages
    {
      name: ROUTES.REVIEWS.PAGES.LANDING,
      path: 'r/l/:landingSlug/:landingId',
      component: Reviews,
      meta: {
        disableClientSideNavigation: areReviewsFrontAppsRoutesOpenedInCountry,
      },
    },
    {
      name: ROUTES.REVIEWS.PAGES.MERCHANT,
      path: 'r/s/:sellerSlug/:sellerId',
      component: Reviews,
      meta: {
        disableClientSideNavigation: areReviewsFrontAppsRoutesOpenedInCountry,
      },
    },
    // Always go through the Ingress because this route is handled only by front-office
    {
      name: ROUTES.REVIEWS.PAGES.NEW_PRODUCT,
      path: 'r/p/:slugV2/:uuid',
      component: null,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    // Never go through the Ingress because this route is not handled by front-office
    {
      name: ROUTES.REVIEWS.PAGES.PRODUCT,
      path: 'r/p/:productId',
      component: Reviews,
      meta: {
        disableClientSideNavigation: false,
      },
    },
    {
      name: ROUTES.REVIEWS.PAGES.ALL,
      path: 'r',
      component: Reviews,
      meta: {
        disableClientSideNavigation: areReviewsFrontAppsRoutesOpenedInCountry,
      },
    },
  ]
}
