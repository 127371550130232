
import { RevBanner } from '@ds/components/Banner'
import { mapGetters } from 'vuex'

import { trackClick } from '@tracking/events'

import translations from './SkinnyBanner.translations'

export default {
  components: {
    RevBanner,
  },

  props: {
    page: {
      type: String,
      required: true,
    },
    bannerId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      skinnyBanner: 'countryConfig/skinnyBanner',
      isDisplayed: 'countryConfig/isSkinnyBannerDisplayed',
      experiments: 'flags/getExperiments',
    }),

    translations: () => translations,

    props() {
      const {
        cta_text: cta,
        text,
        name,
        target_blank: blank,
        link,
      } = this.skinnyBanner(this.page, this.bannerId)

      return {
        to: link,
        label: text,
        cta,
        name,
        target: blank ? '_blank' : '_self',
        closeAlternativeText: this.$t(translations.closeAlternativeText),
      }
    },

    shouldDisplaySkinnyBanner() {
      return (
        this.isDisplayed &&
        this.experiments.removeSkinnyBanner !== 'withoutSkinnyBanner'
      )
    },
  },

  methods: {
    sendMarketingData() {
      trackClick({
        name: this.props.name,
        zone: 'skinnyBanner',
      })
    },

    closeBanner() {
      this.$store.dispatch('countryConfig/closeSkinnyBanner')
    },
  },
}
