import isEmpty from 'lodash/isEmpty'

import { ERROR_RESPONSE_VERSIONS } from '../apiConstants'

// This function will be removed when all the endpoint will be version3
const formatErrorResponseV2 = (response) => {
  return {
    code: response.data.errors[0].code,
  }
}

const formatErrorResponseV3 = (response) => {
  return {
    status: response.data.status,
    title: response.data.title,
    type: response.data.type,
    errors: !isEmpty(response.data.errors) ? response.data.errors : [],
  }
}

const getFormatErrorResponse = (error) => {
  const { response = {} } = error

  if (!isEmpty(response.data)) {
    if (!isEmpty(response.data.title)) {
      return ERROR_RESPONSE_VERSIONS.v3
    }

    if (!isEmpty(response.data.errors)) {
      return ERROR_RESPONSE_VERSIONS.v2
    }

    return ERROR_RESPONSE_VERSIONS.v1
  }

  return ERROR_RESPONSE_VERSIONS.unknown
}

export const formatErrorResponse = (error) => {
  const errorVersion = getFormatErrorResponse(error)

  switch (errorVersion) {
    case ERROR_RESPONSE_VERSIONS.v3:
      return formatErrorResponseV3(error.response)

    case ERROR_RESPONSE_VERSIONS.v2:
      return formatErrorResponseV2(error.response)

    default:
      return {}
  }
}
