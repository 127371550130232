import isEmpty from 'lodash/isEmpty'

export const valueBuilder = (params) =>
  params.filter((value) => !isEmpty(value)).join('_')

export const sourceTypeForProductsTracking = ({
  routeName,
  pageName,
  blockPosition,
}) => {
  return `${pageName || routeName} ${blockPosition}`
}

export const trackingData = ({
  pageName,
  pageType,
  pageCategory,
  blockName = 'UNKNOWN',
}) => ({
  zone: valueBuilder([pageType, pageName, pageCategory]),
  value: blockName,
})
