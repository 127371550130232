
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevModal } from '@ds/components/Modal'
import { RevPicker } from '@ds/components/Picker'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import FormattedMessage from '@i18n/components/FormattedMessage'

import {
  paymentGroupConfig,
  paymentGroupIcon,
  paymentGroupLabel,
  paymentMethodLabel,
} from '../../methods'

import InstallmentSimulation from './InstallmentSimulation.vue'
import translations from './InstallmentSimulationModal.translations'

export default {
  components: {
    RevLink,
    FormattedMessage,
    RevIllustration,
    InstallmentSimulation,
    RevModal,
    RevPicker,
  },

  props: {
    name: {
      type: String,
      default: 'InstallmentSimulationModal',
    },

    paymentGroupId: {
      type: String,
      required: true,
    },

    paymentMethods: {
      type: Array,
      default: null,
    },

    /**
     * @typedef {Price}
     */
    basePrice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedMethodIndex: -1,
    }
  },

  computed: {
    translations: () => translations,

    ...mapGetters({
      country: 'config/country',
      countryPaymentMethods: 'countryConfig/paymentMethods',
    }),

    groupLabel() {
      return paymentGroupLabel(this.paymentGroupId)
    },

    groupIcon() {
      return paymentGroupIcon(this.paymentGroupId)
    },

    steps() {
      return [
        {
          title: translations.step1Title,
          description: translations.step1Description,
          withIcon: true,
        },
        {
          title: translations.step2Title,
          description: translations.step2Description,
          withIcon: true,
        },
        {
          title: translations.step3Title,
          description: translations.step3Description,
          withIcon: false,
        },
      ]
    },

    paymentGroupMethods() {
      const allMethods = this.paymentMethods || this.countryPaymentMethods

      return allMethods.filter(
        (paymentMethod) => paymentMethod.group === this.paymentGroupId,
      )
    },

    hasManyMethods() {
      return this.paymentGroupMethods.length > 1
    },

    selectedMethod() {
      return this.paymentGroupMethods[this.selectedMethodIndex]
    },

    footerValues() {
      return get(
        paymentGroupConfig(this.paymentGroupId),
        `legalNotices[${this.country}]`,
      )
    },

    hasFooterValues() {
      return !isEmpty(this.footerValues)
    },
  },

  methods: {
    paymentMethodLabel,

    isSelected(index) {
      return index === this.selectedMethodIndex
    },

    handleMethodPickerSelect(index) {
      this.selectedMethodIndex = index
    },

    handleOpen() {
      this.selectedMethodIndex = 0
    },
  },
}
