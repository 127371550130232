
import { COMPANY_NAME } from '@config/constants'
import AppStoreButtons from '@core/components/AppStoreButtons'

import Flags from './Flags.vue'

export default {
  components: { AppStoreButtons, Flags },
  computed: {
    COMPANY_NAME: () => COMPANY_NAME,
    year: () => new Date().getFullYear(),
  },
}
