import isEmpty from 'lodash/isEmpty'

import { isBrowser } from '@core/helpers'

export const canUseLocalStorage = () => isBrowser() && window.localStorage

export default {
  setItem: (key, value) => {
    try {
      if (canUseLocalStorage()) {
        window.localStorage.setItem(key, value)
      }
    } catch {
      // fails silently
    }
  },
  getItem: (key) => {
    try {
      if (canUseLocalStorage()) {
        return window.localStorage.getItem(key)
      }
    } catch {
      // fails silently
    }

    return null
  },
  removeItem: (key) => {
    try {
      if (canUseLocalStorage()) {
        return window.localStorage.removeItem(key)
      }
    } catch {
      // fails silently
    }

    return null
  },
  hasKey: (key) => {
    try {
      if (canUseLocalStorage()) {
        return !isEmpty(window.localStorage.getItem(key))
      }
    } catch {
      // fails silently
    }

    return null
  },
}
