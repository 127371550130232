import { COUNTRIES } from '@config/constants'

/**
 * Creates htmlAttrs properties of the Homepage depending on the current country
 * see https://schema.org/Organization and https://schema.org/Corporation
 */
export const getHtmlAttrsByCountry = (country) => {
  const schemaType = country === COUNTRIES.US ? 'Organization' : 'Corporation'

  return {
    itemscope: 'itemscope',
    itemtype: `http://schema.org/${schemaType}`,
  }
}
