export default {
  text: {
    id: 'error_page_text',
    defaultMessage:
      'So why not shop around and check out some of our sweet deals?',
  },
  cta: {
    id: 'error_page_cta',
    defaultMessage: 'Click here to shop',
  },
}
