/**
 * @typedef {(methods: import('@payment').PaymentMethod[]) => { isAvailable: boolean, forMethods: import('@payment').PaymentMethod[] }} IsAvailableForPredicate
 */

/**
 * Payment advertising config helper e.g: 'isAvailableFor: availableWhenAnyOf(PAYMENT_METHODS.PAYPAL, ...)'
 * @param {string[]} methodIds
 * @returns {IsAvailableForPredicate}
 */
export const availableWhenAnyOf =
  (...methodIds) =>
  (methods) => {
    const availableMethods = methods.filter((method) =>
      methodIds.includes(method.bmCode),
    )

    return {
      isAvailable: availableMethods.length > 0,
      forMethods: availableMethods,
    }
  }

/**
 * Payment advertising config helper e.g: 'isAvailableFor: availableWith(PAYMENT_METHODS.PAYPAL)'
 * @param {string} methodId
 * @returns {IsAvailableForPredicate}
 */
export const availableWith = (methodId) => (methods) => {
  const forMethod = methods.find((m) => m.bmCode === methodId)

  const isAvailable = Boolean(forMethod)

  return {
    isAvailable,
    forMethods: isAvailable ? [forMethod] : [],
  }
}

/**
 * Payment advertising config helper e.g: 'isAvailableFor: availableWhenAllOf(PAYMENT_METHODS.PAYPAL, ...)'
 * @param {string[]} methodIds
 * @returns {IsAvailableForPredicate}
 */
export const availableWhenAllOf =
  (...methodIds) =>
  (methods) => {
    const availableMethods = methods.filter((method) =>
      methodIds.includes(method.bmCode),
    )

    const isAvailable =
      new Set(availableMethods.map((m) => m.bmCode)).size ===
      new Set(methodIds).size

    return {
      isAvailable,
      forMethods: isAvailable ? availableMethods : [],
    }
  }
