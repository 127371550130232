
import { RevLink } from '@ds/components/Link'
import { IconShoppingBag } from '@ds/icons/IconShoppingBag'
import { mapGetters } from 'vuex'

import {
  HEADER_TRACKING_NAME,
  HEADER_TRACKING_ZONE,
} from '@navigation/constant'
import { ROUTES } from '@router'
import { trackClick } from '@tracking/events'

import translations from './CartIconAndBubble.translations'

export default {
  components: {
    RevLink,
    IconShoppingBag,
  },

  computed: {
    ...mapGetters({
      cartItemsCount: 'user/cartItemsCount',
    }),
    ROUTES: () => ROUTES,
    HEADER_TRACKING_NAME: () => HEADER_TRACKING_NAME,
    HEADER_TRACKING_ZONE: () => HEADER_TRACKING_ZONE,
    hasCartItems() {
      return this.cartItemsCount > 0
    },
    translations: () => translations,
  },
  methods: {
    trackClick(name) {
      trackClick({
        zone: this.HEADER_TRACKING_ZONE,
        name,
      })
    },
  },
}
