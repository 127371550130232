const getDefaultState = () => ({
  isLoading: true,
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    get: (state) => state.isLoading,
  },
  mutations: {
    set: (state, data) => {
      state.isLoading = data
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    set: ({ commit }, datas) => commit('set', datas),
    reset: ({ commit }) => commit('reset'),
  },
}
