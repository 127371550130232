import { getLink } from '@router/links'

import { ALERTS, STOCK } from './Listings.constants'

export function toAPIFilters({
  title,
  sku,
  uuid,
  backmarketId,
  grade,
  buybox,
  stock,
  alert,
  market,
  categoryId,
  hasNewBattery,
}) {
  const filters = { title, sku, buybox, categoryId, hasNewBattery }

  if (uuid) {
    filters.productId = uuid
  }

  if (backmarketId) {
    filters.bmId = backmarketId
  }

  if (grade) {
    filters.aestheticGrade = grade
  }

  if (market) {
    filters.market = market
  }

  if (stock) {
    if (stock === STOCK.WITH_STOCK) {
      filters.hasStock = true
    }

    if (stock === STOCK.WITHOUT_STOCK) {
      filters.hasStock = false
    }
  }

  if (alert && alert === ALERTS.PRICE_OVER_NEW) {
    filters.priceOverNew = true
  }

  return filters
}

export async function updateMarketsListingLink({ listing, baseUrl }) {
  const marketLinksPromises = listing.markets.map((market) => {
    return getLink({
      baseUrl,
      link: market.productPageLink,
      country: market.countryCode,
    })
  })

  const marketsLinks = await Promise.all(marketLinksPromises)

  return {
    ...listing,
    markets: listing.markets.map((market, index) => {
      return {
        ...market,
        formattedListingLink: marketsLinks[index]?.href,
      }
    }),
  }
}
