import { dynamicImport } from '@core/helpers'
import logger from '@logger'
import { ROUTES } from '@router'

const logAttempt = (to, from, next) => {
  logger.info(`[Checkout] Attempting to load ${to.name}`)

  next()
}

export const checkoutEmptyRoutes = ({ country, config }) => {
  const isFrontAppsRouteOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_CHECKOUT.includes(country)

  return [
    {
      path: 'f2',
      component: () =>
        dynamicImport(
          import('./modules/layout/pages/CheckoutPageErrorBoundaryWrapper.vue'),
        ),
      children: [
        {
          path: 'cart',
          name: ROUTES.CHECKOUT.CART,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(import('./modules/legacy/pages/cart.vue')),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
          },
        },
        {
          path: 'authentication',
          name: ROUTES.CHECKOUT.AUTHENTICATION,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/account/pages/CheckoutAuthenticationPage.vue'),
            ),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
            guest: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
            },
          },
        },
        {
          path: 'post-authentication',
          name: ROUTES.CHECKOUT.POST_AUTHENTICATION,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import(
                './modules/account/pages/CheckoutPostAuthenticationPage.vue'
              ),
            ),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
            auth: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.AUTHENTICATION },
            },
          },
        },
        {
          path: 'hello',
          name: ROUTES.CHECKOUT.HELLO,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/account/pages/CheckoutHelloPage.vue'),
            ),
          meta: {
            guest: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
            },
          },
        },
        {
          path: 'login',
          name: ROUTES.CHECKOUT.LOGIN,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/account/pages/CheckoutLoginPage.vue'),
            ),
          meta: {
            guest: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
            },
          },
        },
        {
          path: 'signup',
          name: ROUTES.CHECKOUT.SIGNUP,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/account/pages/CheckoutSignupPage.vue'),
            ),
          meta: {
            guest: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
            },
          },
        },
        {
          path: 'shipping/confirmation-address',
          name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import(
                './modules/shipping/pages/CheckoutAddressConfirmationPage.vue'
              ),
            ),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
            auth: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.HELLO },
            },
            showShippingAlerts: true,
          },
        },
        {
          path: 'shipping/shipping-address',
          name: ROUTES.CHECKOUT.SHIPPING_ADDRESS,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/shipping/pages/CheckoutShippingPage.vue'),
            ),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
            auth: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.HELLO },
            },
            showShippingAlerts: true,
          },
        },
        {
          path: 'shipping/billing-address',
          name: ROUTES.CHECKOUT.BILLING_ADDRESS,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/shipping/pages/CheckoutBillingPage.vue'),
            ),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
            auth: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.HELLO },
            },
          },
        },
        {
          path: 'payment',
          name: ROUTES.CHECKOUT.PAYMENT,
          beforeEnter: logAttempt,
          component: () =>
            dynamicImport(
              import('./modules/payment/pages/CheckoutPaymentPage.vue'),
            ),
          meta: {
            disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
            auth: {
              required: true,
              redirection: { name: ROUTES.CHECKOUT.HELLO },
            },
            showShippingAlerts: true,
          },
        },
      ],
    },
  ]
}

export const checkoutDefaultRoutes = ({ country, config }) => {
  const isFrontAppsRouteOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_CHECKOUT.includes(country)

  return [
    // Note: payment result page does not share the same layout with the rest of
    // the checkout funnel pages, so it does not have CheckoutPage as parent
    // route component.
    {
      path: 'f2/payment-result/:paymentId?',
      name: ROUTES.CHECKOUT.PAYMENT_RESULT,
      beforeEnter: logAttempt,
      component: () =>
        dynamicImport(
          import(
            './modules/result/pages/CheckoutPaymentResultPageErrorBoundaryWrapper.vue'
          ),
        ),
      meta: {
        disableClientSideNavigation: isFrontAppsRouteOpenedInCountry,
        auth: {
          required: true,
        },
      },
    },
  ]
}
