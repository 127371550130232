import isEmpty from 'lodash/isEmpty'

import { isBoolean, isNumber } from '@core/helpers'

import {
  ALLOWED_FILTERS,
  DEFAULT_FILTERS,
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
} from './constants'

const SERIALIZATION_SEPARATOR = ','

export const roleValidator = (role) =>
  !isEmpty(role) && Object.values(SAV_PLATFORM_ROLES).includes(role)

export function getNonDefaultFilters(filters = {}, { context } = {}) {
  if (isEmpty(filters)) {
    return {}
  }

  return Object.entries(filters).reduce((filteredFilters, [name, value]) => {
    if (
      !ALLOWED_FILTERS[context].includes(name) ||
      DEFAULT_FILTERS[context][name] === value
    ) {
      return filteredFilters
    }

    return {
      ...filteredFilters,
      [name]: value,
    }
  }, {})
}

export function getNonEmptyFilters(filters = {}) {
  if (isEmpty(filters)) {
    return {}
  }

  return Object.entries(filters).reduce(
    (nonEmptyFilters, [filterName, filterValue]) => {
      if (
        !isBoolean(filterValue) &&
        !isNumber(filterValue) &&
        isEmpty(filterValue)
      ) {
        return nonEmptyFilters
      }

      return {
        ...nonEmptyFilters,
        [filterName]: filterValue,
      }
    },
    {},
  )
}

// Transform array to a string with array values separated by comma
export function serializeArray(array) {
  return array.join(SERIALIZATION_SEPARATOR)
}

// Transform string with values separated by comma to array of values
export function deserializeArray(string) {
  return string.split(SERIALIZATION_SEPARATOR)
}

// Return a dictionnary of serialized filter values from a dictionnary of filters values
export function getSerializedFilters(filters) {
  const serializedFilters = Object.keys(filters).reduce((query, filterName) => {
    const filterValue = filters[filterName]

    if (Array.isArray(filters[filterName])) {
      return { ...query, [filterName]: serializeArray(filterValue) }
    }

    return { ...query, [filterName]: filterValue }
  }, {})

  return serializedFilters
}

export function getContextFromRouteParams(routeParams) {
  return routeParams?.context || SAV_PLATFORM_CONTEXTS.standaloneConversation
}
