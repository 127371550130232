export default {
  namespaced: true,

  state() {
    return {
      isBannerOpen: false,
    }
  },

  getters: {
    isBannerOpen: (state) => state.isBannerOpen,
  },

  mutations: {
    storeIsBannerOpen(state, isBannerOpen) {
      state.isBannerOpen = isBannerOpen
    },
  },

  actions: {
    setIsBannerOpen({ commit }, isBannerOpen) {
      commit('storeIsBannerOpen', isBannerOpen)
    },
  },
}
