
import { RevLink } from '@ds/components/Link'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import BurgerMenuDesktop from '@navigation/components/BurgerMenuDesktop.vue'
import {
  HEADER_TRACKING_NAME,
  HEADER_TRACKING_ZONE,
} from '@navigation/constant'
import { trackClick } from '@tracking/events'

export default {
  components: {
    BurgerMenuDesktop,
    RevLink,
  },
  computed: {
    ...mapGetters({
      topSales: 'countryConfig/sales',
    }),

    // We are gonna keep this legacy logic until the end of the ab-test.
    // We can't easily get rid of the style in the header's admin
    // And it's way easier for the Q/A to keep using data-test during the ab-test (E2E on old header work with data-test)
    // https://backmarket.atlassian.net/browse/GNL-2089
    consolidatedTopSales() {
      return this.topSales
        .filter((sale) => sale.title && !isEmpty(sale.link))
        .map((sale) => {
          return {
            ...sale,
            style: { color: sale.style.text_color },
            test: `topsales-${sale.title}`,
          }
        })
    },
  },

  methods: {
    // We re-use the old method to open the sub navigation
    // It is not a good practice but this was not part of the MVP
    // You can read the spec here
    // https://backmarket.atlassian.net/wiki/spaces/NS/pages/2093088813/P+J+Revamp+header
    toggleSubMenu() {
      this.$store.dispatch('toggleNavigation')
      this.trackBurgerMenuClick()
    },
    trackBurgerMenuClick() {
      trackClick({
        zone: HEADER_TRACKING_NAME.NAVIGATION,
        name: 'all_product',
      })
    },
    trackHorizontalNav(sale, index) {
      trackClick({
        zone: HEADER_TRACKING_ZONE,
        name: `topsales-${index}-${sale.title}`,
      })
    },
  },
}
