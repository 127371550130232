import translations from './date.translations'

/**
 * Indicate if the given date is past.
 *
 * @param {Date | String} date - the date to compare
 * @return {Boolean}
 */
export const isPastDate = (date) =>
  new Date(date).getTime() - new Date().getTime() < 0

/**
 * Indicate if the given date is in the future.
 *
 * @param {Date | String} date - the date to compare
 * @return {Boolean}
 */
export const isFutureDate = (date) =>
  new Date(date).getTime() - new Date().getTime() > 0

export const isDateValid = (date) =>
  date instanceof Date && !Number.isNaN(date.valueOf())

export const createTTL = ({
  months = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}) => {
  const newDate = new Date()
  newDate.setMonth(newDate.getMonth() + Number(months))
  newDate.setHours(newDate.getHours() + Number(hours))
  newDate.setMinutes(newDate.getMinutes() + Number(minutes))
  newDate.setSeconds(newDate.getSeconds() + Number(seconds))
  newDate.setMilliseconds(newDate.getMilliseconds() + Number(milliseconds))

  return newDate
}

/**
 * Function that transforms a date like DD/MM/YYYY or MM-DD-YYYY to YYYY-MM-DD
 * @param {string} date Date with format DD/MM/YYYY or MM-DD-YYYY
 */
export const formatDateToYYYYMMDD = (date) => {
  const isUsDateFormat = date.includes('-') && date.split('-')[0].length === 2
  const euToIso = (dateEu) => dateEu.split('/').reverse().join('-')
  function usToIso(dateUs) {
    const [month, day, year] = dateUs.split('-')

    return `${year}-${month}-${day}`
  }

  return isUsDateFormat ? usToIso(date) : euToIso(date)
}
/**
 * Function that transforms a date like YYYY-MM-DD to DD/MM/YYYY
 * @param {string} date Date with format YYYY-MM-DD
 */
export const formatDateToDDMMYYYY = (date) =>
  date.split('-').reverse().join('/')

/**
 * Function that transforms a date like YYYY-MM-DD to plain text date like Month Day, Year
 *
 * @core/helpers/date cannot import getPlugin from @i18n/utils.
 * It would create a circular dependency,
 * as @i18n/utils already imports @core/helpers/date
 *
 * @param {string} date Date with format YYYY-MM-DD
 */
export const formatDateToLongDate = ($t, date) =>
  $t.date(new Date(date), { year: 'numeric', month: 'long', day: 'numeric' })

/**
 * Returns the difference in hours between two dates
 * @param {Date | String} d1 Date with format YYYY-MM-DDTHH:MM:SS.ssssss+HH:MM
 * @param {Date | String} d2 Date with format YYYY-MM-DDTHH:MM:SS.ssssss+HH:MM
 */
export const diffInHours = (d1, d2) => {
  return Math.abs((new Date(d1).getTime() - new Date(d2).getTime()) / 36e5)
}

/**
 * Returns the difference in months between two dates
 * @param {Date | String} d1 Date with format YYYY-MM-DDTHH:MM:SS.ssssss+HH:MM
 * @param {Date | String} d2 Date with format YYYY-MM-DDTHH:MM:SS.ssssss+HH:MM
 */
export const diffInMonths = (d1, d2) => {
  const d1Date = new Date(d1)
  const d2Date = new Date(d2)
  const d1Month = d1Date.getUTCMonth() + 1
  const d2Month = d2Date.getUTCMonth() + 1
  const d1Year = d1Date.getUTCFullYear()
  const d2Year = d2Date.getUTCFullYear()

  const yearsInMonths = (d2Year - d1Year) * 12

  if (d1Year !== d2Year) {
    return yearsInMonths - 12 + (12 - d1Month) + d2Month
  }

  return d2Month - d1Month
}

/**
 * Function that transforms a months into years
 * returned value fixed to 1 to avoid infinite decimals
 */
export const convertToYear = (durationInMonths = 0) =>
  Math.floor((durationInMonths / 12) * 10) / 10

/**
 * @core/helpers/date cannot import getPlugin from @i18n/utils.
 * It would create a circular dependency,
 * as @i18n/utils already imports @core/helpers/date
 *
 * @param {number} hours
 * @returns {string} A translated string: either "{days} days" when over 72
 * hours, otherwise "{hours}h"
 */
export const formatHoursOrDays = ($t, hours) => {
  if (hours >= 72) {
    return $t(translations.hoursInDays, { days: Math.ceil(hours / 24) })
  }

  return $t(translations.hoursInHours, { hours })
}

/**
 * @param {Date | string} date The date to truncate
 * returns {Date} The given date at midnight
 */
export const startOfDay = (date) => {
  const truncatedDate = new Date(date)

  truncatedDate.setHours(0)
  truncatedDate.setMinutes(0)
  truncatedDate.setSeconds(0)

  return truncatedDate
}

/**
 * @param {Date | string} date The date to check
 * @returns {boolean} If the given date (truncated at midnight) is today
 */
export const isToday = (date) =>
  startOfDay(new Date()).getTime() === startOfDay(date).getTime()

export const isAgeLegal = ({ legalAge, birthdate }) => {
  if (!isDateValid(birthdate)) {
    return false
  }

  const legalDate = birthdate
  legalDate.setFullYear(Number(birthdate.getFullYear()) + legalAge)

  return isPastDate(legalDate)
}
