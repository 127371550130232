import isEmpty from 'lodash/isEmpty'

import { SAV_PLATFORM_CONTEXTS } from '@sav-platform/constants'

import { SAV_ROLES } from './constants'

export function getApiPathFromRole(role = SAV_ROLES.merchant) {
  switch (role) {
    case SAV_ROLES.admin:
      return 'staff'

    case SAV_ROLES.backcare:
      return 'backcare_agent'

    case SAV_ROLES.client:
      return 'customer'

    case SAV_ROLES.merchant:
    default:
      return role
  }
}

export function isAdmin(role) {
  return role === SAV_ROLES.admin
}

export function isBackcareAgent(role) {
  return role === SAV_ROLES.backcare
}

export function isMerchant(role) {
  return role === SAV_ROLES.merchant
}

export function isPending(context) {
  return context === SAV_PLATFORM_CONTEXTS.pending
}

export function isReturns(context) {
  return context === SAV_PLATFORM_CONTEXTS.returns
}

export function isStandaloneConversation(context) {
  return context === SAV_PLATFORM_CONTEXTS.standaloneConversation
}

export function parsedContent({ message, shouldFormatLinks }) {
  if (isEmpty(message)) return ''

  // First replace all line breaks.
  const wrappedContent = message.replace(/<br ?\/?>/gi, '\n')

  // Then escape any HTML that may be in the message to avoid injection.
  let cleanedContent = wrappedContent
    .replace(/</gi, '&lt;')
    .replace(/>/gi, '&gt;')

  if (shouldFormatLinks) {
    // However, don't escape HTML links (`<a />` tags) from Backmarket's
    // messages.
    cleanedContent = cleanedContent.replace(
      /&lt;a(.*)&gt;(.*)&lt;\/a&gt;?/gi,
      '<a$1>$2</a>',
    )
  }

  return (
    cleanedContent
      // Then parse any "http" or "https" starting link that is not inside
      // of a `<a />` tag and convert them to clickable links.
      .replace(
        /(^|[^"])(https?:\/\/[^\s]+)/gi,
        '$1<a href="$2" target="_blank" rel="noreferrer noopener">$2</a>',
      )
  )
}
