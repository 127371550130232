import { dangerouslyInjectScriptOnce } from '@core/helpers/dom'

import { PAYMENT_METHODS } from '../../methods'
import {
  PAYPAL_CLIENT_ID_PLACEHOLDER,
  PAYPAL_LIBRARY_URL,
} from '../config/paypal'

/**
 * @typedef {Object} PaypalLibraryConfig
 * @property {string} paypalClientId
 */

/**
 * @param {PaypalLibraryConfig} config
 */
export async function getPaypalLibrary({ paypalClientId }) {
  await dangerouslyInjectScriptOnce(
    PAYPAL_LIBRARY_URL.replace(PAYPAL_CLIENT_ID_PLACEHOLDER, paypalClientId),
  )

  return window.paypal
}

/**
 *
 * @param {import('./advertising').PaymentMethod[]} paymentMethods
 * @returns {PaypalLibraryConfig?}
 */
export function getPaypalLibraryConfig(paymentMethods) {
  const paypalPaymentMethod = paymentMethods?.find((p) => {
    return p.bmCode === PAYMENT_METHODS.PAYPAL && p.config.paypalClientId
  })

  return paypalPaymentMethod?.config
}
