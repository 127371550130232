
import { mapGetters } from 'vuex'

import translations from './4XX.translations'

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      video404: 'config/video404',
    }),
    translations: () => translations,
  },
}
