export const TOAST_CARE_CANCEL_ORDER = 'TOAST_CARE_CANCEL_ORDER'
export const TOAST_CARE_CONNECTED_INSURANCE_WITHDRAW =
  'TOAST_CARE_CONNECTED_INSURANCE_WITHDRAW'
export const TOAST_CARE_CONNECTED_ORDER_WITHDRAW =
  'TOAST_CARE_CONNECTED_ORDER_WITHDRAW'
export const TOAST_CARE_CONNECTED_PROFILE = 'TOAST_CARE_CONNECTED_PROFILE'
export const TOAST_CARE_CONVERSATION = 'TOAST_CARE_CONVERSATION'
export const TOAST_CARE_CONVERSATION_SELLER = 'TOAST_CARE_CONVERSATION_SELLER'
export const TOAST_CARE_PENDING = 'TOAST_CARE_PENDING'
export const TOAST_CARE_TABLE_VIEW = 'TOAST_CARE_TABLE_VIEW'
export const TOAST_CART_COLLECTION_POINTS_SUBMIT_ERROR =
  'TOAST_CART_COLLECTION_POINTS_SUBMIT_ERROR'
export const TOAST_CROSS_SELL = 'TOAST_CROSS_SELL'
export const TOAST_COOKIES_SETTINGS = 'TOAST_COOKIES_SETTINGS'
export const TOAST_COPY_CLIPBOARD = 'TOAST_COPY_CLIPBOARD'
export const TOAST_COPY_TO_CLIPBOARD = 'TOAST_COPY_TO_CLIPBOARD'
export const TOAST_VERIFICATION_EMAIL = 'TOAST_VERIFICATION_EMAIL'
export const TOAST_LOGOUT = 'TOAST_LOGOUT'
export const TOAST_BUYBACK_SHIPPING = 'TOAST_BUYBACK_SHIPPING'
export const TOAST_CMS_EMAIL_COLLECTOR = 'TOAST_CMS_EMAIL_COLLECTOR'
export const TOAST_DASHBOARD_PROFILE_IDCARD_PICTURE_ERROR =
  'TOAST_DASHBOARD_PROFILE_IDCARD_PICTURE_ERROR'
export const TOAST_DASHBOARD_MY_FAVORITES_TOGGLE =
  'TOAST_DASHBOARD_MY_FAVORITES_TOGGLE'
export const TOAST_DIAGNOSIS_CONTACT_SCREEN_PICTURE_ERROR =
  'TOAST_DIAGNOSIS_CONTACT_SCREEN_PICTURE_ERROR'
export const TOAST_DIAGNOSIS_CONTACT_SCREEN_SUBMIT_ERROR =
  'TOAST_DIAGNOSIS_CONTACT_SCREEN_SUBMIT_ERROR'
export const TOAST_DIAGNOSIS_FUNNEL_DESC = 'TOAST_DIAGNOSIS_FUNNEL_DESC'
export const TOAST_DIAGNOSIS_FUNNEL_PICTURES = 'TOAST_DIAGNOSIS_FUNNEL_PICTURES'
export const TOAST_ADD_ACH_PAYMENT = 'TOAST_ADD_ACH_PAYMENT'
export const WRAPPER_TOAST = 'WRAPPER_TOAST'
export const TOAST_CHECKOUT_ADDRESS_SWAP = 'TOAST_CHECKOUT_ADDRESS_SWAP'
export const TOAST_CHECKOUT_ADD_TO_CART = 'TOAST_CHECKOUT_ADD_TO_CART'
export const TOAST_CHECKOUT_SWAP_OR_INSURANCE =
  'TOAST_CHECKOUT_SWAP_OR_INSURANCE'
export const TOAST_BUYBACK_EMAIL_PREPAID_LABEL =
  'TOAST_BUYBACK_EMAIL_PREPAID_LABEL'
export const TOAST_POLICY_TERMINATION_FORM = 'TOAST_POLICY_TERMINATION_FORM'
export const TOAST_BUYBACK_MY_SALES_KYC = 'TOAST_BUYBACK_MY_SALES_KYC'
export const TOAST_BUYBACK_MY_SALES_COUNTEROFFER =
  'TOAST_BUYBACK_MY_SALES_COUNTEROFFER'
export const TOAST_BUYBACK_REFURBISHER = 'TOAST_BUYBACK_REFURBISHER'
export const TOAST_BUYBACK_CONVERSATION = 'TOAST_BUYBACK_CONVERSATION'
export const TOAST_BUYBACK_REFURBISHER_WISHLIST =
  'TOAST_BUYBACK_REFURBISHER_WISHLIST'
export const TOAST_BUYBACK_REFURBISHER_CREATE_LISTING =
  'TOAST_BUYBACK_REFURBISHER_CREATE_LISTING'
export const TOAST_BUYBACK_STAFF_ORDER = 'TOAST_BUYBACK_STAFF_ORDER'
export const TOAST_BUYBACK_ERROR = 'TOAST_BUYBACK_ERROR'
export const TOAST_BUYBACK_SWAP_ERROR = 'TOAST_BUYBACK_SWAP_ERROR'
export const TOAST_REVIEW_TRANSLATION = 'TOAST_REVIEW_TRANSLATION'
