import { PAGE_NAME_BY_PLP_SLUG } from '@cms/service/content-service.config'
import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

// TODO https://backmarket.atlassian.net/browse/SR-3292 GET /bm/content/universe-page to get all the available content and create routes accordingly
export const plpByCMSRoutes = ({ store }) => {
  return [
    {
      name: ROUTES.LANDING.UNIVERSE,
      path: `l/:slugV2(${Object.keys(PAGE_NAME_BY_PLP_SLUG).join('|')})/:uuid`,
      component: () => dynamicImport(import('@cms/Landing.vue')),
      beforeEnter: (to, from, next) => {
        const universePageExperiment =
          store?.store?.getters['flags/getExperiments'].universePage
        if (universePageExperiment !== 'universe') {
          next({
            ...to,
            name: ROUTES.LANDING.PRODUCT,
          })
        } else {
          next()
        }
      },
    },
  ]
}

export const landingRoutes = ({ country, config }) => {
  const disableClientSideNavigation =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_PLP?.includes(country)

  return [
    {
      name: ROUTES.LANDING.SEARCH,
      path: 'search',
      meta: {
        disableClientSideNavigation,
      },
      component: () => dynamicImport(import('./pages/SearchLanding')),
    },
    {
      name: ROUTES.LANDING.MERCHANT,
      // TODO change params names https://backmarket.atlassian.net/browse/URL-465
      path: 's/:merchantName/:uuid',
      meta: {
        disableClientSideNavigation,
      },
      component: () => dynamicImport(import('./pages/SellerLanding')),
    },
    {
      name: ROUTES.LANDING.PRODUCT,
      // TODO change params names https://backmarket.atlassian.net/browse/URL-466
      path: 'l/:slugV2(.*?)/:uuid',
      meta: {
        disableClientSideNavigation,
      },
      component: () => dynamicImport(import('./pages/ProductLanding')),
    },
  ]
}

export const landingFiltersRoutes = () => [
  {
    name: ROUTES.LANDING.FILTERS,
    path: 'l/filters',
    component: () => dynamicImport(import('./pages/Filters')),
  },
  {
    name: ROUTES.LANDING.FILTERS_DETAILS,
    path: 'l/filters/:filter',
    component: () => dynamicImport(import('./pages/SubFilters')),
  },
  {
    name: ROUTES.LANDING.FILTERS_DETAILS_PRICE,
    path: 'l/filters/price',
    component: () => dynamicImport(import('./pages/PriceFilter')),
  },
  {
    name: ROUTES.LANDING.SORT,
    path: 'l/sort',
    component: () => dynamicImport(import('./pages/Sort')),
  },
]
