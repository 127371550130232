
import { RevButtonBase } from '@ds/components/ButtonBase'

import { COUNTRIES } from '@config/constants'
import Flag from '@core/components/Flag'
import {
  APP_COUNTRIES,
  getCountryConfig,
  getMarketBaseURLForCountry,
} from '@core/helpers/countries'
import { ROUTES } from '@router'
import { getLinks } from '@router/links'

import translations from './Footer.translations'

export default {
  components: { Flag, RevButtonBase },
  data() {
    return { flags: [] }
  },
  async fetch() {
    const link = { name: ROUTES.HOME }
    const baseUrl = this.$store.getters['config/baseURL']
    const flags = await getLinks({
      baseUrl,
      link,
      countries: APP_COUNTRIES.filter(
        (country) => getCountryConfig(country).showFooterFlag,
      ),
    })

    this.flags = flags.map((flag) => ({
      ...flag,
      isCurrentCountryPage: this.country === flag.country ? 'page' : false,
    }))

    if (!this.$config.FF_DISABLE_KR_FOOTER_FLAG) {
      this.flags.push({
        country: COUNTRIES.KR,
        href: getMarketBaseURLForCountry({
          hostname: baseUrl,
          country: COUNTRIES.KR,
        }).baseURL,
      })
    }
  },
  computed: {
    translations: () => translations,
  },
}
