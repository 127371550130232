
import { RevIllustration } from '@ds/components/Illustration'

export default {
  components: {
    RevIllustration,
  },
  props: {
    alt: { type: String, default: 'BCorp Logo' },
  },
}
