export default {
  namespaced: true,
  getters: {
    get: (state, commit, { reviewform }) => {
      const reviews = reviewform.reviews.fields.filter(
        (item) => item.rate !== 0,
      )
      const reviewsLength = reviews.length

      if (!reviewsLength) {
        return 0
      }

      const ratesSum = reviews
        .map((item) => item.rate)
        .reduce((a, b) => a + b, null)

      const result = ratesSum / reviewsLength

      if (!result) {
        return 0
      }

      return Math.round(result * 10) / 10
    },
  },
  mutations: {
    set: (state, { id, updatedRate, reviewform }) => {
      // @TODO reviews should update their rate via reviews module
      // eslint-disable-next-line no-param-reassign
      reviewform.reviews.fields = reviewform.reviews.fields.map((review) => {
        if (review.reviewTemplateId !== id) {
          return review
        }

        return {
          ...review,
          rate: updatedRate,
        }
      })
    },
  },
  actions: {
    set: ({ commit, rootState }, datas) => {
      commit('set', { ...datas, ...rootState })
    },
  },
}
