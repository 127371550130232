import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      path: 'c',
      name: ROUTES.BUYING_GUIDE.ROOT_HUB,
      component: () => dynamicImport(import('./pages/Hub/LayoutRootHub.vue')),
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      path: 'c/hub/:unifiedName',
      name: ROUTES.BUYING_GUIDE.HUB,
      component: () => dynamicImport(import('./pages/Hub/LayoutHub.vue')),
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      path: 'c/:category/:pageName',
      name: ROUTES.CMS.ARTICLE,
      component: () =>
        dynamicImport(import('@article/pages/Article/Article.vue')),
      meta: {
        disableClientSideNavigation: true,
      },
    },
  ]
}
