import omit from 'lodash/omit'

import { getRefundState } from './getRefundState'

export const formatOrderlinesFromOrders = (orders) => {
  const results = orders
    .map((item) =>
      item.orderlines.map((orderline) => ({
        ...orderline,
        ...omit(item, 'orderlines'),
        refunded: getRefundState(orderline),
      })),
    )
    .reduce((previous, current) => previous.concat(current), [])

  return results
}
