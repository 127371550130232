import { COUNTRIES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

import { E2E_ROUTES } from './e2e/routes'

const buybackRoutes = [
  {
    path: 'buyback/:pageName',
    component: () => dynamicImport(import('./Landing.vue')),
    name: ROUTES.CMS.BUYBACK,
  },
  {
    path: 'buyback/:pageName/:pageCategory',
    component: () => dynamicImport(import('./Landing.vue')),
    name: ROUTES.CMS.BUYBACK_CATEGORY,
  },
]

const sellerRoutes = [
  {
    path: 'seller/:pageName',
    component: () => dynamicImport(import('./Landing.vue')),
    name: ROUTES.CMS.SELLER,
  },
]
export default ({ country }) => {
  const shouldAddBuybackRoutes = [
    COUNTRIES.FR,
    COUNTRIES.DE,
    COUNTRIES.US,
    COUNTRIES.ES,
    COUNTRIES.GB,
  ].includes(country)

  return [
    {
      path: 'b/:pageName',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.BRAND_STORE,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      path: 'b/:pageName/:pageCategory',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.BRAND_STORE_CATEGORY,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      name: ROUTES.CMS.BACK_STORIES,
      path: 'back-stories/:pageName',
      component: () => dynamicImport(import('./Landing.vue')),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
    {
      name: ROUTES.CMS.BACK_STORIES_CATEGORY,
      path: 'back-stories/:pageName/:pageCategory',
      component: () => dynamicImport(import('./Landing.vue')),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
    {
      path: 'e/:pageName',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.EVENT,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      path: 'business/:pageName',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.BUSINESS,
    },
    {
      path: 'accessibility-statement',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.STATEMENT,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      path: 'gift/:pageName',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.GIFT_FINDER_MAIN,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      path: 'gift/:pageName/:pageCategory',
      component: () => dynamicImport(import('./Landing.vue')),
      name: ROUTES.CMS.GIFT_FINDER_EVENT,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      name: ROUTES.CMS.REFER_FRIEND,
      path: 'refer-friend',
      component: () => dynamicImport(import('./Landing.vue')),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
    ...insertIf(shouldAddBuybackRoutes, buybackRoutes),
    ...sellerRoutes,
    ...E2E_ROUTES,
  ]
}
