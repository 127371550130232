
import { RevAvatar } from '@ds/components/Avatar'
import { RevLink } from '@ds/components/Link'
import { IconAvatar } from '@ds/icons/IconAvatar'
import { IconHeart } from '@ds/icons/IconHeart'
import { IconPackage } from '@ds/icons/IconPackage'
import { IconPower } from '@ds/icons/IconPower'
import { IconSwap } from '@ds/icons/IconSwap'
import { mapGetters } from 'vuex'

import Logout from '@auth-oauth2/components/Logout'
import ClickOutside from '@core/directives/ClickOutside'
import { isJapaneseCharacter, isKoreanCharacter } from '@core/helpers/countries'
import {
  HEADER_TRACKING_NAME,
  HEADER_TRACKING_ZONE,
} from '@navigation/constant'
import { ROUTES } from '@router'
import { trackClick } from '@tracking/events'

import translations from './UserIconAndSubMenu.translations'

const AVATAR_SIZE_MEDIUM = 'medium'
const AVATAR_SIZE_SMALL = 'small'
const AVATAR_COLOR = 'purple'

export default {
  components: {
    RevLink,
    RevAvatar,
    Logout,
    IconAvatar,
    IconHeart,
    IconPackage,
    IconPower,
    IconSwap,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      isVisible: false,
    }
  },

  computed: {
    translations: () => translations,
    ROUTES: () => ROUTES,
    ...mapGetters({
      isAuthenticated: 'auth/status',
      isNewAuthEnabled: 'auth/isNewAuthEnabled',
      firstName: 'user/firstName',
      breakpoint: 'breakpoint',
      isBuyBackEnabled: 'config/isBuyBackEnabled',
      experiments: 'flags/getExperiments',
    }),
    HEADER_TRACKING_NAME: () => HEADER_TRACKING_NAME,
    HEADER_TRACKING_ZONE: () => HEADER_TRACKING_ZONE,
    iconAvatarLink() {
      let authLink = { name: ROUTES.AUTH.REGISTER }

      if (this.isNewAuthEnabled) {
        const { href } = this.$navigation.resolve({
          name: ROUTES.DASHBOARD.SELF,
        })

        authLink = { name: ROUTES.AUTH_OAUTH2.LOGIN, query: { next: href } }
      }

      return this.isAuthenticated ? null : authLink
    },
    trackingName() {
      return this.isAuthenticated
        ? this.HEADER_TRACKING_NAME.DASHBOARD
        : this.HEADER_TRACKING_NAME.REGISTER
    },
    firstLetterOfName() {
      if (this.firstName) {
        return this.firstName.charAt(0)
      }

      return ''
    },
    avatarColor() {
      return AVATAR_COLOR
    },
    avatarSize() {
      return this.breakpoint > 1 ? AVATAR_SIZE_MEDIUM : AVATAR_SIZE_SMALL
    },
    showMySales() {
      return this.isBuyBackEnabled && !this.$config.KILL_MY_SALES_ACCESS
    },

    hasJapaneseOrKoreanFirstName() {
      const character = this.firstLetterOfName
      const isJapanese = isJapaneseCharacter(character)
      const isKorean = isKoreanCharacter(character)

      return isJapanese || isKorean
    },

    // Using a japanese or a korean character
    // does not make sense as an avatar.
    // We're using an avatar icon instead.
    authIconAvatar() {
      if (this.hasJapaneseOrKoreanFirstName) {
        return IconAvatar
      }

      return undefined
    },
  },

  watch: {
    isAuthenticated(isNowAuthenticated) {
      if (!isNowAuthenticated && this.isNewAuthEnabled) {
        this.closeSubMenu()
      }
    },
  },

  methods: {
    toggleSubMenu() {
      if (this.isAuthenticated) {
        this.isVisible = !this.isVisible
      }
    },
    closeSubMenu() {
      this.isVisible = false
    },
    handleClick(name) {
      this.closeSubMenu()
      trackClick({
        zone: this.HEADER_TRACKING_ZONE,
        name,
      })
    },
  },
}
