export default {
  postalCodeInvalid: {
    id: 'address_fields_postal_code_invalid',
    defaultMessage: 'ZIP code is invalid',
  },
  postalCodeUnauthorized: {
    id: 'address_fields_postal_code_unauthorized',
    defaultMessage:
      "Our devices don't do long distance, not even for love. Try a closer delivery address?",
  },
}
