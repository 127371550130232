import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

const ModelsComparisonHome = () =>
  dynamicImport(import('./pages/ModelsComparisonHome/ModelsComparisonHome.vue'))

const ModelsComparisonSearch = () =>
  dynamicImport(
    import('./pages/ModelsComparisonSearch/ModelsComparisonSearch.vue'),
  )

export default ({ config, country }) => {
  const isNewLayoutOpenedInCountry =
    config.FF_NEW_COMPARE_RESULT_PAGE_COUNTRIES?.includes(country)

  return [
    {
      name: ROUTES.MODELS_COMPARISON.HOME,
      path: 'compare/home',
      component: ModelsComparisonHome,
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      name: ROUTES.MODELS_COMPARISON.COMPARISON_RESULTS,
      path: 'compare/:productA/:productB',
      component: () =>
        isNewLayoutOpenedInCountry
          ? dynamicImport(
              import(
                './pages/ModelsComparisonResults/experiment/ModelsComparisonResults.vue'
              ),
            )
          : dynamicImport(
              import(
                './pages/ModelsComparisonResults/ModelsComparisonResults.vue'
              ),
            ),
      meta: {
        disableClientSideNavigation: true,
      },
    },
    {
      name: ROUTES.MODELS_COMPARISON.CATEGORY,
      path: 'compare/:category',
      component: ModelsComparisonSearch,
      meta: {
        disableClientSideNavigation: true,
      },
    },
  ]
}
