import { isUndefined } from '@core/helpers'

/**
 * Redirect the user if he's not seller.
 *
 * @param {Object} context
 * @param {Function} context.redirect
 * @param {Object} context.store
 * @return {Promise}
 */
export default async ({ redirect, route }) => {
  // Matches are listed starting from the first parent, and going through every route
  // until we hit the current route. For example, having three routes (A > B > C)
  // would generate a `matched` array [A, B, C]. Since the route defined latter
  // should take precedence over its parents, we reverse the whole array.
  const reversed = [...route.matched].reverse()

  // Since we reversed the array beforehand, we can now use `Array#find` to traverse
  // the whole array and return the _first_ route matching the given predicate.
  const match = reversed.find(
    (routeDefinition) =>
      routeDefinition.meta && routeDefinition.meta.redirectToFrontApps,
  )

  // We may request a routes tree not protected at all.
  if (isUndefined(match)) return null

  if (!match.meta.redirectToFrontApps.name) return null

  return redirect({
    ...match.meta.redirectToFrontApps,
    params: route.params || {},
    query: route.query || {},
  })
}
