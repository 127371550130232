import isEmpty from 'lodash/isEmpty'

import { isNumber } from '@core/helpers'
import {
  COUNTS_PROPERTIES,
  COUNTS_UPDATE_STATUS,
} from '@customer-requests/Platform/constants'
import {
  fetchCustomerRequestPlatformCarriers,
  fetchCustomerRequestPlatformMerchant,
  fetchCustomerRequestPlatformMerchantInformation,
  getTemplateCategoriesWithTemplates,
} from '@http/endpoints'

export default {
  namespaced: true,

  state() {
    return {
      carriers: [],
      counts: {},
      countsStatus: {},
      dragging: false,
      merchant: {},
      templates: [],
    }
  },
  getters: {
    carriers(state) {
      return state.carriers
    },
    counts(state) {
      return state.counts
    },
    dragging(state) {
      return state.dragging
    },
    merchant(state) {
      return state.merchant
    },
    merchantLocales(state) {
      return Object.keys(state.merchant.locales || {})
        .filter((locale) => state.merchant.locales[locale])
        .map((locale) => (locale === 'en-eu' ? 'en-us' : locale))
    },
    merchantName(state) {
      return state.merchant.name
    },
    returnAddresses(state) {
      return state.merchant.returnAddresses
    },
    templates(state) {
      return state.templates
    },
    countUpdateStatus(state) {
      return (context) =>
        state.countsStatus[context] || COUNTS_UPDATE_STATUS.noUpdate
    },
  },
  mutations: {
    acknowledge(state, { context }) {
      state.countsStatus[context] = COUNTS_UPDATE_STATUS.noUpdate
    },
    clearMerchant(state) {
      state.merchant = {}
    },
    setCarriers(state, { carriers = [] } = {}) {
      state.carriers = carriers.reduce(
        (
          allCarriers,
          {
            id,
            public_id: publicId,
            shipper_name: name,
            is_shipper_used: enabled,
            ...descriptions
          },
        ) => {
          if (!enabled) {
            return allCarriers
          }

          const description = Object.entries(descriptions).reduce(
            (allDescriptions, [descriptionName, value]) => {
              if (isEmpty(value)) {
                return allDescriptions
              }

              const locale = descriptionName.replace('description_', '')

              return {
                ...allDescriptions,
                [locale]: value,
              }
            },
            {},
          )

          return [
            ...allCarriers,
            {
              id,
              publicId,
              name,
              description,
            },
          ]
        },
        [],
      )
    },
    setCounts(state, { counts, notifyUpdate = false }) {
      const newCounts = {}
      const newCountsStatus = {}
      Object.entries(COUNTS_PROPERTIES).forEach(([context, countProperty]) => {
        if (notifyUpdate) {
          const count = counts[countProperty]
          const previousStatus = state.countsStatus[context]

          // Do not update the status of a count that has already been updated
          // but not yet acknowledged.
          if (
            isNumber(previousStatus) &&
            previousStatus !== COUNTS_UPDATE_STATUS.noUpdate
          ) {
            newCountsStatus[context] = previousStatus
          } else {
            let countUpdateStatus = COUNTS_UPDATE_STATUS.noUpdate

            if (
              state.counts[context] === undefined ||
              count > state.counts[context]
            ) {
              countUpdateStatus = COUNTS_UPDATE_STATUS.added
            } else if (count < state.counts[context]) {
              countUpdateStatus = COUNTS_UPDATE_STATUS.removed
            }

            newCountsStatus[context] = countUpdateStatus
          }
        }

        newCounts[context] = counts[countProperty]
      })

      state.countsStatus = newCountsStatus
      state.counts = newCounts
    },
    setDragging(state, { isDragging }) {
      state.dragging = isDragging
    },
    setMerchant(state, { merchant }) {
      state.merchant = merchant
    },
    setTemplates(state, { templates }) {
      state.templates = templates
    },
  },
  actions: {
    acknowledge({ commit }, { context }) {
      commit('acknowledge', { context })
    },
    clearMerchant({ commit }) {
      commit('clearMerchant')
    },
    dragging({ commit }) {
      commit('setDragging', { isDragging: true })
    },
    async fetchCarriers({ commit, dispatch }) {
      const {
        payload: { results: carriers },
      } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformCarriers,
          queryParams: { trackable: true },
        },
        { root: true },
      )

      commit('setCarriers', { carriers })
    },
    async fetchCounts(/* { commit, dispatch }, { notifyUpdate = false } = {} */) {
      /*
      To uncomment in case of CSR rollback https://backmarket.atlassian.net/wiki/spaces/SAX/pages/3198648453/Rollout+plan+for+Centralized+Service+Request+List#Rollback
      const { payload: counts } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformCounts,
        },
        { root: true },
      )

      commit('setCounts', { counts, notifyUpdate })
      */
    },
    async fetchMerchantInformation(
      { commit, dispatch },
      { customerRequestId } = {},
    ) {
      const { payload: merchant } = await dispatch(
        'http/request',
        {
          request: !isNumber(customerRequestId)
            ? fetchCustomerRequestPlatformMerchant
            : fetchCustomerRequestPlatformMerchantInformation,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      commit('setMerchant', { merchant })
    },
    async fetchTemplates({ commit, dispatch }) {
      const { payload: templates } = await dispatch(
        'http/request',
        {
          request: getTemplateCategoriesWithTemplates,
        },
        { root: true },
      )

      commit('setTemplates', { templates })
    },
    stopDragging({ commit }) {
      commit('setDragging', { isDragging: false })
    },
  },
}
