import { RevIllustration } from '@ds/components/Illustration'

import translations from './Footer.translations'

export default {
  __name: 'BestECommerceWebsiteLogo',
  setup(__props) {


return { __sfc: true,RevIllustration, translations }
}

}