// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HnJwfVuV8U8H4L9EDctcbA\\=\\={transform:translateY(-100%);visibility:hidden}._48916K3A0CSk\\+GEHxvqMMQ\\=\\={-ms-overflow-style:none;scrollbar-width:none}._48916K3A0CSk\\+GEHxvqMMQ\\=\\=::-webkit-scrollbar{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "HnJwfVuV8U8H4L9EDctcbA==",
	"hideScrollbar": "_48916K3A0CSk+GEHxvqMMQ=="
};
module.exports = ___CSS_LOADER_EXPORT___;
