import {
  COUNTRIES,
  CURRENCIES,
  MARKETPLACES,
  MARKET_STATUS,
  TRUSTMARKS,
} from '@config/constants'

const fromYoutube = ({ id, params = '' }) =>
  `https://www.youtube.com/embed/${id}?rel=0&amp;showinfo=0${params}`

const APP_CONFIG = {
  [COUNTRIES.FR]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_FR.png',
        url: 'https://www.backmarket.fr/fr-fr/c/news/bcorp-status',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/les-echos.svg',
          url: 'https://capitalfinance.lesechos.fr/analyses/dossiers/thibaud-hug-de-larauze-ceo-de-back-market-le-business-au-service-de-leconomie-circulaire-1775506',
          width: 229,
          height: 80,
          alt: 'Les Echos, Capital finance',
        },
        {
          filepath: '/img/about-us/press/sud-ouest.svg',
          url: 'https://www.sudouest.fr/appareils-numeriques-comment-le-reconditionnement-reussit-a-contrer-l-irreparabilite-programmee-13274974.php',
          width: 99,
          height: 80,
          alt: 'Sud Ouest',
        },
        {
          filepath: '/img/about-us/press/forbes.svg',
          url: 'https://www.forbes.fr/technologie/back-market-la-startup-francaise-la-plus-valorisee-souhaite-creer-une-economie-circulaire-pour-la-technologie/',
          width: 229,
          height: 80,
          alt: 'Forbes',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      refurbisher: true,
      enabled: true,
      swapAvailable: true,
      hasRib: true,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360010648979-Comment-sauvegarder-et-supp%5B%E2%80%A6%5D-il-aux-donn%C3%A9es-de-mon-appareil-apr%C3%A8s-sa-revente-',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360012127479-Comment-d%C3%A9connecter-mon-compte-iCloud-Google-',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360012127479-Comment-d%C3%A9connecter-mon-compte-iCloud-Google-',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360021663319',
        },
      },
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'fr-fr',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'X194F6470570B4368A91A3C7230E9014C',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'qk7kna2HqK4' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.AT]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_DE.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/spiegel.svg',
          url: 'https://www.spiegel.de/netzwelt/gadgets/refurbished-handys-wer-gebraucht-kauft-kauft-besser-a-48292730-ea53-4a00-b0a6-7bb4da456cf5',
          width: 199,
          height: 80,
          alt: 'Spiegel',
        },
        {
          filepath: '/img/about-us/press/stern.svg',
          url: 'https://www.stern.de/digital/smartphones/refurbished-smartphones-kaufen--warentest-empfiehlt-diese-anbieter-33220852.html',
          width: 178,
          height: 80,
          alt: 'Stern',
        },
        {
          filepath: '/img/about-us/press/utopia.svg',
          url: 'https://utopia.de/ratgeber/test-zu-refurbished-smartphones-stiftung-warentest/',
          width: 136,
          height: 80,
          alt: 'Utopia',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'de-at',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'XE714EB9CDE3DA7C8DD4DCFF5B0CE6B9E',
    },
    vatIncluded: true,
    video404: fromYoutube({ id: 'viGnI3gZMqU' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.BE]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_FR.png',
        url: 'https://www.backmarket.be/fr-be/c/events/bcorp-status',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/les-echos.svg',
          url: 'https://capitalfinance.lesechos.fr/analyses/dossiers/thibaud-hug-de-larauze-ceo-de-back-market-le-business-au-service-de-leconomie-circulaire-1775506',
          width: 229,
          height: 80,
          alt: 'Les Echos, Capital finance',
        },
        {
          filepath: '/img/about-us/press/sud-ouest.svg',
          url: 'https://www.sudouest.fr/appareils-numeriques-comment-le-reconditionnement-reussit-a-contrer-l-irreparabilite-programmee-13274974.php',
          width: 99,
          height: 80,
          alt: 'Sud Ouest',
        },
        {
          filepath: '/img/about-us/press/forbes.svg',
          url: 'https://www.forbes.fr/technologie/back-market-la-startup-francaise-la-plus-valorisee-souhaite-creer-une-economie-circulaire-pour-la-technologie/',
          width: 229,
          height: 80,
          alt: 'Forbes',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'fr-be',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'XB9BE628A37B8B2E1477584BD94039251',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'qk7kna2HqK4' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.DE]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_DE.png',
        url: 'https://www.backmarket.de/de-de/c/news/bcorp-status',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/spiegel.svg',
          url: 'https://www.spiegel.de/netzwelt/gadgets/refurbished-handys-wer-gebraucht-kauft-kauft-besser-a-48292730-ea53-4a00-b0a6-7bb4da456cf5',
          width: 199,
          height: 80,
          alt: 'Spiegel',
        },
        {
          filepath: '/img/about-us/press/stern.svg',
          url: 'https://www.stern.de/digital/smartphones/refurbished-smartphones-kaufen--warentest-empfiehlt-diese-anbieter-33220852.html',
          width: 178,
          height: 80,
          alt: 'Stern',
        },
        {
          filepath: '/img/about-us/press/utopia.svg',
          url: 'https://utopia.de/ratgeber/test-zu-refurbished-smartphones-stiftung-warentest/',
          width: 136,
          height: 80,
          alt: 'Utopia',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      refurbisher: true,
      enabled: true,
      swapAvailable: true,
      hasRib: true,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360010648979-Was-passiert-mit-den-Daten-auf-meinem-Ger%C3%A4t-nachdem-ich-es-verkauft-habe-',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360012127479-Wie-melde-ich-mich-von-meinem-iCloud-Konto-ab-',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360012127479-Wie-melde-ich-mich-von-meinem-Google-iCloud-Konto-ab-',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360010965060',
        },
      },
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'de-de',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'X0D2D87BCBFAE89812C9338C968A4BD77',
    },
    vatIncluded: true,
    video404: fromYoutube({ id: 'txjg68LJsRE' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.ES]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_ES.png',
        url: 'https://www.backmarket.es/es-es/c/news/b-corp-back-market',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/el-pais.svg',
          url: 'https://elpais.com/tecnologia/2021-12-03/por-que-deberias-regalar-tecnologia-reacondicionada-estas-navidades.html',
          width: 149,
          height: 80,
          alt: 'El País',
        },
        {
          filepath: '/img/about-us/press/la-vanguardia.svg',
          url: 'https://www.lavanguardia.com/economia/20230226/8782411/moviles-reacondicionar-ventas-empresas-nuevos-usados.html',
          width: 221,
          height: 80,
          alt: 'La Vanguardia',
        },
        {
          filepath: '/img/about-us/press/expansion.svg',
          url: 'https://www.expansion.com/economia-digital/innovacion/2023/01/28/63d25a86e5fdeafd0c8b45e1.html',
          width: 160,
          height: 80,
          alt: 'Expansión',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      refurbisher: true,
      enabled: true,
      swapAvailable: true,
      hasRib: true,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/es/articles/360010648979',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/es/articles/360012127479',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/es/articles/360012127479',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/es/articles/360021663319',
        },
      },
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'es-es',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: true,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.CONFIANZA_ONLINE,
    trustmarkConfig: {
      id: 'X416F4F5FD9E8CB7EC326CBA57589BE5B',
    },
    vatIncluded: true,
    video404: fromYoutube({ id: 'Eop1DPQIRx4' }),
    warranty: {
      displayInYear: true,
      duration: 24,
    },
  },
  [COUNTRIES.FI]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'fi-fi',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '5eb28e4f90998f0001ecc108',
      href: 'https://fi.trustpilot.com/review/backmarket.fi',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: '0d_xT4GMYZo', params: '&start=332&end=374' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.GB]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.backmarket.co.uk/en-gb/c/news/bcorp-status',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/mail-online.svg',
          url: 'https://www.dailymail.co.uk/sciencetech/article-12086337/Im-tech-repair-expert-mistakes-killing-machine.html',
          width: 188,
          height: 80,
          alt: 'Daily mail, Mail online',
        },
        {
          filepath: '/img/about-us/press/the-times.svg',
          url: 'https://www.thetimes.co.uk/article/laptop-and-gadget-recycler-to-open-uk-market-bkm0vxjsx',
          width: 165,
          height: 80,
          alt: 'The Times',
        },
        {
          filepath: '/img/about-us/press/independent.svg',
          url: 'https://www.independent.co.uk/extras/indybest/gadgets-tech/phones-accessories/best-refurbished-iphone-deals-b2318404.html',
          width: 179,
          height: 80,
          alt: 'Independent',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      refurbisher: true,
      enabled: true,
      swapAvailable: true,
      hasRib: false,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-gb/articles/360010648979',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-gb/articles/360012127479',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-gb/articles/360012127479',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360021663319',
        },
      },
    },
    coolingOffDays: 30,
    cgvModalLegals: false,
    countryCode: 'en-gb',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.GBP,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      businessUnitId: '5e2b02758b37ba0001e1d426',
      href: 'https://uk.trustpilot.com/review/backmarket.co.uk',
      templateId: '53aa8807dec7e10d38f59f32',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'twkcf_-Zrcw' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.GR]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'el-gr',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      businessUnitId: '60634b8d27f4190001f3896e',
      href: 'https://gr.trustpilot.com/review/backmarket.gr',
      templateId: '53aa8807dec7e10d38f59f32',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'qo4E0qGypAk' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.IE]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/mail-online.svg',
          url: 'https://www.dailymail.co.uk/sciencetech/article-12086337/Im-tech-repair-expert-mistakes-killing-machine.html',
          width: 188,
          height: 80,
          alt: 'Daily mail, Mail online',
        },
        {
          filepath: '/img/about-us/press/the-times.svg',
          url: 'https://www.thetimes.co.uk/article/laptop-and-gadget-recycler-to-open-uk-market-bkm0vxjsx',
          width: 165,
          height: 80,
          alt: 'The Times',
        },
        {
          filepath: '/img/about-us/press/independent.svg',
          url: 'https://www.independent.co.uk/extras/indybest/gadgets-tech/phones-accessories/best-refurbished-iphone-deals-b2318404.html',
          width: 179,
          height: 80,
          alt: 'Independent',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'en-ie',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: true,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60634b8927f4190001f3896b',
      href: 'https://ie.trustpilot.com/review/backmarket.ie',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'Al7J6cSaSPw' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.IT]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_IT.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'it-it',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '5c6301badc82bd00015430aa',
      href: 'https://it.trustpilot.com/review/backmarket.it',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'Al7J6cSaSPw' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.NL]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'nl-nl',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'XD3D9E2C9264223766FEDD3DB22395FA0',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: '8gqpAEsC_xE' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.PT]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_PT.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'pt-pt',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: true,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60377c9970c83a000172de3d',
      href: 'https://pt.trustpilot.com/review/backmarket.pt ',
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'tHza_jWceDk' }),
    warranty: {
      displayInYear: true,
      duration: 24,
    },
  },
  [COUNTRIES.SE]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'sv-se',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.SEK,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: false,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: true,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    // TODO set to trustpilot when enough reviews
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60634bad27f4190001f38973',
      href: 'https://fr.trustpilot.com/review/backmarket.se',
    },
    vatIncluded: true,
    video404: fromYoutube({ id: 'v8_7yPocGPg' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.SK]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'sk-sk',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: true,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60634bb1e143b400018548c0',
      href: 'https://fr.trustpilot.com/review/backmarket.sk',
    },
    vatIncluded: true,
    // eslint-disable-next-line line-comment-position
    video404: fromYoutube({ id: 'd73MEkygsiY' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.US]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.backmarket.com/en-us/c/news/bcorp-status',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: true,
    },
    buyback: {
      refurbisher: true,
      enabled: true,
      swapAvailable: false,
      hasRib: false,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360010648979-What-happens-to-the-data-on-my-device-after-I-trade-it-in-',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360012127479-How-do-I-disconnect-my-iCloud-or-Google-account-',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360012127479-How-do-I-disconnect-my-iCloud-or-Google-account-',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360010965060',
        },
      },
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'en-us',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.USD,
    marketplace: MARKETPLACES.NA,
    mobileAppAvailable: true,
    showAlternateLinks: true,
    showCarrier: true,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {
      merchantId: 118622995,
    },
    vatIncluded: false,
    video404: fromYoutube({ id: 'KYjrLVUSpfY' }),
    warranty: {
      duration: 12,
      displayInYear: true,
    },
  },
  [COUNTRIES.JP]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.backmarket.co.jp/ja-jp/c/news/bcorp-status',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'ja-jp',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.JPY,
    marketplace: MARKETPLACES.AP,
    mobileAppAvailable: false,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: false,
    showInSAVPlatform: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {},
    vatIncluded: true,
    video404: fromYoutube({ id: 'wXYn0zbcrcY' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.AU]: {
    aboutUs: {
      bCorpLogo: {
        filepath: '/img/about-us/b-corp/b-corp_EN.png',
        url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
      },
      pressLogos: [
        {
          filepath: '/img/about-us/press/wirecutter.svg',
          url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
          width: 187,
          height: 80,
          alt: 'New York Times, Wirecutter',
        },
        {
          filepath: '/img/about-us/press/wsj.svg',
          url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
          width: 160,
          height: 80,
          alt: 'Wall Street Journal',
        },
        {
          filepath: '/img/about-us/press/good-morning-america.svg',
          url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
          width: 157,
          height: 80,
          alt: 'Good Morning America',
        },
      ],
      hasImpactArticle: false,
    },
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'en-au',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.AUD,
    marketplace: MARKETPLACES.AP,
    mobileAppAvailable: false,
    showAlternateLinks: true,
    showCarrier: false,
    showFooterFlag: true,
    showIncludedVat: true,
    showInSAVPlatform: false,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {},
    vatIncluded: false,
    video404: fromYoutube({ id: 'KYjrLVUSpfY' }),
    warranty: {
      duration: 12,
      displayInYear: true,
    },
  },
}

export default APP_CONFIG
