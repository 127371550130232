import IntlFormatMessage from 'intl-messageformat'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'

import { CURRENCIES } from '@config/constants'
import { getCookie, setCookie } from '@core/helpers/cookies'
import {
  HEADER_CFWORKER_LANG,
  HEADER_CFWORKER_LOCALE,
  HEADER_NGINX_LANG,
  HEADER_NGINX_LOCALE,
} from '@http/headers'
import logger from '@logger'

import { translations } from './module'
import utilTranslations from './utils.translations'

/**
 * This function transforms a given message into an array of strings and/or vnodes.
 *
 * @example
 * tokenize('Hello {world}', …) // ['Hello, ', VNode]
 *
 * @example
 * tokenize ('My name is {first} {last}.', …) // ['My name is ', VNode, ' ', VNode, '.']
 *
 * 1. To take advantage of HTML (and components) interpolation, we need to return
 *    an array, and let the Vue compiler do its work. We can't just compute a
 *    string to display in our templates.
 *
 * 2. Note that by doing so, we gain everything Vue has to offer. We can use CSS,
 *    JS and whatever in our translations. We can even use Vue components as-is.
 *
 * 3. We also prevent a huge security breach: XSS issues. Because Vue does the work
 *    for us, we no longer need to manually use `v-html`. So, an attacker would
 *    not be able to inject arbitrary tags (and attributes) in our pages.
 *
 * @param {String} message
 * @param {{ [key: String]: String|VNode }} slots
 *
 * @return {(String|VNode)[]}
 */
const tokenize = (message, slots) => {
  const tokens = []

  let text = ''
  let position = 0

  while (position < message.length) {
    const character = message[position]

    switch (character) {
      case '{':
        tokens.push(text)
        text = ''
        break

      case '}':
        tokens.push(slots[text])
        text = ''
        break

      default:
        text += character
        break
    }

    position += 1
  }

  // Do not forget to push the rest of the message when we reach the end.
  tokens.push(text)

  // Since we do not validate the items pushed into the `tokens` array, we might
  // end up with falsy values (undefined, empty strings…). To avoid polluting
  // our Vue templates with those, let's remove them at the end.
  return tokens.filter(Boolean)
}

const createMessageFormatter =
  ({ locale, formats, showTranslationKeys, useDefaultMessage }) =>
  (definition, values) => {
    if (!definition) {
      // In some cases, when definitions are computed from dynamic values, we
      // may end up in situations where nothing is passed to this function.
      logger.error('Missing translation definition', {
        locale,
      })

      // Instead of crashing the application, let's gracefully handle the error
      // by displaying nothing to our end customers.
      return null
    }

    const { id, defaultMessage } = definition

    const trans = translations.getFromLocale(locale)

    try {
      // Do not display those warning in unit tests as we don't have
      // translations there.
      // This will ease the task when trying to check calls on `logger.errors`.
      if (!(id in trans) && process.env.NODE_ENV !== 'test') {
        logger.error('Missing translation key', {
          locale,
          id,
        })
      }

      if (showTranslationKeys) {
        return id
      }

      let raw = trans[id] || defaultMessage
      if (useDefaultMessage) {
        raw = defaultMessage
      }
      const escaped = raw.replace(/{(\w*), html}/g, "'{$1}'")
      const message = new IntlFormatMessage(escaped, locale, formats)

      return message.format(values)
    } catch {
      // If the key does not have a translation nor a default message.
      return null
    }
  }

const createHtmlFormatter = (formatter) => (definition, values) => {
  const message = formatter(definition, values)

  // If both values are equals, that means the raw formatter returned the key
  // and did not format it at all. We just serve this value, and do not try to
  // process it.
  if (message === definition.id) {
    return message
  }

  if (message) {
    return tokenize(message, values)
  }

  return null
}

const createDateFormatter = (locale) => (date, options) => {
  return Intl.DateTimeFormat(locale, options).format(date)
}

const createRelativeDateFormatter = (locale) => (date, options) => {
  return new Intl.RelativeTimeFormat(locale, options)
}

const createNumberFormatter = (locale) => (number, options) => {
  return Intl.NumberFormat(locale, options).format(number)
}

const createListFormatter = (locale) => (list, options) => {
  return new Intl.ListFormat(locale, options).format(list)
}

const createCountryFormatter = (locale) => (countryCode) => {
  if (typeof Intl.DisplayNames !== 'function') {
    return countryCode
  }

  const regionNames = new Intl.DisplayNames([locale], { type: 'region' })

  return regionNames.of(countryCode)
}

const createLanguageFormatter = (locale) => (localeToTranslate) => {
  if (typeof Intl.DisplayNames !== 'function') {
    return localeToTranslate
  }

  const languageNames = new Intl.DisplayNames([locale], { type: 'language' })

  return languageNames.of(localeToTranslate)
}

/**
 * @param {string} locale
 * @param {string} defaultCurrency
 * @returns {createPriceFormatter~inner}
 */
const createPriceFormatter =
  (locale, defaultCurrency) =>
  /**
   * @param {{amount: string, currency?: string}} price
   * @param {Intl.NumberFormatOptions & { symbol?: boolean }} [options]
   * @return {string}
   */
  (price, options = {}) => {
    // TODO [PAYIN-1098] Ensure all prices have been migrated, then remove this
    // fallback.
    if (typeof price === 'string' || typeof price === 'number') {
      logger.error('[i18n] Price should follow the Price model', {
        price,
      })
      // eslint-disable-next-line no-param-reassign
      price = { amount: price, currency: options.currency }
    }

    if (isEmpty(price)) {
      return ''
    }

    const { symbol = true, ...intlNumberFormatOptions } = options
    const optionsWithOverrides = {
      currency: price.currency || defaultCurrency,
      style: 'currency',
      ...intlNumberFormatOptions,
    }

    const numberFormat = Intl.NumberFormat(locale, optionsWithOverrides)
    const priceWithCurrencySymbol = numberFormat.format(
      parseFloat(price.amount),
    )

    if (symbol) {
      // Native implementation behavior is to display A$XX only for en-us locale
      // but product requirements for backmarket.com.au is to use A$XX for any locale
      if (
        optionsWithOverrides.currency === CURRENCIES.AUD &&
        locale === 'en-au'
      ) {
        return priceWithCurrencySymbol.replace(/\$/, 'A$')
      }

      return priceWithCurrencySymbol
    }

    const removeCurrencySymbolRegex = /[^\d.,-\s]+/g

    return priceWithCurrencySymbol.replace(removeCurrencySymbolRegex, '').trim()
  }

const createCurrencySymbolFormatter =
  (locale, defaultCurrency) =>
  ({ currency = defaultCurrency, options = {} } = {}) => {
    return createPriceFormatter(locale, currency)({ amount: '0.00' }, options)
      .replace(/[\d.,\s]/g, '')
      .trim()
  }

const createFullNameFormatter = (messageFormatter) => {
  return (firstName, lastName) => {
    const fullName = messageFormatter(utilTranslations.fullName, {
      firstName,
      lastName,
    })

    return fullName.trim()
  }
}

/**
 * An opinionated formatter to convert meters into humanly digestable distances.
 *
 * Switches between imperial and metric depending on the locale.
 *
 * Switches between feet to miles and meters to kilometers depending on what
 * is the most sensible.
 *
 * @example
 * createDistanceFormatter('fr-FR', messageFormatter)(1000)
 * > "1 km"
 *
 * createDistanceFormatter('fr-FR', messageFormatter)(300)
 * > "300 m"
 */
function createDistanceFormatter(locale, messageFormatter) {
  const MILE_PER_METER = 0.000621371
  const FEET_PER_METER = 3.28084
  // number of feet where we switch, should correspond to 0.1 miles
  const MAX_FEET_CUTOFF = 528
  const useImperial = new Set(['en-us', 'en-uk', 'en-lr', 'my-mm']).has(
    locale?.toLowerCase(),
  )

  // NOTE: not using `Intl.NumberFormat({style: 'unit'})` for formatters
  // due to Safari 13/14 support
  function feetFormatter(distance) {
    return messageFormatter(utilTranslations.footShort, { distance })
  }
  function mileFormatter(distance) {
    return messageFormatter(utilTranslations.mileShort, { distance })
  }
  function meterFormatter(distance) {
    return messageFormatter(utilTranslations.meterShort, { distance })
  }
  function kilometerFormatter(distance) {
    return messageFormatter(utilTranslations.kilometerShort, { distance })
  }

  /**
   * @param {Number} meters
   */
  return (meters) => {
    if (!Number.isInteger(meters)) {
      return ''
    }

    if (useImperial) {
      const roundedFeet = round(meters * FEET_PER_METER, -1)

      return roundedFeet < MAX_FEET_CUTOFF
        ? feetFormatter(roundedFeet)
        : mileFormatter(round(meters * MILE_PER_METER, 1))
    }

    const roundedMeters = round(meters, -1)

    if (roundedMeters < 1000) {
      return meterFormatter(roundedMeters)
    }
    if (roundedMeters < 10000) {
      return kilometerFormatter(round(roundedMeters / 1000, 1))
    }

    return kilometerFormatter(round(roundedMeters / 1000, 0))
  }
}

export const LANG_COOKIE_NAME = 'BM_lang'

// Locale format: fr-fr, en-us, ...
export const getLocaleFromRequest = (req) => {
  // For the moment, only allow lang override in Back office.
  if (req.url.startsWith('/bo_merchant') || req.url.startsWith('/bo_admin')) {
    return getCookie(LANG_COOKIE_NAME, req.headers.cookie) || null
  }

  return (
    req.headers[HEADER_CFWORKER_LOCALE] ||
    req.headers[HEADER_NGINX_LOCALE] ||
    null
  )
}

export const getLangFromRequest = (req) => {
  // For the moment, only allow lang override in Back office.
  if (req.url.startsWith('/bo_merchant') || req.url.startsWith('/bo_admin')) {
    const cookie = getCookie(LANG_COOKIE_NAME, req.headers.cookie)
    if (cookie) {
      const [lang] = cookie.split('-')

      return lang
    }
  }

  return (
    req.headers[HEADER_CFWORKER_LANG] || req.headers[HEADER_NGINX_LANG] || null
  )
}

export const changeLang = (code) => {
  setCookie(LANG_COOKIE_NAME, code)
  window.location.reload()
}

let plugin

export const getPlugin = () => plugin

export const createPlugin = ({
  locale,
  currencyCode,
  showTranslationKeys,
  useDefaultMessage,
}) => {
  const formats = {
    number: {
      currency: {
        style: 'currency',
        currency: currencyCode,
      },
    },
  }

  const formatter = createMessageFormatter({
    locale,
    formats,
    showTranslationKeys,
    useDefaultMessage,
  })

  formatter.date = createDateFormatter(locale)
  formatter.relativeDate = createRelativeDateFormatter(locale)
  formatter.distance = createDistanceFormatter(locale, formatter)
  formatter.number = createNumberFormatter(locale)
  formatter.currencySign = createCurrencySymbolFormatter(locale, currencyCode)
  formatter.price = createPriceFormatter(locale, currencyCode)
  formatter.html = createHtmlFormatter(formatter)
  formatter.changeLang = changeLang
  formatter.country = createCountryFormatter(locale)
  formatter.fullName = createFullNameFormatter(formatter)
  formatter.locale = createLanguageFormatter(locale)
  formatter.list = createListFormatter(locale)

  plugin = formatter

  return formatter
}
