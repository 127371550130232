import { camelize } from 'humps'

import {
  ERROR_TRANSLATIONS,
  FIELDS_STYLE,
  FIELDS_TAB_INDEX,
} from '../config/fields'

/**
 * @typedef {Object} Translation
 * @property {string} id
 * @property {string} defaultMessage
 *
 * @param {(translation: Translation) => string} $t
 * @return {(error: string, fieldName: string) => string} A function that
 * translates an error message or id, and a field name, into an error message.
 */
export const fieldErrorTranslator = ($t) => (error, fieldName) => {
  const translations = ERROR_TRANSLATIONS[camelize(fieldName)]

  return translations && translations[error] ? $t(translations[error]) : error
}

export function getFieldsStyle({ country }) {
  return FIELDS_STYLE[country] ?? FIELDS_STYLE.default
}

export function getFieldStyle({ country, fieldName }) {
  return getFieldsStyle({ country })[fieldName]
}

export function getFieldsTabIndex({ country }) {
  return FIELDS_TAB_INDEX[country] ?? {}
}

export function getFieldTabIndex({ country, fieldName }) {
  return getFieldsTabIndex({ country })[fieldName]
}
