import logger from '@logger'

import * as DEFINITIONS from './definitions'

export const componentDeprecation = ({ name, vnode, ...context }) => {
  /* The event is emitted in a component ctor, so we're still in its parent's context. */
  const parents = [vnode.context._name] // eslint-disable-line no-underscore-dangle
  let treeIterator = vnode.context
  while (treeIterator.$parent) {
    parents.push(treeIterator.$parent._name) // eslint-disable-line no-underscore-dangle
    treeIterator = treeIterator.$parent
  }
  parents.reverse()

  /* If the user didn't consent to analytics processing, purge props from the
   * data we send. This avoids leaking PII subject to user consent. */
  const consentCookie =
    document &&
    document.cookie.split(';').find((val) => val.includes('BM_Analytics='))
  const hasConsent = consentCookie
    ? consentCookie.split('=')[1] === 'true'
    : false

  logger.info(`[${DEFINITIONS.DEPRECATION_WARNING}] ${name}`, {
    uuid: JSON.stringify({ name, parents }),
    type: 'component',
    gdprConsent: hasConsent,
    name,
    parents,
    ...context,
    props: hasConsent ? context.props : undefined,
  })
}
