export default {
  title: {
    id: 'klarna_information_modal_title',
    defaultMessage: 'Pay in installments',
  },
  headline: {
    id: 'klarna_information_modal_headline',
    defaultMessage: 'This is how it works!',
  },
  introduction: {
    id: 'klarna_information_modal_introduction',
    defaultMessage:
      'Back Market offers you two ways to pay with Klarna. So you can decide for yourself whether you want to pay for your purchase on account or in flexible monthly installments.',
  },
  payLaterOption: {
    id: 'klarna_information_modal_pay_later_option',
    defaultMessage: 'Pay later',
  },
  payLaterQuestion1: {
    id: 'klarna_information_modal_pay_later_question1',
    defaultMessage: 'How can I pay later with Klarna?',
  },
  payLaterAnswer1: {
    id: 'klarna_information_modal_pay_later_answer1',
    defaultMessage:
      'Very simple, after completing your order you have the option to pay for your purchase with Klarna within 14 days. Do you need a little more time? A small surcharge gives you 3 months to pay your Klarna bill. Note: Unfortunately it is currently only possible to pay with Klarna from a value of € 300.',
  },
  payLaterQuestion2: {
    id: 'klarna_information_modal_pay_later_question2',
    defaultMessage: 'Can I pay with Klarna at any retailer?',
  },
  payLaterAnswer2: {
    id: 'klarna_information_modal_pay_later_answer2',
    defaultMessage:
      'Many retailers already offer Klarna on our platform. We are working on making sure you can pay with Klarna at any retailer in the future. If the dealer of your choice does not offer this payment option, we recommend 100% secure payment with PayPal or Instant bank transfer.',
  },
  sliceItOption: {
    id: 'klarna_information_modal_slice_it_option',
    defaultMessage: 'Pay in installments via',
  },
  sliceItQuestion1: {
    id: 'klarna_information_modal_slice_it_question1',
    defaultMessage: 'How can I pay in installments with Klarna?',
  },
  sliceItAnswer1: {
    id: 'klarna_information_modal_slice_it_answer1',
    defaultMessage:
      'Klarna offers you the opportunity to pay for your product in flexible installments (up to 24 months). There is a fixed minimum amount, the rest is up to you. Klarna will send you an invoice every month. This way you are always informed how much of the outstanding amount is still to be paid. Note: Unfortunately it is currently only possible to pay with Klarna from a value of € 300.',
  },
  sliceItQuestion2: {
    id: 'klarna_information_modal_slice_it_question2',
    defaultMessage: 'Can I pay with Klarna at any retailer?',
  },
  sliceItAnswer2: {
    id: 'klarna_information_modal_slice_it_answer2',
    defaultMessage:
      'Many retailers already offer Klarna on our platform. We are working on making sure that you can pay with Klarna at any retailer in the future. If the dealer of your choice does not offer this payment option, we recommend 100% secure payment with PayPal or Instant bank transfer',
  },
}
