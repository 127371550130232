import { CURRENCIES_PRECISIONS } from '@config/constants/currencies'

export const getCurrencyPrecision = (currency) => {
  const precision = CURRENCIES_PRECISIONS[currency]

  if (precision === undefined) {
    throw new Error(`Missing currency precision config for '${currency}'`)
  }

  return precision
}

export const getCurrencyPrecisionAsDecimal = (currency) => {
  return 10 ** -getCurrencyPrecision(currency)
}

export const subunitsToUnits = (subunits, currency) => {
  if (typeof subunits !== 'number') {
    throw new Error(
      `You should provide a numeric subunits amount (${subunits}, ${typeof subunits} given)!`,
    )
  }

  return subunits / 10 ** getCurrencyPrecision(currency)
}
