
import SpecialMenuHeader from './SpecialMenuHeader.vue'

const config = {
  admin: {
    label: 'Logged as admin',
    color: '#FD595E',
  },
  client: {
    label: 'Logged as client',
    color: '#28BC83',
  },
  merchant: {
    label: 'Logged as merchant',
    color: '#FFA500',
  },
}

export default {
  components: {
    SpecialMenuHeader,
  },
  props: {
    as: {
      type: String,
      required: true,
      validator: (value) =>
        value === 'admin' || value === 'client' || value === 'merchant',
    },
  },
  computed: {
    header() {
      return config[this.as]
    },
  },
}
