/**
 * Given a Vue Router route instance, and an optional server request object,
 * compute the `http` key that will be appended to each log (both client
 * and server-side).
 *
 * @example
 * Loading `https://www.backmarket.com/en-us/search?q=iphone#brand=apple`, will
 * output the following:
 *
 * {
 *   routeName: 'search',
 *   route: '/:locale/search',
 *   path: '/en-us/search',
 *   params: {
 *     locale: 'en-us',
 *   },
 *   query: {
 *     q: 'iphone',
 *   },
 *   hash: '#brand=apple',
 *   fullPath: '/en-us/search?q=iphone#brand=apple',
 *   host: 'www.backmarket.com',
 *   protocol: 'https',
 *   userAgent: '...',
 * }
 *
 * @param {import('vue-router').Route} route
 * @param {import('express').Request} request
 */
export function getHttpFromRoute(route, request) {
  const http = {
    params: route.params,
    hash: route.hash,
    query: route.query,
    path: route.path,
    fullPath: route.fullPath,

    // We apply an empty string as default value in order to make this property
    // explicit on Datadog even when no match were found. That way, we'll know
    // that we can filter empty route names when querying our logs.
    routeName: route.name || '',

    // Same here. We apply an empty string as default value in order to make
    // it explicit on Datadog that we can filter empty values. If any route
    // matches on Vue Router side, this will be replaced with the pattern.
    route: '',
  }

  // It is possible that no route was matched here (in case of 404).
  if (route.matched.length) {
    // We get the last item from the matched route array because it's the more
    // specific. The first ones are parent layouts used to render common parts
    // of the UI (header, footer, etc).
    const matched = route.matched[route.matched.length - 1]

    // Some params are validated against regex, like the locale. It allows
    // only one locale per market (`fr-fr` in France, `en-us` in the USA).
    // So, in order to normalise these paths, we get rid of those regex.
    // See https://stackoverflow.com/a/6208415.
    http.route = matched.path.replace(/\(.*?\)/g, '')
  }

  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href)

    http.host = url.hostname
    http.userAgent = navigator.userAgent

    // Client-side JavaScript includes `:` in the protocol. In order to make it
    // identical to the one we retrieve from server request, let's remove it.
    http.protocol = url.protocol.replace(':', '')
  }

  if (request) {
    http.method = request.method
    http.protocol = request.protocol
    http.host = request.hostname
    http.userAgent = request.headers['user-agent']
  }

  return http
}
