export default {
  storePanelMobilePrevious: {
    id: 'store_panel_mobile_previous',
    defaultMessage: 'Back',
  },
  showAllDepartments: {
    id: 'show_all_departments',
    defaultMessage: 'Show all departments',
  },
  linkTradeIn: {
    id: 'mweb_nav_to_buyback',
    defaultMessage: 'Trade-In',
  },
  linkAboutUs: {
    id: 'mweb_nav_to_about_us',
    defaultMessage: 'About us',
  },
  linkHelp: {
    id: 'mweb_nav_to_help',
    defaultMessage: 'Help',
  },
}
