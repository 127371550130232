import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

import {
  deleteMessageCustomerRequestDiscussion,
  fetchDiscussionCustomerRequestDiscussion,
  fetchLiveChat,
  postMessageCustomerRequestDiscussion,
  rateMessageCustomerRequestDiscussion,
  shareAttachmentsWithSeller,
} from '@http/endpoints'
import { SAV_ROLES } from '@sav-common/constants'
import {
  getApiPathFromRole,
  isAdmin,
  isBackcareAgent,
} from '@sav-common/helpers'

// Build a dictionary from an array
//   From: [{ messageId: 1, content: 'first-message' }, { messageId: 2, content: 'second-message' }]
//   To:   { 1: { messageId: 1, content: 'first-message' }, 2: { messageId: 2, content: 'second-message' } }
function getIndexedMessagesFromArray(messages) {
  return messages.reduce((acc, message) => {
    return { ...acc, [message.messageId]: message }
  }, {})
}

// Build a dictionary with every messages of all the given claims
// { 1: { messageId, content }, 2: { messageId, content } }
export function getAllClaimsMessages(claims) {
  return claims.reduce((acc, claim) => {
    return { ...acc, ...getIndexedMessagesFromArray(claim.messages) }
  }, {})
}

export default {
  namespaced: true,

  state() {
    return {
      customerRequest: {},
    }
  },
  getters: {
    claims(state) {
      return get(state.customerRequest, 'claims', [])
    },
    messages(state) {
      return get(state.customerRequest, 'messages', {})
    },
    message(state, getters) {
      return (messageId) => {
        const { messages } = getters
        if (isEmpty(messages)) {
          return {}
        }

        return messages[messageId]
      }
    },
  },
  mutations: {
    updateAttachmentSharedWithSellerStatus(
      state,
      { messageId, sharedAttachmentsIds },
    ) {
      const { messages } = state.customerRequest
      const message = messages[messageId]

      message.attachments = message.attachments.map((attachment) => ({
        ...attachment,
        sharedWithSeller:
          sharedAttachmentsIds.includes(attachment.attachmentId) ||
          attachment.sharedWithSeller,
      }))
    },
    addMessage(state, { message }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      const { claims, messages } = state.customerRequest
      if (isEmpty(claims)) {
        return
      }

      const lastClaim = claims[claims.length - 1]
      lastClaim.messages = lastClaim.messages || []

      lastClaim.messages.push(message)
      Vue.set(messages, message.messageId, message)
    },
    clear(state) {
      state.customerRequest = {}
    },
    deleteMessage(state, { messageId }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      const { claims, messages } = state.customerRequest
      if (isEmpty(claims)) {
        return
      }

      delete messages[messageId]
      for (let i = 0; i < claims.length; i += 1) {
        const hasMessage = claims[i].messages.some(
          (message) => message.messageId === messageId,
        )

        if (hasMessage) {
          claims[i].messages = claims[i].messages.filter(
            (message) => message.messageId !== messageId,
          )

          return
        }
      }
    },
    set(state, { claims }) {
      state.customerRequest = { claims, messages: getAllClaimsMessages(claims) }
    },
    setLastClaimMessages(state, messages) {
      const lastClaim =
        state.customerRequest.claims[state.customerRequest.claims.length - 1]

      lastClaim.messages = messages

      state.customerRequest.messages = getAllClaimsMessages(
        state.customerRequest.claims,
      )
    },
    updateRates(state, { messageId, rates = {} }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      const { messages } = state.customerRequest
      if (isEmpty(messages)) {
        return
      }

      const message = messages[messageId]
      if (isEmpty(messages) || !message.rateable) {
        return
      }

      Vue.set(message, 'rates', {
        ...message.rates,
        ...rates,
      })
    },
  },
  actions: {
    async shareAttachmentWithSeller(
      { commit, state, dispatch },
      { messageId, customerRequestId },
    ) {
      const { messages } = state.customerRequest
      const message = messages[messageId]

      const attachmentsIds = message.attachments
        .filter((attachment) => attachment.sharedWithSeller === false)
        .map((attachment) => attachment.attachmentId)

      await dispatch(
        'http/request',
        {
          request: shareAttachmentsWithSeller,
          pathParams: {
            customerRequestId,
          },
          body: { attachmentsIds },
        },
        { root: true },
      )

      commit('updateAttachmentSharedWithSellerStatus', {
        messageId,
        sharedAttachmentsIds: attachmentsIds,
      })
    },
    clear({ commit }) {
      commit('clear')
    },
    async fetch(
      { commit, dispatch },
      { role = SAV_ROLES.client, customerRequestId },
    ) {
      const { payload: claims } = await dispatch(
        'http/request',
        {
          request: fetchDiscussionCustomerRequestDiscussion,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )

      commit('set', { claims })
    },
    async fetchLiveChat({ commit, dispatch }, { careFolderPublicId }) {
      const { payload: liveChat } = await dispatch(
        'http/request',
        {
          request: fetchLiveChat,
          pathParams: {
            careFolderPublicId,
          },
        },
        { root: true },
      )

      commit('setLastClaimMessages', liveChat.messages)

      return liveChat
    },
    async deleteMessage({ commit, dispatch }, { messageId }) {
      await dispatch(
        'http/request',
        {
          request: deleteMessageCustomerRequestDiscussion,
          pathParams: { messageId },
        },
        { root: true },
      )

      commit('deleteMessage', { messageId })
    },
    async post(
      { commit, dispatch },
      {
        role = SAV_ROLES.client,
        customerRequestId,
        kind,
        informative = false,
        message: content,
        attachmentHandles,
      },
    ) {
      const body = {
        kind,
        message: content,
        attachmentHandles,
      }

      if (isBackcareAgent(role) || isAdmin(role)) {
        body.isInformative = informative
      }

      const { payload: message } = await dispatch(
        'http/request',
        {
          request: postMessageCustomerRequestDiscussion,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
          body,
        },
        { root: true },
      )

      commit('addMessage', { message })
    },
    async rate({ commit, getters, dispatch }, { messageId, rates }) {
      const { rates: previousRates } = getters.message(messageId)

      commit('updateRates', { messageId, rates })

      try {
        await dispatch(
          'http/request',
          {
            request: rateMessageCustomerRequestDiscussion,
            pathParams: { messageId },
            body: rates,
          },
          { root: true },
        )
      } catch (error) {
        commit('updateRates', {
          messageId,
          rates: previousRates,
        })

        throw error
      }
    },
  },
}
