
import MenuMainContent from './MenuMainContent.vue'
import MenuSidebar from './MenuSidebar.vue'

export default {
  components: {
    MenuSidebar,
    MenuMainContent,
  },
  props: {
    items: { type: Array, default: () => [] },
    image: { type: String, default: '' },
    title: { type: String, default: '' },
    link: { type: Object, default: () => {} },
  },

  computed: {
    primaryItems() {
      return this.items.filter((item) => item.menuType !== 'secondary')
    },
    secondaryItems() {
      return this.items.filter((item) => item.menuType === 'secondary')
    },
  },
}
