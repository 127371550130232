export const PAYMENT_SERVICE_PROVIDERS = {
  ADYEN_MARKETPAY: 'adyen_marketpay',
  ADYEN_PAYIN_PAYOUT: 'adyen',
  PAYPAL: 'paypal',
  PROCESSOUT: 'processout',
}

export const PAYMENT_GROUPS = {
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  APPLE_PAY: 'apple_pay',
  CARD: 'card',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  KLARNA_PAY_LATER: 'klarna_pay_later',
  KLARNA_SLICE_IT: 'klarna_slice_it',
  KONBINI: 'konbini',
  MBWAY: 'mbway',
  ONEY: 'oney',
  PAYPAL: 'paypal',
  PROCESSOUT_SANDBOX: 'processout_sandbox',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
}

export const PAYMENT_METHODS = {
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  APPLE_PAY: 'apple_pay',
  CARD: 'card',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  KLARNA_PAY_LATER: 'klarna_pay_later',
  KLARNA_SLICE_IT: 'klarna_slice_it',
  MBWAY: 'mbway',
  ONEY10X: 'oney10x',
  ONEY12X: 'oney12x',
  ONEY3X: 'oney3x',
  ONEY4X: 'oney4x',
  ONEY6X: 'oney6x',
  PAYPAL: 'paypal',
  PROCESSOUT_SANDBOX: 'processout_sandbox',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
  KONBINI_GENERIC: 'konbini_generic',
}

export const PAYMENT_NETWORKS = {
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  AMERICAN_EXPRESS: 'amex',
  APPLE_PAY: 'apple_pay',
  BANCONTACT: 'bancontact',
  CARTES_BANCAIRES: 'cb',
  DISCOVER: 'discover',
  EPS: 'eps',
  FAMILYMART: 'familymart',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  JCB: 'jcb',
  KLARNA: 'klarna',
  LAWSON: 'lawson',
  MAESTRO: 'maestro',
  MASTERCARD: 'mastercard',
  MBWAY: 'mbway',
  MINISTOP: 'ministop',
  ONEY: 'oney',
  PAYEASY: 'payeasy',
  PAYPAL: 'paypal',
  POSTEPAY: 'postepay',
  PROCESSOUT: 'processout',
  SEICOMART: 'seicomart',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
  VISA: 'visa',
}

export const PAYMENT_METHOD_ERRORS = {
  DISABLED: 'not_activated',
  COUNTRY_DISABLED: 'country_not_activated',
  ONLY_MERCHANT_INCOMPATIBLE: 'merchant_not_valid',
  ONE_MERCHANT_INCOMPATIBLE: 'one_merchant_is_not_valid',
  MANY_MERCHANTS_INCOMPATIBLE: 'incompatible_merchants',
  PRICE_INCOMPATIBLE: 'price_not_eligible',
  INSURANCE_INCOMPATIBLE: 'insurance_subscription',
}

export const PAYMENT_ICON_PATHS = {
  // '-v2' was added to the folder name to reset the icon cache.
  // (Assets are uploaded with lifetime cache, so renaming is the only way)
  GROUPS: '/img/payment/groups-v3',
  METHODS: '/img/payment/methods-v4',
  NETWORKS: '/img/payment/networks-v4',
}

export const PAYMENT_INTEGRATION_TYPES = {
  ADYEN_CHECKOUT_API: 'adyen_checkout_api',
  HPP: 'hpp',
  NATIVE: 'native',
}

export const PAYMENT_ARCHITECTURES = {
  LEGACY: 'legacy',
  FINPAY: 'finpay',
}

export const PAYMENT_FRAUD_CHECK_PARTNERS = {
  NONE: '',
  SIGNIFYD: 'signifyd',
  RAVELIN: 'ravelin',
}
