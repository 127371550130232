import { removeEmptyValuesInObject } from '@core/helpers'

export const reviewModel = ({
  reviewPosition,
  reviewScore,
  sourceCountry,
  isReviewFromCurrentCountry,
  isReviewTranslated,
}) => {
  return removeEmptyValuesInObject({
    review_position_impression: reviewPosition,
    review_score_impression: reviewScore,
    review_from_country: isReviewFromCurrentCountry,
    review_language: sourceCountry,
    review_translated: isReviewTranslated,
  })
}

export const globalRatingModel = ({ averageRate, reviewsCount }) => {
  return removeEmptyValuesInObject({
    average_rate: averageRate,
    reviews_total_count: reviewsCount,
  })
}
