import translations from './Listings.translations'

export const PUBLICATION_STATES = {
  MISSING_INFOS: { value: 'missing_infos', label: translations.missingInfos },
  IN_VALIDATION: { value: 'in_validation', label: translations.inValidation },
  ACTIVE: { value: 'active', label: translations.active },
  ARCHIVED: { value: 'archived', label: translations.archived },
}

export const PUBLICATION_STATES_API = {
  [PUBLICATION_STATES.MISSING_INFOS.value]: 'MISSING_PRICE_OR_COMMENT',
  [PUBLICATION_STATES.IN_VALIDATION.value]: 'IN_VALIDATION',
  [PUBLICATION_STATES.ACTIVE.value]: ['ONLINE', 'OFFLINE'],
  [PUBLICATION_STATES.ARCHIVED.value]: 'DISABLED',
}

// This object is temporary and is meant to be deleted once all
// listing related API returns publicationState "Code"
// This "Code" should become the new "PUBLICATION_STATES[XX].value"
export const PUBLICATION_STATES_VALUE_TO_CODE = {
  [PUBLICATION_STATES.MISSING_INFOS.value]: 'MISSING_PRICE_OR_COMMENT',
  [PUBLICATION_STATES.IN_VALIDATION.value]: 'IN_VALIDATION',
  [PUBLICATION_STATES.ACTIVE.value]: 'ACTIVE',
  [PUBLICATION_STATES.ARCHIVED.value]: 'DISABLED',
}
export const MARKET_PUBLICATION_STATE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

export const GRADES = {
  PREMIUM: 'PREMIUM',
  SHINY: 'SHINY',
  GOLD: 'GOLD',
  SILVER: 'SILVER',
  BRONZE: 'BRONZE',
  STALLONE: 'STALLONE',
}

// TODO: rename to stock-asc and stock-desc once API applied the changes (MCC-2650)
export const ORDER_BY = {
  STOCK_ASC: 'quantity',
  STOCK_DESC: '-quantity',
  ALPHABETICAL_ASC: 'title',
  ALPHABETICAL_DESC: '-title',
}

export const SORT_OPTIONS = [
  {
    label: translations.sortStockAscending,
    value: ORDER_BY.STOCK_ASC,
  },
  {
    label: translations.sortStockDescending,
    value: ORDER_BY.STOCK_DESC,
  },
  {
    label: translations.sortAlphabeticalAscending,
    value: ORDER_BY.ALPHABETICAL_ASC,
  },
  {
    label: translations.sortAlphabeticalDescending,
    value: ORDER_BY.ALPHABETICAL_DESC,
  },
]

export const VIEWS = {
  SIMPLE: 'simple',
  DETAILED: 'detailed',
}

export const DEFAULT_PAGE = 1
export const DEFAULT_TAB = 0
export const DEFAULT_LISTINGS_PER_PAGE_COUNT = 10
export const LISTINGS_PER_PAGE_COUNT_OPTIONS = [
  DEFAULT_LISTINGS_PER_PAGE_COUNT,
  20,
  50,
  100,
]
export const DEFAULT_ORDER_BY = ORDER_BY.STOCK_DESC
export const DEFAULT_PUBLICATION_STATE = PUBLICATION_STATES.ACTIVE.value

export const SPECIAL_OFFER_TYPES = {
  NEW_BATTERY: 'NEW_BATTERY',
}

// If we don't differentiate the merchants that the staff may
// be impersonating, he will have the same main market for each merchant
export const MAIN_MARKET_COOKIE = 'BM_MCC_[ID]_SELECTED_MARKET'

// If we don't differentiate the merchants that the staff may
// be impersonating, he will have the same main view for each merchant
export const VIEW_COOKIE = 'BM_MCC_[ID]_SELECTED_VIEW'

export const MIN_STOCK = 0
export const MIN_PRICE = 1

export const FILTER_FIELDS = {
  TITLE: 'title',
  SKU: 'sku',
  UUID: 'uuid',
  BACKMARKET_ID: 'backmarketId',
  GRADE: 'grade',
  BUYBOX: 'buybox',
  STOCK: 'stock',
  ALERT: 'alert',
  MARKET: 'market',
  CATEGORY_ID: 'categoryId',
  HAS_NEW_BATTERY: 'hasNewBattery',
}

export const STOCK = {
  WITH_STOCK: 'with_stock',
  WITHOUT_STOCK: 'without_stock',
}

export const ALERTS = {
  PRICE_OVER_NEW: 'price_over_new',
}

export const FILTER_FIELDS_LIST = Object.values(FILTER_FIELDS)

export const FILTERS_BY_PUBLICATION_STATE = {
  [PUBLICATION_STATES.ACTIVE.value]: FILTER_FIELDS_LIST,
  [PUBLICATION_STATES.MISSING_INFOS.value]: [
    FILTER_FIELDS.TITLE,
    FILTER_FIELDS.SKU,
    FILTER_FIELDS.GRADE,
    FILTER_FIELDS.CATEGORY_ID,
    FILTER_FIELDS.MARKET,
    FILTER_FIELDS.HAS_NEW_BATTERY,
  ],
  [PUBLICATION_STATES.IN_VALIDATION.value]: [
    FILTER_FIELDS.TITLE,
    FILTER_FIELDS.SKU,
    FILTER_FIELDS.GRADE,
    FILTER_FIELDS.CATEGORY_ID,
    FILTER_FIELDS.HAS_NEW_BATTERY,
  ],
  [PUBLICATION_STATES.ARCHIVED.value]: [
    FILTER_FIELDS.TITLE,
    FILTER_FIELDS.SKU,
    FILTER_FIELDS.GRADE,
    FILTER_FIELDS.CATEGORY_ID,
    FILTER_FIELDS.HAS_NEW_BATTERY,
  ],
}

export const ALL_VALUE = 'all'

export const CONDITIONS = {
  HAS_ENOUGH_QUANTITY: 'HAS_ENOUGH_QUANTITY',
  HAS_FREE_SHIPPING: 'HAS_FREE_SHIPPING',
  HAS_FREE_HOME_DELIVERY_SHIPPING: 'HAS_FREE_HOME_DELIVERY_SHIPPING',
  HAS_FREE_COLLECTION_POINT_SHIPPING: 'HAS_FREE_COLLECTION_POINT_SHIPPING',
  HAS_PRICE: 'HAS_PRICE',
  HAS_PRICE_AND_COMMENT: 'HAS_PRICE_AND_COMMENT',
  HAS_COMPLIANT_DELIVERY_METHODS: 'HAS_COMPLIANT_DELIVERY_METHODS',
  HAS_SHIPPING: 'HAS_SHIPPING',
  IS_NOT_DISABLED: 'IS_NOT_DISABLED',
  IS_NOT_LIMITED: 'IS_NOT_LIMITED',
  IS_NOT_TOO_EXPENSIVE: 'IS_NOT_TOO_EXPENSIVE',
  IS_NOT_RESTRICTED_ON_CATEGORY: 'IS_NOT_RESTRICTED_ON_CATEGORY',
  IS_NOT_RESTRICTED_ON_GRADE: 'IS_NOT_RESTRICTED_ON_GRADE',
  IS_PRICE_HIGHER_THAN_MAXIMUM: 'IS_PRICE_HIGHER_THAN_MAXIMUM',
  IS_PRICE_LOWER_THAN_MINIMUM: 'IS_PRICE_LOWER_THAN_MINIMUM',
  IS_PRODUCT_NOT_PENDING: 'IS_PRODUCT_NOT_PENDING',
  IS_PRODUCT_NOT_REJECTED: 'IS_PRODUCT_NOT_REJECTED',
  MERCHANT_IS_ONLINE: 'MERCHANT_IS_ONLINE',
  PRODUCT_IS_APPROVED: 'PRODUCT_IS_APPROVED',
}

export const SALES_SUSPENSIONS_KPIS = {
  DEFECTIVE: 'DEFECTIVE',
  AESTHETIC: 'AESTHETIC',
}

export const CONDITIONS_LIST = Object.values(CONDITIONS)

export const BUYBOX_STATE = {
  SYNCED: 0,
  PENDING: 1,
}

// Buybox calls should take less than 1min with a 5s interval,
// (1min = 60s) / 5s = 12 calls max
export const INTERVAL_BUYBOX_CALLS = 5000
export const MAX_RETRIES_BUYBOX_CALLS = 12

export const INTERVAL_CHECK_COMPUTING_CALL = 1500
export const PRICE_CHANGE_SUSPECT_THRESHOLD = 0.6

export const LIMITATION_ERROR_CODE = 'TOO_MANY_REQUESTS'

export const SWITCH_BUYBOX_BUTTON_DURATION = 100
