export const SELLER_FEATURES_NAMES = {
  ADDRESS_PREFILL: 'addressCountryPrefillEnabled',
  ADYEN: 'adyenEnabled',
  BACKPACK: 'backpackEnabled',
  BACKPRICER: 'backpricerEnabled',
  BACKSHIP: 'backshipEnabled',
  BIG_PRICE: 'bigPrice',
  COPY_LEVY: 'copyLevyEnabled',
  CUSTOMER_RECEIPT_ENABLED: 'customerReceiptEnabled',
  DAC7: 'dac7Enabled',
  DISTRICT_ADDRESS: 'districtAddressEnabled',
  EPR: 'eprEnabled',
  HYPER_WALLET: 'hwEnabled',
  INSIGHTS: 'insightsEnabled',
  INTERNATIONAL_ONBOARDING: 'internationalOnboardingEnabled',
  KANA_NAME: 'kanaNameEnabled',
  MULTI_ENCODING: 'multiEncodingEnabled',
  PRICING_TOOL: 'pricingToolEnabled',
  SUPPORT: 'supportEnabled',
  VAT: 'vatEnabled',
}
