
import { RevLink } from '@ds/components/Link'

export default {
  components: {
    RevLink,
  },
  props: {
    navigationItems: { type: Array, default: () => [] },
  },
}
