export const HEADER_CONTENT_TYPE = 'content-type'
export const HEADER_ACCEPT = 'accept'
export const HEADER_LANGUAGE = 'accept-language'
export const HEADER_ENCODING = 'accept-encoding'
export const HEADER_APP_PLATFORM = 'app-platform'
export const HEADER_APP_VERSION = 'app-version'
export const HEADER_APP_BUNDLE_ID = 'app-bundleid'
export const HEADER_COUNTRY = 'x-country'
export const HEADER_MARKETPLACE = 'x-marketplace'
export const HEADER_REQUEST_ID = 'x-request-id'
export const HEADER_VISITOR_ID = 'x-visitor-id'
export const HEADER_MODIFIED_SINCE = 'if-modified-since'
export const HEADER_CSRF = 'x-csrftoken'
export const HEADER_SET_COOKIE = 'set-cookie'
export const HEADER_NGINX_PROTO = 'x-forwarded-proto'
export const HEADER_NGINX_IP = 'x-forwarded-for'
export const HEADER_ALGOLIA_APP_ID = 'x-algolia-application-id'
export const HEADER_ALGOLIA_API_KEY = 'x-algolia-api-key'
export const HEADER_VERIFIED_BOT = 'x-bm-bopip-verified-bot'
export const HEADER_USER_AGENT = 'user-agent'

// headers from nginx
export const HEADER_NGINX_COUNTRY = 'x-bm-country'
export const HEADER_NGINX_MARKETPLACE = 'x-bm-marketplace'
export const HEADER_NGINX_LOCALE = 'x-bm-locale'
export const HEADER_NGINX_LANG = 'x-bm-lang'

// headers from cloudflare worker
export const HEADER_CFWORKER_COUNTRY = 'bm-country'
export const HEADER_CFWORKER_MARKETPLACE = 'bm-marketplace'
export const HEADER_CFWORKER_LOCALE = 'bm-locale'
export const HEADER_CFWORKER_LANG = 'bm-lang'
export const HEADER_CFWORKER_EXPERIMENTS = 'bm-experiments'

// GeoIP headers to relay to the services (e.g.: Badoom, micro-services)
export const HEADER_GEOIP_CF_COUNTRY = 'cf-ipcountry'
export const HEADER_GEOIP_CF_CITY = 'cf-ipcity'
export const HEADER_GEOIP_CF_LATITUDE = 'cf-iplatitude'
export const HEADER_GEOIP_CF_LONGITUDE = 'cf-iplongitude'
