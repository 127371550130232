
import { mapGetters } from 'vuex'

import translations from './Footer.translations'
import FooterColumns from './FooterColumns.vue'
import FooterHeadline from './FooterHeadline.vue'
import FooterOutro from './FooterOutro.vue'
import { getFooterConfigFor } from './footerConfig'

export default {
  components: {
    FooterOutro,
    FooterColumns,
    FooterHeadline,
  },
  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    translations: () => translations,
    columns() {
      return getFooterConfigFor(this.country).categories.map(
        ({ title, ...rest }) => ({
          title: title && this.$t(title),
          ...rest,
        }),
      )
    },
  },
}
