
import isEmpty from 'lodash/isEmpty'

import { COUNTRIES } from '@config/constants'
import IconFlagAT from '@core/icons/FlagAT.svg'
import IconFlagAU from '@core/icons/FlagAU.svg'
import IconFlagBE from '@core/icons/FlagBE.svg'
import IconFlagBG from '@core/icons/FlagBG.svg'
import IconFlagCY from '@core/icons/FlagCY.svg'
import IconFlagCZ from '@core/icons/FlagCZ.svg'
import IconFlagDE from '@core/icons/FlagDE.svg'
import IconFlagDK from '@core/icons/FlagDK.svg'
import IconFlagEE from '@core/icons/FlagEE.svg'
import IconFlagES from '@core/icons/FlagES.svg'
import IconFlagFI from '@core/icons/FlagFI.svg'
import IconFlagFR from '@core/icons/FlagFR.svg'
import IconFlagGB from '@core/icons/FlagGB.svg'
import IconFlagGR from '@core/icons/FlagGR.svg'
import IconFlagHR from '@core/icons/FlagHR.svg'
import IconFlagHU from '@core/icons/FlagHU.svg'
import IconFlagIE from '@core/icons/FlagIE.svg'
import IconFlagIT from '@core/icons/FlagIT.svg'
import IconFlagJP from '@core/icons/FlagJP.svg'
import IconFlagKR from '@core/icons/FlagKR.svg'
import IconFlagLT from '@core/icons/FlagLT.svg'
import IconFlagLU from '@core/icons/FlagLU.svg'
import IconFlagLV from '@core/icons/FlagLV.svg'
import IconFlagMT from '@core/icons/FlagMT.svg'
import IconFlagNL from '@core/icons/FlagNL.svg'
import IconFlagPL from '@core/icons/FlagPL.svg'
import IconFlagPT from '@core/icons/FlagPT.svg'
import IconFlagRO from '@core/icons/FlagRO.svg'
import IconFlagSE from '@core/icons/FlagSE.svg'
import IconFlagSI from '@core/icons/FlagSI.svg'
import IconFlagSK from '@core/icons/FlagSK.svg'
import IconFlagUS from '@core/icons/FlagUS.svg'
import { tw } from '@core/tailwind'

const flagsComponents = {
  [COUNTRIES.AT]: IconFlagAT,
  [COUNTRIES.AU]: IconFlagAU,
  [COUNTRIES.BE]: IconFlagBE,
  [COUNTRIES.BG]: IconFlagBG,
  [COUNTRIES.CY]: IconFlagCY,
  [COUNTRIES.CZ]: IconFlagCZ,
  [COUNTRIES.DE]: IconFlagDE,
  [COUNTRIES.DK]: IconFlagDK,
  [COUNTRIES.EE]: IconFlagEE,
  [COUNTRIES.ES]: IconFlagES,
  [COUNTRIES.FI]: IconFlagFI,
  [COUNTRIES.FR]: IconFlagFR,
  [COUNTRIES.GB]: IconFlagGB,
  [COUNTRIES.GR]: IconFlagGR,
  [COUNTRIES.HR]: IconFlagHR,
  [COUNTRIES.HU]: IconFlagHU,
  [COUNTRIES.IE]: IconFlagIE,
  [COUNTRIES.IT]: IconFlagIT,
  [COUNTRIES.JP]: IconFlagJP,
  [COUNTRIES.KR]: IconFlagKR,
  [COUNTRIES.LT]: IconFlagLT,
  [COUNTRIES.LU]: IconFlagLU,
  [COUNTRIES.LV]: IconFlagLV,
  [COUNTRIES.MT]: IconFlagMT,
  [COUNTRIES.NL]: IconFlagNL,
  [COUNTRIES.PL]: IconFlagPL,
  [COUNTRIES.PT]: IconFlagPT,
  [COUNTRIES.RO]: IconFlagRO,
  [COUNTRIES.SE]: IconFlagSE,
  [COUNTRIES.SI]: IconFlagSI,
  [COUNTRIES.SK]: IconFlagSK,
  [COUNTRIES.US]: IconFlagUS,
}

export const SIZES = { s: 's', m: 'm', l: 'l', xl: 'xl', xxl: 'xxl' }

export default {
  inheritAttrs: false,
  props: {
    countryCode: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: SIZES.m,
      validator: (size) => isEmpty(size) || Object.values(SIZES).includes(size),
    },
  },
  computed: {
    flagComponent() {
      return flagsComponents[this.countryCode]
    },
    sizeClass() {
      const sizeClasses = {
        [SIZES.s]: tw`h-2 w-3`,
        [SIZES.m]: tw`h-3 w-[1.8rem]`,
        [SIZES.l]: tw`h-4 w-6`,
        [SIZES.xl]: tw`h-5 w-[3rem]`,
        [SIZES.xxl]: tw`h-3 w-[1.8rem]`,
        default: tw`h-3 w-[1.8rem]`,
      }

      return sizeClasses[this.size] || sizeClasses.default
    },
  },
}
