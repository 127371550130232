export const PAYMENT_ADVERTISING_TYPES = {
  CART: 'cart',
  PRODUCT: 'product',
  LANDING: 'landing',
  SEARCH: 'search',
  ORDERS: 'orders',
}

export const PAYMENT_ADVERTISING_LOG_TYPES = {
  LIBRARY_INITIALIZATION_START:
    'PAYMENT_ADVERTISING_LIBRARY_INITIALIZATION_START',
  LIBRARY_INITIALIZATION_ERROR:
    'PAYMENT_ADVERTISING_LIBRARY_INITIALIZATION_ERROR',
}
