import isEmpty from 'lodash/isEmpty'

import { isBrowser } from '@core/helpers'

// We need to add a try/catch in order to prevent errors when the browser settings disabled the session storage
// https://stackoverflow.com/questions/58152236/what-to-do-when-sessionstorage-access-denied-due-to-browser-settings

export const canUseSessionStorage = () => isBrowser() && window.sessionStorage

export default {
  setItem: (key, value) => {
    try {
      if (canUseSessionStorage()) {
        window.sessionStorage.setItem(key, value)
      }
    } catch {
      // fails silently
    }
  },
  getItem: (key) => {
    try {
      if (canUseSessionStorage()) {
        return window.sessionStorage.getItem(key)
      }
    } catch {
      // fails silently
    }

    return null
  },
  removeItem: (key) => {
    try {
      if (canUseSessionStorage()) {
        return window.sessionStorage.removeItem(key)
      }
    } catch {
      // fails silently
    }

    return null
  },
  hasKey: (key) => {
    try {
      if (canUseSessionStorage()) {
        return !isEmpty(window.sessionStorage.getItem(key))
      }
    } catch {
      // fails silently
    }

    return null
  },
}
