/**
 * we'll get something like:
 * - https://preprod.backmarket.fr
 * - or https://www.backmarket.com
 * - or https://www.backmarket.co.uk
 *
 * And we want to return the proper url:
 * - https://accounts.preprod.backmarket.fr
 * - or https://accounts.backmarket.com
 * - or https://accounts.backmarket.co.uk
 *
 * @param {string} apiBaseUrl
 * @returns {string}
 */
export const getIssuerUrl = (apiBaseUrl) => {
  const { hostname } = new URL(apiBaseUrl)
  const [subdomain] = hostname.split('.').slice()

  // if we are on preprod environment,
  // we need to prepend `accounts` to `preprod` subdomain
  // (this also works for local development)
  if (subdomain === 'preprod') {
    return apiBaseUrl.replace(subdomain, 'accounts.preprod')
  }

  return apiBaseUrl.replace(subdomain, 'accounts')
}
