import isEmpty from 'lodash/isEmpty'

export const getCookies = (requestCookies = '') => {
  const cookiesString =
    (process.client ? document.cookie : requestCookies) || ''

  return new Map(cookiesString.split('; ').map((cookie) => cookie.split('=')))
}

/**
 * Get the value of a given cookie.
 *
 * @param {string} cookieName The name of the cookie we want the value of.
 * @param {string} requestCookies The cookies string coming the Nuxt `req`uest, if any.
 * @return {*} The value of the cookie.
 */
export const getCookie = (cookieName, requestCookies) => {
  return getCookies(requestCookies).get(cookieName)
}

/**
 * Set the value for a cookie
 *
 * @param {string} cookieName The name of the cookie to set.
 * @param {*} value The value to set to the cookie.
 */
export const setCookie = (
  cookieName,
  value,
  {
    domain,
    maxAge,
    expires,
    path = '/',
    secure = false,
    httpOnly = false,
  } = {},
) => {
  const domainParam = isEmpty(domain) ? '' : `; domain=${domain}`
  const pathParam = isEmpty(path) ? '' : `; path=${path}`
  const maxAgeParam = isEmpty(maxAge) ? '' : `; max-age=${maxAge}`
  const expiresParam = isEmpty(expires) ? '' : `; expires=${expires}`
  const secureParam = secure ? '; secure' : ''
  const httpOnlyParam = httpOnly ? '; HttpOnly' : ''

  document.cookie = `${cookieName}=${value}${domainParam}${pathParam}${maxAgeParam}${expiresParam}${secureParam}${httpOnlyParam}`
}

export const parseCookiesFromNodeRequest = (request) => {
  const cookies = request.headers.cookie?.split(';') ?? []

  return cookies.reduce((acc, cookie) => {
    const parts = cookie.split('=')

    return {
      ...acc,
      [parts.shift().trim()]: parts.join('='),
    }
  }, {})
}
