import { splitHostname } from '@core/helpers'
import { createTTL } from '@core/helpers/date'

const COOKIE_EXPIRATION_DATE = 6

export function createConfig() {
  const { domain, extension } = splitHostname(window.location.origin)

  return {
    domain: `${domain}.${extension}`,
    expires: createTTL({ months: COOKIE_EXPIRATION_DATE }).toUTCString(),
  }
}
