import { isArray, isUndefined } from '@core/helpers'
import { ROUTES } from '@router'

/**
 * Redirect the user if they're authenticated as a merchant.
 *
 * @param {Object} context
 * @param {Function} context.redirect
 * @param {Object} context.store
 * @return {Promise}
 */
export default async ({ redirect, route, store }) => {
  // Matches are listed starting from the first parent, and going through every route
  // until we hit the current route. For example, having three routes (A > B > C)
  // would generate a `matched` array [A, B, C]. Since the route defined latter
  // should take precedence over its parents, we reverse the whole array.
  const reversed = [...route.matched].reverse()

  // Since we reversed the array beforehand, we can now use `Array#find` to traverse
  // the whole array and return the _first_ route matching the given predicate.
  const match = reversed.find(
    (routeDefinition) => routeDefinition.meta && routeDefinition.meta.notSeller,
  )

  // We may request a routes tree not protected at all.
  if (isUndefined(match)) return null

  // We don't want to fire the request each time this middleware is instanciated.
  // If the authentication status was previously fetched already, there is no
  // need to fetch it again, and we should use the data saved in our store.
  if (store.getters['auth/fetched'] === false) {
    await store.dispatch('user/fetch')
  }

  const { required = false, redirection = { name: ROUTES.BO_MERCHANT.HOME } } =
    match.meta.notSeller

  if (required === false) return null

  if (!store.getters['user/isMerchant']) {
    return null
  }

  // The `redirect` function from Nuxt accepts either a path (as string), or a more
  // complexe configuration using multiple arguments. Since we want to allow the
  // developers to have full power over this, we also accept an array (tupple).
  if (isArray(redirection)) {
    return redirect(...redirection)
  }

  return redirect(redirection)
}
