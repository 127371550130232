import { TRACKING_PROVIDERS } from './constants'
import { createTrackEvent } from './providers'

export const trackContext = createTrackEvent({
  name: 'context',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.NOODLE,
    TRACKING_PROVIDERS.AMPLITUDE,
  ],
})

export const trackModal = createTrackEvent({
  name: 'modal',
  providers: [
    TRACKING_PROVIDERS.AMPLITUDE,
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.NOODLE,
  ],
})

export const trackToast = createTrackEvent({
  name: 'toast',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackResize = createTrackEvent({
  name: 'resize',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.NOODLE,
    TRACKING_PROVIDERS.AMPLITUDE,
  ],
})

export const trackPageView = createTrackEvent({
  name: 'pageView',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.NOODLE,
    TRACKING_PROVIDERS.AMPLITUDE,
  ],
})

/**
 * Track changes within a page that are not automatically
 * covered by `trackPageView`.
 * Useful for e.g. multi-step processes where we do not
 * do a navigation.
 */
export const trackScreenView = createTrackEvent({
  name: 'screenView',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackClick = createTrackEvent({
  name: 'click',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.NOODLE,
    TRACKING_PROVIDERS.AMPLITUDE,
  ],
})

export const trackHoverOver = createTrackEvent({
  name: 'hoverOver',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackChange = createTrackEvent({
  name: 'change',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackSwapClick = createTrackEvent({
  name: 'click',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackUserContext = createTrackEvent({
  name: 'userContext',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.NOODLE,
    TRACKING_PROVIDERS.AMPLITUDE,
    TRACKING_PROVIDERS.BRAZE,
  ],
})

export const trackCarouselClick = createTrackEvent({
  name: 'clickCarousel',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackCarouselImpression = createTrackEvent({
  name: 'viewCarousel',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackReviewImpression = createTrackEvent({
  name: 'reviewImpression',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackReviewBlockImpression = createTrackEvent({
  name: 'reviewBlockImpression',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackEcoBlockImpression = createTrackEvent({
  name: 'ecoBlockImpression',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackVideosBlockImpression = createTrackEvent({
  name: 'videosBlockImpression',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackVideoImpression = createTrackEvent({
  name: 'videoImpression',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackFormSubmit = createTrackEvent({
  name: 'submit',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackError = createTrackEvent({
  name: 'error',
  providers: [TRACKING_PROVIDERS.GA],
})

// Creating a new event for now as we want to control our costs
export const trackErrors = createTrackEvent({
  name: 'errors',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackFunnel = createTrackEvent({
  name: 'funnel',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackProductPage = createTrackEvent({
  name: 'product',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.AMPLITUDE,
    TRACKING_PROVIDERS.BRAZE,
  ],
})

export const trackRemoveFromCart = createTrackEvent({
  name: 'removeFromCart',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackAddToCart = createTrackEvent({
  name: 'addToCart',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.AMPLITUDE,
    TRACKING_PROVIDERS.BRAZE,
  ],
})

export const trackAddToCartBatch = createTrackEvent({
  name: 'addToCartBatch',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.AMPLITUDE,
    TRACKING_PROVIDERS.BRAZE,
  ],
})

export const trackLandingPage = createTrackEvent({
  name: 'landing',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackBuybackConfirmation = createTrackEvent({
  name: 'buybackConfirmation',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackBuybackNoOffer = createTrackEvent({
  name: 'buybackNoOffer',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackBuybackDeviceSpecifications = createTrackEvent({
  name: 'buybackDeviceSpecifications',
  providers: [TRACKING_PROVIDERS.BRAZE],
})

export const trackSwap = createTrackEvent({
  name: 'swap',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackWebPerformance = createTrackEvent({
  name: 'webPerformance',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackWebPerformanceAll = createTrackEvent({
  name: 'webPerformanceAll',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackGDPR = createTrackEvent({
  name: 'gdpr',
  providers: [
    TRACKING_PROVIDERS.GA,
    TRACKING_PROVIDERS.AMPLITUDE,
    TRACKING_PROVIDERS.BRAZE,
  ],
})

export const trackSwapModal = createTrackEvent({
  name: 'swapModal',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackResetPassword = createTrackEvent({
  name: 'resetPassword',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackResetPasswordSuccess = createTrackEvent({
  name: 'resetPasswordSuccess',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackSignIn = createTrackEvent({
  name: 'signIn',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackSignUp = createTrackEvent({
  name: 'signUp',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackSearchAnalytics = createTrackEvent({
  name: 'searchAnalytics',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackSearchTerm = createTrackEvent({
  name: 'searchTerm',
  providers: [TRACKING_PROVIDERS.BRAZE],
})

// Tracks the impression of a product card seen by the user.
export const trackProductImpression = createTrackEvent({
  name: 'productImpression',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

// Tracks the impression of N product(s) card seen by the user in a batched event.
export const trackProductImpressionBatched = createTrackEvent({
  name: 'productImpressionBatched',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackProductClick = createTrackEvent({
  name: 'productClick',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackProductBlockImpression = createTrackEvent({
  name: 'productBlockImpression',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackRumView = createTrackEvent({
  name: 'rumView',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackComponentDeprecation = createTrackEvent({
  name: 'componentDeprecation',
  providers: [TRACKING_PROVIDERS.LOGGER],
})

export const trackPaymentFormDisplay = createTrackEvent({
  name: 'paymentFormDisplay',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentFormMethodSelect = createTrackEvent({
  name: 'paymentFormMethodSelect',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentFormSubmitStart = createTrackEvent({
  name: 'paymentFormSubmitStart',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentFormSubmitCancel = createTrackEvent({
  name: 'paymentFormSubmitCancel',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentFormSubmitError = createTrackEvent({
  name: 'paymentFormSubmitError',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentFormSubmitSuccess = createTrackEvent({
  name: 'paymentFormSubmitSuccess',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentResultSuccessWithoutNoodle = createTrackEvent({
  name: 'paymentSuccess',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackPaymentResultSuccessWithoutNoodleTmp = createTrackEvent({
  name: 'paymentSuccessTmp',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackPaymentResultFailureWithoutNoodle = createTrackEvent({
  name: 'paymentFail',
  providers: [TRACKING_PROVIDERS.GA],
})

export const trackPaymentResultSuccessWithNoodle = createTrackEvent({
  name: 'paymentResultSuccess',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackPaymentResultFailureWithNoodle = createTrackEvent({
  name: 'paymentResultFailure',
  providers: [TRACKING_PROVIDERS.NOODLE],
})

export const trackLandingBannerClick = createTrackEvent({
  name: 'landingBannerClick',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackLandingBannerImpression = createTrackEvent({
  name: 'landingBannerImpression',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackPromptNotificationImpression = createTrackEvent({
  name: 'promptNotificationImpression',
  providers: [TRACKING_PROVIDERS.GA, TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackAddToMyFavorites = createTrackEvent({
  name: 'addToMyFavorites',
  providers: [TRACKING_PROVIDERS.AMPLITUDE, TRACKING_PROVIDERS.BRAZE],
})

export const trackPostPurchaseProduct = createTrackEvent({
  name: 'postPurchaseProduct',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackToastView = createTrackEvent({
  name: 'toastView',
  providers: [TRACKING_PROVIDERS.AMPLITUDE],
})

export const trackFavoritesPageView = createTrackEvent({
  name: 'favoritesPageView',
  providers: [TRACKING_PROVIDERS.AMPLITUDE, TRACKING_PROVIDERS.BRAZE],
})
