
import { RevContainer } from '@ds/components/Container'

export default {
  components: { RevContainer },
  props: {
    defaultWidthClass: {
      type: String,
      default: '',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
}
