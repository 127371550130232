import algoliasearchHelper from 'algoliasearch-helper'
import algoliasearch from 'algoliasearch/lite'
import isEmpty from 'lodash/isEmpty'

// Docs: https://community.algolia.com/algoliasearch-helper-js/reference.html

export const FACET_FILTER = 'facet_filter'
export const FACET_PRICE = 'price'
export const FACET_GRAPH = 'chart'
export const FACET_PAGE = 'page'
export const FACET_SORT = 'sort'
export const FACET_QUERY = 'q'
export const FACET_CARRIER = 'compatible_carriers_facet'
const PAGINATION = 30

export const normalizeFacetLabel = (label, sorted) => {
  if (sorted) {
    const [id, ...words] = label.split(' ')

    return {
      id: Number(id),
      label: words.join(' '),
    }
  }

  return {
    id: 0,
    label,
  }
}

export const orderFacets = (facets, manualSort = false) => {
  const facetsById = Object.entries(facets).reduce(
    (acc, [facetValue, count]) => {
      const { label, id } = normalizeFacetLabel(facetValue, manualSort)

      return [
        ...acc,
        {
          label,
          facet_value: facetValue,
          id,
          count,
        },
      ]
    },
    [],
  )

  facetsById.sort((a, b) => a.id - b.id)

  // The id is only necessary to sort
  return facetsById.map(({ id, ...facetWithoutId }) => facetWithoutId)
}

export const useAlgolia = ({
  ALGOLIA_ID,
  ALGOLIA_KEY,
  context,
  searchParams,
  enableReRanking = false,
} = {}) =>
  new Promise((resolve, reject) => {
    const {
      page: searchPage,
      q,
      sort,
      price,
      ...activeFilters
    } = searchParams.toFilters()

    const facetsWhitelist = context.facets.map((facet) => facet.name)

    const filtersWhitelisted = Object.entries(activeFilters).reduce(
      (acc, [name, values]) => {
        if (facetsWhitelist.includes(name)) {
          return { ...acc, [name]: values }
        }

        return acc
      },
      {},
    )

    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY)
    const indexes = [context.indexes.active, ...(context.indexes.other ?? [])]
    const indexObject = sort
      ? indexes.find((item) => item.name === sort[0]) || {}
      : context.indexes.active || {}

    const { queryParams, name: index } = indexObject

    const facetGraph = context.priceFacet
    const scales = facetGraph
      ? facetGraph.scales.map((scale) => scale.facetName)
      : []

    const facetPriceFilter = facetGraph ? facetGraph.name : FACET_PRICE

    const OR_FACETS = Object.entries(filtersWhitelisted)

    const AND_FACETS_NAME = [...facetsWhitelist, ...scales]
    //  The price has to be there, to be able to retrieve the stats
    const OR_FACETS_NAME = [
      ...Object.keys(filtersWhitelisted),
      facetPriceFilter,
    ]

    const helper = algoliasearchHelper(client, index, {
      facets: AND_FACETS_NAME,
      disjunctiveFacets: OR_FACETS_NAME,
      ...queryParams,
      analyticsTags: searchParams.tags,
      filters: context.complexFilter,
      getRankingInfo: true,
      highlightPreTag: '<span class="font-light">',
      highlightPostTag: '</span>',
      enableReRanking,
    })

    helper.clearCache()

    OR_FACETS.forEach(([filterName, filterValues]) => {
      filterValues.forEach((filterValue) => {
        helper.addDisjunctiveFacetRefinement(filterName, filterValue)
      })
    })

    helper.setQueryParameter('hitsPerPage', PAGINATION)

    if (q) {
      helper.setQuery(q)
    }

    if (!isEmpty(price)) {
      const prices = price.length > 1 ? price : [0, ...price]
      // apply min and max on price
      helper.addNumericRefinement(facetPriceFilter, '>=', Number(prices[0]))
      helper.addNumericRefinement(facetPriceFilter, '<=', Number(prices[1]))
    }

    // Algolia's pagination starts from 0
    helper.setPage(searchPage - 1)

    helper.on('result', ({ results }) => {
      const {
        disjunctiveFacets,
        hits,
        page,
        nbPages,
        nbHits,
        facets: facetsResponse,
        queryID,
        processingTimeMS: searchProcessingTime,
      } = results
      // remove scale facets
      const mergedFacets = facetsResponse
        .filter((facet) => !scales.includes(facet.name))
        .map((facet) => {
          const hasDisjunctiveFacet = disjunctiveFacets.find(
            (f) => f.name === facet.name,
          )

          return hasDisjunctiveFacet || facet
        })

      const graphFacets = results.facets.filter((facet) =>
        scales.includes(facet.name),
      )

      const payload = {
        index,
        searchParams,
        hits,
        page,
        nbPages,
        nbHits,
        facets: [...mergedFacets, ...graphFacets],
        stats: results.getFacetStats(facetPriceFilter),
        called: true,
        queryID,
        searchProcessingTime,
      }

      resolve(payload)
    })

    helper.on('error', (event) => {
      reject(event.error)
    })

    helper.search()
  })
