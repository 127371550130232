import discussion from './modules/discussion'
import information from './modules/information'

export default {
  namespaced: true,
  modules: {
    information,
    discussion,
  },
}
