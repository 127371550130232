
import { mapGetters } from 'vuex'

import { BASE_META, DEFAULT_META_IMAGE, getMetas } from '@core/helpers/head'
import Footer from '@navigation/components/Footer'
import Header from '@navigation/components/Header.vue'

import translations from './default.translations'

export default {
  name: 'DefaultLayout',
  components: {
    Footer,
    Header,
  },

  head() {
    return {
      titleTemplate: `%s | ${this.$t(translations.companyName)}`,

      htmlAttrs: {
        lang: this.locale,
      },

      meta: [
        ...BASE_META,
        ...getMetas({
          image: this.$static(DEFAULT_META_IMAGE),
          url: `${this.baseURL}${this.$route.fullPath}`,
        }),
      ],
    }
  },
  computed: {
    ...mapGetters({
      locale: 'config/locale',
      baseURL: 'config/baseURL',
    }),
  },
}
