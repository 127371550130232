const getDefaultState = () => ({
  currentStep: 1,
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    get: (state) => state.currentStep,
  },
  mutations: {
    next: (state) => {
      state.currentStep += 1
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    next: ({ commit }) => commit('next'),
    reset: ({ commit }) => commit('reset'),
  },
}
