import isEmpty from 'lodash/isEmpty'

import { sortByProperty } from '@core/helpers'
import { fetchPaymentMethods as fetchPaymentMethodsRequest } from '@http/endpoints'
import { getPlugin as getTranslationPlugin } from '@i18n/utils'

import {
  PAYMENT_GROUPS,
  PAYMENT_ICON_PATHS,
  PAYMENT_METHODS,
  PAYMENT_NETWORKS,
} from '../config/constants'
import {
  PAYMENT_GROUP_CONFIGS,
  PAYMENT_GROUP_DYNAMIC_CONFIGS,
  PAYMENT_METHOD_CONFIGS,
  PAYMENT_NETWORK_CONFIGS,
} from '../config/methods'

/**
 * @param {import('@payment').PaymentMethod} paymentMethod
 * @returns {boolean} `true` if the payment method is enabled, `false` otherwise.
 */
export function isPaymentMethodEnabled(paymentMethod) {
  return paymentMethod.enabled !== false
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod - Payment method.
 * @returns {boolean} `true` if the payment method is debit/credit card, `false` otherwise.
 */
export function isCreditCard(paymentMethod) {
  return paymentMethod.group === PAYMENT_GROUPS.CARD
}

/**
 * @param {string} paymentMethodId
 * @returns {import('../config/methods').PaymentMethodConfig}
 */
function paymentMethodConfigFallback(paymentMethodId) {
  const name = {
    id: `Missing payment method config for "${paymentMethodId}"`,
    defaultMessage: paymentMethodId.toUpperCase(),
  }

  return { name, label: name }
}

/**
 * @param {string} paymentMethodId
 * @returns {import('../config/methods').PaymentMethodConfig}
 */
export function paymentMethodConfig(paymentMethodId) {
  return (
    PAYMENT_METHOD_CONFIGS[paymentMethodId] ||
    paymentMethodConfigFallback(paymentMethodId)
  )
}

/**
 * @param {string} paymentMethodId
 * @returns {string} The Payment Method's name
 */
export function paymentMethodName(paymentMethodId) {
  const $t = getTranslationPlugin()
  const { name, ...config } = paymentMethodConfig(paymentMethodId)

  return $t(name, config)
}

/**
 * @param {string} paymentMethodId
 * @returns {string} The Payment Method's name
 */
export function paymentMethodLabel(paymentMethodId) {
  const $t = getTranslationPlugin()
  const { label, ...config } = paymentMethodConfig(paymentMethodId)

  return $t(label, config)
}

/**
 * @param {string} paymentMethodId
 * @returns {string|null} The Payment Method's icon, if any.
 */
export function paymentMethodIcon(paymentMethodId) {
  return [
    ...Object.values(PAYMENT_METHODS),
    // Clearpay / GooglePay are only implemented on front-apps
    // This is here to allow displaying the icon on the BO seller order page before the FSM migration of this page.
    // cf. https://backmarket.atlassian.net/browse/PAYIN-4104
    'clearpay',
    'google_pay',
  ].includes(paymentMethodId)
    ? `${PAYMENT_ICON_PATHS.METHODS}/${paymentMethodId}.svg`
    : null
}

/**
 * @param {string} paymentMethodId
 * @returns {number}
 */
export function paymentMethodInstallmentCount(paymentMethodId) {
  return paymentMethodConfig(paymentMethodId).installmentCount || 0
}

/**
 * @param {string} paymentGroupId
 * @returns {import('../config/methods').PaymentGroupConfig}
 */
function paymentGroupConfigFallback(paymentGroupId) {
  const providerName = paymentGroupId.toUpperCase()

  return {
    hasCustomIcon: false,
    label: {
      id: `Missing payment group config for "${paymentGroupId}"`,
      defaultMessage: providerName,
    },
    providerName,
    messages: [],
  }
}

/**
 * @param {string} paymentGroupId
 * @param {import('../config/methods').PaymentGroupDynamicConfigOptions} [options] - Options to pass to the dynamic config.
 * @returns {import('../config/methods').PaymentGroupConfig}
 */
export function paymentGroupConfig(
  paymentGroupId,
  options = {
    methods: [],
  },
) {
  return (
    PAYMENT_GROUP_DYNAMIC_CONFIGS[paymentGroupId]?.(options) ||
    PAYMENT_GROUP_CONFIGS[paymentGroupId] ||
    paymentGroupConfigFallback(paymentGroupId)
  )
}

/**
 * @param {string} paymentNetworkId
 * @returns {string} The Payment Network's label
 */
export function paymentGroupLabel(paymentNetworkId) {
  const $t = getTranslationPlugin()

  return $t(paymentGroupConfig(paymentNetworkId).label)
}

/**
 * @param {import('../config/methods').PaymentMethod} paymentGroupId
 * @returns {string|null} The Payment Group's icon
 */
export function paymentGroupIcon(group) {
  if (!Object.values(PAYMENT_GROUPS).includes(group)) {
    return null
  }

  return paymentGroupConfig(group).hasCustomIcon
    ? `${PAYMENT_ICON_PATHS.GROUPS}/${group}.svg`
    : null
}

/**
 * Some payment methods can be "grouped" together. When a group contains
 * multiple payment methods, a "method picker" will be displayed to switch
 * between them. For this component, for the top level list (radio buttons),
 * we iterate over groups. We use payment method's `group` property for this
 * purpose.
 * @see PAYMENT_GROUPS
 * @see PAYMENT_GROUP_CONFIGS
 * @see PaymentMethod
 */
export function getSortedPaymentGroups(availablePaymentMethods) {
  const $t = getTranslationPlugin()

  const availablePaymentMethodsByGroup = (availablePaymentMethods ?? []).reduce(
    (acc, method) => ({
      ...acc,
      [method.group]: [...(acc[method.group] || []), method],
    }),
    {},
  )

  const getPaymentGroupDetails = (groupId) => {
    const paymentMethods = availablePaymentMethodsByGroup[groupId]
    const config = paymentGroupConfig(groupId, { methods: paymentMethods })
    const enabledPaymentMethods = paymentMethods.filter(isPaymentMethodEnabled)

    return {
      config,
      enabledMethods: enabledPaymentMethods,
      hasManyEnabledMethods: enabledPaymentMethods.length > 1,
      hasMessages: !isEmpty(config.messages),
      id: groupId,
      isEnabled: !isEmpty(enabledPaymentMethods),
      label: $t(config.label) || groupId,
      methods: paymentMethods,
    }
  }

  const groups = Object.keys(availablePaymentMethodsByGroup).map((groupId) => ({
    id: groupId,
    ...getPaymentGroupDetails(groupId),
  }))

  return sortByProperty(groups, 'id', Object.keys(PAYMENT_GROUP_CONFIGS))
}

/**
 * @param {string} paymentNetworkId
 * @returns {import('../config/methods').PaymentNetworkConfig}
 */
function paymentNetworkConfigFallback(paymentNetworkId) {
  const providerName = paymentNetworkId.toUpperCase()

  return {
    name: {
      id: `Missing payment network config for "${paymentNetworkId}"`,
      defaultMessage: providerName,
    },
    icon: null,
  }
}

/**
 * @param {string} paymentNetworkId
 * @returns {import('../config/methods').PaymentNetworkConfig}
 */
function paymentNetworkConfig(paymentNetworkId) {
  return (
    PAYMENT_NETWORK_CONFIGS[paymentNetworkId] ||
    paymentNetworkConfigFallback(paymentNetworkId)
  )
}

/**
 * @param {string} paymentNetworkId
 * @returns {string} The Payment Network's label
 */
export function paymentNetworkLabel(paymentNetworkId) {
  const $t = getTranslationPlugin()

  return $t(paymentNetworkConfig(paymentNetworkId).name)
}

/**
 * @param {string} paymentNetworkId
 * @returns {string|null} Static path to the Payment Network's icon
 */
export function paymentNetworkIcon(paymentNetworkId) {
  if (!Object.values(PAYMENT_NETWORKS).includes(paymentNetworkId)) {
    return null
  }

  // Re-use payment method icons when a method with the same id exists. This is
  // the case for most payment networks, except: Card ones, `klarna` & `oney`.
  return Object.values(PAYMENT_METHODS).includes(paymentNetworkId)
    ? `${PAYMENT_ICON_PATHS.METHODS}/${paymentNetworkId}.svg`
    : `${PAYMENT_ICON_PATHS.NETWORKS}/${paymentNetworkId}.svg`
}

/**
 * @param {import('../config/methods').PaymentGroupConfig[]} paymentGroups
 * @returns {import('../config/methods').PaymentGroupConfig[]} `paymentGroups`,
 * sorted by order of declaration in `PAYMENT_GROUP_CONFIGS`.
 */
export function sortedPaymentGroups(paymentGroups) {
  return sortByProperty(paymentGroups, 'id', Object.keys(PAYMENT_GROUP_CONFIGS))
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod
 * @returns {Promise<boolean>}
 */
async function isPaymentMethodAvailable(paymentMethod) {
  const isAvailableInBrowser =
    PAYMENT_METHOD_CONFIGS[paymentMethod.bmCode]?.isAvailableInBrowser ||
    (() => true)

  return process.server || isAvailableInBrowser(paymentMethod.config || {})
}

/**
 * Filter a list of payment methods, and retain only those that are available
 * in the user's browser. It relies on `isAvailableInBrowser` from
 * `PAYMENT_METHOD_CONFIGS`, or consider it is always available if not defined.
 * Note that it will return the same list on server-side.
 * @param {import('@payment').PaymentMethod[]} paymentMethods
 * @returns {Promise<import('@payment').PaymentMethod[]>}
 */
export async function filterAvailablePaymentMethods(paymentMethods) {
  const availability = await Promise.all(
    paymentMethods.map(isPaymentMethodAvailable),
  )

  return paymentMethods.filter((_, i) => availability[i])
}

/**
 * Filter a list of payment methods by removing those disabled for AB testing (remove Klarna or Affirm)
 * @param {*} experiments
 * @param {Array<import('@payment').PaymentMethod>} paymentMethods
 */
export function filterABTestedPaymentMethods(experiments, paymentMethods) {
  function getBnplPaymentMethodsFilter() {
    switch (experiments?.bnplPaymentMethods) {
      // [PAYIN-2998] Klarna instead of Oney, remove Oney
      case 'klarna':
        return ({ group }) => group !== PAYMENT_GROUPS.ONEY

      // [PAYIN-2998] Klarna instead of Oney, remove Klarna
      // [PAYIN-2996] Klarna aside Oney, remove Klarna
      // [PAYIN-2997] Klarna aside Affirm, remove Klarna
      case 'oney':
      case 'affirm':
        return ({ group }) =>
          ![
            PAYMENT_GROUPS.KLARNA_PAY_LATER,
            PAYMENT_GROUPS.KLARNA_SLICE_IT,
          ].includes(group)

      // [PAYIN-2996] Klarna aside Oney, no filter needed
      // [PAYIN-2997] Klarna aside Affirm, no filter needed
      case 'klarnaAndOney':
      case 'klarnaAndAffirm':
      case 'noVariation':
      default:
        return () => true
    }
  }

  // TODO [PAYIN-3301] Cleanup once A/B test is completed
  function getOneyOffersDisplayedV2Filter() {
    switch (experiments?.oneyOffersDisplayedV2) {
      case 'oney3':
        return ({ bmCode, group }) =>
          // Remove all Oney methods but Oney 3x
          group !== PAYMENT_GROUPS.ONEY ||
          ![
            PAYMENT_METHODS.ONEY4X,
            PAYMENT_METHODS.ONEY6X,
            PAYMENT_METHODS.ONEY10X,
            PAYMENT_METHODS.ONEY12X,
          ].includes(bmCode)

      case 'oney3and4':
        return ({ bmCode, group }) =>
          // Remove all Oney methods but Oney 3x and 4x
          group !== PAYMENT_GROUPS.ONEY ||
          ![
            PAYMENT_METHODS.ONEY6X,
            PAYMENT_METHODS.ONEY10X,
            PAYMENT_METHODS.ONEY12X,
          ].includes(bmCode)

      case 'oney3and4and6':
        return ({ bmCode, group }) =>
          // Remove all Oney methods but Oney 3x and 4x
          group !== PAYMENT_GROUPS.ONEY ||
          ![PAYMENT_METHODS.ONEY10X, PAYMENT_METHODS.ONEY12X].includes(bmCode)

      case 'noVariation':
      default:
        return () => true
    }
  }

  return paymentMethods
    ?.filter(getBnplPaymentMethodsFilter())
    ?.filter(getOneyOffersDisplayedV2Filter())
}

/**
 * Filter the paymentMethods property of each grade to remove methods that are disabled for AB testing.
 * @param {*} experiments
 * @param {Array<Object>} grades
 * @returns
 */
export function filterGradesABTestedPaymentMethods(experiments, grades) {
  return grades.map((grade) => {
    return {
      ...grade,
      paymentMethods: filterABTestedPaymentMethods(
        experiments,
        grade.paymentMethods,
      ),
    }
  })
}

/**
 * @param {import('vuex').ActionContext<State, Object>} context The Vuex context object.
 * @param {Object} [options] - An object containing optional parameters.
 * @param {Array} [options.listings] - An array of listing IDs.
 * @param {number} [options.bagPrice] - The price of a bag.
 * @param {boolean} [options.hasInsurance] - A flag indicating whether insurance is included.
 * @return {Promise<import('@payment').PaymentMethod[]>} The list of payment methods.
 */
export async function fetchPaymentMethods(context, options = {}) {
  const { dispatch, rootGetters } = context
  const { listings, bagPrice, hasInsurance } = options

  const { payload } = await dispatch(
    'http/request',
    {
      request: fetchPaymentMethodsRequest,
      queryParams: {
        country_code: rootGetters['config/country'],
        listings: listings?.join(','),
        bag_price: bagPrice,
        insurance_subscription: hasInsurance,
      },
    },
    { root: true },
  )

  return payload.results
}

/**
 * Sort the payment networks by putting the preferred ones first.
 * @param {string[]} networksIds
 * @param {string[]} preferredNetworksIds
 */
export function sortPaymentNetworkByPreference(
  networksIds,
  preferredNetworksIds = null,
) {
  if (!preferredNetworksIds) {
    return networksIds
  }

  return [...(networksIds || [])].sort((brandA) =>
    preferredNetworksIds.includes(brandA) ? -1 : 1,
  )
}
