import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      path: 'tracking/:type',
      name: ROUTES.PACKAGE_TRACKING,
      component: () => dynamicImport(import('./TrackingPage.vue')),
      props: true,
    },
  ]
}
