import { COUNTRIES } from '@config/constants'

export const STORE_COUNTRIES = [
  COUNTRIES.AT,
  COUNTRIES.BE,
  COUNTRIES.DE,
  COUNTRIES.ES,
  COUNTRIES.FI,
  COUNTRIES.FR,
  COUNTRIES.GR,
  COUNTRIES.IT,
  COUNTRIES.NL,
  COUNTRIES.GB,
  COUNTRIES.IE,
  COUNTRIES.US,
  COUNTRIES.PT,
  COUNTRIES.GR,
  COUNTRIES.SK,
]

export const STORE = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
}

export const GOOGLE_STORE_REDIRECT_URL =
  'https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.backmarket'
export const APPLE_STORE_REDIRECT_URL =
  'https%3A%2F%2Fapps.apple.com%2Fapp%2Fback-market-achat-vente%2Fid1458344336'

export const APPSFLYER_MEDIA_SOURCE = 'backmarket'
export const APPSFLYER_CAMPAIGN = 'stores'
