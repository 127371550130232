import { dynamicImport } from '@core/helpers'

import { E2E_ROUTE_NAMES } from './constants'

export const E2E_ROUTES = [
  {
    path: 'e2e/:pageName',
    component: () => dynamicImport(import('../Landing.vue')),
    name: E2E_ROUTE_NAMES.PAGE,
  },
]
