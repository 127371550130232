import isEmpty from 'lodash/isEmpty'

import { computeNextPagePath } from '@core/helpers'
import {
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
} from '@customer-requests/Platform/constants'
import { getNonEmptyFilters } from '@customer-requests/Platform/helpers'
import {
  fetchCustomerRequestPlatformConversations,
  fetchPendingProductReturnCustomerRequestPlatform,
} from '@http/endpoints'
import { CUSTOMER_REQUESTS_STATES } from '@sav-common/constants'
import {
  getApiPathFromRole,
  isMerchant,
  isPending,
  isReturns,
  isStandaloneConversation,
} from '@sav-common/helpers'

export default {
  namespaced: true,

  state() {
    return {
      // TODO: [Clément] Replace this by a Map when Vue supports it.
      conversationsOrdered: [],
      conversations: {},
      count: null,
      // We need to save the context each time we fetch conversations.
      // This way, when the API call finished, we can check that we didn't
      // switch context in between and avoid flashing/overwriting content.
      // E.g., I open the SAV Platform on the 'pending' context. The call fires
      // and before the response arrives, the user changes to the 'returns'
      // context, we don't want the 'pending' results to display under the
      // 'returns' context, even for a few seconds (the time to receive the
      // response for the 'returns' context) because it can be troubling for the
      // user.
      lastContext: null,
      nextConversationsPagePath: null,
      selected: null,
    }
  },
  getters: {
    count(state) {
      return state.count || 0
    },
    get(state) {
      return (customerRequestId) => state.conversations[customerRequestId] || {}
    },
    hasMoreConversations(state) {
      return !isEmpty(state.nextConversationsPagePath)
    },
    lastContext(state) {
      return state.lastContext
    },
    list(state) {
      return state.conversationsOrdered.map(
        (customerRequestId) => state.conversations[customerRequestId],
      )
    },
    nextConversationsPagePath(state) {
      return state.nextConversationsPagePath
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    addConversations(state, { nextPageUrl, conversations }) {
      state.nextConversationsPagePath = computeNextPagePath(nextPageUrl)

      conversations.forEach((conversation) => {
        const customerRequestId = parseInt(conversation.customerRequestId, 10)

        state.conversations[customerRequestId] = {
          ...conversation,
          id: customerRequestId,
        }

        const index = state.conversationsOrdered.indexOf(customerRequestId)
        if (index > -1) {
          state.conversationsOrdered.splice(index, 1)
        }
        state.conversationsOrdered.push(customerRequestId)
      })
    },
    close(state, { customerRequestId }) {
      const conversation = state.conversations[customerRequestId]
      if (isEmpty(conversation)) {
        return
      }

      conversation.state = CUSTOMER_REQUESTS_STATES.closed
    },
    emptyConversations(state) {
      state.conversationsOrdered = []
      state.conversations = {}
    },
    emptyNextConversationPagePath(state) {
      state.nextConversationsPagePath = null
    },
    increaseCount(state, { amount = 1 } = {}) {
      state.count += amount
    },
    open(state, { customerRequestId }) {
      const conversation = state.conversations[customerRequestId]
      if (isEmpty(conversation)) {
        return
      }

      conversation.state = CUSTOMER_REQUESTS_STATES.open
    },
    removeConversation(state, { customerRequestId }) {
      state.conversations[customerRequestId] = undefined

      const index = state.conversationsOrdered.indexOf(customerRequestId)
      if (index > -1) {
        state.conversationsOrdered.splice(index, 1)
      }

      state.count -= 1
    },
    resetCount(state) {
      state.count = null
    },
    resetLastContext(state) {
      state.lastContext = null
    },
    select(state, { customerRequestId }) {
      state.selected = customerRequestId
    },
    setConversations(state, { nextPageUrl = null, conversations = [] } = {}) {
      state.nextConversationsPagePath = computeNextPagePath(nextPageUrl)

      state.conversationsOrdered = []
      state.conversations = conversations.reduce(
        (previousConversations, conversation) => {
          const customerRequestId = parseInt(conversation.customerRequestId, 10)

          state.conversationsOrdered.push(customerRequestId)

          return {
            ...previousConversations,
            [customerRequestId]: { ...conversation, id: customerRequestId },
          }
        },
        {},
      )
    },
    setCount(state, { count }) {
      state.count = count
    },
    setLastContext(state, { context }) {
      state.lastContext = context
    },
    unselect(state) {
      state.selected = null
    },
    update(
      state,
      { customerRequestId, updates, params = { moveToBottom: false } },
    ) {
      const conversation = state.conversations[customerRequestId]

      if (params.moveToBottom) {
        const index = state.conversationsOrdered.indexOf(customerRequestId)
        if (index > -1) {
          state.conversationsOrdered.splice(index, 1)
        }

        state.conversationsOrdered.push(customerRequestId)
      }

      state.conversations[customerRequestId] = {
        ...conversation,
        ...updates,
      }
    },
  },
  actions: {
    async closed(
      { commit, dispatch },
      { customerRequestId, context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      if (isMerchant(role)) {
        // Update the global counts (those displayed in the sidebar)
        dispatch(
          'customerRequestPlatform/information/fetchCounts',
          {},
          {
            root: true,
          },
        )
      }

      if (isPending(context)) {
        dispatch('remove', { customerRequestId })

        dispatch('unselect', {
          emptyMerchant: !isMerchant(role),
          hideToast: false,
        })
      } else if (!isStandaloneConversation(context)) {
        // Set the state of the conversation in the list as closed.
        commit('close', { customerRequestId })
      }
    },
    emptyNextConversationPagePath({ commit }) {
      commit('emptyNextConversationPagePath')
    },
    empty({ commit }) {
      commit('resetCount')
      commit('emptyNextConversationPagePath')
      commit('emptyConversations')
      commit('toast/hide', null, { root: true })
    },
    async fetchConversations(
      { commit, dispatch, getters },
      {
        context,
        emptyList = true,
        filters = {},
        role = SAV_PLATFORM_ROLES.merchant,
      },
    ) {
      commit('setLastContext', { context })

      if (emptyList) {
        dispatch('empty')
      }

      const { payload } = await dispatch(
        'http/request',
        {
          request: isReturns(context)
            ? fetchPendingProductReturnCustomerRequestPlatform
            : fetchCustomerRequestPlatformConversations,
          pathParams: {
            context: isMerchant(role) ? context : undefined,
            role: getApiPathFromRole(role),
          },
          queryParams: filters,
        },
        { root: true },
      )

      if (!isEmpty(getters.lastContext) && getters.lastContext !== context) {
        return
      }

      const { count, next: nextPageUrl, results = [] } = payload

      let conversations = results
      // Product returns must be a bit transformed to ressemble other conversations.
      if (isReturns(context)) {
        conversations = results.map(({ claim, ...productReturn }) => ({
          ...claim,
          productReturn,
        }))
      }

      commit('setConversations', { nextPageUrl, conversations })

      if (isMerchant(role)) {
        commit('setCount', { count })

        // Remove the update banner of the context we are loading the list of.
        dispatch(
          'customerRequestPlatform/information/acknowledge',
          { context },
          {
            root: true,
          },
        )
      }

      commit('resetLastContext')
    },
    async storeNextConversations({ commit }, { payload, context }) {
      const { next: nextPageUrl, results = [] } = payload

      let conversations = results
      // Product returns must be a bit transformed to resemble other conversations.
      if (context === SAV_PLATFORM_CONTEXTS.returns) {
        conversations = results.map(({ claim, ...productReturn }) => ({
          ...claim,
          productReturn,
        }))
      }

      commit('addConversations', { nextPageUrl, conversations })
    },
    async opened(
      { commit, dispatch },
      { customerRequestId, context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      if (isMerchant(role)) {
        // Update the global counts (those displayed in the sidebar)
        dispatch(
          'customerRequestPlatform/information/fetchCounts',
          {},
          {
            root: true,
          },
        )
      }

      if (!isStandaloneConversation(context)) {
        // Set the state of the conversation as opened.
        commit('open', { customerRequestId })
      }
    },

    remove({ commit, dispatch }, { customerRequestId }) {
      commit('removeConversation', { customerRequestId })

      // Update the global counts (those displayed in the sidebar)
      dispatch(
        'customerRequestPlatform/information/fetchCounts',
        {},
        {
          root: true,
        },
      )
    },
    async search(
      { dispatch },
      { context, filters = {}, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const nonEmptyFilters = getNonEmptyFilters(filters)

      const searchParams = {
        context,
        filters: nonEmptyFilters,
        role,
      }

      await dispatch('fetchConversations', searchParams)

      return searchParams
    },
    async select(
      { commit, dispatch },
      { customerRequestId, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const promises = [
        dispatch(
          'customerRequestDiscussion/discussion/fetch',
          { customerRequestId, role },
          { root: true },
        ),
        dispatch(
          'customerRequestDiscussion/information/fetchCareFolder',
          { customerRequestId },
          { root: true },
        ),
      ]

      if (!isMerchant(role)) {
        promises.push(
          dispatch(
            'customerRequestPlatform/information/fetchMerchantInformation',
            { customerRequestId },
            { root: true },
          ),
        )
      }

      await Promise.all(promises)

      commit('select', { customerRequestId })
    },
    async removeConversation({ commit }, { customerRequestId }) {
      commit('removeConversation', { customerRequestId })
    },

    async unselect(
      { commit, dispatch },
      { emptyMerchant = false, hideToast = true } = {},
    ) {
      commit('unselect')

      dispatch('customerRequestDiscussion/information/clear', null, {
        root: true,
      })
      dispatch('customerRequestDiscussion/discussion/clear', null, {
        root: true,
      })

      if (emptyMerchant) {
        dispatch('customerRequestPlatform/information/clearMerchant', null, {
          root: true,
        })
      }

      if (hideToast) {
        dispatch('toast/hide', null, { root: true })
      }
    },
    update({ commit }, { customerRequestId, updates, params = {} }) {
      commit('update', { customerRequestId, updates, params })
    },
  },
}
