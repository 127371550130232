export const PAGE_VIEW = 'page_viewed'
export const SCREEN_VIEW = 'screen_viewed'
export const CLICK = 'clicked'
export const HOVER_OVER = 'hover_over'
export const CHANGE = 'changed'
export const PRODUCT_IMPRESSION = 'product_impression'
export const REVIEW_IMPRESSION = 'review_impression'
export const REVIEW_BLOCK_IMPRESSION = 'review_block_impression'
export const ECO_BLOCK_IMPRESSION = 'eco_block_impression'
export const VIDEOS_BLOCK_IMPRESSION = 'videos_block_impression'
export const VIDEO_IMPRESSION = 'video_impression'
export const PURCHASE_PRODUCT = 'purchase_product'
export const PURCHASE_ORDER = 'purchase_order'
export const ADD_TO_CART = 'add_to_cart'
export const PRODUCT_CLICK = 'product_clicked'
export const PRODUCT_BLOCK_IMPRESSION = 'pp_block_impression'
export const FORM_SUBMIT = 'info_submitted'
export const PROMOTION_CLICK = 'promotion_clicked'
export const PROMOTION_IMPRESSION = 'promotion_impression'
export const MODAL = 'modal_viewed'
export const PRODUCT = 'product'
export const CHECKOUT_PAGE_VIEW = 'checkout_page_viewed'
export const PROMPT_NOTIFICATION_IMPRESSION = 'promptNotificationImpression'
export const ERRORS = 'errors'
export const ADD_TO_MY_FAVORITES = 'add_to_my_favorites'
export const FAVORITES_PAGE_VIEW = 'favorites_page_viewed'
export const WEB_PERFORMANCE_ALL = 'web_performances_all'
export const POST_PURCHASE_PRODUCT = 'post_purchase_product'
export const TOAST_VIEWED = 'toast_viewed'
export const PAGINATION_CLICKED = 'pagination_clicked'
export const MEGA_MENU_IMPRESSION = 'mega_menu_impression'

// BO SELLER SPECIFICS EVENTS
export const CREATE_LISTING = 'create_listing'
export const SELL_ITEM_CLICKED = 'sell_item_clicked'
export const BACKOFFICE_FILTERS_USED = 'backoffice_filters_used'
export const DASHBOARDS_ACTIONS_CLICKED = 'dashboards_actions_clicked'
