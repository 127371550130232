import { saveAs } from 'file-saver'
import isMobile from 'ismobilejs'

/**
 * Download a file on the client-side.
 * Should be used when the file comes directly from badoom.
 *
 * We use the FileSaver.js library because of browser compatibility.
 *
 * @param data - The actual file data blob.
 * @param filename - The optional name of the file to be downloaded.
 */
export const download = saveAs

/**
 * Identify is the user is browsing from a mobile device, using the browser's user agent.
 * /!\ A word of caution. This helper should not be used for feature support detection.
 * see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 *
 * @param {string} userAgent
 * @return {Boolean}
 */
export function isMobileDevice(userAgent = '') {
  return isMobile(userAgent).any
}
