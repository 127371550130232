import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { stringify } from 'query-string'

import { getBcp47LocaleFrom } from '@core/helpers/countries'

import { ErrorType, LinkType, Methods, specUrl } from './constant'

const onError = (type) => {
  switch (type) {
    case ErrorType.Hash: {
      throw new Error(
        `Arg. The 'hash' property of an internal link is a string, and not an object as defined in the specifications. As always, the documentation is here to help: ${specUrl}`,
      )
    }

    case ErrorType.Href: {
      throw new Error(
        `Oops, sorry. The 'href' property of an external link is mandatory. If you ever need help, you can refer to the documentation:: ${specUrl}`,
      )
    }

    case ErrorType.Link: {
      throw new Error(
        `Oops, sorry. The 'type' property of a link is a mandatory string ('internal' or 'external'). If you ever need help, you can refer to the documentation:: ${specUrl}`,
      )
    }

    default:
      throw new Error(
        `Oops, sorry. The link object have an issue. If you ever need help, you can refer to the documentation:: ${specUrl}`,
      )
  }
}

export const createInterceptor = (locale) => ({
  get(router, method) {
    /*
    "We must therefore intercept get and return a function that intercepts the function call"
    Proxy doc: https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Proxy
    Handler get doc : https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Proxy/handler/get
    */
    return (...args) => {
      if (Object.values(Methods).includes(method)) {
        const [link = {}, ...otherArgs] = args

        // Do nothing
        if (typeof link === 'string') {
          return router[method](...args)
        }

        // External Link
        if (link.type === LinkType.External) {
          if (!link.href) {
            return onError(ErrorType.Href)
          }

          if (method === Methods.Resolve) {
            return link
          }

          return window.location.assign(link.href)
        }

        // No Link provided
        if (link.type !== LinkType.Internal && isEmpty(link.name)) {
          return onError(ErrorType.Link)
        }

        // Internal Link
        if (link.hash && typeof link.hash !== 'object') {
          return onError(ErrorType.Hash)
        }

        // Support locale automatically in the path
        // eslint-disable-next-line no-param-reassign
        link.params = {
          locale: getBcp47LocaleFrom(locale),
          ...link.params,
        }

        return router[method](
          {
            ...omit(link, 'type'),
            hash: link.hash ? `#${stringify(link.hash)}` : undefined,
          },
          ...otherArgs,
        )
      }

      return router[method](...args)
    }
  },
})

export const navigationPlugin = {
  install(VueInstance, { router, store }) {
    const locale = store.getters['config/locale']
    // eslint-disable-next-line no-param-reassign
    VueInstance.prototype.$navigation = new Proxy(
      router,
      createInterceptor(locale),
    )
  },
}

export default ({ app: { router }, store }, inject) => {
  const locale = store.getters['config/locale']
  inject('navigation', new Proxy(router, createInterceptor(locale)))
}
